export const ACCOUNT = "ACCOUNT";
export const CAMPAIGN = "CAMPAIGN";
export const ADGROUP = "ADGROUP";
export const TAG = "TAG";

export const POST = "POST";
export const PAGE = "PAGE";

export const EVENT_TRACKING = "EVENT_TRACKING";
export const GOAL_CONVERSION = "GOAL_CONVERSION";
export const PAGE_TRACKING = "PAGE_TRACKING";
export const SOCIAL_INTERACTION = "SOCIAL_INTERACTION";
export const TRAFFIC_SOURCE = "TRAFFIC_SOURCE";
export const USER = "USER";
export const VIEW = "VIEW";