import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useTranslation } from "react-i18next";
import { ALERT_STATUS, Metric, Trend, Variable, VisualizerData } from "../../editor/helpers/sentry-template";
import { generateLabel, getColor } from "../helper";

const TrendVisualizer = ({ variable, response }: { response: VisualizerData, variable: Metric }) => {
  const data: any = {};

  const { t } = useTranslation();

  const reference: any = {};

  let first: string | null = null;


  Object.keys(response).forEach(date => {
    data[date] = {
      time: +(new Date(date))
    };

    reference[+(new Date(date))] = {
      time: +(new Date(date))
    };

    response[date].data.forEach((value) => {
      const identifier = value.identifierName ? value.identifierName : generateLabel(value.identifications);

      if (!first) {
        first = identifier;
      }
      data[date][identifier] = value.values[0].value;
      data[date][`${identifier} - trends`] = value.values[1].value;
      reference[+(new Date(date))][identifier] = {
        status: value.status
      };
    });
  });

  const [visualized, setVisualized] = useState<Array<string>>([first as unknown as string]);


  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomDot = (props: any) => {
    if(!reference[props.payload.time][props.dataKey]){
      return <circle {...props} />
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isError = (reference[props.payload.time][props.dataKey].status as ALERT_STATUS) === "ANOMALY";
    // eslint-disable-next-line react/destructuring-assignment
    return <circle {...props} fill={isError ? "red" : props.fill} stroke={isError ? "red" : props.stroke} />;
  };
  return (
    <>
      <div className="h-3/4 border rounded-lg border-gray-200 w-full flex flex-col">
        <div className="border-b flex items-center pl-4" style={{ height: "10%" }}>
          <h2 className="font-medium">{t("Graph")}</h2>
        </div>
        <div style={{ height: "90%" }} className="py-4 pr-4 pt-6">
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={Object.values(data)}>
              <YAxis
                fill="#626266"
                stroke="#626266"
                axisLine={{ stroke: "none" }}
                tickLine={{ stroke: "none" }}
              />
              <CartesianGrid vertical={false} strokeWidth={1} />
              <XAxis
                stroke="#626266"
                tickFormatter={timeStr => new Date(timeStr).toLocaleDateString(undefined, {
                  month: "short",
                  "day": "2-digit"
                })}
                dataKey="time"
                scale="time"
                type="number"
                domain={["dataMin", "dataMax + 1"]}
                strokeWidth={1}
              />
              <Tooltip
                labelFormatter={label => new Date(label).toDateString()}
              />
              {Object.keys(Object.values(data)[0] as never).filter(key => key !== "time"  && visualized.includes(key)).map((key, index) => <Line
                dot={<CustomDot />} strokeWidth="2" type="monotone" dataKey={key} stroke={getColor(index)} />)}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="border rounded-lg border-gray-200 w-full flex flex-col mt-4">
        <div className="border-b flex items-center p-4">
          <h2 className="font-medium">{t("Legend")}</h2>
        </div>
        <div className="p-4">
          {
            Object.keys(Object.values(data)[0] as never).filter(key => key !== "time").map((key) => {
              return <div className="pb-4">
                <div className="flex items-center h-5 ">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        className="cursor-pointer focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        checked={visualized.includes(key)}
                        onClick={e => {
                          if (visualized.includes(key)) {
                            setVisualized(visualized.filter(elem => elem !== key));
                          } else {
                            setVisualized([...visualized, key]);
                          }
                        }}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label className="font-medium text-gray-700">
                        {key}
                      </label>
                    </div>
                  </div>
                </div>
              </div>;
            })
          }
        </div>
      </div>
    </>
  );
};

export default TrendVisualizer;
