export default {
  api: {
    endpoint: "https://api.catchr.io",
    front_path: "catchr.io",
  },
  google_analytics: "UA-145061176-1",
  external_routing: {
    adwords: "https://ads.google.com/",
    "google-merchant-center": "https://merchants.google.com/",
  },
  STRIPE_PUBLIC: "pk_live_KK7R770Zvrx2mhsHOCbdd3iP00vnHWROxR",
  segment_key: "gTwWvxYm1BVPSjuGl0JAxIt1rmR75H0Y",
  mixpanel: "94e2d19523c6d337d32ced5cde9eec87",
  stripe: {
    plans: {
      agency: {
        monthly: {
          EUR: {
            id: "price_1NYVKsFPzu4LrOETwWNozvnN",
            value: 149.99
          },
          USD: {
            id: "price_1NYVKsFPzu4LrOETwWNozvnN",
            value: 164.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYVKsFPzu4LrOETDcoNGzbN",
            value: 1449.90
          },
          USD: {
            id: "price_1NYVKsFPzu4LrOETDcoNGzbN",
            value: 1649.90
          },
        },
      },
      business: {
        monthly: {
          EUR: {
            id: "price_1NYVHzFPzu4LrOETJM8l4jHV",
            value: 74.99
          },
          USD: {
            id: "price_1NYVHzFPzu4LrOETJM8l4jHV",
            value: 84.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYVHzFPzu4LrOETRGUCqq7b",
            value: 749.90
          },
          USD: {
            id: "price_1NYVHzFPzu4LrOETRGUCqq7b",
            value: 849.90
          },
        },
      },
      medium: {
        monthly: {
          EUR: {
            id: "price_1NYVESFPzu4LrOETTfrFLx2J",
            value: 49.99
          },
          USD: {
            id: "price_1NYVESFPzu4LrOETTfrFLx2J",
            value: 54.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYVESFPzu4LrOETYg2Aohkv",
            value: 499.90
          },
          USD: {
            id: "price_1NYVESFPzu4LrOETYg2Aohkv",
            value: 549.90
          },
        },
      },
      small: {
        monthly: {
          EUR: {
            id: "price_1NYVAlFPzu4LrOETKXARMTDW",
            value: 32.99
          },
          USD: {
            id: "price_1NYVAlFPzu4LrOETKXARMTDW",
            value: 35.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYVAlFPzu4LrOETyAnLef7y",
            value: 329.90
          },
          USD: {
            id: "price_1NYVAlFPzu4LrOETyAnLef7y",
            value: 359.90
          },
        },
      },
      basic: {
        monthly: {
          EUR: {
            id: "price_1NYUNGFPzu4LrOET8rXOjIaz",
            value: 23.99
          },
          USD: {
            id: "price_1NYUNGFPzu4LrOET8rXOjIaz",
            value: 25.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYUNGFPzu4LrOET4N8kPll5",
            value: 239.90
          },
          USD: {
            id: "price_1NYUNGFPzu4LrOET4N8kPll5",
            value: 259.90
          },
        },
      },
      starter: {
        monthly: {
          EUR: {
            id: "price_1NYTayFPzu4LrOETrmrdR2VB",
            value: 9.99
          },
          USD: {
            id: "price_1NYTayFPzu4LrOETrmrdR2VB",
            value: 11.99
          },
        },
        annual: {
          EUR: {
            id: "price_1NYTayFPzu4LrOETQyzfUVQa",
            value: 99.90
          },
          USD: {
            id: "price_1NYTayFPzu4LrOETQyzfUVQa",
            value: 119.90
          },
        },
      },
    },
  },
};
