import React from "react";
import DataStreamForm from "../containers/form";

const CreateDataStream = () => (
    <div className="w-full py-4 flex justify-center">
      <DataStreamForm />
    </div>
  );

export default CreateDataStream;
