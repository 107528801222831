import API from "../../api/http/client";
import NetworkAuthorizationModel from "../../models/http/network-authorization/network-authorization";
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";

class NetworkAuthorizationAPI extends API{
  serializer = () => ({
    _getAll: NetworkAuthorizationModel,
    _getOne: NetworkAuthorizationModel,
    _put: NetworkAuthorizationModel,
    _delete: DeleteSerializer
  });
}

export default Proxy<NetworkAuthorizationAPI>(new NetworkAuthorizationAPI('/network_authorizations'));