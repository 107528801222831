import React from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import ClientContainer from '../../ui/containers/client';
import {useDataSourceClient} from "../../hooks/datasource";
import Loading from "../loading";
import Resume from "./containers/resume";
import Create from "../alerts/editor";
import ListingIndex from "../alerts/listing";
import SpecificListingIndex from "../alerts/specific";
import DetailFactory from "../alerts/details";
import Settings from "./containers/settings";
import AsyncLoader from "../../ui/containers/async-loader";
import { useSentryGet } from "../../hooks/alert";
import { sentryNetworkDeSerializer } from "../../services/repository/alerts/alert";
import { SentryRoutes } from "../../index";

const Client = () => {
    // eslint-disable-next-line camelcase
    const { client_id  } = useParams();

    // eslint-disable-next-line camelcase
    const {isLoading, data: datasource} = useDataSourceClient((client_id as unknown as number))
    if(isLoading){
        return <Loading />
    }

    if(datasource){
        return (
            <ClientContainer dataSource={datasource}>
                <SentryRoutes>
                    <Route path="/anomalies" element={<ListingIndex dataSource={datasource} type="metric"/>} />
                    <Route path="/options/*" element={<SpecificListingIndex dataSource={datasource}/>} />
                    <Route path="/pacing" element={<ListingIndex dataSource={datasource}  type="pacing"/>} />
                    <Route path="/settings" element={<Settings dataSource={datasource} />} />
                    <Route path="/alerts/create/default" element={<Create dataSource={datasource} template={false} mode="default"/>} />
                    <Route path="/alerts/create/pacing" element={<Create dataSource={datasource} template={false}  mode="pacing"/>} />
                    <Route path="/alerts/edit/:alert_id" element={
                        <AsyncLoader hookFunc={(params) => useSentryGet(params.alert_id as unknown as number)} render={data => {
                            return <Create dataSource={datasource} template={false} inititialValues={sentryNetworkDeSerializer(data.data as any)} />
                            }
                        } />
                    } />
                    <Route path="/details/:configured_for_id" element={<DetailFactory dataSource={datasource}/>} />
                    <Route path="/" element={<Resume dataSource={datasource}/>} />
                </SentryRoutes>
            </ClientContainer>
        );
    }

    return <div>Unable to load</div>;
};

export default Client;
