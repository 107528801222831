import { useMutation, useQuery } from "react-query";
import NetworkAuthorizationAPI from "../../services/repository/network-authorization/network-authorization";
import NetworkAuthorizationInformationAPI
  from "../../services/repository/network-authorization/network-authorization-information";
import Pagination from "../../services/models/http/paginated";
import NetworkAuthorization from "../../services/models/http/network-authorization/network-authorization";
import NetworkAuthorizationInformation
  from "../../services/models/http/network-authorization/network-authorization-information";
import AuthorizeAPI from "../../services/repository/network-authorization/authorize";
import Authorize from "../../services/models/http/network-authorization/authorize";

export const useGetAllNetworkAuthorization = () => useQuery(["network-authorization"], (): Promise<Pagination<NetworkAuthorization>> => NetworkAuthorizationAPI._getAll({ pagination: false }));
export const useGetAllNetworkAuthorizationSummary = () => useQuery(["network-authorization", "summary"], (): Promise<Pagination<NetworkAuthorization>> => NetworkAuthorizationAPI._getAll({
  pagination: false,
  query: { summary: true }
}));
export const useNetworkAuthorizationAutoFetch = (id: number) => useQuery(["network-authorization", id], (): Promise<NetworkAuthorization> => NetworkAuthorizationAPI._getOne(id), { refetchInterval: 5000 });
export const useNetworkAuthorization = (id: number) => useQuery(["network-authorization", id], (): Promise<NetworkAuthorization> => NetworkAuthorizationAPI._getOne(id));
export const useGetAllNetworkAuthorizationInformation = () => useQuery(["network-authorization-information"], (): Promise<Pagination<NetworkAuthorizationInformation>> => NetworkAuthorizationInformationAPI._getAll());
type PostData = {
  platform: string,
  code: string,
  name: string,
  mode?: string,
  options: any
} | {
  platform: string,
  key: string,
  secret: string,
  name: string,
  mode?: string,
  options: any
} | {
  platform: string,
  oauthVerifier: string,
  oauthToken: string,
  name: string,
  mode?: string,
  options: any
};
export const useMutatePostAuthorizeOAuth2 = () => useMutation<Authorize, unknown, PostData>(({
                                                                                               platform,
                                                                                               mode,
                                                                                               ...rest
                                                                                             }) => AuthorizeAPI._authorize(platform, rest, mode));
type UpdateData = { id: number, platform: string, code: string, options: any } | {
  id: number,
  platform: string,
  key: string,
  secret: string,
  options: any
} | {
  id: number,
  platform: string,
  oauthVerifier: string,
  oauthToken: string,
  mode?: string,
  options: any
};
export const useMutatePutAuthorizeOAuth2 = () => useMutation<Authorize, unknown, UpdateData>(({
                                                                                                id,
                                                                                                platform,
                                                                                                ...rest
                                                                                              }) => AuthorizeAPI._refreshToken(id, platform, rest));
export const useMutatePutNetworkAuthorization = () => useMutation<NetworkAuthorization, unknown, {
  id: number,
  data: { name: string }
}>(({ id, data }) => NetworkAuthorizationAPI._put(id, data));
export const useMutateDeleteAuthorization = (id: number) => useMutation(() => NetworkAuthorizationAPI._delete(id));
