import production_env from "./production";
import development_env from "./development";
import stage_env from "./stage";

type DEFAULT_CONFIG = {
  api: {
    endpoint: string,
    front_path: string,
  },
  google_analytics: string,
  external_routing: {
    adwords: string
    "google-merchant-center": string,
  },
  STRIPE_PUBLIC: string,
  segment_key: string,
  mixpanel: string,
  stripe: {
    plans: {
      agency: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
      business: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
      starter: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
      medium: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
      small: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
      basic: {
        monthly: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        },
        annual: {
          EUR: {
            id: string,
            value: number
          },
          USD: {
            id: string,
            value: number
          },
        }
      },
    },
  },
};

type ENV = "production" | "development";

declare var process: {
  env: {
    REACT_APP_ENV: ENV
  }
};


const env = process.env.REACT_APP_ENV;

const env_dictionary_loader: {
  production: DEFAULT_CONFIG,
  development: DEFAULT_CONFIG,
} = {
  "production": production_env,
  "development": development_env
};

const default_config = {};

export default Object.assign(
  {},
  default_config,
  env ? env_dictionary_loader[env] : development_env
);
