import DataSource from "../../../../services/models/http/datasource/data-source-client";
import { useTranslation } from "react-i18next";
import React from "react";
import Table from "../../../../ui/components/table";
import Async from "../../../../ui/helper/async";
import { classNames } from "../../../../services/helper";
import { useGetAllSecurity } from "../../../../hooks/alert/security";

const Status = ({value} : {value: boolean}) => <span className={classNames(
    !value ? "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800" : "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"
  )}>{!value ? "Not Verified" : "Verified"}</span>

const Security = ({dataSource}: {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const columns = React.useMemo(() => [
    {
      Header: t('NDD'),
      accessor: "ndd",
      sticky: 'left'
    },
    {
      Header: t('Verified'),
      accessor: "verified",
      Cell: Status
    },
  ], []);
  const securities = useGetAllSecurity(dataSource.getAccountId(), dataSource.getPlatform())
  return (
    <div className="mx-8 my-8 flex flex-col space-y-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Summary')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <Async {...securities}>
            {securities.data && !securities.isLoading ?
              <>
                <div key="analyzed" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("Total")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{securities.data.pagination.totalItems}</dd>
                </div>
                <div key="in_error" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("Not verified")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{securities.data.data.filter(elem => elem.getVerifiedAt() === undefined).length}</dd>
                </div>
              </>
              : null}
          </Async>
        </dl>
      </div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t('Details')}</h3>
        <Table data={(securities.data?.data || []).map(elem => ({ndd: elem.getNdd(), verified: elem.getVerifiedAt() !== undefined }))} columns={columns} loading={securities.isLoading}/>
      </div>
    </div>
  );
}

export default Security;