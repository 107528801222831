import API from "../../api/http/client";
import FieldTranslation from "../../models/http/google-datastudio/field-translation";
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";

class FieldTranslationAPI extends API {

  serializer = () => ({
      _getAll: FieldTranslation,
      _put: FieldTranslation,
      _postOne: FieldTranslation,
      _delete: DeleteSerializer
    });

}

export default Proxy<FieldTranslationAPI>(new FieldTranslationAPI('/google-datastudio/field-translations'));