import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import {KLAVIYO, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {EMAILING} from "../../constant/constant";

class Klaviyo extends BaseNetwork{
    connectingMethod = (): LinkMethod => APIKEY;

    getName = (): string => "Klaviyo";

    getNetworkIdentifier = (): NETWORK => KLAVIYO;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => EMAILING;

    isETLActivated(): boolean {
        return false;
    }

    alertOptions = () =>  [];

}

export default Klaviyo;