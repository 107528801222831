import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { ArrowRightIcon, CheckIcon, PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import {components, GroupBase, MenuListProps, createFilter} from "react-select";
import SelectAsync from 'react-select/async'
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../services/helper";
import { usePostDataSourceMutation, usePutDataSourceMutation } from "../../../hooks/datasource";
import { useGetAllNetworkAuthorization } from "../../../hooks/network-authorization";
import NetworkProvider from "../../../config/network/network-provider";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useActivateMutation, useGetAllTemplate } from "../../../hooks/alert";
import routing from "../../../routing";

import Async from "../../../ui/helper/async";
import { NETWORK } from "../../../constant/network";
import TemplateListing from "../../../services/models/http/alert/template-listing";
import BaseNetwork from "../../../config/network/base-network";


type DataSourceLocal = { id: number, platform: string, name: string };

const Preview = ({dataSource, templates}: {dataSource: DataSourceLocal, templates: Array<TemplateListing>}) => {
  const {t} = useTranslation();
  const source = NetworkProvider.getByPlatform(dataSource.platform) as BaseNetwork;
  const activate = useActivateMutation();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setSaving(true)
    try{
      const response = await Promise.all(templates.map(elem => activate.mutateAsync({sentryId: elem.getId(), dataSourceId: dataSource.id})));
      NotificationManager.success(t('Account protected.'), t('Your account is now protected.'));
      navigate(routing.user.client.replace(":client_id", dataSource.id.toString()));
    }catch(e){
      NotificationManager.error(t('Unable to save the configuration.'), t('There is an internal error system.'));
    }finally {
      setSaving(false);
    }

  }
  return <div className="max-w-2xl bg-white shadow overflow-hidden sm:rounded-lg w-full">
    <div className="px-4 py-5 sm:px-6 w-full">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Preview')}</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Once everything is good, you can submit the creation.')}</p>
    </div>
    <div className="border-t border-gray-200 sm:p-0 w-full">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">{t('Datasource : ')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center "><img src={source.networkImage()} className="h-5 w-5 mr-2" /> {dataSource.platform}</dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">{t('Alerts : ')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {templates.length > 0 ?
              <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {templates.map(elem =>  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <span className="ml-2 flex-1 w-0 truncate">{elem.getName()}</span>
                    </div>
                  </li>
                )}
              </ul> : <div>{t('No templates provided')}</div>}

          </dd>
        </div>
      </dl>
    </div>
    <div className=" px-6 py-5 border-t border-gray-200">
      <Button onClick={onSubmit} loading={saving} type="primary" className="w-full justify-center">{t("Save")}</Button>
    </div>
  </div>
}

const ActivateAlert = ({dataSource, onSubmit} : {dataSource: DataSourceLocal, onSubmit: (templates: Array<TemplateListing>) => void}) => {
  const { t } = useTranslation();
  const templates = useGetAllTemplate(dataSource.platform as NETWORK, dataSource.id);
  const [search, setSearch] = useState<string|undefined>(undefined);

  const [selected, setSelected] = useState<Array<TemplateListing>>([]);
  return <div className="max-w-xl space-y-4">
    <h1 className="text-center text-2xl font-bold leading-7 text-gray-900">{t('Choose alert you want to activate on your account.')}</h1>
    <p className="text-gray-500 text-sm text-center">
      {t('You can always activate alert (or create) later on your wish.')}
    </p>
    <Async {...templates}>
      <div>
        <input
          type="text"
          name="search"
          id="search"
          value={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder={t('Search for a template')}
        />
        {templates.data && templates.data.data.length === 0 ? <div className="flex items-center justify-center flex-col mt-4">
          <h3 className="mt-2 text-sm font-medium text-gray-900">{t('You have no template')}</h3>
          <p className="mt-1 text-sm text-gray-500">{t('No template yet ? Skip this step and clicking on "Next".')}</p>
        </div> : null}
        {templates.data && templates.data.data.length > 0 ? <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">

          {templates.data?.data.filter(elem => search === undefined || elem.getName().indexOf(search) !== -1 ).map((item, itemIdx) => (
            <li key={itemIdx}>
              <div className="relative group py-4 flex items-center space-x-3">
                <div className="min-w-0 flex-1">
                  <div className="text-sm font-medium text-gray-900">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.getName()}
                  </div>
                </div>
                <div className="flex-shrink-0 self-center">
                  <Switch
                    onChange={(e) => {
                      if(e) {
                        setSelected([...selected, item])
                      }else{
                        setSelected(selected.filter(elem => elem.getId() !== item.getId()))
                      }
                    }}
                    checked={selected.some(elem => elem.getId() === item.getId())}
                    className={classNames(
                      selected.some(elem => elem.getId() === item.getId()) ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        selected.some(elem => elem.getId() === item.getId()) ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </li>
          ))}
        </ul> : null}
        <div className="flex w-full justify-end mt-4">
          <Button
            type="primary"
            onClick={() => {
              onSubmit(selected)
            }}
          >
            {t('Next')} <ArrowRightIcon className="h-5 w-5 ml-2" />
          </Button>
        </div>
      </div>

    </Async>
  </div>
}

const SelectDataSource = ({onSubmit} : {onSubmit: (id: DataSourceLocal) => void}) => {
  const { t } = useTranslation();
  const networkAuthorization = useGetAllNetworkAuthorization();
  const [selectedDataSource, setSelectedDataSource] = useState<any>(undefined);
  const postMutation = usePostDataSourceMutation({});
  const putMutation = usePutDataSourceMutation();
  const [submitting, setSubmitting] = useState(false);
  const onClick = async (v: any) => {
    setSubmitting(true);
    if(v.dataSource){
      try {
        const response = await putMutation.mutateAsync({
          id: v.dataSource.id,
          data: {
            activateAlert: true
          }
        })
        onSubmit({ id: v.dataSource.id, platform: v.platform, name: v.dataSourceName });
      }catch (e){
        NotificationManager.error(t('Unable to create the DataSource for our alerting system.'), t('Please try again or speak to our account manager.'));
      }
    }else{
      try {
        const response = await postMutation.mutateAsync({
          accountId: v.accountId,
          name: v.dataSourceName,
          activateAlert: true,
          authorization: `/api/network_authorizations/${v.authorization_id}`,
        })
        onSubmit({id: response.getId(), platform: v.platform, name: response.getName()});
      }catch (e){
        NotificationManager.error(t('Unable to create the DataSource for our alerting system.'), t('Please try again or speak to our account manager.'));
      }
      finally {
        setSubmitting(false);
      }
    }
  }

  const options = networkAuthorization.data?.data
    .filter(elem => NetworkProvider.getByPlatform(elem.getPlatform()) !== undefined && NetworkProvider.getByPlatform(elem.getPlatform())?.isAlertActivated())
    .flatMap(elem => {
      const accounts = [];
      return elem.getAvailableDataSource().map(dataSource => {
        const label = <span className="flex items-center"><img className="h-6 mr-1" alt={elem.getPlatform()}
                                                               src={NetworkProvider.getByPlatform(elem.getPlatform())?.networkImage()} />{dataSource.name}</span>;
        return {platform: elem.getPlatform(), accountId: dataSource.accountId, dataSourceName: dataSource.name, authorization_id:elem.getId(), dataSource: elem.getDataSources().find(activatedDataSource => activatedDataSource.accountId === dataSource.accountId),  value: dataSource.accountId, label };
      });
    });

  // eslint-disable-next-line react/no-unstable-nested-components
  const SelectMenuButton = (props: JSX.IntrinsicAttributes & MenuListProps<unknown, boolean, GroupBase<unknown>>) => (
      <components.MenuList  {...props}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {props.children}
        <button className="w-full py-2 text-gray-500 flex items-center justify-center hover:bg-gray-50"><PlusIcon className="h-5 w-5 mr-2" />Add new source</button>
      </components.MenuList >
    )

  const promiseOptions = (inputValue: string) => new Promise((resolve) => {
      resolve((options as Array<any>).filter(elem => elem.dataSourceName.toLowerCase().includes(inputValue.toLowerCase())).slice(0,100))
    })
  return <div className="max-w-xl space-y-4">
    <h1 className="text-center text-2xl font-bold leading-7 text-gray-900">{t('Select an account')}</h1>
    <p className="text-gray-500 text-sm text-center">
      {t('The first step is to select the account you want to work on. If you didn\'t find it, you need to add a new source. If you have more than 100 accounts, the first 100 are displayed and you need to search for the account.')}
    </p>
    <div className="space-y-4">
      <SelectAsync
        classNamePrefix="react-select"
        isLoading={networkAuthorization.isLoading}
        cacheOptions
        defaultOptions={options && (options as Array<any>).length > 100 ? (options as Array<any>).slice(0,  100) : options}
        components={{ MenuList: SelectMenuButton }}
        loadOptions={promiseOptions}
        onChange={(v: any) => {
          setSelectedDataSource(v)
        }}
      />
      <div className="flex w-full justify-end">
        <Button
          disabled={!selectedDataSource}
          type="primary"
          loading={submitting}
          onClick={() => {
            if(selectedDataSource) {
              onClick(selectedDataSource)
            }
          }}
        >
          {t('Next')} <ArrowRightIcon className="h-5 w-5 ml-2" />
        </Button>
      </div>
    </div>
  </div>
}

const AddADataSource = () => {

  const steps : Array<{name: string, id: "create" | "activate" | "preview"}> = [
    { name: 'Create account', id: "create"},
    { name: 'Activate alert', id: "activate"},
    { name: 'Preview', id: "preview"},
  ]

  const [currentStep, setCurrentStep] = useState({
    create: 'current',
    activate: 'upcoming',
    preview: 'upcoming',
  });

  const [dataSource, setDataSource] = useState<DataSourceLocal|undefined>(undefined);
  const [templates, setTemplates] = useState<Array<TemplateListing>>([]);

  return (
    <div className="h-screen flex ">
      <div className="w-1/5 bg-indigo-600 h-full flex justify-center pt-8 ">
        <nav aria-label="Progress">
          <ol role="list" className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                {currentStep[step.id] === 'complete' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-white" aria-hidden="true" />
                    ) : null}
                    <span className="relative flex items-start group">
                      <span className="h-9 flex items-center">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full border-2 border-white group-hover:bg-indigo-800">
                          <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                      </span>
                          <span className="ml-4 mt-2 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-white">{step.name}</span>
                      </span>
                    </span>
                  </>
                ) : currentStep[step.id] === 'current' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-white" aria-hidden="true" />
                    ) : null}
                    <span className="relative flex items-start group" aria-current="step">
                      <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                          <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 mt-2 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-white">{step.name}</span>
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-white" aria-hidden="true" />
                    ) : null}
                    <span className="relative flex items-start group">
                      <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white  rounded-full group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                        </span>
                      </span>
                          <span className="ml-4 mt-2 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-white">{step.name}</span>
                      </span>
                    </span>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="w-4/5 h-full flex justify-center items-center">
        {
          currentStep.create === "current" ? <SelectDataSource onSubmit={(v) => {
            setDataSource(v);
            setCurrentStep({
              ...currentStep,
              create: "complete",
              activate: "current"
            })
          }} /> : null}
        {
          currentStep.activate === "current" && dataSource? <ActivateAlert dataSource={dataSource} onSubmit={(t) => {
            setTemplates(t);
            setCurrentStep({
              ...currentStep,
              activate: "complete",
              preview: "current"
            })
          }} /> : null}{
          currentStep.preview === "current" && dataSource? <Preview dataSource={dataSource} templates={templates} /> : null}
      </div>
    </div>
  );
};

export default AddADataSource;
