import React, { useState } from "react";
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon, TrashIcon
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { Multiselect } from "multiselect-react-dropdown";
import CompanyModel from "../../../services/models/http/company/company";
import Button from "../../../ui/components/button/button";
import { useDeleteSentryMutation, useGetAllTemplateListing, useTemplateHealthGet } from "../../../hooks/alert";
import Async from "../../../ui/helper/async";
import routing from "../../../routing";
import Modal, { DefaultBottom, Title } from "../../../ui/components/modal";
import { useDataSourceListing } from "../../../hooks/datasource";
import NetworkProvider from '../../../config/network/network-provider';
import SentryListing from "../../../services/models/http/alert/sentry-listing";
import TemplateHealth from "../../../services/models/http/alert/template-health";
import { t } from "i18next";
import { classNames } from "../../../services/helper";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import Pagination from "../../../services/models/http/paginated";
import Field from "../../../services/models/http/google-datastudio/field";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useQueryClient } from "react-query";

type FormValue = {
  dataSource: number
}

const Validation = Yup.object().shape({
  dataSource: Yup.number().required()
})

const TemplateDetail = ({sentry}: {sentry: SentryListing}) => {
  const templateHealth = useTemplateHealthGet(sentry.getId());
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();
  const deleteSentryMutation = useDeleteSentryMutation();
  const deleteSentry = async () => ConfirmManager.confirm({
    title: t("Confirm delete a the template"),
    description: t("Are you sure you want to delete this template ? All corresponding alert will be deleted."),
    validButton: t("Delete"),
    cancelButton: t("Cancel"),
    typeImg: "danger",
    onValid: async () => {
      setSaving(true);
      try {
        await deleteSentryMutation.mutateAsync(sentry.getId());
        await queryClient.cancelQueries(['alert-template-listing']);
        queryClient.setQueriesData(['alert-template-listing'], (old) => (old as Pagination<SentryListing>).deleteElement(sentry));
        NotificationManager.success(t('Your template has been successfully deleted'), "");
      } catch(e) {
        NotificationManager.error(t('Unable to delete the template.'), t('An error occurred. Please try again.'))
      }
      setSaving(false);
    }
  });

  return  <div className="px-4 py-4" >
    <Async {...templateHealth}>
      <>
      <div className="flex justify-between items-center mb-4">
        <div className="text-sm text-gray-500">
          {t('You cannot modify a template.')}
        </div>
        <div>
          <Button type="danger" loading={saving} onClick={() => deleteSentry()}>
            <TrashIcon className="h-5 w-5 mr-2" />
            {t('Delete')}
          </Button>
        </div>
      </div>
      <ul className="bg-white shadow overflow-hidden sm:rounded-md divide-y divide-gray-200">
        {templateHealth.data?.getAccounts().map((elem: any, index: number) => <li key={index}>
          <Link to={routing.user.details.replace(":client_id", elem.dataSourceId).replace(":configured_for_id", elem.configuredForId)} className="block hover:bg-gray-50">
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-indigo-600 truncate">{elem.dataSourceName}</p>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className={classNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800",
                    elem.status === "ANOMALY" ? "bg-red-100 text-red800" : "")}>
                    {t(elem.status)}
                  </p>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">

                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <p>
                    {t('Last analyze on : ')} {elem.lastAnalyze ? <time dateTime={elem.lastAnalyze}>{new Intl.DateTimeFormat().format(elem.lastAnalyze)}</time> : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </li>)}
      </ul>
      </>
  </Async>
  </div>
}

const Template = ({sentry}: {sentry: SentryListing}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  return <li className="col-span-1 flex flex-col text-center bg-white rounded-r-lg shadow divide-y divide-gray-200 mb-4">
    <div className="flex items-center py-3 px-4">
      <div className="min-w-0 flex-1">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ShieldCheckIcon className="h-12 text-green-500"/>
          </div>
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-purple-600 truncate text-left">{sentry.getName()}</p>
            </div>
          </div>
        </div>
        <div className="min-w-0 flex-1 px-4 pt-4">
          <div>
            <p className="text-sm text-left text-gray-500">{t('This is used by {{value}} accounts currently.', {value: sentry.getConfiguredFor().length})}</p>
          </div>
        </div>
      </div>
      <div className="cursor-pointer">
        {open ? <ChevronDownIcon
          onClick={() => {
            setOpen(false)
          }}
          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
          aria-hidden="true"
        /> : <ChevronRightIcon
          onClick={() => {
            setOpen(true)
          }}
          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
          aria-hidden="true"
        /> }
      </div>
    </div>
    {open ? <TemplateDetail sentry={sentry} /> : null}
  </li>
}

const AlertTemplateListing = ({company}: {company: CompanyModel}) => {
  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const alertTemplates = useGetAllTemplateListing(company.getId());

  const dataSource = useDataSourceListing({mode: 'listing'});

  const navigate = useNavigate();
  const form = useFormik<Partial<FormValue>>({
    initialValues: {
      dataSource: undefined
    },
    validationSchema: Validation,
    onSubmit : (values) => {
      navigate(routing.user.company_template_create.replace(':id', (values.dataSource as number).toString()))
    }
  });

  return (

    <div className="divide-y divide-gray-200 lg:col-span-9">
      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        bottom={<DefaultBottom
          disabledOkButton={!(form.dirty && form.isValid)}
          onClose={() => setVisible(false)}
          titleButtonOk={t('Next')}
          onOk={() => form.submitForm()}
          titleButtonClose={t('Close')}
        />}
      >
        <Title title={t('Select a data source to create a template')} />
        <p className="mb-8">{t('You need to select a data source to have real data to create your template.')}</p>
        <Multiselect
          options={dataSource.data?.data.map(elem => ({title: `${NetworkProvider.getByPlatform(elem.getPlatform())?.getName()} - ${elem.getName()}`, id: elem.getId()}))}
          displayValue="title"
          onSelect={(list, elem) => form.setFieldValue('dataSource', elem.id)}
          singleSelect
          loading={dataSource.isLoading}
        />
        <p className="mt-3 text-sm text-gray-700">{t("This will not create an alert on the account selected, it will only create a template you can use when you want to create an alert.")}</p>
      </Modal>
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="flex items-center w-100 justify-between">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Alert templates listings')}</h2>
            <p className="mt-1 text-sm text-gray-500">
              {t('Listing of all your alert template that you can use on your account')}
            </p>
          </div>
          <div>
            <Button
              onClick={() => setVisible(true)}
              type="primary-purple"
            >
              <PlusIcon className="h-5 text-white mr-2"/>
              {t('Add')}
            </Button>
          </div>
        </div>
        <Async {...alertTemplates}>
          {
            alertTemplates.data ?
              alertTemplates.data.data.length === 0 ? <div className="rounded-lg p-12 border-2 border-gray-300 border-dashed  text-center mt-6">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">{t('No alert template')}</h3>
              <p className="mt-1 text-sm text-gray-500">{t('Get started by creating a template.')}</p>
              <div className="mt-6">
                  <button
                    onClick={() => setVisible(true)}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {t('New alert template')}
                  </button>
              </div>
            </div> : <div>
                <ul className="mt-4">
                  {alertTemplates.data.data.map(sentry => <Template sentry={sentry} />)}
                </ul>
              </div>
              : <div>No alert</div>
          }
        </Async>
      </div>
    </div>
  );
};

export default AlertTemplateListing;
