import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { ADJUST, APPFLYERS, NETWORK } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class AppFlyers extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "AppFlyers";

  getNetworkIdentifier = (): NETWORK => APPFLYERS;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];

}

export default AppFlyers;
