import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";
import {PostRequest, PutRequest} from "../../form-interface";

export interface FieldTranslation {
  id: number,
  languageCode: string,
  description: string,
  name: string,
}

export interface PostFieldTranslation {
  languageCode: string,
  description: string,
  field: string,
  name: string,
}

export interface PutFieldTranslation {
  id: number,
  languageCode: string,
  description: string,
  field: string,
  name: string,
}

class Field extends Model<FieldTranslation> implements PostRequest<PostFieldTranslation>, PutRequest<PutFieldTranslation>{

  getId() {
    return this.data.id;
  }

  getLanguageCode() {
    return this.data.languageCode;
  }

  getName() {
    return this.data.name;
  }

  getDescription() {
    return this.data.description;
  }

  getSchemaToSerialize = (): JSONSchemaType<FieldTranslation> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "languageCode": { type: "string" },
      "description": { type: "string" },
      "name": { type: "string" }
    },
    required: ["id", "languageCode", "description", "name"]
  });

  getSchemaPostToValidate = (): JSONSchemaType<PostFieldTranslation> => ({
    type: "object",
    properties: {
      "languageCode": { type: "string" },
      "description": { type: "string" },
      "name": { type: "string" },
      "field": { type: "string" }
    },
    required: ["languageCode", "description", "name", "field"]
  });

  getSchemaPutToValidate = (): JSONSchemaType<PutFieldTranslation> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "languageCode": { type: "string" },
      "description": { type: "string" },
      "name": { type: "string" },
      "field": { type: "string" }
    },
    required: ["id", "languageCode", "description", "name", "field"]
  });

}

export default Field;