import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { uniqueId } from "lodash";
import { useQueryClient } from "react-query";
import {
  CalculatedField as CalculatedFieldModel, getTemplateForMetric,
  Metric as Model,
  MetricType,
  Threshold,
  Trend
} from "../helpers/sentry-template";
import { classNames } from "../../../../services/helper";
import AccessibilityModel from "../../../../services/models/http/alert/accessibility";
import CalculatedField from "./metric/calculated-field";
import MetricFactory from "../../factory/metric";


const Metric = ({onChange, value, accessibility, initialValues} : {accessibility: AccessibilityModel, onChange: (metric: Model) => void, value: Model, initialValues: Model}) => {

  const {t} = useTranslation();
  const [key, setKey] = useState(uniqueId());

  const tabs = [
    {name: t('Trends'), value: "trend"},
    {name: t('Threshold'), value: "threshold"},
  ]

  const updateCalculatedField = (fields: Array<CalculatedFieldModel>) => {
    onChange({
      ...value,
      calculatedFields: fields
    })
  }

  const updateMetric = (fields : Array<Threshold | Trend> ) => {
    onChange({
      ...value,
      alertValue: fields
    })
  }
  const queryClient = useQueryClient()

  const updateFieldType = (type: MetricType) => {

    queryClient.resetQueries('visualizer');
    onChange({
      ...value,
      alertType: type,
      alertValue: [getTemplateForMetric(type, undefined)]
    })
    setKey(uniqueId());
  }

  return (
    <div>
      <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200">
        {tabs.map((tab, tabIdx) => (
          <div
            key={tab.name}
            className={classNames(
              value.alertType === tab.value ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
              tabIdx === 0 ? 'rounded-l-lg' : '',
              tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
              "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-2 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 inline"
            )}
            aria-current={value.alertType === tab.value ? 'page' : undefined}
            onClick={() => updateFieldType(tab.value as MetricType)}
          >
            <span>{tab.name}</span>
            <span
              aria-hidden="true"
              className={classNames(
                value.alertType === tab.value ? 'bg-indigo-500' : 'bg-transparent',
                'absolute inset-x-0 bottom-0 h-0.5'
              )}
            />
          </div>
        ))}
      </nav>
      <CalculatedField accessibility={accessibility} onChange={(values) => updateCalculatedField(values)} values={value.calculatedFields}/>
      <div className="mt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="block font-medium text-sm">{t("Trigger when")}</h3>
          {/* <button className="cursor-pointer bg-indigo-600 hover:bg-indigo-700 text-white rounded text-xs p-2 flex items-center">
            <PlusIcon className="h-4 mr-2" />
            {t('Add a trigger')}
          </button> */}
        </div>
        <MetricFactory key={key}  initialValues={initialValues.alertValue[0]} accessibility={accessibility} calculatedFields={value.calculatedFields} type={value.alertType} onChange={(metricValue) => updateMetric([metricValue])} value={value.alertValue[0]} />
      </div>
      {false ? <div className="mt-4">
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="offers"
              name="offers"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="offers" className="font-medium text-gray-700">
              {t('Aggregate result')}
            </label>
            <p id="offers-description" className="text-gray-500">
              {t('Choose if you want to work on all entity or just the global one.')}
            </p>
          </div>
        </div>
      </div> : null}
    </div>
  );
};

export default Metric;
