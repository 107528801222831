import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";



export interface GoogleBigQuery extends Destination {
  projectId: string,
  jsonKey: string,
  dataSetId: string | null
}

export interface PostGoogleBigQuery extends PostDestination {
  projectId: string,
  jsonKey: string,
  dataSetId: string | null
}

class GoogleBigQueryDestination extends DestinationModel<GoogleBigQuery, PostGoogleBigQuery> {

  getProjectId() {
    return this.data.projectId;
  }

  getJsonKey() {
    return this.data.jsonKey;
  }

  getDatasetId() {
    return this.data.dataSetId;
  }


  getSchemaToSerialize(): JSONSchemaType<GoogleBigQuery> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = Object.assign({}, schema.properties,
      {
        "projectId": {
          type: ["string"]
        },
        "jsonKey": {
          type: ["string"]
        },
        "dataSetId": {
          type: ["string"],
          nullable: true
        }
      });

    return schema as JSONSchemaType<GoogleBigQuery>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostGoogleBigQuery> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = Object.assign({}, schema.properties,
      {
        "projectId": {
          type: ["string"]
        },
        "jsonKey": {
          type: ["string"]
        },
        "dataSetId": {
          type: ["string"],
          nullable: true
        }
      });

    return schema as JSONSchemaType<PostDestination & PostGoogleBigQuery>;
  }

}

export default GoogleBigQueryDestination;