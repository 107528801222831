import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  useActivateMutation,
  useDeActivateMutation,
  useGetAllAnomalies,
  useGetAllTemplate
} from "../../../hooks/alert";
import Modal from "../../../ui/components/modal";
import Async from "../../../ui/helper/async";
import routing from "../../../routing";
import Button from "../../../ui/components/button/button";
import AlertCard from "../components/alert-card";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import { classNames } from "../../../services/helper";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useQueryClient } from "react-query";
import Pagination from "../../../services/models/http/paginated";
import TemplateListing from "../../../services/models/http/alert/template-listing";
import ConfiguredFor from "../../../services/models/http/alert/configured_for";

const ModalAlert = ({dataSource, visible, onClose, reload}: {reload: () => void, dataSource: DataSource, visible: boolean, onClose: () => void}) => {
  const templates = useGetAllTemplate(dataSource.getPlatform(), dataSource.getId());
  const {t} = useTranslation();
  const [search, setSearch] = useState<string|undefined>(undefined);
  const deActivate = useDeActivateMutation();
  const activate = useActivateMutation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<Array<number>>([]);

  return <Modal open={visible} onClose={onClose}>
    <Async {...templates}>
      <div>
        <div className="mb-4">
          <h2 className="text-lg font-medium text-gray-900">{t('Create an alert')}</h2>
          <p className="mt-1 text-sm text-gray-500">{t('You can activate an alert by clicking on it. Or create a custom alert from scratch.')}</p>
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder={t('Search for a template')}
        />
        <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
          {templates.data?.data.filter(elem => search === undefined || elem.getName().indexOf(search) !== -1 ).map((item, itemIdx) => (
            <li key={itemIdx}>
              <div className="relative group py-4 flex items-center space-x-3">
                <div className="min-w-0 flex-1">
                  <div className="text-sm font-medium text-gray-900">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.getName()}
                  </div>
                </div>
                <div className="flex-shrink-0 self-center">
                  <Switch
                    onChange={async (e) => {
                      setLoading([...loading, item.getId()]);

                      if(loading.includes(item.getId())){
                        return;
                      }


                      if(!e && item.getConfiguredFors().length === 0){
                        return ;
                      }


                      if(!e) {
                        try {
                          // Do an optimistic update.
                          queryClient.setQueryData(['alert-template', dataSource.getPlatform(), dataSource.getId()], old => {
                            const modify =  new Pagination((old as Pagination<TemplateListing>).data.map(elem => elem.getId() !== item.getId() ? elem :   elem.update({
                              usedByCurrentDataSource: undefined,
                              activatedForDataSourceId: undefined
                            })),(old as Pagination<TemplateListing>).pagination);

                            return modify;
                          });

                          const  configuredId = item.getConfiguredFors()[0].split("/");
                          const response = await deActivate.mutateAsync({ id: configuredId[configuredId.length - 1] as unknown as number});
                          reload();
                          queryClient.setQueryData(['alert-template', dataSource.getPlatform(), dataSource.getId()], (old) => {
                            const modify =  new Pagination((old as Pagination<TemplateListing>).data.map(elem => elem.getId() !== item.getId() ? elem :  elem.update({
                              configuredFor: [],
                            })),(old as Pagination<TemplateListing>).pagination);
                            return modify;
                          })
                          NotificationManager.success(t('Your template is deactivate on the account {{name}}', {name: dataSource.getName()}), t('Your account is now not watched by {{alert}}', {alert: item.getName()}))
                        }catch (error){
                          NotificationManager.error(t('Unable to deactivate {{alert}}', {alert: item.getName()}), t('An error occurred. Please try again.'))
                        }
                      }else{
                        try{
                          // Do an optimistic update.
                          queryClient.setQueryData(['alert-template', dataSource.getPlatform(), dataSource.getId()], (old) => {
                            const modify =  new Pagination((old as Pagination<TemplateListing>).data.map(elem => elem.getId() !== item.getId() ? elem :  elem.update({
                              usedByCurrentDataSource: true,
                              activatedForDataSourceId: dataSource.getId(),
                            })),(old as Pagination<TemplateListing>).pagination);
                            return modify;
                          })

                          const response = await activate.mutateAsync({sentryId: item.getId(), dataSourceId: dataSource.getId()});
                          reload();
                          queryClient.setQueryData(['alert-template', dataSource.getPlatform(), dataSource.getId()], (old) => {
                            const modify =  new Pagination((old as Pagination<TemplateListing>).data.map(elem => elem.getId() !== item.getId() ? elem :  elem.update({
                              configuredFor: [...elem.getConfiguredFors(), `/api/configured_fors/${response.data.id}`],
                            })),(old as Pagination<TemplateListing>).pagination);
                            return modify;
                          })
                          NotificationManager.success(t('Your template is activated on the account {{name}}', {name: dataSource.getName()}), t('Your account is now watched by {{alert}}', {alert: item.getName()}))
                        }catch(error){
                          NotificationManager.error(t('Unable to activate {{alert}}', {alert: item.getName()}), t('An error occurred. Please try again.'))
                        }
                      }
                      setLoading(loading.filter(elem => elem !== item.getId()));
                    }}
                    checked={item.isUsedByCurrentDataSource()}
                    className={classNames(
                      item.isUsedByCurrentDataSource() ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span
                      className={classNames(
                        item.isUsedByCurrentDataSource() ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    >
                        <span
                          className={classNames(
                            item.isUsedByCurrentDataSource() || loading.find(elem => elem === item.getId()) !== undefined ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path
                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className={classNames(
                            loading.find(elem => elem === item.getId()) === undefined ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg className="animate-spin  h-3 w-3 text-gray-400" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="3" cy="3" r="1" stroke="currentColor" strokeWidth="1"/>
                            <path className="opacity-75" fill="currentColor"  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                          </svg>
                        </span>

                        <span
                          className={classNames(
                            item.isUsedByCurrentDataSource() && loading.find(elem => elem === item.getId()) === undefined ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                            <path
                              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                  </Switch>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-6 flex">
          <Link to={routing.user.create_alert.replace(':client_id', dataSource.getId().toString())} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            {t('Or start from scratch')}<span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </Async>
  </Modal>
}

const AlertListing = ({dataSource} : {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const anomalies = useGetAllAnomalies(dataSource.getId());

  const [visible, setVisible] = useState(false);

  return (
    <div className="px-4 mt-6 sm:px-6 lg:px-8">
      <ModalAlert dataSource={dataSource} visible={visible} onClose={() => setVisible(false)} reload={() => anomalies.refetch()}/>
      <header className="flex items-center justify-between">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            {t('Alerts overview')}
          </h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            {t('Overview of all your anomalies for your account.')}
          </p>
        </div>
        <div>
          <Button type="primary" onClick={() => setVisible(true)}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
              {t('Add alert')}
            </>
          </Button>
        </div>
      </header>
      <div className="mt-4">
        <Async {...anomalies}>
          {anomalies.data !== undefined ? <>
            {anomalies.data.data.map(elem => <AlertCard dataSource={dataSource} sentry={elem}/>)}
          </> : <div>test</div> }
        </Async>
      </div>
    </div>
  );
};

export default AlertListing;
