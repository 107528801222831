import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface AccessibilityModelInterface {
  breakdowns: {
    [key: string]: string
  },
  // eslint-disable-next-line camelcase
  class_name: string,
  properties: {
    metrics: {
      accessor: string,
      properties: Array<{ accessor: string, name: string }>,
      type: string
    }
  } & {
    [key: string]: {
      accessor: string,
      name: string
    }
  }
}

class AccessibilityModel extends Model<AccessibilityModelInterface> {

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSchemaToSerialize = (): JSONSchemaType<AccessibilityModelInterface> => ({
    "type": "object",
    "properties" : {
      "breakdowns" : {
        "type": "object",
        'required': []
      },
      "class_name": {
        "type": "string"
      },
      "properties": {
        "type": "object",
        "required": ["metrics"],
        "additionalProperties": true,
        "properties" : {
          "metrics" : {
            "required": ["accessor", "type", "properties"],
            "type" : "object",
            "properties" : {
              "accessor" : {"type": "string"},
              "type" : {"type": "string"},
              "properties" : {
                "type" : "array",
                "items": {
                  "type": "object",
                  "properties" : {
                    "accessor": {"type": "string"},
                    "name": {"type": "string"},
                  },
                  "required": ["accessor", "name"]
                }
              },
            }
          }
        }
      },
    },
    required: ['class_name', 'properties', 'breakdowns']
  });
}

export default AccessibilityModel;