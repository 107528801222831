import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";
import { Destination as DestinationConst, GOOGLE_BIG_QUERY } from "../../../../constant/destination";
import { PostRequest } from "../../form-interface";
import GoogleBigQueryDestination from "./destination/google-big-query";

export interface Destination {
  id: number,
  name: string,
  type: DestinationConst,
  managedByCatchr: boolean
}

export interface PostDestination{
  name: string,
  type: DestinationConst
}

class DestinationModel<T, Y> extends Model<Destination & T> implements PostRequest<PostDestination & Y>{
  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getType(){
    return this.data.type
  }

  getChildren() {
    switch (this.getType()){
      case GOOGLE_BIG_QUERY:
        return new GoogleBigQueryDestination(this.data as never);
      default:
        throw new Error(`Unable to get children type : ${this.getType()}`);
    }
  }
  getManagedByCatchr(){
    return this.data.managedByCatchr
  }

  baseSchemaToSerialize = () : JSONSchemaType<Destination> => ({
    type: "object",
    properties: {
      "id": {
        type: "integer"
      },
      "name": {
        type: "string"
      },
      "managedByCatchr": {
        type: "boolean"
      },
      "type": {
        type: "string"
      }
    },
    required: ['id', 'name', 'type', 'managedByCatchr']
  });

  baseGetSchemaPostToValidate = (): JSONSchemaType<PostDestination> => ({
    type: "object",
    properties: {
      "name": {
        type: "string"
      },
      "type": {
        type: "string"
      }
    },
    required: ['name', 'type']
  })

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & Y> {
    return this.baseGetSchemaPostToValidate() as JSONSchemaType<PostDestination & Y>;
  }

  getSchemaToSerialize(): JSONSchemaType<Destination & T> {
    return this.baseSchemaToSerialize() as JSONSchemaType<Destination & T>;
  }
}

export default DestinationModel;