import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";
import {NETWORK} from "../../../../constant/network";
import { PostRequest, PutRequest } from "../../form-interface";

interface DataSourceHTTP {
    id: number,
    name: string,
    accountId: string,
    backedBy?: {id: number},
    accountUsersManager: Array<{id: number}>,
    authorization: {
        platform: NETWORK
    },
}

export interface DataSourcePostHTTP {
    accountId: string,
    name: string,
    authorization: string,
    activateAlert?: boolean
}

export interface DataSourcePutHTTP {
    accountUsersManager?: Array<string>,
    activateAlert?: boolean
}


class DataSource extends Model<DataSourceHTTP> implements PostRequest<DataSourcePostHTTP>, PutRequest<Partial<DataSourcePutHTTP>>{

    public getId(){
        return this.data.id
    };

    public getName(){
        return this.data.name;
    };

    public getAccountId(){
        return this.data.accountId;
    };

    public getAuthorization(){
        return this.data.authorization;
    };

    public getPlatform(){
        return this.data.authorization.platform;
    };


    public getAccountUserManager(){
        return this.data.accountUsersManager;
    };

    public getBackedBy(){
        return this.data.backedBy;
    }


    getSchemaToSerialize(): JSONSchemaType<DataSourceHTTP> {
        return {
            type: "object",
            properties: {
                "backedBy" : {
                   type: "object",
                   nullable: true,
                   properties: {
                       "id" : {
                           type: "number"
                       }
                   },
                   required: ["id"]
                },
                "id" : {type: "number"},
                "name" : { type: "string"},
                "accountId" : {type: "string"},
                "accountUsersManager": {
                    type: "array",
                    items : {
                        type: "object",
                        properties: {
                            "id": {"type": "number"}
                        },
                        required: ["id"]
                    }
                },
                "authorization" : {
                    type: "object",
                    properties : {
                        "platform" : {"type" : "string"}
                    },
                    required : ["platform"]
                }
            },
            required: ["id", 'name', "accountId", "accountUsersManager"]
        }
    }

    getSchemaPostToValidate = (): JSONSchemaType<DataSourcePostHTTP> => ({
        type: "object",
        properties: {
            "accountId": {
                "type": "string"
            },
            "name": {
                "type": "string"
            },
            "authorization": {
                "type": "string"
            },
            "activateAlert": {
                "type": "boolean",
                nullable: true
            }
        },
        required: ['accountId', "name", "authorization"]
    });

    getSchemaPutToValidate = (): JSONSchemaType<Partial<DataSourcePutHTTP>> => ({
        type: "object",
        properties: {
            "accountUsersManager": {
                "nullable": true,
                "type": "array",
                items: {
                    type: "string"
                }
            },
            "activateAlert": {
                "nullable": true,
                "type": "boolean"
            }
        },
        required: []
    });



}

export default DataSource;