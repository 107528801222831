import React from "react";
import { useTranslation } from "react-i18next";
import SourceForm from "../containers/source-form";
import { useParams } from "react-router-dom";
import { useNetworkAuthorization } from "../../../../hooks/network-authorization";
import Async from "../../../../ui/helper/async";

const EditForm = () => {
  const {t} = useTranslation();
  const {id} = useParams();

  const network = useNetworkAuthorization(id as unknown as number)
  return (
    <Async {...network}>
      {network.data ? <div className="w-full py-4 flex justify-center">
        <SourceForm mode="EDIT" networkAuthorization={network.data} />
      </div> : <div>Not available</div>}
    </Async>
  );
};

export default EditForm;
