import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

interface TemplateHealthResponse200 {
  id: number,
  accounts: Array<{
    dataSourceId: number,
    configuredForId: number,
    dataSourceName: string,
    status: string,
    lastAnalyze: string|null
  }>,
}

class TemplateHealth extends Model<TemplateHealthResponse200> {

  getId() {
    return this.data.id;
  }

  getAccounts(){
    return this.data.accounts
  }

  getSchemaToSerialize = (): JSONSchemaType<TemplateHealthResponse200> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "accounts": {
        type: "array",
        items: {
          type: "object",
          properties: {
            dataSourceId: {
              type: "number"
            },
            configuredForId: {
              type: "number"
            },
            dataSourceName: {
              type: "string"
            },
            status: {
              type: "string"
            },
            lastAnalyze: {
              type: "string",
              nullable: true
            }
          },
          required: ["dataSourceId", "dataSourceName", "status", "lastAnalyze"]
        }
      },
    },
    required: ["id", "accounts"]
  });
}

export default TemplateHealth;