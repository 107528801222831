import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, WOOCOMMERCE } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {E_COMMERCE} from "../../constant/constant";

class Woocommerce extends BaseNetwork{
    connectingMethod = (): LinkMethod => APIKEY;

    getName = (): string => "Woocommerce";

    getNetworkIdentifier = (): NETWORK => WOOCOMMERCE;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => E_COMMERCE;

    isETLActivated(): boolean {
        return true;
    }

    alertOptions = () =>  [];


    connectionMethod(args: any): any {
        return {
            "storeUrl": args.storeUrl
        }
    }
}

export default Woocommerce;