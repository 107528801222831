import React from "react";
import { AlertType } from "../editor/helpers/sentry-template";
import AlertListing from "./alert";
import PacingListing from "./pacing";
import DataSource from "../../../services/models/http/datasource/data-source-client";

const ListingIndex = ({type, dataSource} : {type: AlertType, dataSource: DataSource}) => {
  switch(type){
    case "metric":
      return <AlertListing dataSource={dataSource} />
    case "pacing":
      return <PacingListing dataSource={dataSource} />
    default:
      throw new Error(`Unable to get a rendering function for ${type}`);
  }
};

export default ListingIndex;
