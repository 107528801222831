import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

interface Sentry {
  id: number,
  alertName: string,
  title: string,
  entityAnalyzed: number,
  entityInAlert: number,
  lastAnalyze: string | null,
  configuredFor: Array<string>,
  usedByCurrentDataSource?: boolean,
  activatedForDataSourceId?: boolean|number
}

class TemplateListing extends Model<Sentry> {

  getId() {
    return this.data.id;
  }

  getConfiguredFors(){
    return this.data.configuredFor
  }

  getName() {
    return this.data.title || this.data.alertName;
  }

  getNumberAlertOnEntity() {
    return this.data.entityInAlert;
  }

  isUsedByCurrentDataSource(){
    return this.data.usedByCurrentDataSource || false;
  }

  activatedForDataSourceId(){
    return this.data.activatedForDataSourceId;
  }

  getNumberAnalyzedOnEntity() {
    return this.data.entityAnalyzed;
  }


  getLastAnalyzed() {
    return this.data.lastAnalyze;
  }

  getSchemaToSerialize = (): JSONSchemaType<Sentry> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "alertName": { type: "string" },
      "configuredFor": { type: "array", items: {type: "string"} },
      "title": { type: "string" },
      "entityAnalyzed": { type: "number" },
      "usedByCurrentDataSource": { type: "boolean", nullable: true },
      "entityInAlert": { type: "number" },
      "activatedForDataSourceId": { type: ["number", "boolean"], nullable:true },
      "lastAnalyze": {
        "type": "string",
        "nullable": true
      }
    },
    required: ["id", "alertName", "entityAnalyzed", "entityInAlert", "lastAnalyze"]
  });
}

export default TemplateListing;