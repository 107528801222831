import React, { InputHTMLAttributes, ReactElement } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const Patron = ({
                  id,
                  label,
                  error,
                  description,
                  children,
                }: { children: ReactElement, id: string, label: string | Element, description?: string | Element, error?: string | undefined}) => {

  const enhancedProps: {
    "aria-invalid"?: string,
    "aria-describedby"?: string
  } = {};

  if (error) {
    enhancedProps["aria-invalid"] = "true";
    enhancedProps["aria-describedby"] = `${id}-error`;
  }

  return <>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className={`mt-1 ${error ? "relative rounded-md shadow-sm" : null}`}>
      {children}
      {error ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      </div> : null}
    </div>
    {description ? <p className="mt-2 text-sm text-gray-500">{description}</p> : null}
    {error ? <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
      {error}
    </p> : null}
  </>;
};

export default Patron;
