import React, {useState} from "react";
import { useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useFieldTranslationGoogleDataStudio} from "../../../hooks/google-datastudio";
import Async from "../../../ui/helper/async";
import Header from "../../../ui/components/page-heading/page-header";
import Button from "../../../ui/components/button/button";
import Loader from "../../../ui/components/loader/loader";
import FieldTranslationComponent from "../components/field-translation";
import Container from "../../../ui/components/container/container";
import routing from "../../../routing";
import CreateFieldTranslation from "./create-field-translation";

export const ShowField = () => {
    const {id} = useParams();

    if (id === undefined) {
       throw new Error("The parameter id has not been gave.");
    }

    const fieldTranslations = useFieldTranslationGoogleDataStudio(parseInt(id, undefined));
    const {t} = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);
    const [saving, setSaving] = useState(false);

    return <Container>
        <CreateFieldTranslation visible={visible} setVisible={setVisible} />
        <div>
            <div className="hidden mt-8 sm:block">
                <div className="align-middle inline-block min-w-full border-b border-gray-200">
                    <Async {...fieldTranslations}>
                        <>
                            <Header title={t('Google Data Studio Field')} leftSide={<div className="space-x-2">
                                <Link to={routing.admin.google_data_studio}>
                                    <Button type="secondary">
                                        {t('Back to Field List')}
                                    </Button>
                                </Link>
                                <Button type="primary" onClick={() => setVisible(true)}>{t('Add a field translation')}</Button>
                            </div>}/>
                            <br />
                            <Loader active={saving}>
                                <table className="min-w-full table-fixed">
                                    <thead>
                                    <tr className="border-t border-gray-200 bg-white">
                                        <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <span className="lg:pl-2">{t('Language')}</span>
                                        </th>
                                        <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <span className="lg:pl-2">{t('Name')}</span>
                                        </th>
                                        <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <span className="lg:pl-2">{t('Description')}</span>
                                        </th>
                                        <th scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-100">
                                    {fieldTranslations.data?.data.sort((a, b) => {
                                        const nameA = a.getLanguageCode().toUpperCase();
                                        const nameB = b.getLanguageCode().toUpperCase();
                                        if (nameA < nameB) {
                                            return -1;
                                        }
                                        if (nameA > nameB) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((elem) => (
                                        <FieldTranslationComponent fieldTranslation={elem} setSaving={setSaving} />
                                    ))}
                                    </tbody>
                                </table>
                            </Loader>
                        </>
                    </Async>
                </div>
            </div>
        </div>
    </Container>;
}

export default ShowField;