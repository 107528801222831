import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import GoogleBigQueryDestination from "../../../models/http/data-connector/destination/google-big-query";

class GoogleBigQueryAPI extends API{
  serializer = () => ({
    _postOne : GoogleBigQueryDestination,
    _put: GoogleBigQueryDestination
  })
}

export default Proxy<GoogleBigQueryAPI>(new GoogleBigQueryAPI('/destinations/google-big-query'));