import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import { Link } from "react-router-dom";
import Field  from "../../../services/models/http/google-datastudio/field";
import {useDeleteFieldGoogleDataStudioMutation} from "../../../hooks/google-datastudio";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import datastudioTypeProvider from "../datastudio-type/datastudio-type-provider";
import aggregationTypeProvider from "../aggregation-type/aggregation-type-provider";
import {ConfirmManager} from "../../../ui/components/confirm/confirm";
import networkProvider from "../../../config/network/network-provider";
import UpdateField from "../action/update-field";
import routing from "../../../routing";

const FieldComponent = ({field, setSaving}: { field: Field, setSaving: (x: boolean) => void}) => {
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const deleteGoogleDataStudioMutation = useDeleteFieldGoogleDataStudioMutation();
    const [visible, setVisible] = useState<boolean>(false);

    const deleteField = async () => ConfirmManager.confirm({
        title: t("Confirm delete a Field"),
        description: t("Are you sure you want to delete this field ?"),
        validButton: t("Delete"),
        cancelButton: t("Cancel"),
        typeImg: "danger",
        onValid: async () => {
            setSaving(true);
            try {
                await deleteGoogleDataStudioMutation.mutateAsync(field.getId());
                await queryClient.cancelQueries(['field_datastudio']);
                queryClient.setQueriesData(['field_datastudio'], (old) => (old as Pagination<Field>).deleteElement(field));
                NotificationManager.success(t('Your field has been successfully deleted'), "");
            } catch(e) {
                NotificationManager.error(t('Unable to delete the field'), t('An error occurred. Please try again.'))
            }
            setSaving(false);
        }
    });

    return <tr key={field.getId()}>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <UpdateField field={field} setVisible={setVisible} visible={visible} />
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {networkProvider.getByPlatform(field.getPlatform().name)?.getName()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {field.getDefaultName()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {field.getType()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {aggregationTypeProvider.getSpecificAggregation(field.getAggregationType()).getName()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {datastudioTypeProvider.getSpecificDatastudioType(field.getFieldType()).getName()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {field.getDefaultDescription()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 text-sm text-gray-500 font-medium">
            <Link to={routing.admin.field_google_data_studio.replace(":id", field.getId().toString())} className="text-indigo-600 hover:text-indigo-900 pr-5">
                {t('View')}
            </Link>
            <a href="#" className="text-indigo-600 hover:text-indigo-900 pr-5" onClick={() => setVisible(true)}>
                {t('Update')}
            </a>
            <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={deleteField}>
                {t('Delete')}
            </a>
        </td>
    </tr>
}

export default FieldComponent;