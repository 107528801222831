import React from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Input from "../../../../ui/components/input/input";
import LanguageSelector from "../../../../ui/components/select/language";

export interface FormFieldTranslation {
    id: number|undefined,
    name: string|undefined,
    description: string|undefined,
    languageCode: string|undefined,
    field: string|undefined
}

export const FormFieldTranslationValidation = Yup.object().shape({
    name: Yup.string().required('Please input a name for the field'),
    description: Yup.string().required('Please input a description for the field'),
    languageCode: Yup.string().required('Please input a valid language code')
})

export const FieldForm = ({form}: {form: any}) => {
    const {t} = useTranslation();

    return (
        <form>
            <Input
                className="mt-1"
                type="text"
                label={t('Name :')}
                id="name"
                name="name"
                onChange={form.handleChange}
                value={form.values.name}
                touched={form.touched.name}
                error={form.errors.name}
            />
            <br />
            <Input
                className="mt-1"
                type="text"
                label={t('Description :')}
                id="description"
                name="description"
                onChange={form.handleChange}
                value={form.values.description}
                touched={form.touched.description}
                error={form.errors.description}
            />
            <br />
            <LanguageSelector
                label={t('Language :')}
                onChange={(value) => form.setFieldValue('languageCode', value)} placeholder={t('Input your language')}
                value={form.values.languageCode}
            />
        </form>
    )
}

export default FieldForm;