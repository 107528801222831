import React, { useState } from "react";
import Select from "react-select";
import Input from "../input/input";

const CRON_VALUE = [
  { value: 'EVERY_DAY', label: 'Every day', cron: '0 0 * * *' },
  { value: 'EVERY_WEEK', label: 'Every Week', cron: '0 0 * * 0' },
  { value: 'EVERY_MONTH', label: 'Every Month', cron: '0 0 1 * *' },
  { value: 'EVERY_15_MIN', label: 'Every 15 min', cron: '*/15 * * * *'},
  { value: 'EVERY_30_MIN', label: 'Every 30 min', cron: '*/30 * * * *'},
  { value: 'EVERY_1_HOUR', label: 'Every 1 hours', cron: '0 * * * *'},
  { value: 'EVERY_2_HOUR', label: 'Every 2 hours',  cron: '0 */2 * * *'},
  { value: 'EVERY_3_HOUR', label: 'Every 3 hours',  cron: '0 */3 * * *'},
  { value: 'EVERY_4_HOUR', label: 'Every 4 hours',  cron: '0 */4 * * *'},
  { value: 'EVERY_6_HOUR', label: 'Every 6 hours',  cron: '0 */6 * * *'},
  { value: 'EVERY_8_HOUR', label: 'Every 8 hours',  cron: '0 */8 * * *'},
  { value: 'EVERY_12_HOUR', label: 'Every 12 hours',  cron: '0 */12 * * *'},
];

type Props = {
  value: string,
  onChange: (value: string|null) => void,
}

const detectValue = (cronValue: string) => {
  const splittedValue = cronValue.split(' ');
  if(splittedValue[1] === "*/2"){
    return 'EVERY_2_HOUR';
  }if(splittedValue[1] === "*/3"){
    return 'EVERY_3_HOUR';
  }if(splittedValue[1] === "*/4"){
    return 'EVERY_4_HOUR';
  }if(splittedValue[1] === "*/6"){
    return 'EVERY_6_HOUR';
  }if(splittedValue[1] === "*/8"){
    return 'EVERY_8_HOUR';
  }if(splittedValue[1] === "*/12"){
    return 'EVERY_12_HOUR';
  }if(splittedValue[0] === "0" && splittedValue[1] === "*"){
    return 'EVERY_1_HOUR';
  }if(splittedValue[0] === "*/15"){
    return 'EVERY_15_MIN';
  }if(splittedValue[0] === "*/30"){
    return 'EVERY_30_MIN';
  }if(splittedValue[0] === "0" && splittedValue[2] === "*" && splittedValue[3] === "*" && splittedValue[4] === "*") {
    return 'EVERY_DAY';
  }if(splittedValue[0] === "0" && splittedValue[2] === "*" && splittedValue[3] === "*" && splittedValue[4] !== "*") {
    return 'EVERY_WEEK';
  }else if(splittedValue[0] === "0" && splittedValue[2] !== "*" && splittedValue[3] === "*" && splittedValue[4] === "*") {
    return 'EVERY_MONTH';
  }
}


const DAY_SELECTOR_OPTION = [
  { value: '0', label: "Sunday" },
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
];

const CronSelector = ({value, onChange} : Props) => {
  const encodedValueSelector = detectValue(value);
  return (
        <div className="flex flex-row space-x-2">
          <div>
            <label className={"block text-sm font-medium text-gray-700 mb-1"}>
              Frequency
            </label>
            <Select
              onChange={(newValue) => {onChange((newValue as any).cron)}}
              name="cron_selector"
              menuPortalTarget={document.body}
              defaultValue={
                CRON_VALUE.find(s => s.value === encodedValueSelector) as unknown
              }
              value={CRON_VALUE.find(s => s.value === encodedValueSelector) as unknown}
              options={CRON_VALUE}
              classNamePrefix="react-select mt-1"
            />
          </div>
          {encodedValueSelector === "EVERY_MONTH" ? (
            <div>
              <Input
                id="day_selector"
                name="day_selector"
                type="number"
                label="Day of Month"
                min={1}
                max={31}
                value={value.split(' ')[2]}
                onChange={(e) => { onChange(`${value.split(' ')[0]} ${value.split(' ')[1]} ${e.target.value} ${value.split(' ')[3]} ${value.split(' ')[4]}`)}}
              />
            </div>
          ) : null}
          {encodedValueSelector === "EVERY_WEEK" ? (
            <div>
              <label className={"block text-sm font-medium text-gray-700 mb-1"}>
                Day
              </label>
              <Select
                name="week_day_selector"
                options={DAY_SELECTOR_OPTION}
                value={DAY_SELECTOR_OPTION.find(e => e.value === value.split(' ')[4])}
                className="react-select"
                onChange={(newValue) => {onChange(`${value.split(' ')[0]} ${value.split(' ')[1]} ${value.split(' ')[2]} ${value.split(' ')[3]} ${(newValue as any).value}`)}}
                menuPortalTarget={document.body}
              />
            </div>
          ) : null}
          {encodedValueSelector === "EVERY_WEEK" || encodedValueSelector === "EVERY_DAY" || encodedValueSelector === "EVERY_MONTH"  ? (
            <div>
              <Input
                id="hour_selector"
                name="hour_selector"
                type="number"
                label="Hour"
                onChange={(e) => { onChange(`${value.split(' ')[0]} ${e.target.value} ${value.split(' ')[2]} ${value.split(' ')[3]} ${value.split(' ')[4]}`)}}
                value={value.split(' ')[1]}
                min={0}
                max={24}
              />
            </div>
          ) : null}
        </div>
  );
};

export default CronSelector;
