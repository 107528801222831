import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Logout = () => {
  const {t} = useTranslation();
  useEffect(() => {
      localStorage.clear();
      window.location.href = "/"
    }, []);

  return (
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{t('You have been logout')}</h1>
              <p className="mt-1 text-base text-gray-500">{t('You will be redirect to the homepage.')}</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Logout;
