import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ChartBarIcon, CurrencyDollarIcon, FireIcon} from "@heroicons/react/solid";
import DataSource from "../../../services/models/http/datasource/datasource-all";
import DataSourceClient, { DataSourceHTTP } from "../../../services/models/http/datasource/data-source-client";
import {networkImageFromURI} from "../../../ui/components/network";
import routing from "../../../routing";
import { getResultOnAnomaly, getResultOnPacing } from "../../../services/health";

const Client = ({dataSource}: { dataSource: DataSource }) => {

    const {t} = useTranslation();
    const dataSourceClient = new DataSourceClient(dataSource.data as DataSourceHTTP);
    const alerts = getResultOnAnomaly(dataSourceClient);
    const pacing = getResultOnPacing(dataSourceClient)

    return <li>
        <Link className="bg-white py-6 px-6 shadow sm:rounded-lg mb-4 block hover:bg-gray-50"
              to={routing.user.client.replace(':client_id', dataSource.getId().toString())}>
            <div className="flex justify-between space-x-3">
                <div className="flex items-center space-x-3">
                    <div className="flex-1 min-w-0">
                        <p className="text-lg font-semibold text-indigo-900 capitalize flex items-center">

                            <img className="inline mr-2 h-5 w-5"
                                 src={networkImageFromURI(dataSource.getPlatform())}
                                 alt=""/>
                            {dataSource.getName()}
                        </p>
                    </div>
                </div>
                <div>
                    {
                        pacing.amount + alerts.amount > 0 ? <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        {t('Anomalies detected')}
                    </p> : <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {t('No problem')}
                    </p>
                    }
                </div>
            </div>
            <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div className="relative bg-white pt-4  px-4 pb-4  border border-gray-100 rounded-lg overflow-hidden">
                    <dt className="text-base font-medium text-gray-900 flex items-center mb-2">
                        <FireIcon className="h-5 w-5 mr-2"/>
                        {t('Anomalies')}
                    </dt>
                    <div className="grid grid-cols-2">
                        <div>
                            <div className="text-red-400 font-semibold">
                                {alerts.amount}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                                {t('Anomalies')}
                            </div>
                        </div>
                        <div>
                            <div className="text-green-400 font-semibold">
                                {alerts.total}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                                {t('Scheduled')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative bg-white pt-4  px-4 pb-4  border border-gray-100 rounded-lg overflow-hidden">
                    <dt className="text-base font-medium text-gray-900 flex items-center mb-2">
                        <CurrencyDollarIcon className="h-5 w-5 mr-2"/>
                        {t('Pacing')}
                    </dt>
                    <div className="grid grid-cols-2">
                        <div>
                            <div className="text-red-400 font-semibold">
                                {pacing.amount}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                                {t('Anomalies')}
                            </div>
                        </div>
                        <div>
                            <div className="text-green-400 font-semibold">
                                {pacing.total}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                                {t('Scheduled')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </li>
}

export default Client;