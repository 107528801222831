import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import ConfiguredFor from "../../models/http/alert/configured_for";
import DeleteSerializer from "../../models/http/delete";

class ConfiguredForApi extends API {


  _snoozed = (id: number, date : Date | undefined) => this._put(id, {snoozed: date ? date.toUTCString() : null})

  serializer = () => ({
    _getOne: ConfiguredFor,
    _getAll: ConfiguredFor,
    _postOne: ConfiguredFor,
    _snoozed: ConfiguredFor,
    _delete: DeleteSerializer
  });

}

export default Proxy<ConfiguredForApi>(new ConfiguredForApi('/configured_fors'));