import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import FieldTranslation from "../../../services/models/http/google-datastudio/field-translation";
import {useDeleteFieldTranslationGoogleDataStudioMutation} from "../../../hooks/google-datastudio";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import {ConfirmManager} from "../../../ui/components/confirm/confirm";
import UpdateFieldTranslation from "../action/update-field-translation";

const FieldTranslationComponent = ({fieldTranslation, setSaving}: { fieldTranslation: FieldTranslation, setSaving: (x: boolean) => void}) => {
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const deleteGoogleDataStudioMutation = useDeleteFieldTranslationGoogleDataStudioMutation();
    const [visible, setVisible] = useState<boolean>(false);

    const deleteFieldTranslation = async () => ConfirmManager.confirm({
        title: t("Confirm delete a Field Translation"),
        description: t("Are you sure you want to delete this field translation ?"),
        validButton: t("Delete"),
        cancelButton: t("Cancel"),
        typeImg: "danger",
        onValid: async () => {
            setSaving(true);
            try {
                await deleteGoogleDataStudioMutation.mutateAsync(fieldTranslation.getId());
                await queryClient.cancelQueries(['field_translation_datastudio']);
                queryClient.setQueriesData(['field_translation_datastudio'], (old) => (old as Pagination<FieldTranslation>).deleteElement(fieldTranslation));
                NotificationManager.success(t('Your field translation has been successfully deleted'), "");
            } catch(e) {
                NotificationManager.error(t('Unable to delete the field translation'), t('An error occurred. Please try again.'))
            }
            setSaving(false);
        }
    });

    return <tr key={fieldTranslation.getId()}>
        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
            <UpdateFieldTranslation fieldTranslation={fieldTranslation} setVisible={setVisible} visible={visible} />
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {fieldTranslation.getLanguageCode()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {fieldTranslation.getName()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="flex items-center lg:pl-2">
                <a href="#" className="truncate hover:text-gray-600">
                  <span>
                    {fieldTranslation.getDescription()}
                  </span>
                </a>
            </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
            <a href="#" className="text-indigo-600 hover:text-indigo-900 pr-5" onClick={() => setVisible(true)}>
                {t('Update')}
            </a>
            <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={deleteFieldTranslation}>
                {t('Delete')}
            </a>
        </td>
    </tr>
}

export default FieldTranslationComponent;