import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { FireIcon, SearchIcon } from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import { networkImageFromURI } from "../../../ui/components/network";
import SelectWithAvatar from "../../../ui/components/select/select-avatar";
import {
  useGetAccessibility,
  useGetModel,
  usePostSentryMutation,
  usePutSentryMutation,
  useVisualize
} from "../../../hooks/alert";
import { useNavigate, useParams } from "react-router-dom";
import Async from "../../../ui/helper/async";
import Button from "../../../ui/components/button/button";
import Header from "./components/header";
import { getDefault } from "./helpers/level";
import { NETWORK } from "../../../constant/network";
import SentryModelListing, { SentryModel } from "../../../services/models/http/alert/model";
import { AlertType, metricVariableTemplate, Variable, VisualizerData } from "./helpers/sentry-template";
import { classNames } from "../../../services/helper";
import { getIcon } from "../../../ui/components/icons/alert-type";
import { KPI, METRIC, OBJECTIVE, PACING } from "../../../constant/alert";
import ConfigurationFactory from "../factory/configuration";
import { Validation as ThresholdValidation } from "./type/metric/threshold";
import { Validation as TrendsValidation } from "./type/metric/trend";
import { Validation as CalculatedFieldValidation } from "./type/metric/calculated-field";
import { useQueryClient } from "react-query";
import { SentryVisualizerPost, SentryVisualizerResponse } from "../../../services/models/http/alert/visualize";
import { debounce, isEqual, pick, uniqueId } from "lodash";
import EmptyState from "./images/empty-state.svg";
import VisualizerFactory from "../factory/visualizer";
import Notification, { NotificationManager } from "../../../ui/components/notification/notification";
import datasource from "../../../services/repository/datasource/datasource";
import { useDebounce } from "../../../hooks/helpers/use-debounce";
import routing from "../../../routing";
import { useDataSourceClient } from "../../../hooks/datasource";
import Select from "../../../ui/components/select/select";
import Filter from "./filter";

export type Mode = "default" | "pacing" | "objective";

const BaseValidation = Yup.object().shape({
  network: Yup.object().shape({
    A: Yup.object().shape({
      accountId: Yup.string().required(),
      platform: Yup.string().required(),
      level: Yup.string().required()
    })
  }),
  type: Yup.string().oneOf(["metric", "objective", "kpi", "pacing"])
});

const AlertValueChooserValidator = Yup.lazy((item) => {

  if (item.type === "threshold") {
    return ThresholdValidation;
  }

  if (item.type === "trend") {
    return TrendsValidation;
  }

  return Yup.mixed();
});

const VariableKpiValidator = Yup.object().shape({
  operator: Yup.string().required().oneOf(["not_null", "null", "equal", "not_equal"]),
  kpi: Yup.string().required(),
  value: Yup.string(),
  type: Yup.string().oneOf(["kpi"])
});

const VariableMetricValidator = Yup.object().shape({
  alertType: Yup.string().required().oneOf(["trend", "threshold"]),
  calculatedFields: Yup.array().required().of(
    CalculatedFieldValidation
  ),
  alertValue: Yup.array().required().of(AlertValueChooserValidator as any),
  breakdown: Yup.string().nullable(true),
  type: Yup.string().oneOf(["metric"])
});

const VariableChooserValidator = Yup.lazy((item) => {
  if (item.type === "metric") {
    return VariableMetricValidator;
  }
  if (item.type === "kpi") {
    return VariableKpiValidator;
  }
  return Yup.mixed();
});


const VariableValidator = Yup.object().shape({
  variables: Yup.array().of(VariableChooserValidator as any)// @ts
});

const NameValidator = Yup.object().shape({
  name: Yup.string().required()
});

const GlobaValidatorVisualization = BaseValidation.concat(VariableValidator);
const GlobalValidationAlert = BaseValidation.concat(VariableValidator).concat(NameValidator);


export type Filter = {
  field: string,
  operator: string,
  value: Array<string>
};

export type FormValue = {
  name: string | undefined,
  network: {
    [key: string]: {
      accountId: string,
      platform: NETWORK,
      level: string | undefined,
      dataSourceId: number
      filters: Array<Filter>
    }
  },
  variables: Array<Variable>,
  type: AlertType,
  useAsTemplate: boolean
}

const getLevel = (mode: Mode, network: NETWORK, sentryModel: SentryModelListing): { [key: string]: string } => {
  switch (mode) {
    case "default":
      return getDefault(network, sentryModel);
    default:
      throw new Error(`Unable to locate mode = ${mode}`);
  }
};

const visualizerSerializer = (data: SentryVisualizerResponse): VisualizerData => {
  const visualization: VisualizerData = {};
  Object.keys(data.results).forEach(date => {
    visualization[date] = {
      data: data.results[date].results.map(elem => ({
        values: elem.data.values || [elem.data],
        identifications: elem.points,
        status: elem.status,
        identifierName: elem.identifierName
      }))
    };
  });

  return visualization;
};

export const EditorById = (props: any) => {
  const { id } = useParams();

  const dataSource = useDataSourceClient(id as unknown as number);

  return <Async {...dataSource}>
    <Editor {...props} dataSource={dataSource.data} />
  </Async>;
};

const Editor = ({
                  dataSource,
                  mode = "default",
                  template = false,
                  inititialValues
                }: { template: boolean, dataSource: DataSource, mode?: Mode, inititialValues?: FormValue & { id: number } }) => {
  const { t, i18n } = useTranslation();
  const model = useGetModel();
  const navigate = useNavigate();
  const [key, setKey] = useState(uniqueId());
  const tabs = [
    { name: t("Metrics"), value: "metric", icon: getIcon(METRIC) },
    { name: t("Configuration"), value: "kpi", icon: getIcon(KPI) },
    { name: t("Pacing"), value: "pacing", icon: getIcon(PACING) }
    /* {name: t('Objective'), value: "objective", icon: getIcon(OBJECTIVE)}, */
  ];

  const [activateSave, setActivateSave] = useState(false);
  const [currentTab, setCurrentTab] = useState<AlertType>(inititialValues?.type ? inititialValues?.type : (mode === "default" ? "metric" : mode as AlertType));

  const [visualizeState, setVisualizeState] = useState<FormValue | null>(null);
  const queryClient = useQueryClient();

  const sentryMutation = inititialValues?.id ? usePutSentryMutation() : usePostSentryMutation();

  if (inititialValues !== undefined) {
    inititialValues.network.A.dataSourceId = dataSource.getId();
  }
  const initialFormValues = Object.assign({
    useAsTemplate: template,
    name: undefined,
    type: currentTab,
    network: {
      A: {
        accountId: dataSource.getAccountId().toString(),
        platform: dataSource.getPlatform(),
        level: undefined,
        dataSourceId: dataSource.getId(),
        filters: []
      }
    },
    variables: [
      metricVariableTemplate(currentTab as any)
    ]
  }, pick(inititialValues, ["useAsTemplate", "name", "type", "network", "variables"]));

  const formik = useFormik<FormValue>({
    initialValues: initialFormValues,
    onSubmit: async (values) => {
      try {

        let title = initialFormValues.id ? "Your alert as been updated." : "Your alert as been created.";
        switch (currentTab) {
          case "kpi":
            title = initialFormValues.id ? "Your alert on configuration as been updated." : "Your alert on configuration as been created.";
            break;
          case "metric":
            title = initialFormValues.id ? "Your alert on metric as been updated." : "Your alert on metric as been created.";
            break;
          case "pacing":
            title = initialFormValues.id ? "Your alert on pacing as been updated." : "Your alert on pacing as been created.";
            break;
        }

        const postDataValues: any = { ...values, useAsTemplate: template || inititialValues?.useAsTemplate };

        if (inititialValues?.id) {
          postDataValues.id = inititialValues?.id;
        }

        await sentryMutation.mutate(postDataValues, {
          onSuccess: () => {

            if(template){
              NotificationManager.info(t(title), <span dangerouslySetInnerHTML={{
                __html: t("New template created.", {
                  alertname: formik.values.name,
                  account: dataSource.getName(),
                  interpolation: { escapeValue: true }
                })
              }} />);
              navigate(routing.user.company_template);
            }else {
              NotificationManager.info(t(title), <span dangerouslySetInnerHTML={{
                __html: t("<b>{{account}}</b> is now protected by <b>{{alertname}}</b>", {
                  alertname: formik.values.name,
                  account: dataSource.getName(),
                  interpolation: { escapeValue: true }
                })
              }} />);
              if(currentTab === "pacing"){
                navigate(routing.user.pacing.replace(":client_id", dataSource.getId().toString()));
              }else {
                navigate(routing.user.anomalies.replace(":client_id", dataSource.getId().toString()));
              }
            }
          }, onError: (e) => {
            console.error(e);
            NotificationManager.error(t("An error occured"), t("We are not able to enable the alert for the moment."));
          }
        });

      } catch (e) {
        console.error(e);
        NotificationManager.error(t("An error occured"), t("We are not able to enable the alert for the moment."));
      }
    }
  });
  console.log("Form values", formik.values);
  const verify = useDebounce(
    (values: any) => {
      setVisualizeState(values);
      queryClient.invalidateQueries("visualizer");
    }, 1000);

  /**
   *
   */
  useEffect(() => {
    (async () => {
      try {
        const valid = await GlobaValidatorVisualization.validate(formik.values);
        if (!isEqual(visualizeState, formik.values)) {
          verify(formik.values);
        }
      } catch (e) {
        console.error(e);
        setVisualizeState(null);
      }
      try {
        const valid = await GlobalValidationAlert.validate(formik.values);
        if (!isEqual(visualizeState, formik.values)) {
          setActivateSave(true);
        }
      } catch (e) {
        console.error(e);
        setActivateSave(false);
      }
    })();
  });

  const prev = useVisualize(visualizeState ? visualizeState as FormValue : formik.values, visualizeState !== null);

  /**
   * Reset the form when tab change
   */
  useEffect(() => {
    if (model.data && !inititialValues) {
      console.log("Reset form");
      queryClient.resetQueries("visualizer");
      formik.resetForm({
        values: {
          name: undefined,
          useAsTemplate: template,
          type: currentTab,
          network: {
            A: {
              accountId: dataSource.getAccountId().toString(),
              platform: dataSource.getPlatform(),
              dataSourceId: dataSource.getId(),
              level: undefined,
              filters: []
            }
          },
          variables: [
            metricVariableTemplate(currentTab as any)
          ]
        }
      });
    }
    setKey(uniqueId());
  }, [currentTab]);

  useEffect(() => () => {
    formik.setFieldValue("network.A.filters", []);
  }, [formik.values.network.A.level]);

  useEffect(() => () => {
    if (model.data) {
      formik.setFieldValue("network.A.level", Object.keys(model.data.data[currentTab as unknown as "kpi"][formik.values.network.A.platform as unknown as "facebook-ads"])[0]);
    }
  }, []);

  const accessibility = useGetAccessibility(currentTab, formik.values.network.A.platform, formik.values.network.A.level);

  const dataSourceProviderList = [
    {
      value: dataSource.getAccountId().toString(),
      name: dataSource.getName().charAt(0).toUpperCase() + dataSource.getName().slice(1),
      avatar: networkImageFromURI(dataSource.getPlatform())
    }
  ];


  return (
    <form className="w-full h-full" style={{ "height": "calc(100vh - 71px)" }} onSubmit={formik.handleSubmit}>
      <ReactTooltip effect="solid" />
      <div className="p-4 py-2 flex items-center justify-between ">
        <div className="w-2/3">
          <input id="name" name="name" value={formik.values.name} onChange={formik.handleChange}
                 className="py-2 text-l block w-full font-medium focus:border-transparent focus:outline-none focus:border-gray-300 text-gray-900 bg-transparent"
                 placeholder={t("Input a name for your alert")} />
        </div>
        <div>
          <Button htmlType="submit" disabled={!activateSave || sentryMutation.isLoading} type="primary-purple">
            {inititialValues?.id ? t("Save") : t("Save")}
          </Button>
        </div>
      </div>
      <div className="flex" style={{ "height": "calc(100vh - 126px)" }}>
        <div className="w-1/3 h-full border-r overflow-y-scroll">
          <div className="border-gray-200 border-t px-4">
            <nav className="-mb-px flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <span
                  data-tip={tab.name}
                  key={tab.value}
                  className={classNames(
                    currentTab === tab.value
                      ? "border-purple-500 text-purple-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-2 px-1 border-b-2 font-medium text-sm cursor-pointer"
                  )}
                  aria-current={currentTab === tab.value ? "page" : undefined}
                  onClick={() => {
                    setCurrentTab(tab.value as AlertType);
                  }}
                  role="button"
                >
              <tab.icon
                className={classNames(
                  currentTab === tab.value ? "text-purple-500" : "text-gray-400 group-hover:text-gray-500",
                  "h-5 w-5"
                )}
                aria-hidden="true"
              />
            </span>
              ))}
            </nav>
          </div>
          <Header icon={SearchIcon} title={t("Account and level")} />
          <Async {...model}>
            {model.data ?
              model.data.data[currentTab as unknown as "kpi"].hasOwnProperty(formik.values.network["A"].platform) ?
                <>
                  <div className="py-4 px-4">
                    {Object.keys(formik.values.network).map(networkKey => {
                      const network = formik.values.network[networkKey];
                      return <div>
                        <div className="flex items-center">
                          <div className=" mr-3  font-medium text-l text-purple-800">
                            {networkKey}
                          </div>
                          <SelectWithAvatar
                            placeholder={t("Select an account")}
                            value={network.accountId.toString()}
                            onChange={(value) => {
                              formik.setFieldValue(`network.${networkKey}.accountId`, value);
                            }}
                            list={dataSourceProviderList}
                          />
                          <Select
                            id={`network.${networkKey}.level`}
                            value={network.level}
                            placeholder={t("Choose a level")}
                            className="ml-3"
                            list={model.data ? Object.entries(model.data.data[currentTab as unknown as "kpi"][network.platform as unknown as "facebook-ads"]).map(([key, value]) => ({
                              value: key,
                              name: t(key.charAt(0).toUpperCase() + key.toLowerCase().slice(1))
                            })) : []}
                            onChange={value => formik.setFieldValue(`network.${networkKey}.level`, value)}
                          />
                        </div>
                        <div className="mr-4 mt-2">
                          {accessibility.data && formik.values.network['A'].level ? <Filter updateValue={e => formik.setFieldValue("network.A.filters", e) } value={formik.values.network['A'].filters} accountId={formik.values.network['A'].accountId} key={`${formik.values.network['A'].platform}-${formik.values.network['A'].level}`} accessibility={accessibility.data} level={formik.values.network['A'].level}  platform={formik.values.network['A'].platform} /> : null}
                        </div>

                      </div>;
                    })}
                  </div>
                  <Header icon={FireIcon} title={t("Detection Method")} />
                  <div className="p-4">
                    <Async {...accessibility}>
                      <>
                        {formik.values.variables.length > 0 && accessibility.data ?
                          <ConfigurationFactory key={key} accessibility={accessibility.data} type={formik.values.type}
                                                onChange={(values) => formik.setFieldValue("variables", [values])}
                                                value={formik.values.variables[0]}
                                                initialValues={formik.values.variables[0]} /> : null}
                      </>
                    </Async>
                  </div>
                </> : <div className="w-full px-4 py-4 flex justify-center flex-col items-center">
                  <h3 className="font-bold text-2xl">{t('Comming soon')}</h3>
                  <p>{t('This features is not available yet for this platform. ')}</p>
                </div>
              : <div> Not </div>}
          </Async>

        </div>
        <div className="w-2/3 h-full border-t border-gray-200 overflow-y-scroll">
          <Async {...prev}>
            {prev.data ? <div className="flex items-center h-full justify-center">
              <div className="w-full h-full p-4"><VisualizerFactory type={formik.values.type}
                                                                    response={visualizerSerializer(prev.data.data)}
                                                                    variable={formik.values.variables[0]} /></div>
            </div> : <div className="flex items-center h-full justify-center">
              <div className="w-1/2"><img src={EmptyState} /></div>
            </div>}
          </Async>
        </div>
      </div>
    </form>
  );
};

export default Editor;
