import { JSONSchemaType } from "ajv/dist/types/json-schema";
import { NETWORK } from "../../../../../constant/network";
import Model from "../../../model";
import { PostRequest } from "../../../form-interface";

export type JobLog = {
  id: number,
  dateEnd?: string,
  dateStart?: string,
  duration?: number,
  lastStepReached?: string,
  memoryFootprint?: number,
  message?: string,
  createdAt?: string,
  numberRow?: number,
  requestId?: string,
  size?: string,
  success?: boolean
};

export interface DataStreamJob {
  id: number,
  name: string,
  datastream : {
    id: number
  },
  datastreamJobDestinationOption : {
    id: number,
    type: string,
    tableName: string,
    partitionFieldId?: string
  },
  date : string,
  status : string,
  dateInitialization?: string,
  endDate?: string,
  endDateInitialization?: string,
  fields: {[p:string] : string},
  hasToBeInitialized: boolean,
  initializedAt?: string,
  isInitialized: boolean,
  issues?: string,
  lastExtractEnded?: string,
  lastLaunchedAt?: string,
  lastMessageSendAt?: string,
  logs: Array<JobLog>,
  nextRun: string,
  options: Array<string>,
  recurrency: string,
  sources: Array<{
    id: number
    accountId: string,
    authorization: {
      id:number
    }
    name: string
  }>
  startDate?: string
  startDateInitialization?: string
}

export interface PostDataStreamJob {
  name: string,
  platform: NETWORK,
}



class DataStreamJobModel extends Model<DataStreamJob> implements PostRequest<PostDataStreamJob>{
  getId(){
    return this.data.id;
  }

  getStatus(){
    return this.data.status;
  }

  getNextRun(){
    return this.data.nextRun;
  }

  getLogs(){
    return this.data.logs;
  }

  getReccurency(){
    return this.data.recurrency;
  }

  getSources(){
    return this.data.sources;
  }

  getName(){
    return this.data.name
  }

  getLastLaunchedAt(){
    return this.data.lastLaunchedAt;
  }

  getLastMessageSendAt(){
    return this.data.lastMessageSendAt;
  }

  getLastExtractEnded(){
    return this.data.lastExtractEnded;
  }


  baseSchemaToSerialize = () : JSONSchemaType<DataStreamJob> => ({
    "properties": {
      "datastream": {
        "properties": {
          "id": {
            "type": "number"
          }
        },
        "required": [
          "id"
        ],
        "type": "object"
      },
      "datastreamJobDestinationOption": {
        "properties": {
          "id": {
            "type": "number"
          },
          "tableName": {
            "type": "string"
          },
          "partitionFieldId": {
            "type": "string",
            nullable: true
          },
          "type": {
            "type": "string"
          }
        },
        "required": [
          "id",
          "tableName",
          "type"
        ],
        "type": "object"
      },
      "date": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "status": {
        "type": "string"
      },
      "dateInitialization": {
        "type": "string",
        nullable: true
      },
      "endDate": {
        "type": "string",
        nullable: true
      },
      "endDateInitialization": {
        "type": "string",
        nullable: true
      },
      "fields": {
        "additionalProperties": {
          "type": "string"
        },
        required: [],
        "type": "object"
      },
      "hasToBeInitialized": {
        "type": "boolean"
      },
      "id": {
        "type": "number"
      },
      "initializedAt": {
        "type": "string",
        nullable: true
      },
      "isInitialized": {
        "type": "boolean"
      },
      "issues": {
        "type": "string",
        nullable: true
      },
      "lastExtractEnded": {
        "type": "string",
        nullable: true
      },
      "lastLaunchedAt": {
        "type": "string",
        nullable: true
      },
      "lastMessageSendAt": {
        "type": "string",
        nullable: true
      },
      "logs": {
        "items": {
          "type": "object",
          properties: {
            "id": {
              "type": "number"
            },
            "dateEnd": {
              "type": "string",
              nullable: true
            },
            "dateStart": {
              "type": "string",
              nullable: true
            },
            "duration": {
              "type": "number",
              nullable: true
            },
            "lastStepReached": {
              "type": "string",
              nullable: true
            },
            "memoryFootprint": {
              "type": "number",
              nullable: true
            },
            "message": {
              "type": "string",
              nullable: true
            },
            "createdAt": {
              "type": "string",
              nullable: true
            },
            "numberRow": {
              "type": "number",
              nullable: true
            },
            "requestId": {
              "type": "string",
              nullable: true
            },
            "size": {
              "type": "string",
              nullable: true
            },
            "success": {
              "type": "boolean",
              nullable: true
            },
          },
          required: ['id'],
        },
        "type": "array"
      },
      "nextRun": {
        "type": "string"
      },
      "options": {
        "type": "array",
        items: {
          type: "string"
        }
      },
      "recurrency": {
        "type": "string"
      },
      "sources": {
        "items": {
          type: "object",
          required: ["id", "name", "accountId", "authorization"],
          "properties": {
            "id": {
              "type": "number"
            },
            "name": {
              "type": "string"
            },
            "accountId": {
              "type": "string"
            },
            "authorization": {
              "type": "object",
              "properties": {
                "id": {
                  "type": "number"
                }
              },
              "required": ["id"]
            }
          }
        },
        "type": "array"
      },
      "startDate": {
        "type": "string",
        nullable: true
      },
      "startDateInitialization": {
        "type": "string",
        nullable: true
      }
    },
    "required": [
      "datastream",
      "datastreamJobDestinationOption",
      "date",
      "fields",
      "hasToBeInitialized",
      "id",
      "isInitialized",
      "logs",
      "nextRun",
      "options",
      "recurrency",
      "sources"
    ],
    "type": "object"
  });

  baseGetSchemaPostToValidate = (): JSONSchemaType<PostDataStreamJob> => ({
    type: "object",
    properties: {
      "name": {
        type: "string"
      },
      "platform": {
        type: "string"
      }
    },
    required: ['name', 'platform']
  })

  getSchemaPostToValidate(): JSONSchemaType<PostDataStreamJob> {
    return this.baseGetSchemaPostToValidate() as JSONSchemaType<PostDataStreamJob>;
  }

  getSchemaToSerialize(): JSONSchemaType<DataStreamJob> {
    return this.baseSchemaToSerialize() as unknown as JSONSchemaType<DataStreamJob>;
  }
}

export default DataStreamJobModel;
