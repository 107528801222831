import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";
import {PostRequest, PutRequest} from "../../form-interface";

export interface FieldDTOModel {
  fieldId: string,
  formula: string,
  defaultDescription: string,
  defaultName: string,
  description: string,
  name: string,
  type: string,
  fieldType: string,
  aggregationType: string|null
}

class FieldDTO extends Model<FieldDTOModel> {

  getFieldID() {
    return this.data.fieldId;
  }

  getFormula() {
    return this.data.formula;
  }

  getDefaultDescription() {
    return this.data.defaultDescription;
  }

  getDefaultName() {
    return this.data.defaultName;
  }

  getName() {
    return this.data.name;
  }

  getDescription() {
    return this.data.description;
  }

  getType() {
    return this.data.type;
  }

  getFieldType() {
    return this.data.fieldType;
  }

  getAggregationType() {
    return this.data.aggregationType;
  }


  getSchemaToSerialize = (): JSONSchemaType<FieldDTOModel> => ({
    type: "object",
    properties: {
      "fieldId": { type: "string" },
      "formula": { type: "string", nullable: true },
      "defaultDescription": { type: "string" },
      "defaultName": { type: "string" },
      "name": { type: "string" },
      "description": { type: "string" },
      "type": { type: "string" },
      "fieldType": { type: "string" },
      "aggregationType": {
        nullable: true,
        type: "string"
      },
    },
    required: ["formula", "defaultDescription", "defaultName"]
  });

}

export default FieldDTO;
