import { ReactElement } from "react";
import { LinkMethod } from "../../constant/link-method";
import { NETWORK } from "../../constant/network";
import { SPECIFIC_ALERT } from "../../constant/alert";


abstract class BaseNetwork {
  abstract getName() : string;

  abstract getNetworkIdentifier() : NETWORK;

  abstract connectingMethod() : LinkMethod;

  abstract isDataStudioActivate() : boolean;

  abstract isAvailableForAll() : boolean;

  abstract isETLActivated() : boolean;

  abstract isAlertActivated() : boolean;

  abstract networkImage() : string;

  abstract categoryType(): string;

  abstract alertOptions(): Array<SPECIFIC_ALERT>;

  // eslint-disable-next-line class-methods-use-this
  connectionMethod(args: any) : any {
    return [];
  }
}

export default BaseNetwork;