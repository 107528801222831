import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import {ACTIVE_CAMPAIGN, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {EMAILING} from "../../constant/constant";

class ActiveCampaign extends BaseNetwork{
    connectingMethod = (): LinkMethod => APIKEY;

    getName = (): string => "Active Campaign";

    getNetworkIdentifier = (): NETWORK => ACTIVE_CAMPAIGN;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => EMAILING;

    isETLActivated(): boolean {
        return false;
    }

    alertOptions = () =>  [];

}

export default ActiveCampaign;