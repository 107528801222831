import React from "react";
import { AlertType, Kpi, Metric, Pacing } from "../editor/helpers/sentry-template";
import MetricComponent from "../editor/type/metric";
import AccessibilityModel from "../../../services/models/http/alert/accessibility";
import ConfigurationComponent  from "../editor/type/kpi";
import PacingComponent  from "../editor/type/pacing";

type ConfigurationType = Metric | Kpi | Pacing;
const ConfigurationFactory = ({initialValues, accessibility, type, onChange, value} : {accessibility:AccessibilityModel,  type: AlertType, onChange: (config: ConfigurationType) => void, value: ConfigurationType, initialValues: ConfigurationType}) => {
  switch (type) {
    case "metric":
      return <MetricComponent initialValues={initialValues  as Metric} onChange={onChange} value={value as Metric} accessibility={accessibility}/>
    case "kpi":
      return <ConfigurationComponent initialValues={initialValues  as Kpi} onChange={onChange} value={value as Kpi} accessibility={accessibility}/>
    case "pacing":
      return <PacingComponent initialValues={initialValues as Pacing} onChange={onChange} value={value as Pacing} accessibility={accessibility}/>
    default:
      throw new Error(`Unable to get Configuration for ${type}`);
  }
};

export default ConfigurationFactory;
