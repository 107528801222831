import config from "../../config";
import * as qs from "query-string";
import NetworkProvider from '../../config/network/network-provider';
import BaseNetwork from "../../config/network/base-network";

export const popup = (endpoint: string) =>  window.open(endpoint, "OAuth 2 Authentification", "width=972,height=660,modal=yes,alwaysRaised=yes");

const getFullUrlPath = (location: Location) => {
  const isHttps = location.protocol === 'https:';
  return `${location.protocol}//${location.hostname }:${location.port || (isHttps ? '443' : '80')}${/^\//.test(location.pathname) ? location.pathname : `/${location.pathname}`}`;
}


export const getAuth2Code = (endpoint:string, platform: string) => {
  const win = popup(endpoint) as Window;
  const network = NetworkProvider.getByPlatform(platform) as BaseNetwork;
  return new Promise((resolve, reject) => {
    const checkConnect = setInterval(() => {
      try {
        if(!win || win.closed){
          reject(new Error('Popup closed without code.'));
        }
        const popupWindowPath = getFullUrlPath(win.location);
        if(popupWindowPath.indexOf(config.api.front_path) !== -1 && (popupWindowPath.indexOf("/addons") === -1 || (popupWindowPath.indexOf("/addons") !== -1 && qs.parse(win.location.search).success))){
          const stringParsedQuery = qs.parse(win.location.search);
          const {code} = stringParsedQuery;
          const query = stringParsedQuery;
          const uri = win.location.search;
          win.close();
          resolve({code, query, uri, options: network.connectionMethod(stringParsedQuery)})
          clearInterval(checkConnect);
        }
      }catch (e) {
        console.error(e);
      }
    }, 100);
  })

}

export const getApiKey = (endpoint:string, platform: string) => {
  const win = popup(endpoint) as Window;
  console.log(endpoint);
  const network = NetworkProvider.getByPlatform(platform) as BaseNetwork;
  return new Promise((resolve, reject) => {
    const checkConnect = setInterval(() => {
      try {
        if(!win || win.closed){
          reject(new Error('Popup closed without code.'));
        }
        const popupWindowPath = getFullUrlPath(win.location);
        const stringParsedQuery = qs.parse(win.location.search);
        if(popupWindowPath.indexOf(config.api.front_path) !== -1 && (popupWindowPath.indexOf("/addons") === -1 || (popupWindowPath.indexOf("/addons") !== -1 && stringParsedQuery.success))){

          console.log(popupWindowPath, stringParsedQuery)
          const {consumerKey, consumerSecret, success, ...options} = stringParsedQuery;

          win.close();
          resolve({consumerKey, consumerSecret,options})
          clearInterval(checkConnect);
        }
      }catch (e) {
        console.error(e);
      }
    }, 100);
  })

}
