import React, { InputHTMLAttributes } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";


const Textarea = ({
                 id,
                 name,
                 label,
                 error,
                 touched,
                 description,
                 rows = 4,
                 ...props
               }: { id: string, name: string, label: string | Element, description?: string | Element, error?: string | undefined, touched?: boolean | undefined, rows?: number } & InputHTMLAttributes<HTMLTextAreaElement>) => {

  const enhancedProps: {
    "aria-invalid"?: string,
    "aria-describedby"?: string
  } = {};

  if (error) {
    enhancedProps["aria-invalid"] = "true";
    enhancedProps["aria-describedby"] = `${id}-error`;
  }

  return <>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className={`mt-1 ${error ? "relative rounded-md shadow-sm" : null}`}>
      <textarea
        name={name}
        rows={rows}
        id={id}
        {...props}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
      {error ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      </div> : null}
    </div>
    {description ? <p className="mt-2 text-sm text-gray-500">{description}</p> : null}
    {error ? <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
      {error}
    </p> : null}
  </>;
};

export default Textarea;