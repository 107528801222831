import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useQueryClient } from "react-query";
import { getCurrentUser, useMutateUser } from "../../../hooks/user";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import CurrentUser, { CurrentUserHTTP } from "../../../services/models/http/user/current-user";
import { classNames } from "../../../services/helper";

type FormValue = {
  email: string,
  firstName?: string,
  lastName?: string,
  notAvailable: boolean
}

const Validation = Yup.object().shape({
  email: Yup.string().required().email(),
  lastName: Yup.string(),
  firstname: Yup.string(),
  notAvailable: Yup.boolean().required(),
});


const General = () => {
  const currentUser = getCurrentUser();
  const {t} = useTranslation();
  const mutate = useMutateUser();
  const queryClient = useQueryClient();

  const form = useFormik<FormValue>({
    initialValues: {
      email: currentUser.getEmail(),
      firstName: currentUser.getFirstName(),
      lastName: currentUser.getLastName(),
      notAvailable: currentUser.isAvailable()
    },
    validationSchema: Validation,
    onSubmit: async (values) => {
      try {
        const data = {id: currentUser.getId(), ...values};
        const response = await mutate.mutateAsync(data);
        NotificationManager.success(t('Your information has been saved'), t('The system has updated your profile information.'));
        queryClient.cancelQueries('user');
        queryClient.setQueriesData('user', (old) => (old as CurrentUser).update(response as CurrentUserHTTP))
      }catch (e){
        NotificationManager.error(t('Unable to save your user'), t('There is an internal error when you try to save your profile information.'))
      }
    }
  })

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={form.handleSubmit}>
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Profile')}</h2>
          <p className="mt-1 text-sm text-gray-500">
            {t('Basic information about your account.')}
          </p>
        </div>
        <div className="mt-6 flex flex-col lg:flex-row">
          <div className="flex-grow space-y-6">
            <div>
              <Input
                id="email"
                type="email"
                name="email"
                label={t('Email : ')}
                defaultValue={form.initialValues.email}
                value={form.values.email}
                onChange={form.handleChange}
                error={form.errors.email}
                touched={form.touched.email}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              id="firstName"
              type="text"
              name="firstName"
              label={t('First name : ')}
              defaultValue={form.initialValues.firstName}
              value={form.values.firstName}
              onChange={form.handleChange}
              error={form.errors.firstName}
              touched={form.touched.firstName}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Input
              id="lastName"
              type="text"
              name="lastName"
              label={t('Last name : ')}
              defaultValue={form.initialValues.lastName}
              value={form.values.lastName}
              onChange={form.handleChange}
              error={form.errors.lastName}
              touched={form.touched.lastName}
            />
          </div>
        </div>
      </div>
      <div className="pt-6 divide-y divide-gray-200">
        <div className="px-4 sm:px-6">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Options')}</h2>
            <p className="mt-1 text-sm text-gray-500">
              {t('Enable or disable options about your preference and status.')}
            </p>
          </div>
          <ul role="list" className="mt-2 divide-y divide-gray-200">
            <Switch.Group as="li" className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                  {t('Available')}
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  {t('If you are not available because of holidays or other, you can disable this switch and all your alert will be forward to your default sub account manager or fallback company email.')}
                </Switch.Description>
              </div>
              <Switch
                checked={!form.values.notAvailable}
                onChange={e => {
                  form.setFieldValue('notAvailable', !e)
                }}
                className={classNames(
                  !form.values.notAvailable ? 'bg-purple-500' : 'bg-gray-200',
                  'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                )}
              >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              !form.values.notAvailable ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
              </Switch>
            </Switch.Group>
          </ul>
        </div>
      </div>
      <div className="mt-4 py-4 flex justify-end sm:px-6">
        <Button
          loading={mutate.isLoading}
          type="primary-purple"
          htmlType="submit"
        >
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default General;
