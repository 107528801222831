import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import ConfigDto from "../../models/http/google-datastudio/config-dto";

class ConfigFieldAPI extends API {

  serializer = () => ({
    _getAll: ConfigDto
  });

}

export default Proxy<ConfigFieldAPI>(new ConfigFieldAPI('/google-datastudio/config_fields'));
