import React, { useState } from "react";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Pagination from "../../../services/models/http/paginated";
import UserModel from "../../../services/models/http/user/user";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import Button from "../../../ui/components/button/button";
import {  useMutationAccountUserManager } from "../../../hooks/client";
import { NotificationManager } from "../../../ui/components/notification/notification";

type FormValue = {
  users: Array<{ value: number }>
}

const General = ({dataSource}: {dataSource: DataSource}) => {

  const queryClient = useQueryClient();
  const users : Pagination<UserModel> | undefined = queryClient.getQueryData('users');
  const [submitting, setSubmitting] = useState(false);
  const options = (users?.data  || []).map(elem => ({value: elem.getId(), label: elem.getEmail()})) || [];
  const {t} = useTranslation();
  const mutate = useMutationAccountUserManager();
  const form = useFormik<FormValue>({
    initialValues: {
      users : dataSource.getAccountUsersManager().map(elem => ({value: elem.id}))
    },
    onSubmit: async ( values) => {
      setSubmitting(true);
      try {
        await mutate.mutateAsync({
          id: dataSource.getId(),
          accountUsersManager: values.users.map(elem => `/api/users/${elem.value}`)
        });
        NotificationManager.success(t('The data source have been updated.'), "");

      }catch (e){
        NotificationManager.error(t('Unable to update the datasource'), "");

      }finally {
        setSubmitting(false);
      }
    }
  });

  const defaultOptions = options.filter(elem => dataSource.getAccountUsersManager().map(user => user.id).includes(elem.value))
  return (
    <div>
      <div>
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Users')}</h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('Which user should be alerted when an alert are raised on from this account :')}
          </p>
        </div>
        <div className="mt-4">
          <Select options={options} isMulti defaultValue={defaultOptions} onChange={e => form.setFieldValue('users', e)}/>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={submitting}
          type="primary-purple"
          onClick={e => {
            form.handleSubmit()
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};

export default General;
