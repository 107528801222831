import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useSegmentPage} from "react-segment-analytics";

export const usePageView = () => {
    const location = useLocation();
    const segment = useSegmentPage();
    useEffect( () => {
        segment(location.pathname);
    }, [location]);
}