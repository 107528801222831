// eslint-disable-next-line max-classes-per-file
import {ErrorObject} from "ajv";
import {PostRequest} from "../../services/models/form-interface";

export class PostValidationError extends Error{

    private readonly _errors: ErrorObject[];

    private readonly _mappedClass: PostRequest<any>;

    constructor(errors: ErrorObject[], mappedClass: PostRequest<any>) {
        console.error(errors);
        super(`We found ${errors.length} errors on ${mappedClass.constructor.name}.`);
        this._errors = errors;
        this._mappedClass = mappedClass;
        Object.setPrototypeOf(this, PostValidationError.prototype)
    }

    get errors(): ErrorObject[] {
        return this._errors;
    }

    get mappedClass(): PostRequest<any> {
        return this._mappedClass;
    }
}

export class SerializeValidationError extends Error{

    private readonly _errors: ErrorObject[];

    private readonly _mappedClass: PostRequest<any>;

    constructor(errors: ErrorObject[], mappedClass: PostRequest<any>) {
        console.error(errors);
        super(`We found ${errors.length} errors on ${mappedClass.constructor.name}.`);
        this._errors = errors;
        this._mappedClass = mappedClass;
        Object.setPrototypeOf(this, PostValidationError.prototype)
    }

    get errors(): ErrorObject[] {
        return this._errors;
    }

    get mappedClass(): PostRequest<any> {
        return this._mappedClass;
    }
}