import { useMutation, useQuery } from "react-query";
import CompanyModel from "../../services/models/http/company/company";
import CompanyAPI from "../../services/repository/company/company";
import Pagination from "../../services/models/http/paginated";

export const useCompany = (id: number) => useQuery('company', () : Promise<CompanyModel> => CompanyAPI._getOne(id));

export const useCompaniesAsAdmin = (page: number, options = {}) => useQuery(['companies', page, options], () : Promise<Pagination<CompanyModel>> => CompanyAPI.asAdmin()._getAll({query : {page, ...options}}));

export const useMutationCompany = () => useMutation('company', ({id, ...data}: {id: number} & any)  => CompanyAPI._put(id, data));

export const useInviteUser = () => useMutation(['company', 'invite-user'], ({id, email} : {id: number, email: string}) => CompanyAPI.inviteUser(id, email));