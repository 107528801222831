import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { AMAZON_ADS, NETWORK } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class AmazonAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Amazon Ads";

  getNetworkIdentifier = (): NETWORK => AMAZON_ADS;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];
}

export default AmazonAds;