import {useTranslation} from "react-i18next";
import DatastudioType from "./datastudio-type";
import {allDatastudio} from './datastudio-type-enum';

class DatastudioTypeProvider {

    /**
     * @param id
     */
    getSpecificDatastudioType = (id: string): DatastudioType => {
        const {t} = useTranslation();
        const type = allDatastudio.find(element => element.id === id);
        if(type === undefined) {
            throw new Error(`The type ${id} provided is not a valid Datastudio Type.`);
        }
        return new DatastudioType(type.id, t(type.name), type.description);
    }

    getAllTypes = (): Array<DatastudioType> => {
        const {t} = useTranslation();
        const response: Array<DatastudioType> = [];
        allDatastudio.forEach((element) => response.push(new DatastudioType(element.id, t(element.name), element.description)))
        return response;
    }
}

export default new DatastudioTypeProvider;