import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AccessibilityModel from "../../../../services/models/http/alert/accessibility";
import PopoverFilter from "./popover";
import { Filter as FilterType } from "../index";
import { XCircleIcon } from "@heroicons/react/solid";

const Filter = ({ accessibility, platform, level, value, accountId, updateValue }: {updateValue: (type: Array<FilterType>) => void, accountId:string, accessibility: AccessibilityModel, platform: string, level: string, value: Array<FilterType>}) => {

  const [selected, setSelected] = useState<undefined|FilterType>(undefined);
  const {t} = useTranslation();

  return (
    <div>
      <div className="space-y-2">
        {value.map((elem, index) => <div className="rounded-md py-2 px-2 bg-gray-100 border border-grey-200 flex items-center justify-between">
          <div className="font-medium text-gray-500">{t('Filter on ')} {elem.field}</div>
          <div>
            <div onClick={e => updateValue(value.filter((elemValue, indexValue) => index !== indexValue))} className="hover:text-white h-6 w-6 hover:bg-purple-500 rounded-md cursor-pointer flex items-center justify-between ">
              <XCircleIcon className="m-auto h-4 w-4 border-grey-300 " />
            </div>
          </div>
        </div>)}
      </div>
      <PopoverFilter onSubmit={(field) => {
        updateValue([field, ...value]);
      }} accountId={accountId} initialValues={selected}  accessibility={accessibility} platform={platform} level={level} />
    </div>
  );
};

export default Filter;
