import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldGoogleDataStudio } from "../../hooks/google-datastudio";
import Header from "../../ui/components/page-heading/page-header";
import Async from "../../ui/helper/async";
import Button from "../../ui/components/button/button";
import Container from "../../ui/components/container/container";
import FieldComponent from "./components/field";
import Loader from "../../ui/components/loader/loader";
import CreateField from "./action/create-field";
import NetworkSelector from "../../ui/components/select/network";



const GoogleDataStudioField = () => {

  const fields = useFieldGoogleDataStudio();

  const {t} = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<{network: string|undefined}>({network: undefined});
  const [saving, setSaving] = useState(false);

  const filterChange = (column: "network", value: string) => {
    setFilter({
      ...filter,
      [column]: value
    })
    console.log(filter);
  }

  return (
    <Container>
      <CreateField visible={visible} setVisible={setVisible} />
      <div>
        <div className="hidden mt-8 sm:block">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            <Async {...fields}>
              <>
                <Header title={t('Google Datatudio fields')} leftSide={<Button type="primary" onClick={() => setVisible(true)}>{t('Add a field')}</Button>}/>
                <br />
                <div className="space-x-2">
                  <div className="w-64">
                    <NetworkSelector mode="google-data-studio" value={filter.network} onChange={(value) => filterChange("network", value)} placeholder={t('Filter by gateway')} />
                  </div>
                </div>
                <br />
                <Loader active={saving}>
                  <table className="min-w-full table-fixed">
                    <thead>
                    <tr className="border-t border-gray-200 bg-white">
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Platform')}</span>
                      </th>
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Field Name')}</span>
                      </th>
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Type')}</span>
                      </th>
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Default Aggregation Type')}</span>
                      </th>
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Field Type')}</span>
                      </th>
                      <th scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <span className="lg:pl-2">{t('Description')}</span>
                      </th>
                      <th scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                      </tr>
                    </thead>
                          <tbody className="bg-white divide-y divide-gray-100">
                            {fields.data?.data.filter((field) => filter.network === undefined || filter.network === field.getPlatform().name).sort((a, b) => {
                              const nameA = a.getDefaultName().toUpperCase();
                              const nameB = b.getDefaultName().toUpperCase();
                              if (nameA < nameB) {
                                return -1;
                              }
                              if (nameA > nameB) {
                                return 1;
                              }
                              return 0;
                            }).map((elem) => (
                                <FieldComponent field={elem} setSaving={setSaving} />
                            ))}
                        </tbody>
                  </table>
                </Loader>
              </>
            </Async>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GoogleDataStudioField;
