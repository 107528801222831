export const YEAR = "YEAR"; // Year in the format of YYYY such as 2017.
export const YEAR_QUARTER = "YEAR_QUARTER"; // Year and quarter in the format of YYYYQ such as 20171.
export const YEAR_MONTH = "YEAR_MONTH"; // Year and month in the format of YYYYMM such as 201703.
export const YEAR_WEEK = "YEAR_WEEK"; // Year and week in the format of YYYYww such as 201707.
export const YEAR_MONTH_DAY = "YEAR_MONTH_DAY"; // Year, month, and day in the format of YYYYMMDD such as 20170317.
export const YEAR_MONTH_DAY_HOUR = "YEAR_MONTH_DAY_HOUR"; // Year, month, day, and hour in the format of YYYYMMDDHH such as 2017031703.
export const YEAR_MONTH_DAY_SECOND = "YEAR_MONTH_DAY_SECOND"; // Year, month, day, hour, and second in the format of YYYYMMDDHHss such as 201703170330.
export const QUARTER = "QUARTER"; // Quarter in the format of 1, 2, 3, or 4).
export const MONTH = "MONTH"; // Month in the format of MM such as 03.
export const WEEK = "WEEK"; // Week in the format of ww such as 07.
export const MONTH_DAY = "MONTH_DAY"; // Month and day in the format of MMDD such as 0317.
export const DAY_OF_WEEK = "DAY_OF_WEEK"; // A number in the range of [0,6] with 0 representing Sunday.
export const DAY = "DAY"; // Day in the format of DD such as 17.
export const HOUR = "HOUR"; // Hour in the format of HH such as 13.
export const MINUTE = "MINUTE"; // Minute in the format of mm such as 12.
export const DURATION = "DURATION"; //	A duration of time in seconds.
export const COUNTRY = "COUNTRY"; // A country such as United States.
export const COUNTRY_CODE = "COUNTRY_CODE"; // A country code such as US.
export const CONTINENT = "CONTINENT"; // 	A continent such as Americas.
export const CONTINENT_CODE = "CONTINENT_CODE"; // A continent code such as 019.
export const SUB_CONTINENT = "SUB_CONTINENT"; // A sub-continent such as North America.
export const SUB_CONTINENT_CODE = "SUB_CONTINENT_CODE"; // A sub-continent code such as 003.
export const REGION = "REGION"; // A region such as California.
export const REGION_CODE = "REGION_CODE"; // A region code such as CA.
export const CITY = "CITY"; // A city such as Mountain View.
export const CITY_CODE = "CITY_CODE"; // A city code such as 1014044.
export const METRO = "METRO"; // A metro such as San Francisco-Oakland-San Jose CA.
export const METRO_CODE = "METRO_CODE"; // A metro code such as 200807.
export const LATITUDE_LONGITUDE = "LATITUDE_LONGITUDE"; // A latitude longitude pair such as 51.5074, -0.1278.
export const NUMBER = "NUMBER"; // A decimal number.
export const PERCENT = "PERCENT"; // Decimal percentage (can be over 1.0). For example, 137% is represented as 1.37.
export const TEXT = "TEXT"; // Free-form text.
export const BOOLEAN = "BOOLEAN"; // A true or false boolean value.
export const URL = "URL"; // A URL as text such as https://google.com.
export const HYPERLINK = "HYPERLINK"; // A hyperlink. Only use this for calculated fields with the HYPERLINK function.
export const IMAGE = "IMAGE"; // An image. Only use this for calculated fields with the IMAGE function.
export const IMAGE_LINK = "IMAGE_LINK"; // An image link. Only use this for calculated fields with the HYPERLINK function while using IMAGE for the link label.
export const CURRENCY_ALL = "CURRENCY_ALL"; // Currency from ALL.


export const allDatastudio = [
        {
            "id": YEAR,
            "name": "Year",
            "description": "Year in the format of YYYY such as 2017."
        },
        {
            "id": YEAR_QUARTER,
            "name": "Year quarter",
            "description": "Year and quarter in the format of YYYYQ such as 20171."
        },
        {
            "id": YEAR_MONTH,
            "name": "Year month",
            "description": "Year and month in the format of YYYYMM such as 201703."
        },
        {
            "id": YEAR_WEEK,
            "name": "Year Month",
            "description": "Year and week in the format of YYYYww such as 201707."
        },
        {
            "id": YEAR_MONTH_DAY,
            "name": "Year Month Day",
            "description": "Year, month, and day in the format of YYYYMMDD such as 20170317."
        },
        {
            "id": YEAR_MONTH_DAY_HOUR,
            "name": "Year Month Day Hour",
            "description": "Year, month, day, and hour in the format of YYYYMMDDHH such as 2017031703."
        },
        {
            "id": YEAR_MONTH_DAY_SECOND,
            "name": "Year Month Day Second",
            "description": "Year, month, day, hour, and second in the format of YYYYMMDDHHss such as 201703170330."
        },
        {
            "id": QUARTER,
            "name": "Quarter",
            "description": "Quarter in the format of 1, 2, 3, or 4)."
        },
        {
            "id": MONTH,
            "name": "'Month",
            "description": "Month in the format of MM such as 03."
        },
        {
            "id": WEEK,
            "name": "Week",
            "description": "Week in the format of ww such as 07."
        },
        {
            "id": MONTH_DAY,
            "name": "Month Day",
            "description": "Month and day in the format of MMDD such as 0317."
        },
        {
            "id": DAY_OF_WEEK,
            "name": "Day of Week",
            "description": "A number in the range of [0,6] with 0 representing Sunday."
        },
        {
            "id": DAY,
            "name": "Day",
            "description": "Day in the format of DD such as 17."
        },
        {
            "id": HOUR,
            "name": "Hour",
            "description": "Hour in the format of HH such as 13."
        },
        {
            "id": MINUTE,
            "name": "Minute",
            "description": "Minute in the format of mm such as 12."
        },
        {
            "id": DURATION,
            "name": "Duration",
            "description": "A duration of time in seconds."
        },
        {
            "id": COUNTRY,
            "name": "Country",
            "description": "A country such as United States."
        },
        {
            "id": COUNTRY_CODE,
            "name": "Country Code",
            "description": "A country code such as US."
        },
        {
            "id": CONTINENT,
            "name": "Continent",
            "description": "A continent such as Americas."
        },
        {
            "id": CONTINENT_CODE,
            "name": "Continent Code",
            "description": "A continent code such as 019."
        },
        {
            "id": SUB_CONTINENT,
            "name": "Sub Continent",
            "description": "A sub-continent such as North America."
        },
        {
            "id": SUB_CONTINENT_CODE,
            "name": "Sub Continent Code",
            "description": "A sub-continent code such as 003."
        },
        {
            "id": REGION,
            "name": "Region",
            "description": "A region such as California."
        },
        {
            "id": REGION_CODE,
            "name": "Region Code",
            "description": "A region code such as CA."
        },
        {
            "id": CITY,
            "name": "City",
            "description": "A city such as Mountain View."
        },
        {
            "id": CITY_CODE,
            "name": "City Code",
            "description": "A city code such as 1014044."
        },
        {
            "id": METRO,
            "name": "Metro",
            "description": "A metro such as San Francisco-Oakland-San Jose CA."
        },
        {
            "id": METRO_CODE,
            "name": "Metro Code",
            "description": "A metro code such as 200807."
        },
        {
            "id": LATITUDE_LONGITUDE,
            "name": "Latitude Longitude",
            "description": "A latitude longitude pair such as 51.5074, -0.1278."
        },
        {
            "id": NUMBER,
            "name": "Number",
            "description": "A decimal number."
        },
        {
            "id": PERCENT,
            "name": "Percent",
            "description": "Decimal percentage (can be over 1.0). For example, 137% is represented as 1.37."
        },
        {
            "id": TEXT,
            "name": "Text",
            "description": "Free-form text."
        },
        {
            "id": BOOLEAN,
            "name": "Boolean",
            "description": "A true or false boolean value."
        },
        {
            "id": URL,
            "name": "Url",
            "description": "A URL as text such as https://google.com."
        },
        {
            "id": HYPERLINK,
            "name": "Hyperlink",
            "description": "A hyperlink. Only use this for calculated fields with the HYPERLINK function."
        },
        {
            "id": IMAGE,
            "name": "Image",
            "description": "An image. Only use this for calculated fields with the IMAGE function."
        },
        {
            "id": IMAGE_LINK,
            "name": "Image Link",
            "description": "An image link. Only use this for calculated fields with the HYPERLINK function while using IMAGE for the link label."
        },
        {
            "id": CURRENCY_ALL,
            "name": "Currency All",
            "description": "Currency from ALL."
        },
    ]