import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

interface CompanyHTTP {
  id: number,
  name: string,
  createdAt: string,
  trialFinished: string,
  stripeSubscriptionId?: string
}

class CompanyModel extends Model<CompanyHTTP> {

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getCreatedAt(){
    return this.data.createdAt;
  }

  getTrialFinished(){
    return this.data.trialFinished;
  }

  getStripeSubscriptionId(){
    return this.data.stripeSubscriptionId;
  }

  getSchemaToSerialize = (): JSONSchemaType<CompanyHTTP> => ({
    type: "object",
    properties: {
      "id": {
        type: "number"
      },
      "name": {
        type: "string"
      },
      "createdAt": {
        type: "string"
      },
      "trialFinished": {
        type: "string"
      },
      "stripeSubscriptionId": {
        type: "string",
        nullable: true
      },
    },
    required: ['id', 'name']
  });
}

export default CompanyModel;