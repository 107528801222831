import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllUserAsAdmin, useUseAs } from "../../../hooks/user";
import Async from "../../../ui/helper/async";
import Button from "../../../ui/components/button/button";
import Input from "../../../ui/components/input/input";
import { useDebounceValue } from "../../../hooks/helpers/use-debounce";
import { IdentificationIcon } from "@heroicons/react/solid";
import UserAPI from '../../../services/repository/user/user';
import LocalStorage from "../../../services/storage/local";

const Users = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState<string|undefined>(undefined);
  const debounceEmail = useDebounceValue(email, 1000);
  const users = useGetAllUserAsAdmin(page, {email: debounceEmail});

  const handleUseAt = async (id: number) => {
    const response = await UserAPI.useAs(id);
    console.log(response.data)
    LocalStorage.set('JWT_TOKEN', response.data.token);
    window.location.href = "/";
  }

  return (
    <div>
      <div className="px-4 mt-6 sm:px-6 lg:px-8">
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{t('Users list')}</h2>
        <ul role="list" className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
          <li>
            <Input
              id="email"
              name="email"
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              label={t('Email :')}
            />
          </li>
        </ul>
      </div>
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <Async {...users}>
            <table className="min-w-full table-fixed">
              <thead>
              <tr className="border-t border-gray-200 bg-white">
                <th
                  scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">{t('Email')}</span>
                </th>
                <th
                  scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider text-right" >
                  <span className="lg:pl-2">{t('Actions')}</span>

                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
              {users.data?.data.map((user) => (
                <tr key={user.getId()}>
                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center lg:pl-2">
                      <a href="#" className="truncate hover:text-gray-600">
                          <span>
                            {user.getEmail()}
                          </span>
                      </a>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                    <div className=" flex items-center justify-end">
                      <Button type="primary-purple" onClick={ () => handleUseAt(user.getId())}>
                        <IdentificationIcon className="text-white h-5 w-5 mr-4"/>
                        {t('Use as')}
                      </Button>
                    </div>
                  </td>
                </tr>))
              }
              </tbody>
            </table>
          </Async>
        </div>
      </div>
    </div>
  );
};

export default Users;
