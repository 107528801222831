import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import { useGetAllAnomalies } from "../../../hooks/alert";
import Async from "../../../ui/helper/async";
import routing from "../../../routing";
import Button from "../../../ui/components/button/button";
import AlertCard from "../components/alert-card";

const PacingListing = ({dataSource} : {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const anomalies = useGetAllAnomalies(dataSource.getId(), "pacing");
  const navigate = useNavigate();
  return (
    <div className="px-4 mt-6 sm:px-6 lg:px-8">
      <header className="flex items-center justify-between">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            {t('Alerts overview')}
          </h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            {t('Overview of all your pacing for your account.')}
          </p>
        </div>
        <div>
          <Button type="primary" onClick={() => navigate(routing.user.create_alert_pacing.replace(":client_id", dataSource.getId().toString()))}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
              {t('Add pacing')}
            </>
          </Button>
        </div>
      </header>
      <div className="mt-4">
        <Async {...anomalies}>
          {anomalies.data !== undefined ? <>
            {anomalies.data.data.map(elem => <AlertCard dataSource={dataSource} sentry={elem}/>)}
          </> : <div>test</div> }
        </Async>
      </div>
    </div>
  );
};

export default PacingListing;
