import React, { Fragment, ReactElement, useState } from "react";
import { Link, Route, Routes, useMatch, useResolvedPath } from "react-router-dom";
import {
  AtSymbolIcon,
  HomeIcon,
  MenuAlt1Icon,
  XIcon
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { GlobeIcon, UsersIcon } from "@heroicons/react/solid";
import routing from "../../routing";
import GoogleDataStudio from "../google-datastudio";
import FieldGoogleDataStudio from "../google-datastudio/action/show-field";
import Logo from "../../ui/components/logo/logo.svg";
import { classNames } from "../../services/helper";
import Companies from "./containers/companies";
import Users from "./containers/users";
import {getCurrentUser} from "../../hooks/user";
import { SentryRoutes } from "../..";

const AdminContainer = ({children} : {children: ReactElement}) => {

  const navigation = [
    {name: 'Home', href: routing.user.home, icon: HomeIcon},
    {name: 'Google Data Studio', href: routing.admin.google_data_studio, icon: AtSymbolIcon},
    {name: 'Companies', href: routing.admin.companies, icon: GlobeIcon},
    {name: 'Users', href: routing.admin.users, icon: UsersIcon},
  ]

  const {t} = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <div className="min-h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">{t('Close sidebar')}</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src={Logo}
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navigation.map((item) => {
                      let resolved = useResolvedPath(item.href);
                      let match = useMatch({ path: resolved.pathname, end: true });
                      return <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          match
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                          'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                        )}
                        aria-current={match ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            match ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    } )}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
        <div className="flex items-center flex-shrink-0 px-6">
          <img
            className="h-8 w-auto"
            src={Logo}
            alt="Workflow"
          />
        </div>
        {/* Sidebar components, swap this element with another sidebar if you like */}
        <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
          {/* Navigation */}
          <nav className="px-3 mt-6">
            <div className="space-y-1">
              {navigation.map((item) => {
                let resolved = useResolvedPath(item.href);
                let match = useMatch({ path: resolved.pathname, end: true });
                return <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    match ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                  aria-current={match ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      match ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              } )}
            </div>
          </nav>
        </div>
      </div>
      <div
        className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
        <div className="flex items-center flex-shrink-0 px-6">
          <img
            className="h-8 w-auto"
            src={Logo}
            alt="Workflow"
          />
        </div>
        {/* Sidebar components, swap this element with another sidebar if you like */}
        <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
          {/* Navigation */}
          <nav className="px-3 mt-6">
            <div className="space-y-1">
              {navigation.map((item) => {
                let resolved = useResolvedPath(item.href);
                let match = useMatch({ path: resolved.pathname, end: true });
                return <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    match ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                  aria-current={match ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      match ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              } )}
            </div>
          </nav>
        </div>
      </div>
      {/* Main column */}
      <div className="lg:pl-64 flex flex-col">
        {/* Search header */}
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt1Icon className="h-6 w-6" aria-hidden="true"/>
          </button>
        </div>
        <main className="flex-1">
          {/* Page title & actions */}
          <div
            className="border-b bg-white border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
            <div className="flex-1 min-w-0">
              <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate capitalize">
                <div className="flex items-center">
                 Admin
                </div>
              </h1>
            </div>
          </div>
          {/* Pinned projects */}
          {children}
        </main>
      </div>
    </div>
  );
};
const AdminIndex = () => {
  const user = getCurrentUser();
  if(!user.getRoles().includes("ROLE_ADMIN")) {
    window.location.pathname = "/";
  }
  return (
      <AdminContainer>
        <SentryRoutes>
          <Route path="/google_data_studio" element={<GoogleDataStudio />}/>
          <Route path="/google_data_studio/:id" element={<FieldGoogleDataStudio />}/>
          <Route path="/companies" element={<Companies />}/>
          <Route path="/users" element={<Users />}/>
        </SentryRoutes>
      </AdminContainer>
  );
}

export default AdminIndex;
