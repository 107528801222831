import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, POWERBI } from "../../constant/destination";

class PowerBI extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => POWERBI

  getName = (): string => "PowerBI";

}

export default PowerBI;
