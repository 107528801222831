import Model from "../model";

interface PaginationInterface {
    itemPerPage: number
    page: number
    totalItems: number
}

class Pagination<T> {

    private _pagination: PaginationInterface;

    private _data: Array<T>;


    constructor(data: Array<T>, pagination: PaginationInterface) {
        this._data = data;
        this._pagination = pagination;
    }

    get pagination(): PaginationInterface {
        return this._pagination;
    }


    get data(): Array<T> {
        return this._data;
    }

    unshiftElement(element: T): Pagination<T> {
        this._data.unshift(element);
        return this;
    }

    pushElement(element: T): Pagination<T> {
        this._data.push(element);
        return this;
    }

    updateElement(getter: string, value: any, element: T): Pagination<T> {
        for (let i = 0; i < this._data.length; i++) {
            // @ts-ignore
            if(this._data[i][getter]() === value) {
                this._data[i] = element;
            }
        }
        return this;
    }

    deleteElement(element: T): Pagination<T> {
        for (let i = 0; i < this._data.length; i++) {
            console.log(this._data[i], element);
            if(this._data[i] === element) {
                this._data.splice(i, 1);
            }
        }
        return this;
    }
}

export default Pagination;