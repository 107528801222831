import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import DataSourceModel from "../../models/http/datasource/datasource-all";
import DataSourceModelGet from "../../models/http/datasource/data-source-client";
import DeleteSerializer from "../../models/http/delete";

class DataSourceAPI extends API {

    serializer = () => ({
        _getAll: DataSourceModel,
        _put: DataSourceModel,
        _delete: DeleteSerializer,
        _getOne: DataSourceModelGet,
        _postOne: DataSourceModel
    });

}

export default Proxy<DataSourceAPI>(new DataSourceAPI('/data_sources'));