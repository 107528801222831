import React, {ReactElement} from "react";
import {getColor, getIcon, STATE_TYPE} from "../base/helper";


const Alert = ({icon, title, children, type} : {type: STATE_TYPE, icon?: ReactElement, title: ReactElement|string, children: ReactElement}) => {
    const color = getColor(type);

    return <div className={`rounded-md bg-${color}-50 p-4`}>
        <div className="flex">
            <div className="flex-shrink-0">
                {icon ? icon : getIcon(type, color)}
            </div>
            <div className="ml-3">
                <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
                <div className={`mt-2 text-sm text-${color}-700`}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default Alert;