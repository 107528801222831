import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Logo from "../../../ui/components/logo/logo.svg";
import Loader from "../../../ui/components/loader/loader";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import UserAPI from "../../../services/repository/user/user";
import { useCompanyByInvitedUser } from "../../../hooks/user";

const CreateUser = () => {
  const {t} = useTranslation();
  const {token} = useParams();
  const user = useCompanyByInvitedUser(token as string);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ''
    },
    onSubmit: async values => {
      setLoading(true);
      try {
        const response = await UserAPI.createUser(token as string, values.password);
        localStorage.clear();
        window.location.href = "/login";
      }catch (e){
        console.error(e);
        NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'))
      }
      finally {
        setLoading(false);
      }
    },
  });


  useEffect(() => {
      if(user.data) {
        formik.setFieldValue('email', user.data?.getEmail())
      }
    }, [user.isSuccess]);

  return ( <>
    <Helmet>
      <title>Catchr - Invited user</title>
    </Helmet>
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={Logo}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('Activate your account')}</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Loader active={user.isLoading || loading}>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  label={t('Email')}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  touched={formik.touched.email}
                  error={formik.errors.email}
                  required
                  disabled
                />
              </div>

              <div>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label={t('Choose a password')}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  touched={formik.touched.password}
                  error={formik.errors.password}
                  required
                  autoComplete="current-password"
                />
              </div>

              <div>
                <Button full size="large" type="primary" htmlType="submit">
                  {t('Activate')}
                </Button>
              </div>
            </form>
          </div>
        </Loader>
      </div>
    </div>
  </>
  );
};

export default CreateUser;
