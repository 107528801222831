import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BellIcon, UserCircleIcon } from "@heroicons/react/solid";
import { Link, LinkProps, Route, Routes, useMatch, useResolvedPath } from "react-router-dom";
import { classNames } from "../../services/helper";
import General from "./containers/general";
import BackUp from "./containers/backup";
import ChangePassword from "./containers/change-password";
import { SentryRoutes } from "../../index";

export const CustomLink = ({icon, children, to, ...props }: LinkProps & {icon: any}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true })
  if((to as string).indexOf('https') !== -1){
    return <a
      href={to as string}
      className={classNames(
        match
          ? 'bg-purple-50 border-purple-500 text-purple-700 hover:bg-purple-50 hover:text-purple-700'
          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
      )}
      aria-current={match ? 'page' : undefined}
    >
      {React.createElement(icon, {
        className: classNames(
          match
            ? 'text-teal-500 group-hover:text-purple-500'
            : 'text-gray-400 group-hover:text-purple-500',
          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
        )
      })}
      <span className="truncate">{children}</span>
    </a>
  }

  return <Link
    to={to}
    {...props}
    className={classNames(
      match
        ? 'bg-purple-50 border-purple-500 text-purple-700 hover:bg-purple-50 hover:text-purple-700'
        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
      'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
    )}
    aria-current={match ? 'page' : undefined}
  >
    {React.createElement(icon, {
      className: classNames(
        match
          ? 'text-teal-500 group-hover:text-purple-500'
          : 'text-gray-400 group-hover:text-purple-500',
        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
      )
    })}
    <span className="truncate">{children}</span>
  </Link>
}

const Profile = () => {
  const {t} = useTranslation();

  const subNavigation = [
    {name: t('Profile'), key: "profile", link: "/profile", icon: UserCircleIcon},
    {name: t('Change Password'), key: "change-password", link: "/profile/change-password", icon: UserCircleIcon},
    {name: t('Back-up'), key: "backup", link: "/profile/backup", icon: BellIcon},
  ];


  return (
    <main className="relative -mt-32">
      <div className="max-w-screen-xl mx-auto pb-6 lg:pb-16 ">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <CustomLink to={item.link} icon={item.icon}>{item.name}</CustomLink>
                ))}
              </nav>
            </aside>
            <SentryRoutes>
              <Route index element={<General />}/>
              <Route path="change-password" element={<ChangePassword />}/>
              <Route path="backup" element={<BackUp />}/>
            </SentryRoutes>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
