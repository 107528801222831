import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import Container from "../../../ui/components/container/container";
import Modal, {DefaultBottom, Title} from "../../../ui/components/modal";
import FieldForm, {FormField, FormFieldValidation} from "./form/field-form";
import Field, {PutField} from "../../../services/models/http/google-datastudio/field";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import {usePutFieldGoogleDataStudioMutation} from "../../../hooks/google-datastudio";

export const UpdateField = ({field, visible, setVisible}: {field: Field, visible: boolean, setVisible: (value: boolean) => void}) => {
    const queryClient = useQueryClient();
    const putGoogleDataStudio = usePutFieldGoogleDataStudioMutation();

    const initialValues = {
        id: field.getId(),
        companyId: field.getCompanyId(),
        platformName: field.getPlatform().name,
        platform: `/api/platforms/${field.getPlatform().id}`,
        formula: field.getFormula(),
        defaultDescription: field.getDefaultDescription(),
        defaultName: field.getDefaultName(),
        description: field.getDescription(),
        name: field.getName(),
        aggregationType: field.getAggregationType(),
        fieldType: field.getFieldType(),
        type: field.getType(),
        status: field.getStatus()
    };

    const {t} = useTranslation();

    const onClose = () => {
        setVisible(false);
    }

    const onSubmit = async (values: FormField) => {
        try {
            const response = await putGoogleDataStudio.mutateAsync(values as PutField);
            NotificationManager.success(t('Your field has been successfully updated'), "")
            await queryClient.cancelQueries(['field_datastudio']);
            queryClient.setQueriesData(['field_datastudio'], (old) => (old as Pagination<Field>).updateElement("getId", response.getId(), response))
            setVisible(false);
        } catch(error) {
            NotificationManager.error(t('Unable to update the field'), t('An error occurred. Please try again.'))
        }
    }

    const form = useFormik<FormField>({
        initialValues,
        validationSchema: FormFieldValidation,
        onSubmit
    });

    return <Container>
        <Modal
            open={visible}
            onClose={onClose}
            bottom={<DefaultBottom disabledOkButton={!(form.isValid)} loading={putGoogleDataStudio.isLoading} onClose={onClose} titleButtonOk={t('Save')} onOk={() => form.submitForm()} titleButtonClose={t('Close')} />}
        >
            <Title title={t('Update a field')} />
            <FieldForm form={form} />
        </Modal>
    </Container>
}

export default UpdateField;