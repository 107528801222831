import React from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Input from "../../../../ui/components/input/input";
import DatastudioTypeSelector from "../../../../ui/components/select/datastudio-type";
import DatastudioFieldTypeSelector from "../../../../ui/components/select/datstudio-field-type";
import DatastudioAggregationTypeSelector from "../../../../ui/components/select/datastudio-aggregation-type";
import NetworkSelector from "../../../../ui/components/select/network";

export interface FormField {
    id: number|undefined,
    companyId: number|undefined,
    platform: string|undefined,
    platformName: string|undefined,
    formula: string|undefined,
    defaultDescription: string|undefined,
    defaultName: string|undefined,
    description: string|undefined,
    aggregationType: string|null|undefined,
    fieldType: string|undefined,
    type: string|undefined,
    name: string|undefined,
    status: string|undefined
}

export const FormFieldValidation = Yup.object().shape({
    defaultName: Yup.string().required('Please input a default name for the field'),
    defaultDescription: Yup.string().required('Please input a default description for the field'),
    platformName: Yup.string().required('Please input the gateway type'),
    aggregationType: Yup.string(),
    fieldType: Yup.string().required('Please input the field type'),
    type: Yup.string().required('Please input the type')
})

export const FieldForm = ({form}: {form: any}) => {
    const {t} = useTranslation();

    return (
        <form>
            <Input
                className="mt-1"
                type="text"
                label={t('Field Default Name :')}
                id="defaultName"
                name="defaultName"
                onChange={form.handleChange}
                value={form.values.defaultName}
                touched={form.touched.defaultName}
                error={form.errors.defaultName}
            />
            <br />
            <Input
                className="mt-1"
                type="text"
                label={t('Default Description :')}
                id="defaultDescription"
                name="defaultDescription"
                onChange={form.handleChange}
                value={form.values.defaultDescription}
                touched={form.touched.defaultDescription}
                error={form.errors.defaultDescription}
            />
            <br />
            <DatastudioTypeSelector
                label={t('Type :')}
                onChange={(value) => form.setFieldValue('type', value)} placeholder={t('Input your type')}
                value={form.values.type}
            />
            <br />
            <DatastudioFieldTypeSelector
                label={t('Field type :')}
                onChange={(value) => form.setFieldValue('fieldType', value)} placeholder={t('Input your field')}
                value={form.values.fieldType}
            />
            <br />
            <DatastudioAggregationTypeSelector
                label={t('Aggregation Type :')}
                onChange={(value) => form.setFieldValue('aggregationType', value)} placeholder={t('Input your aggregation type')}
                value={form.values.aggregationType}
            />
            <br />
            <Input
                type="text"
                label={t('Formula :')}
                id="formula"
                name="formula"
                onChange={form.handleChange}
                value={form.values.formula}
                touched={form.touched.formula}
                error={form.errors.formula}
            />
            <br />
            <NetworkSelector label={t('Platform :')} mode="google-data-studio" value={form.values.platformName} onChange={(value) => form.setFieldValue('platformName', value)} placeholder={t('Input your gateway')} />
        </form>
    )
}

export default FieldForm;