import BaseDestination from "./base-destination";
import GoogleBigQuery from "./google-big-query";
import GoogleSheets from "./google-sheets";
import GoogleDataStudio from "./google-data-studio";
import { GOOGLE_BIG_QUERY, GOOGLE_SHEETS } from "../../constant/destination";
import Tableau from "./tableau";
import PowerBI from "./powerbi";
import API from "./api";
import JSON from "./json";
import CSV from "./csv";

class DestinationProvider {

  private available: BaseDestination[];

  constructor() {
    this.available = [
      new GoogleBigQuery(),
      new GoogleSheets(),
      new GoogleDataStudio(),
      new Tableau(),
      new PowerBI(),
      new API(),
      new JSON(),
      new CSV(),
      new API(),
    ]
  }

  public getAll(){
    return this.available;
  }

  public getAllConfigurable(){
    return this.available.filter(elem => elem.getDestinationIdentifier() !== GOOGLE_SHEETS && elem.getDestinationIdentifier() === GOOGLE_BIG_QUERY);
  }

  public getByPlatform(platform: string){
    console.log(platform, this.available.find(elem => elem.getDestinationIdentifier() === platform));
    return this.available.find(elem => elem.getDestinationIdentifier() === platform)
  }

  public isAvailable(platform: string){
    return this.available.some(elem => elem.getDestinationIdentifier() === platform)
  }
}

export default new DestinationProvider();
