import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetConnection, usePutConnectionMutation } from "../../../../hooks/connections";
import Async from "../../../../ui/helper/async";
import ConnectionForm, { ConnectionFormValue } from "../form/connection-form";
import { deserialize, serializer } from "../utils";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import routing from "../../../../routing";

const EditConnection = () => {
  const {t} = useTranslation();
  const {id} = useParams();

  const [submitting, setSubmitting] = useState(false);

  const connection = useGetConnection(parseInt(id as string, 10));
  const mutation = usePutConnectionMutation();
  const navigate = useNavigate();

  const onSubmit = async (data : ConnectionFormValue) => {
    setSubmitting(true);
    try{
      await mutation.mutateAsync({id: parseInt(id as string, 10), ...serializer(data)});
      NotificationManager.success(t('Your connection has been updated.'), t('Catchr will synchronize your data.'));
      navigate(routing.user.data_connector);
    }catch (e){
      NotificationManager.error(t('Unable to update the connection.'), t('An error occurred when we try to add your connection.'));
    }
    setSubmitting(false);
  }

  return (
    <div className="w-full py-4 flex justify-center">
      <Async {...connection}>
        {connection.data ?
          <ConnectionForm submitting={submitting} mode="EDIT" initialValues={deserialize(connection.data)} onSubmit={onSubmit}   />
        : <div>{t('Not available')}</div>}
      </Async>
    </div>
  );
};

export default EditConnection;
