import {Transition} from "@headlessui/react";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import ReactDom from "react-dom";
import {XIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import {getColor, getIcon, STATE_TYPE} from "../base/helper";

// eslint-disable-next-line import/no-mutable-exports
export let listMap: any[] = [];
let uniqKey = 0;
const Notification = ({
                          type,
                          title,
                          description,
                          children
                      }: { type: STATE_TYPE, title: string|ReactElement, description: string|ReactElement, children?: ReactElement }) => {
    const [show, setShow] = useState(true)
    const {t} = useTranslation();
    return (
      <div
        aria-live="assertive"
        className="z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
          <div className="z-30 w-full flex flex-col items-center space-y-4 sm:items-end">
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                  <div
                    className="z-30 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="p-4">
                          <div className="flex items-start">
                              <div className="flex-shrink-0">
                                  {getIcon(type, getColor(type), 'h-6 w-6')}
                              </div>
                              <div className="ml-3 w-0 flex-1 pt-0.5">
                                  <p className="text-sm font-medium text-gray-900">{title}</p>
                                  <p className="mt-1 text-sm text-gray-500">{description}</p>
                                  {children ? <div className="mt-3 flex space-x-7"> {children}</div> : null}
                              </div>
                              <div className="ml-4 flex-shrink-0 flex">
                                  <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        setShow(false)
                                    }}
                                  >
                                      <span className="sr-only">{t('Close')}</span>
                                      <XIcon className="h-5 w-5" aria-hidden="true"/>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </Transition>
          </div>
      </div>
    )
}

export const NotificationManager = {
    info: (title : string | ReactElement,
           description : string| ReactElement,
           time  = 5000
           ) => {

        uniqKey += 1;
        const event = new CustomEvent('notifications', {detail : {type: "info", title, description, key: uniqKey, time}});
        window.dispatchEvent(event);
    },
    success: (title : string | ReactElement,
           description : string| ReactElement,
           time  = 5000
           ) => {

        uniqKey += 1;
        const event = new CustomEvent('notifications', {detail : {type: "success", title, description, key: uniqKey, time}});
        window.dispatchEvent(event);
    },
    error: (title : string|ReactElement,
           description : string|ReactElement,
           time  = 5000
           ) => {

        uniqKey += 1;
        const event = new CustomEvent('notifications', {detail : {type: "error", title, description, key: uniqKey, time}});
        window.dispatchEvent(event);
    }
}
export const NotificationContainer = () => {
    const [list, setList] = useState<Array<any>>([]);

    useEffect(() => {
        window.addEventListener("notifications", (e: CustomEvent|Event) => {
            const eventNotification = e as CustomEvent;

            listMap = [...listMap, eventNotification.detail ]

            setList(listMap);
            setTimeout(() => {
                listMap = [...listMap.filter(elem => elem.key !== eventNotification.detail.key)];
                setList(listMap);
            }, eventNotification.detail.time);

        });
    }, []);

    return <div id="notification-container">
        {
            listMap.map(({ type, title, description, key }) => <Notification key={`test - ${key}`} type={type} title={title} description={description} />)
        }
    </div>
}

export default Notification;