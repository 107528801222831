import Model from "../../model";
import { JSONSchemaType } from "ajv/dist/types/json-schema";

export interface History {
  status: number,
  date: string,
  costYesterday: number
}

export interface UrlChecker200Response {
  id: number,
  lastCrawl: string,
  lastStatus: number,
  histories: Array<History>,
  url: string,
  platform: string,
  accountId: string
}

class URLChecker extends Model<UrlChecker200Response>{

  getId() {
    return this.data.id;
  }

  getLastCrawl() {
    return new Date(this.data.lastCrawl);
  }

  getLastStatus() {
    return this.data.lastStatus;
  }

  getUrl() {
    return this.data.url;
  }

  getHistories() : Array<History>{
    return this.data.histories.sort((a, b) => b.date.localeCompare(a.date));
  }

  getSchemaToSerialize(): JSONSchemaType<UrlChecker200Response>{
    return {
      type: "object",
      required: ['id', 'lastCrawl', 'lastStatus', 'platform', 'url', 'histories', 'accountId'],
      properties: {
        id: {
          type: "number"
        },
        lastCrawl: {
          type: "string"
        },
        lastStatus: {
          type: "number"
        },
        url: {
          type: "string"
        },
        platform: {
          type: "string"
        },
        accountId: {
          type: "string"
        },
        histories: {
          type: "array",
          items: {
            type: "object",
            required: ['status', 'date', 'costYesterday'],
            properties: {
              status: {
                type: "number"
              },
              date: {
                type: "string"
              },
              costYesterday: {
                type: "number"
              }
            }
          }
        },
      }
    };
  }
}

export default URLChecker;