import React, { ReactNode } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';

export const Collapse = ({
                           header,
                           open,
                           onClick,
                           children,
                           subHeader,
                         }: {
  header: string | ReactNode;
  open: boolean;
  onClick: () => void;
  children: ReactNode;
  subHeader?: string | null;
}) => {
  return (
    <div>
      <div
        role="button"
        onClick={onClick}
        className={
          'cursor-pointer flex items-center justify-between border-t border-gray-200 border-b border-gray-200 bg-gray-50 px-2 py-2 text-left text-sm font-semibold text-gray-900'
        }
      >
        <div>
          {header}{' '}
          {subHeader ? (
            <span className="text-gray-500 text-xs font-light">
              {subHeader}
            </span>
          ) : null}
        </div>
        <div>
          {!open ? (
            <ArrowDownIcon className="w-4" />
          ) : (
            <ArrowUpIcon className="w-4" />
          )}
        </div>
      </div>
      <div className={`bg-white ${open ? 'block' : 'hidden'}`}>{children}</div>
    </div>
  );
};
