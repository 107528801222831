import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export type KeyDescribe = {
  accessor: string,
  name: string,
  value?: string,
  values?: Array<string>,
  can_filter: boolean
};

export type Describe200Response = {
  class_name: string,
  properties: {
    [key: string] : KeyDescribe
  }
}

class Describe extends Model<Describe200Response>{

  getProperties(){
    return this.data.properties;
  }

  getSchemaToSerialize = (): JSONSchemaType<Describe200Response> => ({
    "type": "object",
    "required": ["class_name", "properties"],
    "properties": {
      "class_name": {
        "type": "string"
      },
      "properties": {
        "type": "object",
        "required": [],
        patternProperties: {
          ".*": {
            type: "object",
            "required": ["name", "can_filter", "accessor"],
            properties: {
              accessor: {
                type: "string"
              },
              name: {
                type: "string"
              },
              value: {
                type: "string",
                nullable: true
              },
              values: {
                type: "array",
                items: {
                  type: "string"
                },
                nullable: true
              },
              can_filter: {
                type: "boolean"
              },
            }
          }
        }
      }
    }
  });
}

export default Describe;