import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import aggregationTypeProvider from "../../../features/google-datastudio/aggregation-type/aggregation-type-provider";
import Select from "./select";

const DatastudioAggregationTypeSelector = ({
                           ...props
                         }: { label?: string, value?: string, onChange: (item: string) => void, placeholder: string }) => {

    const list = aggregationTypeProvider.getAllAggregation().reduce((stack: Array<{name: string, value: string|null}>, current) => {
        const elem = {name: current.getName(), value: current.getId()};
        stack.push(elem);
        return stack;
    }, []);
  return <Select {...props} list={list} />;
};

export default DatastudioAggregationTypeSelector;
