import { useMutation, useQuery } from "react-query";
import DataSourceAPI from "../../services/repository/datasource/datasource";
import DataSourceModelAll, {
  DataSourcePostHTTP,
  DataSourcePutHTTP
} from "../../services/models/http/datasource/datasource-all";
import DataSourceModelClient from "../../services/models/http/datasource/data-source-client";
import Pagination from "../../services/models/http/paginated";

export const useDataSourceListing = (query = {}) => useQuery(["datasource_listing", query], (): Promise<Pagination<DataSourceModelAll>> => DataSourceAPI._getAll({ pagination: false, query }), {
  retry: 0,
  cacheTime: 15 * 60 * 1000
});

export const useDataSourceClient = (id: number) => useQuery(["datasource_client", id], (): Promise<DataSourceModelClient> => DataSourceAPI._getOne(id), {
  retry: 0
});

export const useDeleteMutation = (options: any = {}) => useMutation((id: number) => DataSourceAPI._delete(id), options);

export const useBackUpMutation = () => useMutation(({id, backedBy} : {id: number, backedBy: string|null}) => DataSourceAPI._put(id, {backedBy}))

export const usePostDataSourceMutation = (options: any) => useMutation<DataSourceModelAll, unknown, DataSourcePostHTTP>((data) => DataSourceAPI._postOne(data), options);
export const usePutDataSourceMutation = () => useMutation<DataSourceModelAll, unknown, {id: number, data: DataSourcePutHTTP}>(({id, data}) => DataSourceAPI._put(id, data));
