import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";
import {NETWORK} from "../../../../constant/network";
import NetworkProvider from '../../../../config/network/network-provider';

type Stat = { [key: string]: { type: string, numberStatusTrue: number, numberTotalConfigured: number } };

export interface DataSourceHTTP {
    id: number,
    name: string,
    accountId: string,
    accountUsersManager: Array<{id: number}>
    authorization: {
        platform: NETWORK
    },
    health: {
        summaryAccountNumber: Array<{ time: string, clicks: number, conversions: number, cost: number, impressions: number }>,
        statsOnAlert: Stat | Array<string>
    }
}

class DataSource extends Model<DataSourceHTTP> {

    public getId() {
        return this.data.id
    };

    public getName() {
        return this.data.name;
    };

    public getAccountId() {
        return this.data.accountId;
    };

    public getAuthorization() {
        return this.data.authorization;
    };

    public getPlatform() {
        return this.data.authorization.platform;
    };

    public getNetworkConfig(){
        return NetworkProvider.getByPlatform(this.getPlatform());
    }

    public getAccountUsersManager() {
        return this.data.accountUsersManager;
    };


    public getHealthMetrics() {
        return this.data.health.summaryAccountNumber;
    };

    public getHealthAlert() {
        return Array.isArray(this.data.health.statsOnAlert) ? {} : this.data.health.statsOnAlert  as Stat;
    };


    getSchemaToSerialize(): JSONSchemaType<DataSourceHTTP> {
        return {
            type: "object",
            properties: {
                "id": {type: "number"},
                "name": {type: "string"},
                "accountId": {type: "string"},
                "accountUsersManager": {
                    type: "array",
                    items : {
                        type: "object",
                        properties: {
                            "id": {"type": "number"}
                        },
                        required: ["id"]
                    }
                },
                "authorization": {
                    type: "object",
                    properties: {
                        "platform": {"type": "string"}
                    },
                    required: ["platform"]
                },
                "health": {
                    type: "object",
                    properties: {
                        "summaryAccountNumber": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "required": ["clicks", "cost", "conversions", "impressions", "time"],
                                "properties": {
                                    "clicks": {
                                        "type": "number",
                                    },
                                    "cost": {
                                        "type": "number",
                                    },
                                    "conversions": {
                                        "type": "number",
                                    },
                                    "impressions": {
                                        "type": "number",
                                    },
                                    "time": {
                                        "type": "string",
                                    }
                                }
                            }
                        },
                        "statsOnAlert": {
                            anyOf: [{
                                "type": "object",
                                "required": [],
                                "patternProperties": {
                                    ".*": {
                                        "type": "object",
                                        "required": ["numberTotalConfigured", "numberStatusTrue", "type"],
                                        "properties": {
                                            "numberTotalConfigured": {
                                                "type": "number",
                                            },
                                            "numberStatusTrue": {
                                                "type": "number",
                                            },
                                            "type": {
                                                "type": "string",
                                            }
                                        }
                                    }
                                }
                            },{
                                type: "array",
                                items: {
                                    type: "string"
                                }
                            }],
                        }
                    },
                    required: ["summaryAccountNumber", "statsOnAlert"]
                }
            },
            required: ["id", 'name', "accountId", "health","accountUsersManager"]
        }
    }

}

export default DataSource;