import React, {ReactElement} from 'react';
import {UseQueryResult} from "react-query";
import {useTranslation} from "react-i18next";
import Alert from "../components/alert/alert";
import Loader from "../components/loader/loader";

const Async = ({
                   isLoading,
                   error,
                   isError,
                   children,
                   skeleton,
                   errorComponent,
                  isFetching,
                  isSuccess
               }: UseQueryResult & { children?: React.ReactElement|null, skeleton?: ReactElement, errorComponent?: (error: any) => ReactElement }) => {
    const {t} = useTranslation();

    if (isLoading || isFetching || (isSuccess  === undefined && isError === undefined)) {
        return skeleton || <Loader active/>
    }

    if (isError) {
        return errorComponent ? errorComponent(error) : (
            <Alert title="An error occurred" type="error">
                {t('An error occurred. Do not worry, it\'s not you, it\'s us. Try again or contact your account manager to get help.')}
            </Alert>
        );
    }

    if(!children){
      return <></>;
    }
    return children;
};

export default Async;
