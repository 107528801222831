import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface NetworkAuthorizationHTTP {
  id: number,
  name: string
  platform: string,
  state: string,
  lastRefresh?: string,
  status: "SYNC_STATUS_SUCCESS" | "SYNC_STATUS_ERROR"
}

class Authorize extends Model<NetworkAuthorizationHTTP>{

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getPlatform(){
    return this.data.platform;
  }

  getState(){
    return this.data.state;
  }

  getStatus(){
    return this.data.status;
  }

  getLastRefreshed(){
    return this.data.lastRefresh;
  }

  getSchemaToSerialize = (): JSONSchemaType<NetworkAuthorizationHTTP> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "name": { type: "string" },
      "platform": { type: "string" },
      "state": { type: "string" },
      "status": { type: "string" },
      "lastRefresh": {
        type: "string" ,
        nullable: true
      },
    },
    required: ['id', 'name', 'platform', 'state', 'status']
  });
}

export default Authorize;