import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import NetworkAuthorizationInformation from "../../models/http/network-authorization/network-authorization-information";

class NetworkAuthorizationInformationAPI extends API{
  serializer = () => ({
    _getOne: NetworkAuthorizationInformation,
    _getAll: NetworkAuthorizationInformation
  });
}

export default Proxy<NetworkAuthorizationInformationAPI>(new NetworkAuthorizationInformationAPI('/network_authorization_informations'));