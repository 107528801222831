import { Destination } from "../../constant/destination";

abstract class BaseDestination {
  abstract getName() : string;

  abstract getDestinationIdentifier() : Destination;

  abstract destinationImage() : string;
}

export default BaseDestination;