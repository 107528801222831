import React, { ReactElement } from "react";
import Async from "../../helper/async";
import { UseQueryResult } from "react-query/types/react/types";
import { Params, useParams } from "react-router-dom";

type Props<T, Element> = {
  hookFunc: (params: Readonly<Params<string>>) => UseQueryResult<T, any>,
  render : (data:  UseQueryResult<T, any>) => ReactElement<Element>
}
const AsyncLoader : <T, Element>(props:Props<T, Element>) => ReactElement = ({hookFunc, render}) => {
  const params = useParams();
  const data = hookFunc(params);
  return (
    <Async {...data}>
      {data.data ? render(data) : <div> No data </div>}
    </Async>
  );
};

export default AsyncLoader;
