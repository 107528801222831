import API from "../../api/http/client";
import UserModel from '../../models/http/user/user'
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";

class User extends API {

  useAs = (id:number) =>  this.getClient().post(`/admin/useAs/${id}`)

  _getByToken = (token:string) : Promise<UserModel> => this.getClient().get(`${this.baseUrl}/by-token/${token}`);

  createUser = (token: string, password: string) : Promise<UserModel> => this.getClient().put(`${this.baseUrl}/${token}/activate`, {
    id: token,
    plainPassword: password
  })

  forgetPassword = ({email} : {email: string}) => this.getClient().post(`${this.endpoint}/forgot_password`, { email })

  public serializer = () => ({
    '_getAll': UserModel,
    '_getByToken': UserModel,
    _delete: DeleteSerializer
  });

  public changePassword({id, plainPassword} : {id: number; plainPassword : string}){
    return this.getClient().put(`${this.endpoint}/users/${id}/new_password`, {id, plainPassword})
  }
}

export default Proxy<User>(new User('/users'));
