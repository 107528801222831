import React from "react";
import { LinkIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useGetAllUrlChecker } from "../../../../hooks/alert/url-checker";
import DataSource from "../../../../services/models/http/datasource/data-source-client";
import Async from "../../../../ui/helper/async";
import { classNames } from "../../../../services/helper";
import Table, { DateCell } from "../../../../ui/components/table";

const Status = ({value} : {value: number}) => {
  return <span className={classNames(
    value !== 200 ? "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800" : "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"
  )}>{value}</span>
}

const LinkCell = ({value}: {value: string}) => {
  return <div className="flex items-center">
    <a href={value} >{value}</a> <LinkIcon className="h-5 w-5 ml-2"/>
  </div>
}

const URLChecker = ({dataSource}: {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const columns = React.useMemo(() => [
    {
      Header: t('Url'),
      accessor: "url",
      Cell: LinkCell,
      sticky: 'left',
      width: 500
    },
    {
      Header: t('Status'),
      accessor: "status",
      Cell: Status,
      width: 100
    },
    {
      Header: t('Cost'),
      accessor: "cost",
      width: 100
    },
    {
      Header: t('Last Update'),
      accessor: "last_crawl",
      Cell: DateCell,
      width: 100
    }
  ], []);
  const urls = useGetAllUrlChecker(dataSource.getAccountId(), dataSource.getPlatform())
  return (

    <div className="mx-8 my-8 flex flex-col space-y-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Summary')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <Async {...urls}>
            {urls.data && !urls.isLoading ?
              <>
                <div key="analyzed" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("Analyzed")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{urls.data.pagination.totalItems}</dd>
                </div>
                <div key="in_error" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("In error")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{urls.data.data.filter(elem => elem.getLastStatus() !== 200).length}</dd>
                </div>
              </>
            : null}
          </Async>
        </dl>
      </div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t('Details')}</h3>

        <Table data={(urls.data?.data || []).map(elem => ({url: elem.getUrl(), last_crawl: elem.getLastCrawl(), status: elem.getLastStatus(), cost: elem.getHistories()[0].costYesterday}))} columns={columns} loading={urls.isLoading}/>
      </div>
    </div>
  );
};

export default URLChecker;
