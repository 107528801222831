import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Helmet} from "react-helmet";
import * as Yup from "yup";
import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import { useNavigate } from "react-router-dom";
import Logo from '../../ui/components/logo/logo.svg';
import Checkbox from "../../ui/components/input/checkbox";
import Input from "../../ui/components/input/input";
import Button from "../../ui/components/button/button";
import Loader from "../../ui/components/loader/loader";
import LoginAPI from '../../services/repository/user/login';
import LocalStorage from '../../services/storage/local';
import { NotificationManager } from "../../ui/components/notification/notification";
import { useRegisterFromGoogleMutation } from "../../hooks/user";
import Modal, { DefaultBottom, Title } from "../../ui/components/modal";
import UserAPI from '../../services/repository/user/user';
import {useSegmentTrack} from "react-segment-analytics";

type ModalProps = {
    visible: boolean,
    onClose: () => void,
    onSuccess: () => void
}

type PeopleRecover = {
    email: string
}

const Validation = Yup.object({
    email: Yup.string().email().required()
})

const RecoverPassword = ({visible, onClose, onSuccess}: ModalProps) => {
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);

    const form = useFormik<Partial<PeopleRecover>>({
        initialValues: {
            email: undefined
        },
        validationSchema: Validation,
        onSubmit: async (values) => {
            setSaving(true);
            try{
                await UserAPI.forgetPassword({email: values.email as string})
                NotificationManager.success(`🚀 An email has been send to ${values.email}.`, 'Once the account is activated, the user can access Catchr. ');
                onSuccess();
                form.resetForm();
            }catch (e: any){
                NotificationManager.error(`An error occurred when we try to recover the password for ${values.email}`, e.response.data.message)
            }finally {
                setSaving(false);

            }
        }
    })

    return <Modal
      open={visible}
      onClose={onClose}
      bottom={<DefaultBottom
        disabledOkButton={!(form.dirty && form.isValid)}
        onClose={onClose}
        titleButtonOk={t('Next')}
        onOk={() => form.submitForm()}
        loading={saving}
        titleButtonClose={t('Close')}
      />}
    >
        <Title title={t('Recover your password')} />
        <Input
          id="email"
          name="email"
          type="email"
          label="Email"

          onChange={form.handleChange}
          value={form.values.email}
          touched={form.touched.email}
          error={form.errors.email}
        />
    </Modal>
}
const Login = () => {
    const track = useSegmentTrack();
    const registerFromGoogle = useRegisterFromGoogleMutation();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async values => {
            setLoading(true);
            track("Trying to Sign In", {email: values.email});
            try {
                const response = await LoginAPI._login({ username: values.email, password: values.password });
                LocalStorage.set('JWT_TOKEN', response.data.token);
                await track("Signed In", {email: values.email});
                window.location.href = "/";
            }catch (e){
                console.error(e);
                NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'))
                track("Unable to Sign In", {email: values.email});
            }
            finally {
                setLoading(false);
            }
        },
    });


    return <>
        <Helmet>
            <title>Catchr - Login</title>
        </Helmet>
        <RecoverPassword visible={openModal} onClose={() => setOpenModal(false)} onSuccess={() => setOpenModal(false)} />
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={Logo}
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('Sign in to your account')}</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    {t('Or')}{' '}
                    <GoogleLogin
                        onRequest={() => {
                                track("Trying to Sign In from Google")
                            }
                        }
                        clientId="105475051584-fs2e6vvtku92jnt698jbukgmmjhal63l.apps.googleusercontent.com"
                        onSuccess={async (data) => {
                            const googleResponse = (data as GoogleLoginResponse);
                            setLoading(true);
                            try {
                                const response = await registerFromGoogle.mutateAsync({
                                    lastName: googleResponse.profileObj.familyName,
                                    firstName: googleResponse.profileObj.givenName,
                                    email: googleResponse.profileObj.email,
                                    username: googleResponse.profileObj.email,
                                    googleId: googleResponse.googleId
                                });
                                LocalStorage.set('JWT_TOKEN', response.data.token);
                                await track("Signed In", {email: googleResponse.profileObj.email});
                                window.location.href = "/";
                            }catch (e){
                                console.error(e);
                                NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'));
                            }finally {
                                setLoading(false)
                            }
                        }}
                        onFailure={(e) => {
                            track("Failure to Sign In from Google");
                            console.error(e);
                            NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'));
                        }}
                        render={renderProps => (
                            <a role={"button"} onClick={renderProps.onClick} className="font-medium text-indigo-600 hover:text-indigo-500">
                                {t('start your 14-day free trial')}
                            </a>
                        )}
                        cookiePolicy="single_host_origin"
                    />

                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <Loader active={loading}>
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={formik.handleSubmit}>
                            <div>
                                <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    label={t('Email')}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    touched={formik.touched.email}
                                    error={formik.errors.email}
                                    required
                                />
                            </div>

                            <div>
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    label={t('Password')}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    touched={formik.touched.password}
                                    error={formik.errors.password}
                                    required
                                    autoComplete="current-password"
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <Checkbox id="remember-me" name="remember-me" label={t('Remember Me')}/>
                                </div>

                                <div className="text-sm">
                                    <a onClick={() => setOpenModal(true)} href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        {t('Forgot your password?')}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <Button full size="large" type="primary" htmlType="submit">
                                    {t('Sign in')}
                                </Button>
                            </div>
                        </form>

                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"/>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">{t('Or continue with')}</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <GoogleLogin
                                  clientId="105475051584-fs2e6vvtku92jnt698jbukgmmjhal63l.apps.googleusercontent.com"
                                  onSuccess={async (data) => {
                                      const googleResponse = (data as GoogleLoginResponse);
                                      setLoading(true);
                                      try {
                                          const response = await registerFromGoogle.mutateAsync({
                                              lastName: googleResponse.profileObj.familyName,
                                              firstName: googleResponse.profileObj.givenName,
                                              email: googleResponse.profileObj.email,
                                              username: googleResponse.profileObj.email,
                                              googleId: googleResponse.googleId
                                          });
                                          LocalStorage.set('JWT_TOKEN', response.data.token);
                                          await track("Success Sign In from Google", { email: googleResponse.profileObj.email });
                                          window.location.href = "/";
                                      }catch (e){
                                          console.error(e);
                                          NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'));
                                      }finally {
                                          setLoading(false)
                                      }
                                  }}
                                  onFailure={(e) => {
                                      track("Failure to Sign In from Google");
                                      console.error(e);
                                      NotificationManager.error(t('Unable to login'), t('There is an error when you trying to Login.'));
                                  }}
                                  render={renderProps => (
                                    <button
                                      onClick={renderProps.onClick} disabled={renderProps.disabled}
                                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                                <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                                                <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                                                <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                                                <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                                            </g>
                                        </svg>
                                        <span className="ml-2">{t('Sign in with Google')}</span>
                                    </button>
                                  )}
                                  cookiePolicy="single_host_origin"
                                />
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        </div>
    </>
}

export default Login;
