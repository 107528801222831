import API from "../../api/http/client";
import LoginModel, {LoginRequest} from '../../models/http/user/login';
import Proxy from "../../api/http/proxy";

class Login extends API{

    public _login(data: LoginRequest) {
        return this.getClient().post('/login', data);
    }

    getCode(clientId: string, clientSecret: string, redirectUri: string){
        return this.getClient().get(`${this.endpoint}/authorize`, {params : {response_type:"code", client_id: clientId, client_secret: clientSecret, redirect_uri: redirectUri}});
    }

    serializer = () => ({
        '_login': LoginModel
    });
}

export default Proxy<Login>(new Login());