import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, GOOGLE_DATA_STUDIO, TABLEAU } from "../../constant/destination";

class Tableau extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => TABLEAU

  getName = (): string => "Tableau";

}

export default Tableau;
