import { useMutation, useQuery } from "react-query";
import { omit } from "lodash";
import Pagination from "../../services/models/http/paginated";
import ConnectionModel, { PostConnectionHTTP } from "../../services/models/http/data-connector/connection";
import ConnectionAPI from "../../services/repository/data-connector/connection";

export const useConnection = (page: number, options = {}) => useQuery(['connections', page, options], () : Promise<Pagination<ConnectionModel>> => ConnectionAPI._getAll({query : {page, ...options}}));
export const useConnectionUsage = (page: number, options = {}) => useQuery(['connection-usage'], () : Promise<Pagination<ConnectionModel>> => ConnectionAPI._getAll({query : {page, ...options}}));

export const useGetConnection = (id: number) => useQuery(['connection', id], () => ConnectionAPI._getOne(id));

export const useGetLogConnection = (id: number) => useQuery(['connection', 'log',  id], () => ConnectionAPI._summary(id));

export const usePostConnectionMutation = () => useMutation<ConnectionModel, unknown, PostConnectionHTTP>('connection', (data) => ConnectionAPI._postOne(data));

export const usePutConnectionMutation = () => useMutation<ConnectionModel, unknown, {id: number} & PostConnectionHTTP>('connection', (data) => ConnectionAPI._put(data.id, omit(data, 'id')));

export const useDeleteConnectionMutation = (id: number) => useMutation(['connection', id], () => ConnectionAPI._delete(id));
