import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompaniesAsAdmin } from "../../../hooks/company";
import { classNames } from "../../../services/helper";
import Async from "../../../ui/helper/async";

const Companies = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState({} );
  const companies = useCompaniesAsAdmin(page, {});


  return (
    <div>
      <div className="px-4 mt-6 sm:px-6 lg:px-8">
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{t('Companies list')}</h2>
        <ul role="list" className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
        </ul>
      </div>
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <Async {...companies}>
            <table className="min-w-full table-fixed">
              <thead>
              <tr className="border-t border-gray-200 bg-white">
                <th
                  scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">{t('Company Name')}</span>
                </th>
                <th
                  scope="col" className="hidden md:table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Status')}
                </th>
                <th
                  scope="col" className="hidden md:table-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Created At')}
                </th>
                <th
                  scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
              {companies.data?.data.map((company) => (
                <tr key={company.getId()}>
                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center lg:pl-2">
                      <a href="#" className="truncate hover:text-gray-600">
                          <span>
                            {company.getName()}
                          </span>
                      </a>
                    </div>
                  </td>
                  <td className="hpx-6 py-3 text-sm text-gray-500 font-medium">
                    <div className="flex items-center flex-col">
                      <div>{company.getStripeSubscriptionId() === null ? <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">

                    {t('Free trial')}
                      </span> : <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                    {t('Payed')}
                      </span>}</div>
                    </div>
                  </td>
                  <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                    <div className="flex items-center flex-col">
                      <div><b>{t('Created At : ')}</b>{new Date(company.getCreatedAt()).toDateString()}</div>

                      <div><b>{t('Free-trial : ')}</b>{new Date(company.getTrialFinished()).toDateString()}</div>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                      {t('View')}
                    </a>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </Async>
        </div>
      </div>
    </div>
  );
};

export default Companies;
