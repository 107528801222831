import API, { ModelDerived } from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import SentryListing from "../../models/http/alert/sentry-listing";
import TemplateListing from "../../models/http/alert/template-listing";
import { NETWORK } from "../../../constant/network";
import SentryModelListing from "../../models/http/alert/model";
import { AlertType, Variable } from "../../../features/alerts/editor/helpers/sentry-template";
import AccessibilityModel from "../../models/http/alert/accessibility";
import Visualize, { NetworkType, SentryVisualizerPost } from "../../models/http/alert/visualize";
import { FormValue } from "../../../features/alerts/editor";
import Sentry from "../../models/http/alert/sentry";
import DeleteSerializer from "../../models/http/delete";


const sentryNetworkSerializer = (sentry: FormValue): SentryVisualizerPost => {
  const { network, ...others } = sentry;
  return ({
    ...others,
    title: sentry.name,
    pivot: sentry.type === "metric" || sentry.type === "kpi",
    requests: Object.keys(network).map(key => ({ ...network[key], key }))
  });
};

export const sentryNetworkDeSerializer = (sentry: Sentry): FormValue & {id: number} => {
  return ({
    ...sentry.data,
    type: sentry.getType() as AlertType,
    name: sentry.getName(),
    network: sentry.data.requests.reduce((previous: { [x: string]: any; }, current: { key: string; }) => ({
        [current.key]: current,
        ...previous
    }), {}),
    useAsTemplate: sentry.data.useAsTemplate,
    variables: sentry.data.variables as unknown as Array<Variable>
  });
};

class SentryAPI extends API {

  _getTemplateListing = (network: NETWORK, dataSourceId: number) => this.internalGetAllRequest({
    pagination: false,
    query: { "useAsTemplate": true, "mode": "catalog", "platform": network, "dataSourceId": dataSourceId }
  });

  _getModel = (): Promise<ModelDerived> => this.getClient().get(`alerts/sentry/model`);

  _getAccessibility = (type: AlertType, platform: NETWORK, model: string): Promise<ModelDerived> => this.getClient().get(`alerts/sentry/accessibility`, {
    params: {
      type,
      platform,
      model
    }
  });

  _postVisualize = (sentry: FormValue): Promise<ModelDerived> => this.getClient().post(`/sentries/visualize`, sentryNetworkSerializer(sentry));

  _postOne<PostData>(data: PostData): Promise<ModelDerived> {
    return super._postOne(sentryNetworkSerializer(data as unknown as FormValue));
  }

  _put<PostData>(id: number, data: PostData): Promise<ModelDerived> {
    return super._put(id, sentryNetworkSerializer(data as unknown as FormValue));
  }


  serializer = () => ({
    _getAll: SentryListing,
    _getOne: Sentry,
    _delete: DeleteSerializer,
    _getTemplateListing: TemplateListing,
    _getModel: SentryModelListing,
    _getAccessibility: AccessibilityModel,
    _postVisualize: Visualize,
    _postOne: Sentry,
    _put: Sentry,
  });



}

export default Proxy<SentryAPI>(new SentryAPI("/sentries"));