import { JSONSchemaType } from "ajv/dist/types/json-schema";
import { NETWORK } from "../../../../../constant/network";
import Model from "../../../model";
import { PostRequest } from "../../../form-interface";

export interface DataStream {
  id: number,
  name: string,
  platform: NETWORK,
  datastreamJobs: Array<string>
  destination: {
    id: number,
    name: string,
    type: string
  }
}

export interface PostDataStream {
  name: string,
  platform: NETWORK,
}



class DataStreamModel extends Model<DataStream> implements PostRequest<PostDataStream>{
  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getJobs() {
    return this.data.datastreamJobs;
  }

  getPlatform(){
    return this.data.platform
  }

  getDestination(){
    return this.data.destination
  }

  baseSchemaToSerialize = () : JSONSchemaType<DataStream> => ({

    type: "object",
    properties: {
      "id": {
        type: "integer"
      },
      "name": {
        type: "string"
      },
      "datastreamJobs": {
        type: "array",
        items: {
          type: "string"
        }
      },
      "platform": {
        type: "string"
      },
      "destination" : {
        type: "object",
        properties: {
          "id": {
            type: "integer"
          },
          "name": {
            type: "string"
          },
          "type": {
            type: "string"
          }
        },
        required: ['id', 'name', 'type']
      }
    },
    required: ['id', 'name', 'platform', 'datastreamJobs']
  });

  baseGetSchemaPostToValidate = (): JSONSchemaType<PostDataStream> => ({
    type: "object",
    properties: {
      "name": {
        type: "string"
      },
      "platform": {
        type: "string"
      }
    },
    required: ['name', 'platform']
  })

  getSchemaPostToValidate(): JSONSchemaType<PostDataStream> {
    return this.baseGetSchemaPostToValidate() as JSONSchemaType<PostDataStream>;
  }

  getSchemaToSerialize(): JSONSchemaType<DataStream> {
    return this.baseSchemaToSerialize() as JSONSchemaType<DataStream>;
  }
}

export default DataStreamModel;
