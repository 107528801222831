import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, TWITTER_PUBLIC_DATA } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { SOCIAL } from "../../constant/constant";

class TwitterPublicData extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "X - Twitter Public Data";

  getNetworkIdentifier = (): NETWORK => TWITTER_PUBLIC_DATA;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];

  connectionMethod(args: any): any {
    return {
      "accounts": args.accounts
    }
  }

}

export default TwitterPublicData;
