import React from "react";
import AccessibilityModel from "../../../services/models/http/alert/accessibility";
import { CalculatedField, MetricType, Threshold, Trend } from "../editor/helpers/sentry-template";
import ThresholdComponent from "../editor/type/metric/threshold";
import TrendsComponent from "../editor/type/metric/trend";

const MetricFactory = ({key, accessibility, calculatedFields, type, onChange, value, initialValues} : {key:string, accessibility:AccessibilityModel, calculatedFields: CalculatedField[], type: MetricType, onChange: (config: Threshold|Trend) => void, value: Trend|Threshold, initialValues: Trend|Threshold}) => {
  switch (type) {
    case "threshold":
      return <ThresholdComponent initialValues={initialValues as Threshold} key={key} onChange={onChange} value={value as Threshold} accessibility={accessibility} calculatedField={calculatedFields}/>
    case "trend":
      return <TrendsComponent initialValues={initialValues as Trend} key={key} onChange={onChange} value={value as Trend} accessibility={accessibility} calculatedField={calculatedFields}/>
    default:
      throw new Error(`Unable to get Configuration for ${type}`);
  }
};

export default MetricFactory;
