import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import Describe from "../../models/http/search/describe";
import * as NETWORK from '../../../constant/network';
import * as LEVEL from '../../../constant/level';


const mapping = {
  [NETWORK.ADWORDS] : {
    [LEVEL.ACCOUNT] : "/adwords_accounts",
    [LEVEL.CAMPAIGN] : "/adwords_campaigns",
    [LEVEL.TAG] : "/adwords_conversion_trackers"
  },
  [NETWORK.AMAZON_ADS] : {
    [LEVEL.ACCOUNT] : "/amazon_accounts",
    [LEVEL.CAMPAIGN] : "/amazon_campaigns",
  },
  [NETWORK.BING] : {
    [LEVEL.TAG] : "/bing_conversion_goals",
    [LEVEL.ACCOUNT] : "/bing_accounts",
    [LEVEL.CAMPAIGN] : "/bing_campaigns"
  },
  [NETWORK.FACEBOOK] : {
    [LEVEL.ACCOUNT] : "/facebook_accounts",
    [LEVEL.CAMPAIGN] : "/facebook_campaigns",
    [LEVEL.ADGROUP] : "/facebook_ad_groups",
    [LEVEL.TAG] : "/facebook_pixels"
  },
  [NETWORK.FACEBOOK_ANALYTICS] : {
    [LEVEL.POST] : "/facebook_posts",
    [LEVEL.PAGE] : "/facebook_pages",
  },
  [NETWORK.GOOGLE_ANALYTICS] : {
    [LEVEL.EVENT_TRACKING] : "/google_analytics_event_trackings",
    [LEVEL.GOAL_CONVERSION] : "/google_analytics_goal_conversions",
    [LEVEL.PAGE_TRACKING] : "/google_analytics_page_trackings",
    [LEVEL.SOCIAL_INTERACTION] : "/google_analytics_social_interactions",
    [LEVEL.TRAFFIC_SOURCE] : "/google_analytics_traffic_sources",
    [LEVEL.USER] : "/google_analytics_users",
    [LEVEL.VIEW] : "/google_analytics_views"
  }
}

class SearchAPI extends API {

  _describe(platform: string, type: string) : Promise<Describe> {
    return this.getClient().get('/search/describe', {params: {level: type, platform}})
  }

  search(platform: string, level: string, params: any){
    return this.getClient().get(`/search/models${mapping[platform as never][level.toUpperCase()]}`, {params})
  }

  serializer = () => ({
    _describe: Describe
  });

}

export default Proxy<SearchAPI>(new SearchAPI('/search'));