import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, INSTAGRAM, INSTAGRAM_PUBLIC_DATA } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { SOCIAL } from "../../constant/constant";
import { boolean, string } from "yup";

class InstagramPublicData extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Instagram Public Data";

  getNetworkIdentifier = (): NETWORK => INSTAGRAM_PUBLIC_DATA;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "accounts": args.accounts,
    }
  }
}

export default InstagramPublicData;
