import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "./select";

const LanguageSelector = ({
                           ...props
                         }: { label?: string, value?: string, onChange: (item: string) => void, placeholder: string }) => {

    const {t} = useTranslation();
    const list = [
        {
            name: "Français",
            value: "fr"
        },
        {
            name: "English",
            value: "en"
        },
    ];
  return <Select {...props} list={list} />;
};

export default LanguageSelector;
