import API from "../../api/http/client";
import TemplateHealthModel from "../../models/http/alert/template-health";
import Proxy from "../../api/http/proxy";

class TemplateHealth extends API {

  serializer = () => ({
    _getOne: TemplateHealthModel,
  });
}

export default Proxy<TemplateHealth>(new TemplateHealth('/template_healths'))