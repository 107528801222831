import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import DestinationModel from "../../models/http/data-connector/destination";
import DeleteSerializer from "../../models/http/delete";
import BaseDestination from "../../../config/destination/base-destination";

class DestinationAPI extends API{

  checkConnection(type: string, data: any){
    return this.getClient().post(`${this.endpoint}/networks/check_destination_connection/${type}`, data)
  }

  serializer = () => ({
    _getAll: DestinationModel,
    _getOne: DestinationModel,
    _delete: DeleteSerializer
  })

}

export default Proxy<DestinationAPI>(new DestinationAPI('/destinations'))
