import { useMutation, useQuery } from "react-query";
import SentryListing from "../../services/models/http/alert/sentry-listing";
import SentryAPI from "../../services/repository/alerts/alert";
import TemplateHealthAPI from "../../services/repository/alerts/template-health";
import ConfiguredForAPI from "../../services/repository/alerts/configured_for";
import Pagination from "../../services/models/http/paginated";
import { NETWORK } from "../../constant/network";
import TemplateListing from "../../services/models/http/alert/template-listing";
import SentryModelListing, { SentryModel } from "../../services/models/http/alert/model";
import { AlertType } from "../../features/alerts/editor/helpers/sentry-template";
import AccessibilityModel from "../../services/models/http/alert/accessibility";
import Visualize from "../../services/models/http/alert/visualize";
import { FormValue } from "../../features/alerts/editor";
import ConfiguredForModel from "../../services/models/http/alert/configured_for";
import { omit } from "lodash";

export const useGetAllAnomalies = (accountId: number, type: AlertType = "metric") => useQuery(["alert-listing", accountId, type], (): Promise<Pagination<SentryListing>> => SentryAPI._getAll({ query : {dataSourceId: accountId, mode: 'anomaly', type }}), {
  retry: 0
});

export const useGetAllTemplateListing = (companyId: number) => useQuery(["alert-template-listing"], (): Promise<Pagination<SentryListing>> => SentryAPI._getAll({ query : {companyId, useAsTemplate: true}}), {
  retry: 0
});

export const useGetAllTemplate = (network: NETWORK, dataSourceId: number) => useQuery(['alert-template', network, dataSourceId], (): Promise<Pagination<TemplateListing>> => SentryAPI._getTemplateListing(network, dataSourceId));

export const useGetModel = () => useQuery('model-alert', (): Promise<SentryModelListing> => SentryAPI._getModel())

export const useGetAccessibility = (type: AlertType, platform: NETWORK, model: string|undefined) => useQuery(['accessibility', platform, type, model], (): Promise<AccessibilityModel> => SentryAPI._getAccessibility(type, platform, model as string), {enabled: !!model})

export const useVisualize = (sentry: FormValue, valid: boolean) => useQuery(['visualizer', omit(sentry, 'name')], (): Promise<Visualize> => SentryAPI._postVisualize(sentry), {enabled: valid, retry: 0});

export const useConfiguredFor = (id: number) => useQuery(['configured_for', id], () : Promise<ConfiguredForModel> => ConfiguredForAPI._getOne(id) , {retry: 0});
export const useConfiguredForAll = () => useQuery(['configured_for'], () : Promise<Pagination<ConfiguredForModel>> => ConfiguredForAPI._getAll({pagination: false}) , {retry: 0});

export const useSentryGet = (id: number) => useQuery(['sentry', id], () : Promise<SentryModel> => SentryAPI._getOne(id) , {retry: 0});

export const usePostSentryMutation = () => useMutation((data: FormValue) => SentryAPI._postOne(data));
export const usePutSentryMutation = () => useMutation((data: FormValue & {id: number}) => SentryAPI._put(data.id, data));

export const usePutSnoozedMutation = () => useMutation(({ id, date } : {id: number, date: Date}) => ConfiguredForAPI._snoozed(id, date));

export const useDeActivateMutation = () => useMutation(({id}: { id: number }) => ConfiguredForAPI._delete(id))
export const useDeleteSentryMutation = () => useMutation((id: number) => SentryAPI._delete(id))

export const useTemplateHealthGet = (id: number) => useQuery(['template-health', id], () => TemplateHealthAPI._getOne(id))
export const useActivateMutation = () => useMutation(({dataSourceId, sentryId}: { dataSourceId: number, sentryId: number}) => ConfiguredForAPI._postOne({
  dataSourceId : {
    A: dataSourceId
  },
  sentry: `/api/sentries/${sentryId}`
}))