export type Destination = "GOOGLE_DATA_STUDIO" | "GOOGLE_SHEET" | "GOOGLE_BIG_QUERY" | "TABLEAU" | "POWERBI" | "API" | "JSON" | "CSV"

export const GOOGLE_DATA_STUDIO = "GOOGLE_DATA_STUDIO";
export const GOOGLE_SHEETS = "GOOGLE_SHEET";
export const TABLEAU = "TABLEAU";
export const POWERBI = "POWERBI";
export const API = "API";
export const JSON = "JSON";
export const CSV = "CSV";
export const GOOGLE_BIG_QUERY = "GOOGLE_BIG_QUERY";
