import React from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {
  eachHourOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek
} from "date-fns";
import { Pacing, VisualizerData } from "../../editor/helpers/sentry-template";
import { getColor } from "../helper";

const PacingVisualizer = ({variable, response} : {response: VisualizerData, variable: Pacing}) => {

  const { t } = useTranslation();
  const firstKey = Object.keys(response)[0];

  const date = new Date();
  let first = null;
  let last = null;

  if(variable.period === "start_of_month"){
    first = startOfMonth(date);
    last = endOfMonth(date);
  }else if(variable.period === "start_of_week"){
    first = startOfWeek(date,  {weekStartsOn: 1});
    last = endOfWeek(date, {weekStartsOn: 1});
  }else{
    first = startOfDay(date);
    last = endOfDay(date);
  }

  const periods = isSameDay(first, last ) ? eachHourOfInterval(
    { start: first, end: last }
  ) : eachHourOfInterval(
    { start: first, end: last }
  );

  const data : any = {};
  let currentCost = 0;
  let projected = 0;

  periods.forEach(period => {
    const time = period.getTime();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if(response[firstKey].data[0].values[0].dataSpend.find(elem => elem[0]*1000 === time) && time < date.getTime()){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentCost += response[firstKey].data[0].values[0].dataSpend.find(elem => elem[0]*1000 === time)[1];
      projected = currentCost
    }else if(time >= date.getTime()){
      // @ts-ignore
      projected += response[firstKey].data[0].values[0].projectedByCurrentInterval
    }
    data[time] = {
      cost: time < date.getTime() ? currentCost : null,
      time,
      projected: time >= date.getTime() ? projected : null,
    }
  })


  return (
    <div className="h-full ">
      <div className=" bg-white h-full border rounded-lg border-gray-200 w-full flex flex-col">
        <div className="border-b flex items-center p-4"  style={{ height: "10%" }}>
          <h2 className="font-medium">{t('Graph')}</h2>
        </div>
        <div style={{ height: "90%" }} className="py-4 pr-4 pt-6">
          <ResponsiveContainer height="100%" width="100%">
            <LineChart  data={Object.values(data)}>
            <YAxis
              fill="#626266"
              stroke="#626266"
              axisLine={{ stroke: "none" }}
              tickLine={{ stroke: "none" }}
            />
            <XAxis
              stroke="#626266"
              tickFormatter={timeStr => new Date(timeStr).toLocaleDateString(undefined, {
                month: "short",
                "day": "2-digit"
              })}
              dataKey="time"
              scale="time"
              type="number"
              domain={["dataMin", "dataMax + 1"]}
              strokeWidth={1}
            />
              {variable.thresholds.below ?  <ReferenceLine y={variable.thresholds.below} label="Min" stroke="red" strokeDasharray="3 3" /> : null}
              {variable.thresholds.above ?  <ReferenceLine y={variable.thresholds.above} label="Max" stroke="red" strokeDasharray="3 3" /> : null}
            <CartesianGrid vertical={false} strokeWidth={1} />
              <Tooltip
                labelFormatter={label => new Date(label).toLocaleString()}
                formatter={(value: number | bigint) => new Intl.NumberFormat(undefined).format(value)}
              />
              <Line strokeWidth="2" dot={false} type="monotone" dataKey="cost" stroke={getColor(0)} />
              <Line strokeWidth="2" dot={false} type="monotone" dataKey="projected" stroke={getColor(0)} strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PacingVisualizer;
