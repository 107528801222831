import React from "react";
import { useTranslation } from "react-i18next";
import DataSource from "../../../../services/models/http/datasource/data-source-client";
import { useGetAllConversionTag } from "../../../../hooks/alert/conversion-tag";
import Async from "../../../../ui/helper/async";
import Table, { DateCell } from "../../../../ui/components/table";
import { classNames } from "../../../../services/helper";

const Status = ({value} : {value: boolean}) => <span className={classNames(
  value ? "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800" : "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"
)}>{value ? "Not Running" : "Running"}</span>


const ConversionTag = ({dataSource}: {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const conversionTags = useGetAllConversionTag(dataSource.getAccountId(), dataSource.getPlatform())
  const columns = React.useMemo(() => [
    {
      Header: t('Tag ID'),
      accessor: "tagID",
      sticky: 'left'
    },
    {
      Header: t('Name'),
      accessor: "name",
    },
    {
      Header: t('Status'),
      accessor: "status",
      Cell: Status
    },
    {
      Header: t('Last View'),
      accessor: "lastView",
      Cell: DateCell
    },
  ], []);
  return (
    <div className="mx-8 my-8 flex flex-col space-y-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Summary')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <Async {...conversionTags}>
            {conversionTags.data && !conversionTags.isLoading ?
              <>
                <div key="analyzed" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("Total")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{conversionTags.data.data.length}</dd>
                </div>
                <div key="in_error" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t("Active")}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{conversionTags.data.data.filter(elem => elem.getStatus() === "ENABLED").length}</dd>
                </div>
              </>
              : null}
          </Async>
        </dl>
      </div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t('Details')}</h3>
        <Table data={(conversionTags.data?.data || []).map(elem => ({tagID: elem.getTagId(), name: elem.getName(), status: elem.getStatus() !== "ENABLED", lastView: elem.getLastView()}))} columns={columns} loading={conversionTags.isLoading}/>
      </div>
    </div>
  );
};

export default ConversionTag;
