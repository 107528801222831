import API from "../../api/http/client";
import ConnectionModel from "../../models/http/data-connector/connection";
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";
import LogModel from "../../models/http/data-connector/log";

class ConnectionAPI extends API {

  public queue(id: number) {
    return this.getClient().get(`${this.endpoint}${this.uri}/${id}/queue`);
  }

  public _summary(id: number) :  Promise<LogModel>{
    return this.getClient().get(`${this.endpoint}${this.uri}/logs/${id}`);
  }

  serializer = () => ({
    _getAll: ConnectionModel,
    _postOne: ConnectionModel,
    _put: ConnectionModel,
    _getOne: ConnectionModel,
    _summary: LogModel,
    _delete: DeleteSerializer
  });

}

export default Proxy<ConnectionAPI>(new ConnectionAPI('/connections'));