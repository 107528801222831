import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, API as API_CONSTANT } from "../../constant/destination";

class API extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => API_CONSTANT

  getName = (): string => "API";

}

export default API;
