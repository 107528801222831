import { ConnectionFormValue } from "../form/connection-form";
import ConnectionModel, { PostConnectionHTTP } from "../../../../services/models/http/data-connector/connection";
import { ALL } from "../../../../constant/connection/connection-schedule";

export const serializer = (data: ConnectionFormValue) : PostConnectionHTTP => {
  return {
    name: data.name,
    schedule: data.schedule,
    destination: `/api/abstract_destinations/${data.destinationId}`,
    dataSource: `/api/data_sources/${data.dataSourceId}`,
  }
}

export const deserialize = (data: ConnectionModel) : ConnectionFormValue => ({
  name: data.getName(),
  schedule: data.getSchedule() as never ,
  dataSourceId: data.getDataSourceId(),
  destinationId: data.getDestinationId() as number
})