import React from 'react';
import {Route, Routes} from "react-router-dom";
import routing from '../../routing';
import Login from "../login";
import Authorize from "../authorize";
import OAuth2 from "../authorize/containers/oauth2";
import CreateUser from "../company/create-user";
import AddOn from "../authorize/addons";
import { usePageView } from '../../hooks/segment';
import Redirect from "../redirect";
import { SentryRoutes } from '../..';

const Public = () => {
        usePageView();
        return <SentryRoutes>
                <Route path={routing.public.invite_user} element={<CreateUser />}/>
                <Route path={routing.public.login} element={<Login />}/>
                <Route path={routing.public.authorize} element={<Authorize />}/>
                <Route path={`${routing.public.addons}/*`} element={<AddOn />}/>
                <Route path={routing.public.oauth2_authorize} element={<OAuth2 />}/>
                <Route path="/redirect" element={<Redirect />}/>
                <Route path="*" element={<Login />}/>
        </SentryRoutes>
}

export default Public;
