import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import DataStreamJobModel from "../../../models/http/data-connector/data-stream/data-stream-job";
import DeleteSerializer from "../../../models/http/delete";

class DataStreamJobAPI extends API{

  preview = (networkAuthorization: number, account_id: string, destination_id: number, fields: { [p: string]: string }, options: Array<any>, start_date: string, end_date: string) => {
    return this.getClient().post(`/etl/preview`, {
      network_authorization: networkAuthorization,
      account_id,
      destination_id,
      fields,
      options,
      start_date,
      end_date
    })
  }

  _refresh(jobId: number) {
    return this.getClient().post(`/datastreams/jobs/${jobId}/run-now`, {})
  }

  serializer = () => ({
    _postOne : DataStreamJobModel,
    _refresh : DataStreamJobModel,
    _getOne : DataStreamJobModel,
    _put : DataStreamJobModel,
    _getAll: DataStreamJobModel,
    _delete: DeleteSerializer
  })

}

export default Proxy<DataStreamJobAPI>(new DataStreamJobAPI('/datastreams/jobs'))
