import React, {Fragment, ReactElement} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {classNames} from "../../../services/helper";
import {useMe} from "../../../hooks/user";
import SingleLogo from '../../components/logo/solo-logo.svg';
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/solid";
import CurrentUser from "../../../services/models/http/user/current-user";
import { useQueryClient } from "react-query";



const userNavigation = [
    { name: 'Index', href: '/' },
    { name: 'Settings', href: '/company/settings' },
    { name: 'Logout', href: '/logout' },
]

const Home = ({children, header}: {children: ReactElement, header?: React.ReactNode}) => {
    const {t} = useTranslation();
    const {data: user} = useMe();

  const navigation = [
    { name: 'Dashboard', href: '/', current: false},
    { name: 'Data Connector', href: '/data-connector', current: false},
    { name: 'Settings', href: '/company/settings', current: false},
  ].map(elem => {
    const resolved = useResolvedPath(elem.href);
    const match = useMatch({ path: resolved.pathname, end: true });

    return {...elem, current: match}
  })
  const queryClient = useQueryClient();

  const currentUser : CurrentUser | undefined = queryClient.getQueryData('user');



  return (
        <div className="min-h-full">
            <div className="bg-indigo-600 pb-32">
                <Disclosure as="nav" className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 lg:border-none">
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                                <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                                    <div className="px-2 flex items-center lg:px-0">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="block h-8 w-8"
                                                src={SingleLogo}
                                                alt="Catchr"
                                            />
                                        </div>
                                        <div className="hidden lg:block lg:ml-10">
                                            <div className="flex space-x-4">
                                                {navigation.map((item) => (
                                                    <Link to={item.href} className={classNames(
                                                        item.current
                                                            ? 'bg-indigo-700 text-white'
                                                            : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                                        'rounded-md py-2 px-3 text-sm font-medium'
                                                    )} >{t(item.name)}</Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex lg:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                            <span className="sr-only">{t('Open main menu')}</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="hidden lg:block lg:ml-4">
                                        <div className="flex items-center">
                                            <Link to="/buy/google-data-studio" className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
                                                {user?.getCompany().stripeSubscriptionId !== null ? <div className="flex items-center"><CheckIcon className="w-6 h-6 mr-1"/> {t('Subscribed')} </div>: t('Subscribe now') }
                                            </Link>
                                            <Menu as="div" className="ml-3 relative">
                                              <div>
                                                <Menu.Button className="max-w-xs rounded-full flex items-center text-white text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:rounded-md ">
                                                  <p data-letters={currentUser?.getEmail().charAt(0).toUpperCase()} className="flex items-center">
                                        <span className="hidden text-white text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>{currentUser?.getEmail()}
                      </span>
                                                    <ChevronDownIcon
                                                      className="hidden flex-shrink-0 ml-1 h-5 w-5 text-white lg:block"
                                                      aria-hidden="true"
                                                    />
                                                  </p>
                                                </Menu.Button>
                                              </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({ active }) => (
                                                                    <a
                                                                        href={item.href}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block py-2 px-4 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="lg:hidden">
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as={Link}
                                            to={item.href}
                                            className={classNames(
                                              item.current
                                                    ? 'bg-indigo-700 text-white'
                                                    : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                                'block rounded-md py-2 px-3 text-base font-medium'
                                            )}
                                            aria-current={false ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="pt-4 pb-3 border-t border-indigo-700">
                                    <div className="px-5 flex items-center">
                                        <div className="flex-shrink-0">
                                            <img className="rounded-full h-10 w-10" alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-sm font-medium text-indigo-300">{user?.getEmail()}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto bg-indigo-600 flex-shrink-0 rounded-full p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
              {header || null}
            </div>


            <main className="">
                <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </main>
        </div>
    );
}

export default Home;
