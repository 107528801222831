import React, { useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from "../components/header";
import routing from "../../../routing";
import { useDeleteDestinationMutation, useDestinationListing } from "../../../hooks/destinations";
import Async from "../../../ui/helper/async";
import DestinationModel from "../../../services/models/http/data-connector/destination";
import DestinationProvider from "../../../config/destination/destination-provider";
import BaseDestination from "../../../config/destination/base-destination";
import Button from "../../../ui/components/button/button";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import { NotificationManager } from "../../../ui/components/notification/notification";
import MeetingBooker from "../../../ui/components/meeting-booking";
import {useSegmentTrack} from "react-segment-analytics";
import BillingNotification from "../../../ui/components/billing-notification";

const RowDestination = ({destination, onDelete} : {destination: DestinationModel<any, any>, onDelete: () => void}) => {
  const destinationProvider = DestinationProvider.getByPlatform(destination.getType()) as BaseDestination;
  const {t} = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const track = useSegmentTrack();
  const deleteMutation = useDeleteDestinationMutation(destination.getId());

  const deleteDestination = async () => ConfirmManager.confirm({
    title: t("Confirm the deletion of the destination"),
    description: t("Are you sure you want to delete this destination ?"),
    validButton: t("Delete"),
    cancelButton: t("Cancel"),
    typeImg: "danger",
    onValid: async () => {
      track("Trying to delete a destination")
      setDeleting(true);
      try {
        await deleteMutation.mutateAsync();
       NotificationManager.success(t('The destination has been deleted'), "");
       onDelete();
      } catch(e) {
        NotificationManager.error(t('Unable to delete the destination'), t('An error occurred. Please try again.'))
      }
      setDeleting(false);
    }
  });

  return <tr key={destination.getId()}>
    <td className="px-6 py-4 whitespace-nowrap">
      {destination.getName()}
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 " src={(destinationProvider.destinationImage())} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{destinationProvider.getName()}</div>
          <div className="text-sm text-gray-500">{destinationProvider.getName()}</div>
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
        </span>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
      <Link to={routing.user.destination_edit.replace(":id", destination.getId().toString())}>
        <Button type="primary-purple">
          <PencilIcon className="h-5 w-5 mr-2"/>
          {t('Edit')}
        </Button>
      </Link>
      <Button type="danger" onClick={deleteDestination} loading={deleting}>
        <TrashIcon className="h-5 w-5 mr-2" />
        {t("Delete")}
      </Button>
    </td>
  </tr>
}
const DestinationIndex = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const destinations = useDestinationListing(page);
  const track = useSegmentTrack();
  const AddDestinationButton = <Link
      to={routing.user.new_destination}
      onClick={() => {
        track("Trying to add a destination");
      }
      }
  ><button
    type="button"
    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    <PlusIcon className="h-6 w-6 mr-2" />
    {t('Add database')}
  </button></Link>

  return (
    <div className="w-full py-4">
      <BillingNotification />
      <Header title={t('Database')} actionButton={AddDestinationButton} />
      <div className="mt-8 block">
        <div className="align-middle inline-block min-w-full">
          <Async {...destinations}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr className="border-t border-gray-200 bg-white">
                  <th
                    scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Name')}</span>
                  </th>
                  <th
                    scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Destination')}
                  </th>
                  <th
                    scope="col" className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Status')}
                  </th>
                  <th
                    scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {destinations.data?.data.map(elem => <RowDestination destination={elem as never} onDelete={() => destinations.refetch()}/>)}
                </tbody>
              </table>
            </div>
          </Async>
        </div>
      </div>
      <MeetingBooker title="Get help creating your database." description="Do you need help creating a database ? Book a meeting with our expert. PS: If you need to connect to Looker Studio or Google Sheets, you just need to create a new Source." />

    </div>

  );
};

export default DestinationIndex;
