import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { networkImageFromURI } from "../../../ui/components/network";
import {KLAVIYO} from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import { checkConnectionStatus } from "../../../services/helper";
import { XCircleIcon } from "@heroicons/react/solid";

const validation = Yup.object({
    consumerKey: Yup.string().required(),
})
const Klaviyo = () => {
    const query = parse(window.location.search)
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);

    const formik = useFormik({
        initialValues: {
            consumerKey: '',
        },
        validationSchema: validation,
        async onSubmit(values){
            setLoading(true);
            const returnValue = {
                key: values.consumerKey,
            }
            const response = await checkConnectionStatus("klaviyo", returnValue);
            if(response.getStatus()) {
                if (query.from && query.from === "google-data-studio") {
                    const url = new URL(generatePath(window.location, "/oauth2/authorize/klaviyo/response"));
                    url.search = (new URLSearchParams({ ...values, success: "true" })).toString();
                    window.location.replace(url);
                } else {
                    window.location.search = stringify({ ...values, success: true });
                }
            }else{
                setStatus(false);
            }

            setLoading(false);
        }
    })
    return (
        <div className="w-full h-full">
            <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
                <h1 className="text-3xl font-bold text-white">Catchr x Klaviyo</h1>
            </div>
            {!status ? <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
                        <div className="mt-2 text-sm text-red-700">
                            Please review the information that you have submitted. We couldn't connect to your Klaviyo account.
                        </div>
                    </div>
                </div>
            </div> : null}
            <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
                <div className="flex">
                    <div className="mr-4 flex-shrink-0">
                        <img src={networkImageFromURI(KLAVIYO)}
                             className="h-16 w-16 bg-white text-gray-300" />
                    </div>
                    <div>
                        <h4 className="text-lg font-bold">{t("Connect to your Klaviyo account")}</h4>
                        <p className="mt-1">
                            {t("In order to connect to your Klaviyo analytics, we need to get some informations.")}
                        </p>
                    </div>
                </div>
                <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
                    <Input
                        label="Private Key :"
                        id="consumerKey"
                        name="consumerKey"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.consumerKey}
                        touched={formik.touched.consumerKey}
                        error={formik.errors.consumerKey}
                        required
                    />
                    <Button loading={loading} full size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Klaviyo;
