import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, FACEBOOK, BING } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";
import { CONVERSION_TAG, SECURITY, URL_CHECKER, ADS as ADS_TYPE } from "../../constant/alert";

class BingAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Bing Ads / Microsoft Ads";

  getNetworkIdentifier = (): NETWORK => BING;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [CONVERSION_TAG, SECURITY, URL_CHECKER];

}

export default BingAds;