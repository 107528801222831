import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import DataStreamModel from "../../../models/http/data-connector/data-stream/data-stream";
import DeleteSerializer from "../../../models/http/delete";

class DataStreamAPI extends API{

  serializer = () => ({
    _postOne : DataStreamModel,
    _put: DataStreamModel,
    _getAll: DataStreamModel,
    _getOne: DataStreamModel,
    _delete: DeleteSerializer
  })

}

export default Proxy<DataStreamAPI>(new DataStreamAPI('/datastreams'))
