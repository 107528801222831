import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import BaseNetwork from "../../../../config/network/base-network";

const Source = ({
                  source,
                  accountAvailable,
                  onClick,
                }: {
  source: BaseNetwork;
  accountAvailable?: number;
  onClick: (base: string) => void;
}) => {
  return (
    <div
      role="button"
      onClick={() => {
        if (accountAvailable) {
          onClick(source.getNetworkIdentifier());
        }
      }}
      className={`relative flex items-center space-x-3 px-6 py-1 hover:bg-gray-50 ${
        accountAvailable ? 'hover:cursor-pointer' : null
      }`}
    >
      <div className="flex-shrink-0">
        <img
          className="h-4 w-4 rounded-full"
          src={source.networkImage()}
          alt=""
        />
      </div>
      <div className="flex-1 flex items-center justify-between">
        <div className="focus:outline-none">
          <p className="text-sm font-medium text-gray-900">
            {source.getName()}
          </p>
          <p className="truncate text-sm text-gray-500">
            {accountAvailable
              ? `Account connected : ${accountAvailable}`
              : `Click + to connect account`}
          </p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(
              'https://app.catchr.io/data-connector/sources/new',
              '_blank'
            );
          }}
          role="button"
        >
          <PlusCircleIcon className="h-6 w-6 text-gray-400 hover:text-indigo-600" />
        </div>
      </div>
    </div>
  );
};

export default Source;
