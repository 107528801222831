import React from 'react';
import {CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/solid";

export type STATE_TYPE = "success" | "warning" | "error" | "normal" | "info";
export type BASE_COLOR = "green" | "yellow" | "red" | "blue" | "grey";

export const getColor = (color: STATE_TYPE) : BASE_COLOR => {
    switch (color){
        case "success":
            return "green";
        case "warning":
            return "yellow";
        case "error":
            return "red";
        case "info":
            return "blue";
        case "normal":
            return "grey";
        default:
            throw new Error(`Unable to get color for ${color}`)
    }
}

export const getIcon = (type: STATE_TYPE, color: BASE_COLOR, baseCss = "h-5 w-5") => {
    switch (type){
        case "success":
            return <CheckCircleIcon className={`${baseCss} text-${color}-400`} aria-hidden="true" />;
        case "warning":
            return <ExclamationIcon className={`${baseCss} text-${color}-400`} aria-hidden="true" />;
        case "error":
            return <XCircleIcon className={`${baseCss} text-${color}-400`} aria-hidden="true" />;
        case "normal":
        case "info":
            return <InformationCircleIcon className={`${baseCss} text-${color}-400`} aria-hidden="true" />;
        default:
            throw new Error(`Unable to get icon for ${type}`)
        }
}