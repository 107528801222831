import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeIndex from "./home";
import DataConnector from "../../ui/containers/data-connector";
import DestinationIndex from "./destination";
import CreateDestination from "./destination/create";
import EditDestination from "./destination/edit";
import SourceIndex from "./source";
import CreateSource from "./source/create";
import EditSource from "./source/edit";
import CreateConnection from "./home/actions/create";
import EditConnection from "./home/actions/edit";
import ConnectionDetail from "./home/details";
import API from "./api";
import { SentryRoutes } from "../../index";
import routing from "../../routing";
import DataStream from "./datastream";
import CreateDataStream from "./datastream/create";
import DataStreamView from "./datastream/view";
import DataStreamJob from "./datastream-job/view";
import DataStreamJobCreate from "./datastream-job/create";
import Usage from "./usage";

const DataConnectorIndex = () => {
  return (
    <DataConnector>
      <SentryRoutes>
        <Route path="/"  element={<HomeIndex />} />
        <Route path="/usage"  element={<Usage />} />
        <Route path="/data-stream" element={<DataStream />} />
        <Route path="/data-stream/new" element={<CreateDataStream />} />
        <Route path="/data-stream/:id" element={<DataStreamView />} />
        <Route path="/data-stream/:id_data_stream/jobs/:id_job" element={<DataStreamJob />} />
        <Route path="/data-stream/:id/jobs/new" element={<DataStreamJobCreate />} />
        <Route path="/connections/new"  element={<CreateConnection />} />
        <Route path="/api"  element={<API />} />
        <Route path="/connections/:id"  element={<EditConnection />} />
        <Route path="/connections/:id/details"  element={<ConnectionDetail />} />
        <Route path="/destinations"  element={<DestinationIndex />} />
        <Route path="/sources"  element={<SourceIndex />} />
        <Route path="/sources/:id"  element={<EditSource />} />
        <Route path="/sources/new"  element={<CreateSource />} />
        <Route path="/destinations/new"  element={<CreateDestination />} />
        <Route path="/destinations/:id"  element={<EditDestination />} />
      </SentryRoutes>
    </DataConnector>
  );
};

export default DataConnectorIndex;
