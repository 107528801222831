import React from "react";
import { useTranslation } from "react-i18next";
import DataStreamJobModel, {
  JobLog
} from "../../../../../services/models/http/data-connector/data-stream/data-stream-job";
import { formatDuration, intervalToDuration } from "date-fns";

type Props = {
  job: DataStreamJobModel
}

type RowLog = {
  log: JobLog
}

const RowJobLog = ({log} : RowLog) => (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{ log.createdAt ? `${new Date(log.createdAt as string).toLocaleString()}` : 'N/A'}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{ log.dateEnd && log.dateEnd ? `${new Date(log.dateStart as string).toDateString()} - ${new Date(log.dateEnd as string).toDateString()}` : 'N/A'}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{log.duration ? `${formatDuration(intervalToDuration({ start: 0, end: log.duration }))}` : "N/A"}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{log.lastStepReached === "END_PROCESS" ? "Finished" : log.lastStepReached}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{log.success ? "OK" : log.message}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{log.numberRow}</div>
      </td>
    </tr>
  )

const Overview = ({job} : Props) => {
  const {t} = useTranslation();
  return (
    <div className="mt-8 block">
      <div className="align-middle inline-block min-w-full">
          <h2 className="text-base font-semibold leading-6 text-gray-900 mb-2">Overview of run</h2>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
              <tr className="border-t border-gray-200 bg-white">
                <th
                  scope="col"
                  className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">{t("Extracted Date Time")}</span>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">{t("Extracted Range Time")}</span>
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Duration")}
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Step")}
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Status")}
                </th>
                <th
                  scope="col"
                  className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Extracted Row")}
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {job.getLogs().map((log: JobLog) => <RowJobLog log={log} />)}
              </tbody>
            </table>
          </div>
      </div>
    </div>
  );
};

export default Overview;
