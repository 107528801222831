import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod } from "../../constant/link-method";
import { NETWORK, CALL_RAIL } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS } from "../../constant/constant";

class CallRail extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "CallRail";

  getNetworkIdentifier = (): NETWORK => CALL_RAIL;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ANALYTICS;

  isETLActivated(): boolean {
    return false;
  }


  alertOptions = () =>  [];


}

export default CallRail;
