import BaseNetwork from "./base-network";
import {LinkMethod, OAUTH_2} from "../../constant/link-method";
import {AD_FORM, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {ADS} from "../../constant/constant";

class AdForm extends BaseNetwork{
    connectingMethod = (): LinkMethod => OAUTH_2;

    getName = (): string => "AdForm";

    getNetworkIdentifier = (): NETWORK => AD_FORM;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => ADS;

    isETLActivated(): boolean {
        return false;
    }

    alertOptions = () =>  [];

}

export default AdForm;