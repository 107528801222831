class AggregationType {
    private _id: string|null;

    private _name: string;

    private _description: string;

    constructor(id: string|null, name: string, description: string) {
        this._id = id;
        this._name = name;
        this._description = description;
    }

    getId(): string|null {
        return this._id;
    }

    setId(value: string|null) {
        this._id = value;
    }

    getName(): string {
        return this._name;
    }

    setName(value: string) {
        this._name = value;
    }

    getDescription(): string {
        return this._description;
    }

    setDescription(value: string) {
        this._description = value;
    }
}

export default AggregationType;
