import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import Container from "../../../ui/components/container/container";
import Modal, {DefaultBottom, Title} from "../../../ui/components/modal";
import FieldTranslationForm, {FormFieldTranslation, FormFieldTranslationValidation} from "./form/field-translation-form";
import FieldTranslation, {PutFieldTranslation} from "../../../services/models/http/google-datastudio/field-translation";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import {usePutFieldTranslationGoogleDataStudioMutation} from "../../../hooks/google-datastudio";

export const UpdateFieldTranslation = ({fieldTranslation, visible, setVisible}: {fieldTranslation: FieldTranslation, visible: boolean, setVisible: (value: boolean) => void}) => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const putGoogleDataStudio = usePutFieldTranslationGoogleDataStudioMutation();

    const initialValues = {
        id: fieldTranslation.getId(),
        name: fieldTranslation.getName(),
        description: fieldTranslation.getDescription(),
        languageCode: fieldTranslation.getLanguageCode(),
        field: `/api/fields/${id}`
    };

    const {t} = useTranslation();

    const onClose = () => {
        setVisible(false);
    }

    const onSubmit = async (values: FormFieldTranslation) => {
        try {
            const response = await putGoogleDataStudio.mutateAsync(values as PutFieldTranslation);
            NotificationManager.success(t('Your field has been successfully updated'), "")
            await queryClient.cancelQueries(['field_translation_datastudio']);
            queryClient.setQueriesData(['field_translation_datastudio'], (old) => (old as Pagination<FieldTranslation>).updateElement("getId", response.getId(), response))
            setVisible(false);
        } catch(error) {
            NotificationManager.error(t('Unable to update the field'), t('An error occurred. Please try again.'))
        }
    }

    const form = useFormik<FormFieldTranslation>({
        initialValues,
        validationSchema: FormFieldTranslationValidation,
        onSubmit
    });

    return <Container>
        <Modal
            open={visible}
            onClose={onClose}
            bottom={<DefaultBottom disabledOkButton={!(form.isValid)} loading={putGoogleDataStudio.isLoading} onClose={onClose} titleButtonOk={t('Save')} onOk={() => form.submitForm()} titleButtonClose={t('Close')} />}
        >
            <Title title={t('Update a field')} />
            <FieldTranslationForm form={form} />
        </Modal>
    </Container>
}

export default UpdateFieldTranslation;