import DataSource from "../models/http/datasource/data-source-client";

export const getResultOnAnomaly = (dataSource :DataSource) => {
  const alerts = {
    amount: 0,
    total: 0
  };

  if(dataSource.getHealthAlert()['kpi/default']){
    alerts.amount += dataSource.getHealthAlert()['kpi/default'].numberStatusTrue;
    alerts.total += dataSource.getHealthAlert()['kpi/default'].numberTotalConfigured;
  }

  if(dataSource.getHealthAlert()['metric/default']){
    alerts.amount += dataSource.getHealthAlert()['metric/default'].numberStatusTrue;
    alerts.total += dataSource.getHealthAlert()['metric/default'].numberTotalConfigured;
  }

  return alerts;
}

export const getResultOnPacing = (dataSource :DataSource) => {
  return {
    amount: dataSource.getHealthAlert()['pacing/default'] ? dataSource.getHealthAlert()['pacing/default'].numberStatusTrue : 0,
    total: dataSource.getHealthAlert()['pacing/default'] ? dataSource.getHealthAlert()['pacing/default'].numberTotalConfigured : 0
  };
}