export default {
  admin: {
    home: "/admin",
    google_data_studio: "/admin/google_data_studio",
    field_google_data_studio: "/admin/google_data_studio/:id",
    companies: "/admin/companies",
    users: "/admin/users",
  },
  user: {
    home : "/app",
    data_source: "/data-sources",
    data_source_add: "/data-sources/add",
    data_connector : "/data-connector",
    data_stream : "/data-connector/data-stream",
    data_stream_new : "/data-connector/data-stream/new",
    data_stream_job_new : "/data-connector/data-stream/:id/jobs/new",
    data_stream_job_id : "/data-connector/data-stream/:id/jobs/:id_job",
    data_stream_id : "/data-connector/data-stream/:id",
    destination : "/data-connector/destinations",
    source : "/data-connector/sources",
    new_source : "/data-connector/sources/new",
    source_edit : "/data-connector/sources/:id",
    destination_edit : "/data-connector/destinations/:id",
    new_destination : "/data-connector/destinations/new",
    new_connection : "/data-connector/connections/new",
    connection_edit : "/data-connector/connections/:id",
    client: "/clients/:client_id",
    profile: "/profile",
    company_setting: "/company/settings",
    company_template: "/company/settings/alerts/templates",
    company_template_create: "/company/settings/alerts/templates/create/:id",
    settings: "/clients/:client_id/settings",
    create_alert: "/clients/:client_id/alerts/create/default",
    edit_alert: "/clients/:client_id/alerts/edit/:alert_id",
    create_alert_pacing: "/clients/:client_id/alerts/create/pacing",
    anomalies: "/clients/:client_id/anomalies",
    pacing: "/clients/:client_id/pacing",
    objectives: "/clients/:client_id/objectives",
    url_checker: "/clients/:client_id/options/url-checker",
    conversion_tag: "/clients/:client_id/options/conversion-tag",
    security: "/clients/:client_id/options/security",
    ads: "/clients/:client_id/ads",
    billing_success: "/success",
    details: "/clients/:client_id/details/:configured_for_id",
  },
  public: {
    'login' : '/login',
    'authorize' : '/authorize',
    'addons' : '/addons',
    'oauth2_authorize' : '/oauth2/authorize/:platform/response',
    'invite_user' : '/company/create-user/:token'
  },
};
