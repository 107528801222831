import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface SentryModel {
  "pacing": {
    "facebook-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "bing-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "amazon-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "linkedin": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "google-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    }
  },
  "metric": {
    "facebook-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "bing-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "amazon-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    }, "linkedin": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "google-ads": {
      "CAMPAIGN": "campaign",
      "ACCOUNT": "account"
    },
    "facebook-analytics": {
      "PAGE": "page",
      "POST": "post"
    },
    "google-analytics": {
      "E_COMMERCE": "view",
      "EVENT_TRACKING": "event_tracking",
      "GOAL_CONVERSION": "goal_conversion",
      "PAGE_TRACKING": "page_tracking",
      "SESSION": "view",
      "SITE_SPEED": "view",
      "SOCIAL_INTERACTION": "social_interaction",
      "TRAFFIC_SOURCE": "traffic_source",
      "USER": "user"
    },
    "website": {
      "ACCOUNT": "account"
    }
  },
  "security": {
    "google-ads": {
      "ACCOUNT": "account"
    },
    "bing-ads": {
      "ACCOUNT": "account"
    },
    "facebook-ads": {
      "ACCOUNT": "account"
    }
  },
  "polymathee": {
    "google-ads": {
      "ACCOUNT": "account"
    }
  },
  "product_available": {
    "google-ads": {
      "PRODUCT_AVAILABILITY": "product"
    }
  },
  "kpi": {
    "facebook-ads": {
      "CAMPAIGN": "campaign",
      "ADGROUP": "adgroup"
    },
    "amazon-ads": {
      "CAMPAIGN": "campaign"
    }
  }
}

class SentryModelListing extends Model<SentryModel> {

  getSchemaToSerialize = (): JSONSchemaType<SentryModel> => ({
    "type": "object",
    "properties": {
      "pacing": {
        "type": "object",
        "properties": {
          "facebook-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "bing-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "amazon-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "linkedin": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "google-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          }
        },
        "required": [
          "facebook-ads",
          "bing-ads",
          "amazon-ads",
          "linkedin",
          "google-ads"
        ]
      },
      "metric": {
        "type": "object",
        "properties": {
          "facebook-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "bing-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "amazon-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "linkedin": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "google-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ACCOUNT"
            ]
          },
          "facebook-analytics": {
            "type": "object",
            "properties": {
              "PAGE": {
                "type": "string"
              },
              "POST": {
                "type": "string"
              }
            },
            "required": [
              "PAGE",
              "POST"
            ]
          },
          "google-analytics": {
            "type": "object",
            "properties": {
              "E_COMMERCE": {
                "type": "string"
              },
              "EVENT_TRACKING": {
                "type": "string"
              },
              "GOAL_CONVERSION": {
                "type": "string"
              },
              "PAGE_TRACKING": {
                "type": "string"
              },
              "SESSION": {
                "type": "string"
              },
              "SITE_SPEED": {
                "type": "string"
              },
              "SOCIAL_INTERACTION": {
                "type": "string"
              },
              "TRAFFIC_SOURCE": {
                "type": "string"
              },
              "USER": {
                "type": "string"
              }
            },
            "required": [
              "E_COMMERCE",
              "EVENT_TRACKING",
              "GOAL_CONVERSION",
              "PAGE_TRACKING",
              "SESSION",
              "SITE_SPEED",
              "SOCIAL_INTERACTION",
              "TRAFFIC_SOURCE",
              "USER"
            ]
          },
          "website": {
            "type": "object",
            "properties": {
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "ACCOUNT"
            ]
          }
        },
        "required": [
          "facebook-ads",
          "bing-ads",
          "amazon-ads",
          "linkedin",
          "google-ads",
          "facebook-analytics",
          "google-analytics",
          "website"
        ]
      },
      "security": {
        "type": "object",
        "properties": {
          "google-ads": {
            "type": "object",
            "properties": {
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "ACCOUNT"
            ]
          },
          "bing-ads": {
            "type": "object",
            "properties": {
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "ACCOUNT"
            ]
          },
          "facebook-ads": {
            "type": "object",
            "properties": {
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "ACCOUNT"
            ]
          }
        },
        "required": [
          "google-ads",
          "bing-ads",
          "facebook-ads"
        ]
      },
      "polymathee": {
        "type": "object",
        "properties": {
          "google-ads": {
            "type": "object",
            "properties": {
              "ACCOUNT": {
                "type": "string"
              }
            },
            "required": [
              "ACCOUNT"
            ]
          }
        },
        "required": [
          "google-ads"
        ]
      },
      "product_available": {
        "type": "object",
        "properties": {
          "google-ads": {
            "type": "object",
            "properties": {
              "PRODUCT_AVAILABILITY": {
                "type": "string"
              }
            },
            "required": [
              "PRODUCT_AVAILABILITY"
            ]
          }
        },
        "required": [
          "google-ads"
        ]
      },
      "kpi": {
        "type": "object",
        "properties": {
          "facebook-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              },
              "ADGROUP": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN",
              "ADGROUP"
            ]
          },
          "amazon-ads": {
            "type": "object",
            "properties": {
              "CAMPAIGN": {
                "type": "string"
              }
            },
            "required": [
              "CAMPAIGN"
            ]
          }
        },
        "required": [
          "facebook-ads",
          "amazon-ads"
        ]
      }
    },
    "required": [
      "pacing",
      "metric",
      "security",
      "polymathee",
      "product_available",
      "kpi"
    ]
  });
}

export default SentryModelListing;