import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, SHOPIFY } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { E_COMMERCE } from "../../constant/constant";

class Shopify extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Shopify";

  getNetworkIdentifier = (): NETWORK => SHOPIFY;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => E_COMMERCE;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
   return {
     shop: args.shop
   }
  }
}

export default Shopify;