import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, GOOGLE_PLAY_STORE, PIANO_ANALYTICS } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS, WEBSITE } from "../../constant/constant";

class PianoAnalytics extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Piano Analytics";

  getNetworkIdentifier = (): NETWORK => PIANO_ANALYTICS;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ANALYTICS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {

    }
  }
}

export default PianoAnalytics;
