import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../services/helper";

const Modal = ({
                 open,
                 children,
                 bottom,
                 onClose
               }: { open: boolean, children: React.ReactNode, bottom?: React.ReactNode, onClose: () => void }) => {
  const { t } = useTranslation();
  return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="inline-block align-bottom bg-white rounded-lg  text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onClose()}
              >
                <span className="sr-only">{t("Close")}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className=" sm:p-6 px-4 pt-5 pb-4">
              {children}
            </div>
            {bottom ? <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
              {bottom}
            </div> : null}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>;
};

export const Title = ({ title, subtitle }: { title: React.ReactNode, subtitle?: React.ReactNode }) => <div
  className="mb-4">
  <h2 className="text-lg font-medium text-gray-900">{title}</h2>
  {subtitle ? <p className="mt-1 text-sm text-gray-500">{subtitle}</p> : null}
</div>;

export const DefaultBottom = ({
                                disabledOkButton,
                                loading,
                                onClose,
                                titleButtonClose,
                                onOk,
                                titleButtonOk
                              }: { disabledOkButton?: boolean, loading?: boolean, onClose: () => void, titleButtonClose: React.ReactNode, onOk: () => void, titleButtonOk: React.ReactNode }) => {
  return <>
    <button
      disabled={disabledOkButton}
      type="button"
      className={classNames(disabledOkButton ? "cursor-not-allowed bg-indigo-300" : 'hover:bg-indigo-700 bg-indigo-600',
        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm")}

      onClick={onOk}
    >
      {loading ?
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
          <path className="opacity-75" fill="currentColor"
  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg> : null}
      {titleButtonOk}
    </button>
    <button
      type="button"
      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      onClick={onClose}
    >
      {titleButtonClose}
    </button>
  </>;
};
export default Modal;
