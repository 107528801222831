import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { XCircleIcon } from "@heroicons/react/solid";
import * as qs from "query-string";
import { networkImageFromURI } from "../../../ui/components/network";
import { INSTAGRAM_PUBLIC_DATA } from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import { checkConnectionOAuth2Status } from "../../../services/helper";

const validation = Yup.object()
const InstagramPublicData = () => {
  const query = parse(window.location.search)
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if(query.code){
      const queryFrom = localStorage.getItem("instagram-public-data-from");
      const accounts = localStorage.getItem("instagram-public-data-account") as string;
      const returnValue = { accounts, code: query.code as string, success: "true" };
      if (queryFrom) {
        const url = new URL(generatePath(window.location, "/oauth2/authorize/instagram-public-data/response"));
        url.search = stringify(returnValue);
        window.location.replace(url);
      } else {
        window.location.search = stringify(returnValue);
      }
    }else {
      localStorage.removeItem("instagram-public-data-account");
      localStorage.removeItem("instagram-public-data-from");
    }
  },[]);


  const formik = useFormik({
    initialValues: {
      accounts : ''
    },
    validationSchema: validation,
    async onSubmit(values){
      setLoading(true);
      const returnValue = {
        options: {
          accounts : values.accounts
        }
      }
      try {
        const response = await checkConnectionOAuth2Status("instagram-public-data", returnValue);
        localStorage.setItem("instagram-public-data-account", values.accounts);
        if(query.from) {
          localStorage.setItem("instagram-public-data-from", query.from as string);
        }
        const search = qs.parse(window.location.search);

        if (response.getStatus() && search.redirectTo) {
          window.location.replace(search.redirectTo as string);
        } else {
          setStatus(false);
        }
      }catch (e) {
        setStatus(false);
      }

      setLoading(false);
    }
  })
  return (
    <div className="w-full h-full">
      <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
        <h1 className="text-3xl font-bold text-white">Catchr x Instagram Public Data</h1>
      </div>
      {!status ? <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
            <div className="mt-2 text-sm text-red-700">
              Please review the information that you have submitted. We can't connect to your Instagram account.
            </div>
          </div>
        </div>
      </div> : null}
      <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
        <div className="flex">
          <div className="mr-4 flex-shrink-0">
            <img src={networkImageFromURI(INSTAGRAM_PUBLIC_DATA)}
                 className="h-16 w-16 bg-white text-gray-300" />
          </div>
          <div>
            <h4 className="text-lg font-bold">{t("Connect to your Instagram Public Data account")}</h4>
          </div>
        </div>
        <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
          <Input
            label="Account username(s) :"
            id="accounts"
            name="accounts"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.accounts}
            touched={formik.touched.accounts}
            error={formik.errors.accounts}
            required
          />
          <Button loading={loading} full size="large" type="primary" htmlType="submit">
            Next
          </Button>
        </form>
      </div>
    </div>
  );
};

export default InstagramPublicData;
