import React from "react";
import NetworkProvider from "../../../config/network/network-provider";
import SelectWithAvatar from "./select-avatar";
import { orderBy } from "lodash";

type NETWORK_SELECTOR_MODE = "google-data-studio" | "all";

const NetworkSelector = ({
                           mode,
                           ...props
                         }: { mode: NETWORK_SELECTOR_MODE, label?: string, value?: string, onChange: (item: string) => void, placeholder: string }) => {
  const allNetwork = mode === "google-data-studio" ? NetworkProvider.getAllDataStudio() : NetworkProvider.getAll();
  const list = allNetwork.map(elem => ({
    avatar: elem.networkImage(),
    name: elem.getName(),
    value: elem.getNetworkIdentifier()
  }));
  return <SelectWithAvatar {...props} list={orderBy(list, ['name'], ['asc'])} />;
};

export default NetworkSelector;
