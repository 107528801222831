import API from "../../api/http/client";
import CompanyModel from "../../models/http/company/company";
import Proxy from "../../api/http/proxy";

class Company extends API {

  inviteUser = (id: number, email: string) => this.getClient().post(`${this.endpoint}/companies/${id}/invite-user`,{invitedUsers: [email]})


  serializer = () => ({
    _put: CompanyModel,
    _getOne: CompanyModel,
    _getAll: CompanyModel,
  });
}

export default Proxy<Company>(new Company('/companies'))