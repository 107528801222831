// @ts-nocheck
/* eslint-disable */

import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { FormikConfig, useFormik } from "formik";
import { Popover, Transition } from "@headlessui/react";
import ReactDOM from "react-dom";
import * as Yup from "yup";
import { Editable, ReactEditor, Slate, withReact, useSelected, useFocused } from "slate-react";
import { Node, Editor, Transforms, Range, createEditor } from "slate";
import { withHistory } from "slate-history";
import AccessibilityModel from "../../../../../services/models/http/alert/accessibility";
import { CalculatedField as CalculatedFieldType } from "../../helpers/sentry-template";
import Input from "../../../../../ui/components/input/input";
import { classNames } from "../../../../../services/helper";
import { usePopper } from "react-popper";

export const Validation = Yup.object().shape({
  name: Yup.string().required("Please input a name for the field"),
  field: Yup.array().required("Please input a formula")
});

const Portal = ({ children }: any) => ReactDOM.createPortal(children, document.body);

const MentionElement = ({ attributes, children, element }: any) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        padding: "3px 3px 2px",
        margin: "0 1px",
        verticalAlign: "baseline",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: "#eee",
        fontSize: "0.9em",
        boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none"
      }}
    >
      {element.character}
      {children}
    </span>
  );
};

const withMentions = (editor: any) => {
  const { isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element: any) => element.type === "mention" ? true : isInline(element);

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element: any) => element.type === "mention" ? true : isVoid(element);

  return editor;
};

const insertMention = (editor: any, character: any) => {
  const mention = {
    type: "mention",
    character: character.name,
    accessor: character.accessor,
    children: [{ text: "" }]
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const Element = (props: any) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "mention":
      return <MentionElement {...props} />;
    default:
      return <span{...attributes}>{children}</span>;
  }
};


const CalculatedFieldEditor = ({ onChange, availableFields, value = [{ children: [{ text: "" }] }] }: any) => {
  const ref = useRef();

  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const renderElement = useCallback(props => <Element {...props} />, []);
  const editor = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      withMentions(withReact(withHistory(createEditor())))
    ,
    []
  );

  const chars = availableFields.filter((c: any) =>
    c.name.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10);

  const { t } = useTranslation();
  const onKeyDown = useCallback(
    event => {
      if (target) {
        // eslint-disable-next-line default-case
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            if (chars[index]) {
              insertMention(editor, chars[index]);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setTarget(null);
            }
            break;
          case "Escape":
            event.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setTarget(null);
            break;
        }
      }
    },
    [index, search, target]
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);


  return (
    <Slate
      placeholder={t("Calculate your field")}
      editor={editor}
      value={value}
      onChange={(value: any) => {
        onChange(value);
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: "word" });
          const before = wordBefore && Editor.after(editor, wordBefore, { unit: "word" });
          const beforeRange = before && Editor.range(editor, wordBefore, before);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);
          if (beforeMatch && afterMatch) {
            // @ts-ignore
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }

        // @ts-ignore
        setTarget(null);
      }}
    >
      <Editable
        renderElement={renderElement}
        onKeyDown={onKeyDown}
        placeholder={t("Enter your formula")}
      />
      {target && chars.length > 0 && (
        <Portal>
          <div
            ref={ref}
            style={{
              top: "-9999px",
              left: "-9999px",
              position: "absolute",
              zIndex: 9999,
              padding: "3px",
              background: "white",
              borderRadius: "4px",
              boxShadow: "0 1px 5px rgba(0,0,0,.2)"
            }}
          >
            {chars.map((char: any, i: any) => (
              <div
                key={char.name}
                className={
                  classNames("p-2 hover:bg-purple-500 hover:text-white cursor-pointer",
                    i === index ? "bg-purple-500 text-white" : null)
                }
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.preventDefault();
                  Transforms.select(editor, target);
                  insertMention(editor, chars[i]);
                  setTarget(null);
                }}
              >
                {char.name}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </Slate>
  );
};

const PopOverCalculatedField = ({
                                  accessibility,
                                  onSubmit
                                }: { accessibility: AccessibilityModel, onSubmit: (name: string, values: any) => void }) => {


  const fields = accessibility.data.properties.metrics.properties;

  const { t } = useTranslation();

  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement,{placement: "bottom"})

  return (
    <Popover >
      {({ close }) => {
        const calculatedFieldForm = useFormik({
          validationSchema: Validation,
          initialValues: {
            name: undefined,
            field: undefined
          },
          onSubmit: (values) => {
            console.log(values.name, values.field, close)
            onSubmit(values.name, values.field);
            close();
            calculatedFieldForm.resetForm();
          }
        });

        return (
          <>
            <Popover.Button
              ref={setReferenceElement}
              className="inline-flex items-center px-2.5 py-1.5 border border-gray-200 border-dashed text-xs font-normal bg-white rounded text-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm">
              <PlusIcon className="h-4 mr-2" />
              {t("Add")}
            </Popover.Button>
              {ReactDOM.createPortal(<Popover.Panel
                ref={setPopperElement}
                className={"z-10 w-screen max-w-sm px-4 mt-3 sm:px-0"}
                style={styles.popper}
                {...attributes.popper}>
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                     onKeyDown={(e) => {
                       if (e.key === "Enter") {
                         e.preventDefault();
                         e.stopPropagation();
                       }
                     }} onSubmit={(e) => {
                       console.log(e)
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.preventDefault();
                  calculatedFieldForm.handleSubmit(e);
                }}>
                  <div className="relative bg-white px-7 py-4">
                    <Input
                      className="mt-1"
                      type="text"
                      label={t("Field Name :")}
                      id="name"
                      name="name"
                      onChange={calculatedFieldForm.handleChange}
                      value={calculatedFieldForm.values.name}
                      touched={calculatedFieldForm.touched.name}
                      error={calculatedFieldForm.errors.name}
                    />
                  </div>
                  <div className="relative bg-white px-7 pb-4">
                    <CalculatedFieldEditor availableFields={fields}
                                           onChange={(value) => calculatedFieldForm.setFieldValue("field", value)}
                                           value={calculatedFieldForm.values.field} />
                    {calculatedFieldForm.errors.field ?
                      <p className="mt-2 text-sm text-red-600" id={`calculatedfield-error`}>
                        {calculatedFieldForm.errors.field}
                      </p> : null}
                  </div>
                  <button
                    type="submit"
                    className="w-full cursor-pointer bg-purple-500 text-white rounded-t-none p-4 bg-gray-50 text-center flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-purple-600 focus:outline-none "
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      e.nativeEvent.preventDefault();
                      calculatedFieldForm.handleSubmit(e);
                    }}
                  >
                    {t("Save")}
                  </button>
                </div>
              </Popover.Panel>, document.body)}</>
        );
      }}
    </Popover>
  );
};

const SingleCalculatedField = ({ field, onRemove }: { field: CalculatedFieldType, onRemove: () => void }) => {
  return <div className="mt-4 p-2 border-gray-200 border w-full rounded flex items-center justify-between bg-white hover:border-indigo-600 cursor-pointer">
    <h4>{field.name}</h4>
    <div className="mr-1  hover:text-red-400 hover:bg-red-100 rounded">
      <TrashIcon className="h-5 text-gray-600 hover:text-red-400" onClick={() => onRemove()} />
    </div>
  </div>;
};
const CalculatedField = ({
                           accessibility,
                           onChange,
                           values
                         }: { accessibility: AccessibilityModel, onChange: (calculatedField: Array<CalculatedFieldType>) => void, values: Array<CalculatedFieldType> }) => {
  const { t } = useTranslation();

  const addField = (field, value) => {
    onChange([...values, { name: field, field: value }]);
  };

  const removeField = (index) => {
    values.splice(index, 1)
    onChange([...values])
  }

  return (
    <div>
      <div className="mt-4 w-full justify-between flex items-center">
        <h3 className=" block font-medium text-sm mb-4">{t("Calculated Field")}</h3>
        <PopOverCalculatedField accessibility={accessibility} onSubmit={addField} />
      </div>
      <div >
        {values.map((elem, index) => <SingleCalculatedField field={elem} onRemove={() => removeField(index)} />)}
      </div>
    </div>
  );
};

export default CalculatedField;
