import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";
import { PostRequest, PutRequest } from "../../form-interface";

export interface ConfigDtoModel {
  id: string,
  name: string,
  helpText: string,
  allowedMultiple: true,
  values: Array<{ value: string, label: string }>,
  default: boolean | string,
  type: "checkbox" | "multiple",
}

class ConfigDto extends Model<ConfigDtoModel> {

  getId() {
    return this.data.id;
  }

  getName() {
    return this.data.name;
  }

  getHelpText() {
    return this.data.helpText;
  }

  getAllowedMultiple() {
    return this.data.allowedMultiple;
  }

  getValue() {
    return this.data.values;
  }

  getDefault() {
    return this.data.default;
  }

  getType() {
    return this.data.type;
  }

  getSchemaToSerialize = (): JSONSchemaType<ConfigDtoModel> => ({
    type: "object",
    properties: {
      "id": { type: "string" },
      "name": { type: "string" },
      "helpText": { type: "string" },
      "allowedMultiple": { type: "boolean" },
      "values": {
        type: "array",
        items: {
          required: ["value", "label"],
          type: "object",
          properties: { "value": { type: "string" }, "label": { type: "string" } }
        }
      },
      "default": { type: ["boolean", "string"] },
      "type": { type: "string", enum: ["checkbox", "multiple"] }
    },
    required: ["id", "name", "helpText", "allowedMultiple", "values", "default", "type"]
  });

}

export default ConfigDto;
