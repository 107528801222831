import React from "react";
import { useTranslation } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import { useBackUpMutation, useDataSourceListing } from "../../../hooks/datasource";
import Async from "../../../ui/helper/async";
import Pagination from "../../../services/models/http/paginated";
import DataSourceModelAll from "../../../services/models/http/datasource/datasource-all";
import { getAllUser, getCurrentUser } from "../../../hooks/user";


const BackUpListing = ({ dataSources }: { dataSources: Pagination<DataSourceModelAll> }) => {
  const currentUser = getCurrentUser();
  const workingOn = dataSources.data.filter(dataSource => dataSource.getAccountUserManager().some(account => account.id === currentUser.getId()));
  const mutateDataSource = useBackUpMutation();
  const users = getAllUser();

  return <dl className="divide-y divide-gray-200 w-full">
    {workingOn.map(elem => <div className="w-full py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:pt-5">
      <dt className="text-sm font-medium text-gray-500 items-center flex">{elem.getName()}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 justify-end ">
        <span className="ml-4 flex-shrink-0 flex space-x-4 items-center flex">
          <Multiselect
            options={users?.data.map(user => ({id: user.getId(), email: user.getEmail()}))}
            displayValue="email"
            singleSelect
            onSelect={(all, value) => {
              mutateDataSource.mutate({id: elem.getId(), backedBy: `/api/users/${currentUser.getId()}`})
            }}
            onRemove={(all, value) => {
              mutateDataSource.mutate({id: elem.getId(), backedBy: null})
            }}
            loading={mutateDataSource.isLoading}
            selectedValues={elem.getBackedBy() ? users?.data.filter(user => user.getId() === (elem.getBackedBy() as any).id).map(user => ({id: user.getId(), email: user.getEmail()})) : []}
          />
        </span>
      </dd>
    </div>)}
  </dl>;

};

const BackUp = () => {
  const { t } = useTranslation();
  const dataSource = useDataSourceListing({query: {mode: 'listing'}});

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t("Backup")}</h2>
          <p className="mt-1 text-sm text-gray-500">
            {t("Here you can manage who is receiving your email when you are not available.")}
          </p>
        </div>
        <div className="mt-6 flex flex-col lg:flex-row">
          <Async {...dataSource}>
            {dataSource.data ? <BackUpListing dataSources={dataSource.data} /> : <div>No data</div>}
          </Async>
        </div>
      </div>
    </div>
  );
};

export default BackUp;
