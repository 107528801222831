import React from 'react';
import { useTranslation } from "react-i18next";
import { ALERT_STATUS } from "../editor/helpers/sentry-template";

export const AlertBadge = ({status} : {status: ALERT_STATUS}) => {
  const {t} = useTranslation();
  return status === "ANOMALY" ? <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800">
        {t('Anomaly')}
      </span> : <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
        {t('Not in anomaly')}
      </span>
}