import React, { Fragment, ReactElement, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  CloudDownloadIcon,
  MenuAlt2Icon,
  XIcon
} from "@heroicons/react/outline";
import {
  CheckIcon,
  ChevronDownIcon,
  CloudUploadIcon,
  LinkIcon,
  DatabaseIcon,
  ShareIcon,
  ChipIcon, BookOpenIcon
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import {useSegmentTrack} from "react-segment-analytics";
import moment from "moment/moment";
import CurrentUser from "../../../services/models/http/user/current-user";
import { classNames } from "../../../services/helper";
import routing from "../../../routing";
import Logo from "../../components/logo/solo-logo.svg";
import { getCurrentUser, useBillingSessionPortal, useMe } from "../../../hooks/user";


const DataConnector = ({ children }: { children: ReactElement }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currentUser: CurrentUser | undefined = queryClient.getQueryData("user");

  const isMatch = (item: { href: string }) => {
    const resolved = useResolvedPath(item.href);
    return useMatch({ path: resolved.pathname, end: true });
  };

  const me = useMe();


  const billingPortal = useBillingSessionPortal();

  const sidebarNavigation = [
    { name: "Connection", href: "/data-connector", icon: ShareIcon, current: false },
    { name: "Sources", href: "/data-connector/sources", icon: CloudDownloadIcon, current: false },
    { name: "Datastream", href: "/data-connector/data-stream", icon: ChipIcon, current: false },
    { name: "Database", href: "/data-connector/destinations", icon: CloudUploadIcon, current: true },
    { name: "API", href: "/data-connector/api", icon: LinkIcon, current: false }
  ].map(elem => ({ ...elem, current: isMatch(elem) }));

  const accountNumber = me.data?.getCompany().userNumberDataSourceForLast30days || 0;
  let maxAccountNumber = me.data?.getCompany().stripeSubscriptionNumberDataSource;

  if(me.data?.getCompany().stripeSubscriptionState === null && moment().isAfter(moment(me.data?.getCompany().trialFinished))){
    maxAccountNumber = 0;
  }

  console.log(maxAccountNumber);
  const percent = !maxAccountNumber ? 0 : (accountNumber / maxAccountNumber) * 100;

  let colorOfBg = "grey";
  if(percent === 0){
    colorOfBg = "grey"
  }
  else if(percent < 75){
    colorOfBg = "green";
  }else if(percent < 99){
    colorOfBg = "yellow";
  }else {
    colorOfBg = "red"
  }

  if(me.data?.getCompany().stripeSubscriptionState === null && moment().isAfter(moment(me.data?.getCompany().trialFinished))){
    colorOfBg = "red";
  }

  const track = useSegmentTrack();
  return (
    <div className="h-full flex">
        {/* Narrow sidebar */}
        <div className="hidden w-28 bg-indigo-700 overflow-y-auto md:block">
          <div className="w-full h-full py-6 flex flex-col items-center">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/"><img
                className="h-8 w-auto"
                src={Logo}
                alt="Catchr"
              /></Link>
            </div>
            <div className="flex-1 mt-6 w-full px-2 space-y-1">

              {sidebarNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? "bg-indigo-800 text-white" : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => {
                      track(`Go to ${item.name} from sidebar`)
                    }
                  }
                >
                  <item.icon
                    className={classNames(
                      item.current ? "text-white" : "text-indigo-300 group-hover:text-white",
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{t(item.name)}</span>
                </Link>
              ))}
            </div>
            <div className="flex flex-1 mt-6 w-full px-2 space-y-1">
            <a
              key="helpdesk"
              target="_blank"
              href="https://help.catchr.io"
              className={classNames(
                "text-indigo-100 hover:bg-indigo-800 hover:text-white self-end", "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
              )} rel="noreferrer"
            >
              <BookOpenIcon
                className={classNames(
                  "text-indigo-300 group-hover:text-white",
                  "h-6 w-6"
                )}
                aria-hidden="true"
              />
              <span className="mt-2">{t("Help")}</span>
            </a>
          </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        <span className="sr-only">{t("Close sidebar")}</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 px-4 flex items-center">
                    <Link to="/"><img
                      className="h-8 w-auto"
                      src={Logo}
                      alt="Workflow"
                    /></Link>
                  </div>
                  <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                    <nav className="h-full flex flex-col">
                      <div className="space-y-1">
                        {sidebarNavigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-indigo-800 text-white"
                                : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? "text-white" : "text-indigo-300 group-hover:text-white",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span>{t(item.name)}</span>
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex-1 flex flex-col overflow-y-scroll">
          <header className="w-full">
            <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
              <button
                type="button"
                className="border-r borlder-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">{t("Open sidebar")}</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 sm:px-6 items-center">
                <div className="flex-1 flex">
                  <div className="w-full flex md:ml-0 py-2 pr-3 text-xl text-gray-900 font-bold">
                    <h1>{t("Data connector")}</h1></div>
                </div>

                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Link to="/data-connector/usage">
                    <div className="w-96 flex items-center justify-center">
                      <div className="mr-1 pb-0.5">
                        <b>Usage :</b>
                      </div>
                      <div className="bg-gray-200 rounded flex-1">
                        <div
                          className={`bg-${colorOfBg}-500 p-0.5 text-white rounded text-center text-xs font-medium leading-none text-primary-100`}
                          style={{width: `100%`}}>
                          {accountNumber} / {(maxAccountNumber || 0) >= 0 && (me.data?.getCompany().stripeSubscriptionState !== null || me.data?.getCompany().stripeSubscriptionState === null && moment().isAfter(moment(me.data?.getCompany().trialFinished))) ? maxAccountNumber : '∞'} accounts
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/buy/google-data-studio" className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
                    {me.data?.getCompany().stripeSubscriptionId !== null ? <div className="flex items-center"><CheckIcon className="w-6 h-6 mr-1"/> {t('Upgrade')} </div>: t('Subscribe now') }
                  </Link>
                  <Menu as="div" className="ml-3 relative z-10">
                    <div>
                      <Menu.Button
                        className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:rounded-md lg:hover:bg-gray-50">
                        <p data-letters={currentUser?.getEmail().charAt(0).toUpperCase()} className="flex items-center">
                                        <span className="hidden text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>{currentUser?.getEmail()}
                      </span>
                          <ChevronDownIcon
                            className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                            aria-hidden="true"
                          />
                        </p>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={routing.user.company_setting}
                              className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              {t("Your profile")}
                            </Link>
                          )}
                        </Menu.Item>
                        {billingPortal.data ? <Menu.Item>
                          {({ active }) => (
                              <a
                                  onClick={() => {
                                    track('View billing information');
                                  }
                                  }
                                  href={billingPortal.data.data.url}
                                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                              >
                                {t('Billing')}
                              </a>
                          )}
                        </Menu.Item> : null}
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={() => {
                                track('Logout');
                                }
                              }
                              to="/logout"
                              className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              Logout
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex-1 flex items-stretch overflow-y-scroll px-4 sm:px-6 mb-4">
            {children}
          </div>
        </div>
      </div>
  );
};

export default DataConnector;
