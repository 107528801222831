import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";
import {PostRequest, PutRequest} from "../../form-interface";

export interface PostConnectionHTTP {
    dataSource: string,
    destination?: string,
    schedule?: string,
    name: string,
}

export type Log = {
    id: number
    dateStart?: string
    startOfTheRequest?: string
    dateEnd?: string
    oauthId?: number
    message?: string
    requestId?: string
    userId?: number
    httpStatus?: number
    success?: boolean
    size?: number
    duration?: number
    memoryFootprint?: number
    fromCache?: boolean
    numberRow?: number
    fields?: Array<string>
};

interface ConnectionHTTP {
    id: number,
    schedule: string,
    name: string,
    dataSource: {
        id: number,
        name: string,
        authorization: {
            id:number,
            platform: string
        }
    },
    destination?: {
        id: number
    }
    destinationType: string,
    lastSeen: string | null,
    active: boolean,
    logsCount?: number,
    logs?: Array<Log>
}

class ConnectionModel extends Model<ConnectionHTTP> implements PostRequest<PostConnectionHTTP>, PutRequest<PostConnectionHTTP> {

    getId() {
        return this.data.id;
    }

    getName() {
        return this.data.name;
    }

    getSchedule() {
        return this.data.schedule;
    }

    getDataSourceName() {
        return this.data.dataSource.name;
    }

    getDataSourceId() {
        return this.data.dataSource.id;
    }

    getSourceId() {
        return this.data.dataSource.authorization.id;
    }

    getDestinationId() {
        return this.data.destination?.id;
    }

    getPlatform() {
        return this.data.dataSource.authorization.platform;
    }

    getLastSeen() {
        return this.data.lastSeen;
    }

    getActive() {
        return this.data.active;
    }

    getLogsCount() {
        return this.data.logsCount;
    }

    getDestinationType() {
        return this.data.destinationType;
    }

    getLogs() {
        return this.data.logs;
    }

    getSchemaToSerialize = (): JSONSchemaType<ConnectionHTTP> => ({
        type: "object",
        properties: {
            "id": {
                type: "number"
            },
            "name": {
                type: "string"
            },
            "schedule": {
                type: "string",
                nullable: true
            },
            "logsCount": {
                type: "number",
                nullable: true
            },
            "destination": {
                type: "object",
                required: ['id'],
                properties: {
                    "id": {
                        type: "integer"
                    }
                },
                nullable: true
            },
            "dataSource": {
                type: "object",
                required: ['id', 'name', "authorization"],
                properties: {
                    "id": {
                        type: "integer"
                    },
                    "name": {
                        type: "string"
                    },
                    "authorization": {
                        type: "object",
                        required: ['platform', 'id'],
                        properties: {
                            "platform": {
                                type: "string"
                            },
                            "id": {
                                type: "number"
                            }
                        }
                    },
                }
            },
            "destinationType": {
                type: "string"
            },
            "lastSeen": {
                type: "string",
                nullable: true
            },
            "active": {
                type: "boolean",
            },
            "logs": {
                nullable: true,
                type: "array",
                "items": {
                    "type": "object",
                    required: ['id'],
                    "properties": {
                        "id": {
                            "type": "integer"
                        },
                        "dateStart": {
                            "type": "string",
                            nullable: true
                        },
                        "startOfTheRequest": {
                            "type": "string",
                            nullable: true
                        },
                        "dateEnd": {
                            "type": "string",
                            nullable: true
                        },
                        "oauthId": {
                            "type": "integer",
                            nullable: true
                        },
                        "message": {
                            "type": "string",
                            nullable: true
                        },
                        "requestId": {
                            "type": "string",
                            nullable: true
                        },
                        "userId": {
                            "type": "integer",
                            nullable: true
                        },
                        "httpStatus": {
                            "type": "integer",
                            nullable: true
                        },
                        "success": {
                            "type": "boolean",
                            nullable: true
                        },
                        "size": {
                            "type": "integer",
                            nullable: true
                        },
                        "duration": {
                            "type": "integer",
                            nullable: true
                        },
                        "memoryFootprint": {
                            "type": "integer",
                            nullable: true
                        },
                        "fromCache": {
                            "type": "boolean",
                            nullable: true
                        },
                        "numberRow": {
                            "type": "number",
                            nullable: true
                        },
                        "fields": {
                            "type": "array",
                            nullable: true,
                            "items":{
                                "type": "string"
                            }
                        }
                    }
                },
            },
        },
        required: ['id', 'name', 'destinationType', 'lastSeen', 'active', 'dataSource']
    });

    getSchemaPostToValidate = (): JSONSchemaType<PostConnectionHTTP> => ({
        type: "object",
        properties: {
            "name": {
                type: "string"
            },
            "schedule": {
                type: "string",
                nullable: true
            },
            "destination": {
                type: "string",
                nullable: true
            },
            "dataSource": {
                type: "string"
            },
        },
        required: ['name', "dataSource"]
    });

    getSchemaPutToValidate = (): JSONSchemaType<PostConnectionHTTP> => this.getSchemaPostToValidate();
}

export default ConnectionModel;
