import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, TIK_TOK, TIK_TOK_ANALYTICS } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS, SOCIAL } from "../../constant/constant";

class TikTokAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "TikTok";

  getNetworkIdentifier = (): NETWORK => TIK_TOK_ANALYTICS;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];

}

export default TikTokAds;
