import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, LINKEDIN_COMPANY_PAGE } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { SOCIAL } from "../../constant/constant";

class LinkedInPage extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "LinkedIn Company Pages";

  getNetworkIdentifier = (): NETWORK => LINKEDIN_COMPANY_PAGE;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];

}

export default LinkedInPage;
