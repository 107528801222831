import API from "../../api/http/client";
import Field from "../../models/http/google-datastudio/field";
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";
import FieldDTO from "../../models/http/google-datastudio/field-dto";

class FieldAPI extends API {

  serializer = () => ({
    _getAll: FieldDTO
  });

}

export default Proxy<FieldAPI>(new FieldAPI('/google-datastudio/dto_fields'));
