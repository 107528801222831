import Model from "../../model";
import {PostRequest} from "../../form-interface";
import {JSONSchemaType} from "ajv";

export interface LoginResponse200 {
    token: string
}

export interface LoginRequest {
    username: string,
    password: string
}

class Login extends Model<LoginResponse200> implements PostRequest<LoginRequest>{

    getSchemaToSerialize(): JSONSchemaType<LoginResponse200> {
        return {
            type: "object",
            properties: {
                token: {"type": "string"}
            },
            required: ['token']
        };
    }

    getSchemaPostToValidate(): JSONSchemaType<LoginRequest> {
        return {
            type: "object",
            properties: {
                username: {"type": "string"},
                password: {"type": "string"},
            },
            required: ['username', "password"]
        };
    }
}

export default Login;