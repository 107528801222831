import React, { ReactElement, ReactNode } from "react";
import { useMe } from "../../../hooks/user";
import { classNames } from "../../../services/helper";

const Header = ({title, actionButton, autoGrowth} : {title: ReactNode, actionButton?: ReactNode, autoGrowth?: boolean}) => {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h2>
      </div>
      <div className={classNames(autoGrowth ? "flex-1" : "", "mt-4 flex md:mt-0 md:ml-4")}>
        {actionButton}
      </div>
    </div>
  );
};

export default Header;
