import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, ADROLL } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";
import { CONVERSION_TAG, SECURITY, URL_CHECKER, ADS as ADS_TYPE } from "../../constant/alert";

class AdRoll extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "AdRoll";

  getNetworkIdentifier = (): NETWORK => ADROLL;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [];

}

export default AdRoll;
