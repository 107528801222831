import {
  API, CSV, JSON,
  GOOGLE_BIG_QUERY,
  GOOGLE_DATA_STUDIO,
  GOOGLE_SHEETS,
  POWERBI,
  TABLEAU
} from "../../../constant/destination";
import GoogleDataStudio from './img/google-data-studio.svg';
import GoogleSheets from './img/google-sheets.svg';
import GoogleBigQuery from './img/google-big-query.svg';
import Api from './img/api.svg';
import Tableau from './img/tableau.svg';
import PowerBI from './img/powerbi.svg';

/**
 * Destination Image URI
 * @param type
 */
export const destinationImageUri = (type: string) : string => {
  switch (type) {
    case GOOGLE_DATA_STUDIO:
      return GoogleDataStudio;
    case GOOGLE_BIG_QUERY:
      return GoogleBigQuery;
    case GOOGLE_SHEETS:
      return GoogleSheets;
    case API:
    case JSON:
    case CSV:
      return Api;
    case TABLEAU:
      return Tableau;
    case POWERBI:
      return PowerBI;
    default:
      throw new Error('Not available destination');
  }
}
