import React from "react";
import { Metric, MetricType, Variable, VisualizerData } from "../../editor/helpers/sentry-template";
import ThresholdVisualizer from "./threshold";
import TrendVisualizer from "./trend";

const FactoryMetric = ({variable, response} : {response: VisualizerData, variable: Metric}) => {
  switch (variable.alertValue[0].type) {
    case "threshold":
      return < ThresholdVisualizer response={response} variable={variable} />
    case "trend":
      return < TrendVisualizer response={response} variable={variable} />
    default:
      throw new Error(`Unable to get visualizer for ${variable.type}`);
  }
};

export default FactoryMetric;
