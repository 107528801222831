import React from "react";
import { Route, Routes } from "react-router-dom";
import routing from "../../routing";
import AddADataSource from "./add";
import { SentryRoutes } from "../../index";

const DataSource = () => {
  return <SentryRoutes>
    <Route path="/add" element={<AddADataSource />}/>
  </SentryRoutes>
};

export default DataSource;
