import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { networkImageFromURI } from "../../../ui/components/network";
import {APPLE_SEARCH_ADS} from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import {checkConnectionOAuth2Status} from "../../../services/helper";
import { XCircleIcon } from "@heroicons/react/solid";

const validation = Yup.object({
    clientId: Yup.string().required(),
    teamId: Yup.string().required(),
    keyId: Yup.string().required()
})
const AppleSearchAds = () => {
    const query = parse(window.location.search)
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const formik = useFormik({
        initialValues: {
            clientId: '',
            teamId: '',
            keyId: ''
        },
        validationSchema: validation,
        async onSubmit(values){
            setLoading(true);
            const returnValue = {
                options:{
                    clientId: values.clientId,
                    teamId: values.teamId,
                    keyId: values.keyId
                }
            }
            const response = await checkConnectionOAuth2Status("apple-search-ads", returnValue);
            if(response.getStatus()) {
                if (query.from && query.from === "google-data-studio") {
                    const url = new URL(generatePath(window.location, "/oauth2/authorize/apple-search-ads/response"));
                    url.search = (new URLSearchParams({ ...values, success: "true" })).toString();
                    window.location.replace(url);
                } else {
                    window.location.search = stringify({ ...values, success: true, code: "" });
                }
            }else{
                setStatus(false);
            }

            setLoading(false);
        }
    })
    return (
        <div className="w-full h-full">
            <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
                <h1 className="text-3xl font-bold text-white">Catchr x Apple Search Ads</h1>
            </div>
            {!status ? <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
                        <div className="mt-2 text-sm text-red-700">
                            Please review the information that you have submitted. We couldn't connect to your Apple Search Ads account.
                        </div>
                    </div>
                </div>
            </div> : null}
            <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
                <div className="flex">
                    <div className="mr-4 flex-shrink-0">
                        <img src={networkImageFromURI(APPLE_SEARCH_ADS)}
                             className="h-16 w-16 bg-white text-gray-300" />
                    </div>
                    <div>
                        <h4 className="text-lg font-bold">{t("Connect to your Apple Search Ads")}</h4>
                        <p className="mt-1">
                            {t("In order to connect to your Apple Search Ads, we need to get some informations.")}<br/>
                            <ul>
                                <li>
                                    1. Download the <a
                                href="https://catchr-production-public.s3.eu-west-3.amazonaws.com/apple-public-key.pem"
                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >
                                {t("public key certificate.")}
                                    </a>
                                </li>
                                <li>2. {t("Go to the folder you download the file and Right-click on it (it name is apple-public-key.pem). Click Open with, and select a text editor. Copy all the content of the file.")}</li>
                                <li>3. {t("Go to Apple Search Ads with a API user Account. Go the Settings (in your username in the top right corner).")}</li>
                                <li>4. {t("In the API tab, paste the key into the Public key field and save it.")}</li>
                                <li>5. {t("Copy the Client ID, Team ID and the Key ID from the API tab and paste it in the form below.")}</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
                    <Input
                        label="Client Id :"
                        id="clientId"
                        name="clientId"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.clientId}
                        touched={formik.touched.clientId}
                        error={formik.errors.clientId}
                        required
                    />
                    <Input
                        label="Team Id :"
                        id="teamId"
                        name="teamId"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.teamId}
                        touched={formik.touched.teamId}
                        error={formik.errors.teamId}
                        required
                    />
                    <Input
                        label="Key Id :"
                        id="keyId"
                        name="keyId"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.keyId}
                        touched={formik.touched.keyId}
                        error={formik.errors.keyId}
                        required
                    />
                    <Button loading={loading} full size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default AppleSearchAds;
