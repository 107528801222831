import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

interface LogHTTP {
  id: number;
  lastCrawlStart: string|null;
  lastCrawlEnd: string|null;
  status: string;
  rowExtracted: number;
  numberTotalCrawl: number;
  logs: Array<{
    id: number,
    endAt: string|null,
    error: {
      type: string,
      message: string
    } | Array<string>,
    launchAt: string,
    runId: string,
    status: string,
    step: string
    steps: Array<{
      id: number,
      endAt: string|null,
      launchAt: string|null,
      createdAt: string,
      error: {
        type: string,
        message: string
      } | Array<string>,
      extractedRow: number,
      stream: string,
      status: string,
    }>
  }>
}

class LogModel extends Model<LogHTTP>{
  getId(){
    return this.data.id;
  }

  getLastCrawlStart(){
    return this.data.lastCrawlStart;
  }

  getLastCrawlEnd(){
    return this.data.lastCrawlEnd;
  }

  getStatus(){
    return this.data.status;
  }

  getRowExtracted(){
    return this.data.rowExtracted;
  }

  getNumberTotalCrawl(){
    return this.data.numberTotalCrawl;
  }

  getLogs(){
    return this.data.logs || [];
  }

  getSchemaToSerialize = (): JSONSchemaType<LogHTTP> => ({
    type: "object",
    required: ["id", "status", "rowExtracted", "numberTotalCrawl", "logs"],
    properties: {
      "id": {
        type: "number"
      },
      logs: {
        type: "array",
        items: {
          type: "object",
          required: ['id', 'runId', 'launchAt', 'status', 'step', 'steps'],
          properties: {
            id: {
              type: ['number']
            },
            error: {
              oneOf: [
                {
                  type: "array",
                  items: {
                    type: "string"
                  }
                },{
                  type: "object",
                  required: ['type', 'message'],
                  properties: {
                    type: {
                      type: "string"
                    },
                    message: {
                      type: "string"
                    },
                  }
                }
              ]
            },
            endAt: {
              type: "string",
              nullable: true
            },
            launchAt: {
              type: "string"
            },
            runId: {
              type: "string"
            },
            status: {
              type: "string"
            },
            step: {
              type: "string"
            },
            steps: {
              type: "array",
              items : {
                type: "object",
                required: ['id', 'status', 'stream', 'error', 'extractedRow'],
                properties: {
                  "id" : {
                    type: "number"
                  },
                  "extractedRow" : {
                    type: "number"
                  },
                  status: {
                    type: "string"
                  },
                  error: {
                    oneOf: [
                      {
                        type: "array",
                        items: {
                          type: "string"
                        }
                      },{
                        type: "object",
                        required: ['type', 'message'],
                        properties: {
                          type: {
                            type: "string"
                          },
                          message: {
                            type: "string"
                          },
                        }
                      }
                    ]
                  },
                  endAt: {
                    type: "string",
                    nullable: true
                  },
                  launchAt: {
                    type: "string",
                    nullable: true
                  },
                  createdAt: {
                    type: "string"
                  },
                  stream: {
                    type: "string"
                  },
                }
              }
            },
          }
        }
      },
      "lastCrawlStart": {
        type: "string",
        nullable: true
      },
      "lastCrawlEnd": {
        type: "string",
        nullable: true
      },
      "status": {
        type: "string"
      },
      "rowExtracted": {
        type: "number"
      },
      "numberTotalCrawl": {
        type: "number"
      }
    }
  });
}

export default LogModel;