import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { classNames } from "../../../services/helper";

type Value = { name: string, value: string|null };
type Style = "transparent" | "normal";

const Select = ({className, id, label, value, onChange, list, placeholder, style = "normal"} : {id?: string, className?: string, style?:Style, label?: string, value?: string, onChange: (item: string) => void, list: Array<Value>, placeholder: string}) => {

  const divProps = {id};
  const currentValue = list.find(elem => elem.value === value);
  console.log(currentValue, value, list);
  return <Listbox value={value} onChange={onChange}>
    {({ open }) => (
      <>
      {label ? <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label> : null}
        <div className={`mt-1 relative ${className}`} {...divProps}>
          <Listbox.Button className={classNames(style === "normal" ? "border border-gray-300" : "", "cursor-pointer relative w-full bg-white  rounded-md  pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm")}>
              <span className="flex items-center">
                {currentValue ? <span className="ml-3 block truncate">{currentValue.name}</span> : <span className="ml-3 block truncate">{placeholder}</span>}
              </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="cursor-pointer absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {list.map((item: Value) => (
                <Listbox.Option
                  key={item.value}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={item.value}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center cursor-pointer">
                        <span
                          className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                        >
                            {item.name}
                          </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>;
}

export default Select;
