import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../../ui/components/alert/alert";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import Button from "../../../ui/components/button/button";
import { TrashIcon } from "@heroicons/react/solid";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useDeleteMutation } from "../../../hooks/datasource";
import { useNavigate } from "react-router-dom";

const DeleteTab = ({dataSource}: {dataSource: DataSource}) => {
  const {t} = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const deleteMutation = useDeleteMutation();
  const navigate = useNavigate();
  const deleteConnection = async () => ConfirmManager.confirm({
    title: t("Confirm the deletion of the client"),
    description: t("Are you sure you want to delete this client ?"),
    validButton: t("Delete"),
    cancelButton: t("Cancel"),
    typeImg: "danger",
    onValid: async () => {
      setDeleting(true);
      try {
        await deleteMutation.mutateAsync(dataSource.getId());
        NotificationManager.success(t('The account has been deleted'), "");
        navigate("/");
      } catch(e) {
        NotificationManager.error(t('Unable to delete the account'), t('An error occurred. Please try again.'))
      }
      setDeleting(false);
    }
  });
  return (
    <div>
      <Alert type="error" title="Delete a client">
        <div className="flex flex-col space-y-2">
          <div>
            {t('You should be careful with this. If you delete a client, you will lose all relative data (all alerts will be stopped).')}
          </div>
          <div>
            <Button type="danger" loading={deleting} onClick={deleteConnection}><TrashIcon className="h-5 w-5 mr-2"/> {t('Delete')}</Button>
          </div>
        </div>
      </Alert>
    </div>
  );
};

export default DeleteTab;
