import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface CheckConnectionHTTPResult {
  status: boolean,
}

class CheckConnection extends Model<CheckConnectionHTTPResult>{

  getStatus(){
    return this.data.status;
  }


  getSchemaToSerialize = (): JSONSchemaType<CheckConnectionHTTPResult> => ({
    type: "object",
    properties: {
      "status": { type: "boolean" }
    },
    required: ['status']
  });
}

export default CheckConnection;
