import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";
import { ALERT_STATUS } from "../../../../features/alerts/editor/helpers/sentry-template";
import SentryListing, { Sentry } from "./sentry-listing";

export interface History {
  date: string,
  status: ALERT_STATUS,
  result : {
    type: string | null,
    values : Array<{
      name: string,
      value: string
    }>
  }
}


export interface Result {
  id: number,
  identifications: {
    [key: string] :string | number
  } & {
    requests : {
      // eslint-disable-next-line camelcase
      account_id : number | string,
      platform: string
    }
  },
  identifierName?: string
  lastAnalyze: string | null | undefined,
  lastHistory: History | null | undefined,
  histories: Array<History>
  lastStatus: ALERT_STATUS
}

export interface ConfiguredForInterface {
  id: number,
  companyId: number,
  snoozed: string | undefined,
  dataSourceId: {
    [key: string] : number
  },
  results: Array<Result>,
  sentry: Sentry
}

class ConfiguredFor extends Model<ConfiguredForInterface> {

  getId(){
    return this.data.id;
  }

  getCompanyId(){
    return this.data.companyId;
  }

  getSentry(){
    return new SentryListing(this.data.sentry)
  }

  getSnoozed(){
    if(this.data.snoozed){
      return new Date(this.data.snoozed);
    }
    return null;
  }

  getDataSourceId(){
    return this.data.dataSourceId;
  }

  haveAlert(){
    return this.data.results.some(elem => elem.lastStatus === "ANOMALY");
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSchemaToSerialize = (): JSONSchemaType<ConfiguredForInterface> => ({
    "type": "object",
    "properties": {
      "id" : {
        "type": "number"
      },
      "companyId" : {
        "type": "number"
      },
      "dataSourceId" : {
        "type": "object",
        "patternProperties": {
          ".*" : {
            "type": "number"
          }
        },
        "required": [""]
      },
      "results" : {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "id" : {
              "type" : "number"
            },
            "identifications" : {
              "type": "object",
              "required": [""]
            },
            "lastAnalyze" : {
              "type" : "string",
              "nullable": true
            },
            "lastHistory" : {
              "type" : "object",
              nullable: true,
              "required": ['date', 'result'],
              "properties": {
                "date" : {
                  "type": "string"
                },
                "result" : {
                  "type" : "object",
                  "required": ['type'],
                  "properties" : {
                    "type" : {
                      "type": ["string", "null"],
                      "nullable": true
                    },
                  }
                }
              }
            },
            "lastStatus" : {
              "type" : "string"
            },
            "histories" : {
              type: "array",
              items: {
                type:"object",
                required: []
              }
            },
          },
          required: ['histories', 'lastStatus', 'id', 'identifications']
        }
      },
      "sentry" : {  },
    },
    required: ["id", "companyId", "dataSourceId", "results", "sentry"]
  });
}

export default ConfiguredFor;