import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConnectionForm, { ConnectionFormValue } from "../form/connection-form";
import { usePostConnectionMutation } from "../../../../hooks/connections";
import { serializer } from "../utils";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import routing from "../../../../routing";
import { useNavigate } from "react-router";

const CreateConnection = () => {
  const {t} = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const mutation = usePostConnectionMutation();
  const navigate = useNavigate();

  const onSubmit = async (data : ConnectionFormValue) => {
    setSubmitting(true);
    try{
      await mutation.mutateAsync(serializer(data));
      NotificationManager.success(t('Your connection has been added.'), t('Catchr will synchronize your data.'));
      navigate(routing.user.data_connector);
    }catch (e){
      NotificationManager.error(t('Unable to create the connection.'), t('An error occurred when we try to add your connection.'));
    }
    setSubmitting(false);
  }

  return (
    <div className="w-full py-4 flex justify-center">
      <ConnectionForm submitting={submitting} mode="CREATE" initialValues={{}} onSubmit={onSubmit}   />
    </div>
  );
};

export default CreateConnection;
