import {
    ChartBarIcon, ClipboardIcon,
    ClipboardListIcon,
    CurrencyDollarIcon,
    FireIcon,
    LinkIcon,
    LockClosedIcon, PhotographIcon, TagIcon, TrendingUpIcon
} from "@heroicons/react/outline";

import {
    ADS,
    ALL_ALERT,
    ANOMALY,
    CONVERSION_TAG, KPI, METRIC,
    OBJECTIVE,
    PACING,
    SECURITY,
    URL_CHECKER
} from "../../../constant/alert";

// eslint-disable-next-line import/prefer-default-export
export const getIcon = (type: ALL_ALERT) => {
    switch(type){
        case ANOMALY:
            return FireIcon
        case METRIC:
            return ChartBarIcon;
        case KPI:
            return ClipboardIcon;
        case OBJECTIVE:
            return TrendingUpIcon
        case PACING:
            return CurrencyDollarIcon
        case URL_CHECKER:
            return LinkIcon
        case SECURITY:
            return LockClosedIcon
        case ADS:
            return PhotographIcon
        case CONVERSION_TAG:
            return TagIcon
        default:
            throw new Error(`Unable to found icon for ${type}`);
    }
}