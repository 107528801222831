import React from "react";
import { useTranslation } from "react-i18next";
import { FormikConfig } from "formik";
import * as Yup from 'yup';
import Input from "../../../../ui/components/input/input";
import Textarea from "../../../../ui/components/input/textarea";

export type GoogleBigQueryOptionsType = {
  type: "GOOGLE_BIG_QUERY",
  projectId: string,
  dataSetId: string|null,
  jsonKey: string
}

export const Validation = Yup.object().shape({
  projectId:  Yup.string().required('You need to pass a project Id'),
  dataSetId:  Yup
    .string()
    .required('You need to put a dataSet Id')
    .matches(/^[a-zA-Z0-9_]+$/ , 'You only can put alphanumerical character and "_".'),
  jsonKey:  Yup.string().required('You need to pass the a json key.'),
});


const GoogleBigQueryOptions = ({ formik } : {formik: any}) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <Input
          id="projectId"
          name="projectId"
          type="text"
          label={t('Project Id')}
          error={formik.errors.options && formik.errors.options.projectId}
          touched={formik.touched.options && formik.touched.options.projectId}
          description={t('Input the project Id from your google console developer')}
          value={formik.values.options.projectId}
          onChange={(e) => formik.setFieldValue('options.projectId', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="dataSetId"
          name="dataSetId"
          type="text"
          label={t('Dataset Id')}
          error={formik.errors.options && formik.errors.options.dataSetId}
          touched={formik.touched.options && formik.touched.options.dataSetId}
          description={t('Input the dataSet Id you want. If set to null, we use the default string from the destination.')}
          value={formik.values.options.dataSetId}
          onChange={(e) => formik.setFieldValue('options.dataSetId', e.target.value)}
        />
      </div>
      <div>
        <Textarea
          id="jsonKey"
          name="jsonKey"
          rows={4}
          label={t('Json Key')}
          error={formik.errors.options && formik.errors.options.jsonKey}
          touched={formik.touched.options &&  formik.touched.options.jsonKey}
          description={t('Input the JsonKey that you use to connect from developer console.')}
          value={formik.values.options.jsonKey}
          onChange={(e) => formik.setFieldValue('options.jsonKey', e.target.value)}
        />
      </div>
    </div>
  );
};

export default GoogleBigQueryOptions;
