import BaseNetwork from "./base-network";
import {LinkMethod, OAUTH_2} from "../../constant/link-method";
import {CRITEO, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS, ANALYTICS } from "../../constant/constant";

class Criteo extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Criteo";

  getNetworkIdentifier = (): NETWORK => CRITEO;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];

  connectionMethod(args: any): any {
    return {
      clientId: args.clientId,
      clientSecret: args.clientSecret
    }
  }
}

export default Criteo;
