import { useMutation, useQuery } from "react-query";
import GoogleBigQueryAPI from "../../services/repository/data-connector/destinations/google-big-query";
import GoogleBigQueryDestination, { PostGoogleBigQuery } from "../../services/models/http/data-connector/destination/google-big-query";
import Pagination from "../../services/models/http/paginated";
import DestinationAPI from "../../services/repository/data-connector/destination";

export const usePostDestinationMutation = () => useMutation('destination', (data: PostGoogleBigQuery)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._postOne(data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const usePutDestinationMutation = (id: number) => useMutation(['destination', id], (data: PostGoogleBigQuery)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._put(id, data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const useDeleteDestinationMutation = (id: number) => useMutation(['destination', id], ()  => {
  return DestinationAPI._delete(id);
});

export const useDestination = (id: number) => useQuery(['destination', id], () : Promise<GoogleBigQueryDestination> => DestinationAPI._getOne(id));
export const useDestinationListing = (page: number, options = {}) => useQuery(['destinations', page, options], () : Promise<Pagination<GoogleBigQueryDestination>> => DestinationAPI._getAll({pagination: false, query : {page, ...options}}));