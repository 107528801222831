import React from "react";
import Select from 'react-select'
import { useTranslation } from "react-i18next";
import { useDataSourceListing } from "../../../hooks/datasource";
import NetworkProvider from '../../../config/network/network-provider';

const DatasourceSelect = ({ value, ...props }: { value: string } & any) => {
  const dataSource = useDataSourceListing({mode: 'listing'});
  const {t} = useTranslation();


  const options = dataSource.data?.data.filter(elem => NetworkProvider.getByPlatform(elem.getPlatform()) !== undefined && NetworkProvider.getByPlatform(elem.getPlatform())?.isETLActivated()).map(elem => {
    const label = <span className="flex items-center"><img className="h-6 mr-1" alt={elem.getPlatform()} src={NetworkProvider.getByPlatform(elem.getPlatform())?.networkImage()} />{elem.getName()}</span>
    return {value: elem.getId(), label}
  });

  let localValue, defaultValue;

  if(value){
    localValue = options?.find(elem => elem.value === value);
  }
  if(props.defaultValue){
    defaultValue = options?.find(elem => elem.value === props.defaultValue);
  }

  return (
    <Select classNamePrefix="react-select" {...props}  defaultValue={defaultValue} value={localValue} isLoading={dataSource.isLoading} options={options} />
  );
};

export default DatasourceSelect;
