
import React, {ButtonHTMLAttributes, ReactElement} from "react";

type ButtonSize = "x-small" | "small" | "medium" | "large" | "x-large";
type ButtonStyle = "grey" | "primary" | "secondary" | "white" | "primary-inverted" | "transparent" | "primary-purple" | "danger";
type HTMLType = "submit" | "button";

const getSize = (size: ButtonSize) => {
    switch (size){
        case "x-small":
            return "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-normal rounded ";
        case "small":
            return "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-normal rounded-md";
        case "medium":
            return "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md ";
        case "large":
            return "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md ";
        case "x-large":
            return "inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md";
    }
}

const getColor = (color: ButtonStyle) => {
    switch (color){
        case "primary":
            return "text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm";
         case "danger":
            return "text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm";
        case "primary-purple":
            return "text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 shadow-sm";
        case "transparent":
            return "text-gray-700 hover:text-white bg-transparent hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
        case "secondary":
            return "text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm";
        case "white":
            return "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm";
        case "primary-inverted":
            return "text-indigo-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm";
        case "grey":
            return "inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500";
        default:
            throw new Error(`Unable to found color : ${color}`);
    }
}

const Button = ({children, disabled = false, size = "medium", full = false, type = "primary", htmlType = "button", loading = false, className = undefined, ...props} : {className?: string, loading?: boolean, full?: boolean, disabled?: boolean, children: Element | React.ReactNode | string, size?: ButtonSize, type?: ButtonStyle, htmlType?: HTMLType} & React.HTMLAttributes<HTMLElement>) => {
    let baseClass = `${getSize(size)} ${getColor(type)}`;

    if(full){
        baseClass += " w-full justify-center";
    }
    if(disabled){
        baseClass += " opacity-50 cursor-not-allowed"
    }
    if(className){
        baseClass += ` ${className}`;
    }
    return <button
        {...props}
        type={htmlType}
        className={baseClass}
        onClick={
            (e) => {

                if(htmlType === "submit" && (disabled || loading)){
                    e.stopPropagation();
                    e.preventDefault();
                }

                // eslint-disable-next-line react/prop-types
                if(props.onClick && !disabled){
                    e.stopPropagation();
                    e.preventDefault();
                    // eslint-disable-next-line react/prop-types
                    props.onClick(e);
                }

            }
        }
    >
        {loading ?  <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg> : null}
        {children}
    </button>
}

export default Button;