import API from "../../api/http/client";
import Proxy from "../../api/http/proxy";
import Model from '../../models/http/alert/security';

class Security extends API{
  serializer = () => ({
    _getAll: Model
  });

}

export default Proxy<Security>(new Security('/securities'))