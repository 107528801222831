import API from "../../api/http/client";
import Field from "../../models/http/google-datastudio/field";
import Proxy from "../../api/http/proxy";
import DeleteSerializer from "../../models/http/delete";

class FieldAPI extends API {

  checkFieldValidation(networkAuthorizationId: number,accountId: string, fields: Array<string>) {
    return this.getClient().post(`/fields/validation`, {network_authorization: networkAuthorizationId, account_id: accountId, fields, options: []});
  }

  serializer = () => ({
      _getAll: Field,
      _put: Field,
      _postOne: Field,
      _delete: DeleteSerializer
    });

}

export default Proxy<FieldAPI>(new FieldAPI('/google-datastudio/fields'));
