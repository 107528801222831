import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import AccessibilityModel from "../../../../services/models/http/alert/accessibility";
import { kpiOperator, KPIOperator, Pacing as Model, periodPacing, PeriodPacing } from "../helpers/sentry-template";
import { useFormik } from "formik";
import { ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../../services/helper";
import Button from "../../../../ui/components/button/button";
import { pickBy } from "lodash";

export const Validation = Yup.object().shape({
  period: Yup.string().required().oneOf(["start_of_month", "start_of_week", "every_day"]),
  thresholds: Yup.object().shape({
    above: Yup.number().positive(),
    below: Yup.number().positive(),
  }),
})

type FormField = {
  period: PeriodPacing,
  thresholds: {
    above?: number,
    below? : number
  }
}

const Pacing = ({onChange, value, accessibility, initialValues} : {accessibility: AccessibilityModel, onChange: (metric: Model) => void, value: Model, initialValues: Model}) => {
  const form = useFormik<Partial<FormField>>({
    initialValues: {...{
      period: "start_of_month",
      thresholds: {
        above: undefined,
        below: undefined
      }
    }, ...pickBy(initialValues, v => v !== undefined)},
    validationSchema: Validation,
    onSubmit: (values) => {
      const change = { ...values, type: "pacing"} as unknown as Model;

      if(Number.isNaN(parseFloat(change.thresholds.below as unknown as string))){
        change.thresholds.below = undefined;
      }

      if(Number.isNaN(parseFloat(change.thresholds.above as unknown as string))){
        change.thresholds.above = undefined;
      }
      onChange(change)
    }
  });

  const {t} = useTranslation();


  const [opened, setOpened] = useState(true);


  async function handleSubmit(e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    const validateData = await form.validateForm();
    if (Object.keys(validateData).length === 0) {
      setOpened(false);
      form.handleSubmit();
    }
  }

  return (
    <div className="text-sm text-gray-600 rounded bg-white shadow" onKeyDown={async e => {
      if(e.key === "Enter"){
        await handleSubmit(e);
      }
    }}>
      <div className=" cursor-pointer hover:bg-gray-100 flex justify-between gap-2 items-center px-4 pt-4 pb-4 border-b border-gray-200 " onClick={async () => {
        if(opened){
          const validateData = await form.validateForm();
          if(Object.keys(validateData).length === 0){
            setOpened(!opened);
            form.handleSubmit();
          }
        }else {
          setOpened(!opened)
        }
      }} role="button">
        <h3 className="block">Pacing detection</h3>
        <ChevronDownIcon className="block h-8"/>
      </div>
      <Transition
        as={Fragment}
        show={opened}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="p-4">
          <div className="flex items-center gap-4 justify-between">
            <div className="w-full">
              <label htmlFor="kpi" className="block text-xs font-medium text-gray-700">
                {t('Period :')}
              </label>
              <select
                id="kpi"
                name="kpi"
                value={form.values.period}
                onChange={e => form.setFieldValue('period', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {periodPacing.map(elem => <option value={elem.value}>{t(elem.name)}</option>)}
              </select>
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600 flex items-center gap-2 justify-between">
            <div className="w-full">
              <label htmlFor="value" className="block text-xs font-medium text-gray-700">
                {t('Below :')}
              </label>
              <div className="relative">
                <input
                  type="number"
                  id="value"
                  name="value"
                  min={0}
                  value={form.values.thresholds?.below}
                  onChange={e => form.setFieldValue('thresholds.below', e.target.value)}
                  className={
                    classNames(
                      "border-gray-300 border mt-1 block w-full pl-3py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                      form.errors.thresholds ? "pr-10" : ""
                    )
                  }
                />
                {form.errors.thresholds ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>: null}
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="value" className="block text-xs font-medium text-gray-700">
                {t('Above :')}
              </label>
              <div className="relative">
                <input
                  type="number"
                  id="value"
                  min={0}
                  name="value"
                  value={form.values.thresholds?.above}
                  onChange={e => form.setFieldValue('thresholds.above', e.target.value)}
                  className={
                    classNames(
                      "border-gray-300 border mt-1 block w-full pl-3py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                      form.errors.thresholds ? "pr-10" : ""
                    )
                  }
                />
                {form.errors.thresholds ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>: null}
              </div>
            </div>
          </div>
          <div className="mt-4 w-full">
            <Button onClick={e => handleSubmit(e)} className="w-full text-center flex justify-center">{t('Add')}</Button>
          </div>
        </div>
      </Transition>
    </div>)
};

export default Pacing;
