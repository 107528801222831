import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";
import {PostRequest, PutRequest} from "../../form-interface";

export interface FieldTranslation {
  id: number,
  languageCode: string,
  description: string,
  name: string,
}

export interface Platform {
  id: number,
  name: string,
}

export interface PostField {
  companyId: number,
  platform: string,
  formula: string,
  defaultDescription: string,
  defaultName: string,
  type: string,
  fieldType: string,
  aggregationType: string
}

export interface PutField {
  id: number,
  companyId: number,
  platform: string,
  formula: string,
  defaultDescription: string,
  defaultName: string,
  type: string,
  fieldType: string,
  aggregationType: string
}

export interface FieldModel {
  id: number,
  companyId?: number,
  platform: Platform,
  formula: string,
  defaultDescription: string,
  defaultName: string,
  description: string,
  name: string,
  status: string,
  translations: Array<FieldTranslation>,
  type: string,
  fieldType: string,
  aggregationType: string|null
}

class Field extends Model<FieldModel> implements PostRequest<PostField>, PutRequest<PutField>{

  getId() {
    return this.data.id;
  }

  getCompanyId() {
    return this.data.companyId;
  }

  getPlatform() {
    return this.data.platform;
  }

  getFormula() {
    return this.data.formula;
  }

  getDefaultDescription() {
    return this.data.defaultDescription;
  }

  getDefaultName() {
    return this.data.defaultName;
  }

  getName() {
    return this.data.name;
  }

  getDescription() {
    return this.data.description;
  }

  getStatus() {
    return this.data.status;
  }

  getType() {
    return this.data.type;
  }

  getFieldType() {
    return this.data.fieldType;
  }

  getAggregationType() {
    return this.data.aggregationType;
  }

  getTranslations() {
    return this.data.translations;
  }

  getSchemaToSerialize = (): JSONSchemaType<FieldModel> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "companyId": {
        nullable: true,
        type: "number"
      },
      "platform": {
        type: "object" ,
        properties: {
          "id": {"type": "number"},
          "name": {type: "string"}
        },
        required: ["id", "name"]
      },
      "formula": { type: "string", nullable: true },
      "defaultDescription": { type: "string" },
      "defaultName": { type: "string" },
      "name": { type: "string" },
      "description": { type: "string" },
      "status": { type: "string" },
      "type": { type: "string" },
      "fieldType": { type: "string" },
      "aggregationType": {
        nullable: true,
        type: "string"
      },
      "translations": {
        type: "array",
        items: {
          type: "object",
          properties: {
            "id": {"type": "number"},
            "languageCode": {type: "string"},
            "description": {type: "string"},
            "name": {type: "string"}
          },
          required: ["id", "languageCode", "description", "name"]
        }
      }
    },
    required: ["id", "platform", "formula", "defaultDescription", "defaultName"]
  });

  getSchemaPostToValidate = (): JSONSchemaType<PostField> => ({
    type: "object",
    properties: {
      "companyId": {
        nullable: true,
        type: "number"
      },
      "platform": { type: "string" },
      "formula": { type: "string", nullable: true },
      "defaultDescription": { type: "string" },
      "defaultName": { type: "string" },
      "type": { type: "string" },
      "fieldType": { type: "string" },
      "aggregationType": { type: "string" }
    },
    required: ["platform", "formula", "defaultDescription", "defaultName"]
  });

  getSchemaPutToValidate = (): JSONSchemaType<PutField> => ({
    type: "object",
    properties: {
      "id": { type: "number", nullable: true },
      "companyId": {
        nullable: true,
        type: "number"
      },
      "platform": { type: "string" },
      "formula": { type: "string", nullable: true },
      "defaultDescription": { type: "string" },
      "defaultName": { type: "string" },
      "type": { type: "string" },
      "fieldType": { type: "string" },
      "aggregationType": { type: "string" }
    },
    required: ["platform", "formula", "defaultDescription", "defaultName"]
  });

}

export default Field;