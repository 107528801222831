import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSegmentTrack } from "react-segment-analytics";
import BillingNotification from "../../../ui/components/billing-notification";
import Header from "../components/header";
import Async from "../../../ui/helper/async";
import MeetingBooker from "../../../ui/components/meeting-booking";
import { useConnectionUsage } from "../../../hooks/connections";
import { useMe } from "../../../hooks/user";
import { RowConnection } from "../home";
import moment from "moment";

const UsageDetail = () => {
  const {t} = useTranslation();
  const track = useSegmentTrack();
  const connections = useConnectionUsage(1, {pagination: false, "lastSeen[after]" : moment().subtract(1, 'month').toISOString()})
  const me = useMe();
  return (
    <div className="w-full py-4">
      <BillingNotification />
      <Header title={t('Usage')} />
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full ">
          <Async {...connections}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr className="border-t border-gray-200 bg-white">
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Name')}</span>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Source')}</span>
                  </th>
                  <th
                    scope="col"
                    className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Destination')}
                  </th>
                  <th
                    scope="col"
                    className="text-left table-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Last Seen At')}
                  </th>
                  <th
                    scope="col"
                    className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Status')}
                  </th>
                  <th
                    scope="col"
                    className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {connections.data?.data.map(elem => <RowConnection connection={elem}
                                                                   onDelete={() => connections.refetch()} />)}
                </tbody>
              </table>
            </div>
          </Async>
        </div>
      </div>
      <MeetingBooker title="Get help connecting your BigQuery !"
                     description="Do you need help creating a connection? Book a meeting with our expert. Or use our chat to get a quick answer! PS: If you need to connect to Looker Studio or Google Sheets, you just need to create a new Source." />
    </div>
  );
};

export default UsageDetail;
