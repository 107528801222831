export type During = "4_hour" | "8_hour" | "12_hour" | "24_hour" | "yesterday" | "7_days" | "30_days" | "last_month" | "lifetime";

export type ThresholdType = "above" | "below" | "equals";

export type TrendType = "above" | "below" | "equals";

export type MetricType = "trend" | "threshold";

export type MetricOperator = "sum" | "avg" | "max" | "min" | "last" | "first";

export type KPIOperator = "not_null" | "null" | "equal" | "not_equal";

export type AlertType = "metric" | "kpi" | "objective" | "pacing";

export type PeriodPacing = "start_of_month" | "start_of_week" | "every_day";

export type FieldList = {
  name: string,
  accessor: string
}

export type ALERT_STATUS = "ANOMALY" | "NOT_ANOMALY";

export type Pacing = {
  type: "pacing",
  period: PeriodPacing,
  thresholds : {
    above? : number,
    below? : number,
  }
}

export type VisualizerData = {
  [date: string]:  {
    data : Array<{
      values: Array<{
        name: string,
        value: number | string
      }&{kpi: string, value: string | number}>,
      status: ALERT_STATUS
      identifications: {
        [key: string]: string | number | object,
      },
      identifierName?: string
    }>
  }
}
export type CalculatedField = {
  name? : string,
  value? : Array<{children:Array<{text: string} | {text:string, character: string, accessor: string}>}>
}


export type Metric = {
  calculatedFields: Array<CalculatedField>,
  alertType: MetricType,
  alertValue: Array<Trend | Threshold>
  type: "metric"
}

export type Kpi = {
  type : "metric"
  kpi : string,
  operator: KPIOperator,
  value: string
}

export type Variable = Metric | Kpi | Pacing

export type Threshold = {
  operator: MetricOperator,
  thresholdValue: number,
  metric: string | undefined,
  during: During,
  thresholdType: ThresholdType,
  type: "threshold"
}

export type Trend = {
  operator: MetricOperator,
  trendValue: number,
  metric: string | undefined,
  duringTime1: During,
  duringTime2: During,
  trendType: TrendType,
  type: "trend"
}

const thresholdTemplate = (metric?: string) => ({
  operator: "avg" as const,
  thresholdValue: 20,
  metric,
  during: "4_hour" as const,
  thresholdType: "above" as const,
  type: "threshold" as const,
})

const trendsTemplate = (metric?: string) => ({
    operator: "avg" as const,
    trendValue: 20,
    metric,
    duringTime1: "4_hour" as const,
    duringTime2: "yesterday" as const,
    trendType: "above" as const,
    type: "trend" as const,
})


export const getTemplateForMetric = (type: "trend" | "threshold", metric: string | undefined) => type === "trend" ? trendsTemplate(metric) : thresholdTemplate(metric);

export const operator = [
  {value: "sum", name: "Sum"},
  {value: "avg", name: "Average"},
  {value: "min", name: "Max"},
  {value: "max", name: "Min"},
  {value: "last", name: "Last"},
  {value: "first", name: "First"},
];

export const kpiOperator = [
  {value: "equal", name: "Equals"},
  {value: "not_equal", name: "Not Equals"},
  {value: "not_null", name: "Not null"},
  {value: "null", name: "Null"}
];

export const detection = [
  {value: "above", name: "Above"},
  {value: "below", name: "Below"},
  {value: "equals", name: "Equals"}
];

export const during = [
  {value: "4_hour", name: "Four hours"},
  {value: "8_hour", name: "Eight hours"},
  {value: "12_hour", name: "Twelve hours"},
  {value: "24_hour", name: "Twenty four hours"},
  {value: "yesterday", name: "Yesterday"},
  {value: "7_days", name: "Seven days"},
  {value: "30_days", name: "Thirteen days"},
  {value: "last_month", name: "Last month"},
  {value: "lifetime", name: "Lifetime"},
];

export const periodPacing = [
  {value: "start_of_month", name: "Start of the month"},
  {value: "start_of_week", name: "Start of the week"},
  {value: "every_day", name: "Every day"}
];

export type Network = {
  [key:string] : {
    platform: string,
    level: string
    accountId: string;
    dataSourceId: number
  }
}


export const metricVariableTemplate = (type: MetricType, metric: string | undefined = undefined) : Metric => {
  const template = getTemplateForMetric(type, metric);

  return {
    type: "metric",
    alertValue: [template],
    alertType: type === "trend" ? "trend" : "threshold",
    calculatedFields: []
  }
}
