import BaseNetwork from "./base-network";
import {LinkMethod, OAUTH_2} from "../../constant/link-method";
import {PIWIK, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {ANALYTICS} from "../../constant/constant";

class Piwik extends BaseNetwork{
    connectingMethod = (): LinkMethod => OAUTH_2;

    getName = (): string => "Piwik";

    getNetworkIdentifier = (): NETWORK => PIWIK;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => ANALYTICS;

    isETLActivated(): boolean {
        return false;
    }

    alertOptions = () =>  [];

    connectionMethod(args: any): any {
        return {
            domain: args.domain,
            clientId: args.clientId,
            clientSecret: args.clientSecret
        }
    }
}

export default Piwik;