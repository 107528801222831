import Model from "../../model";
import { JSONSchemaType } from "ajv/dist/types/json-schema";

export interface ConversionTagResponse200{
  accountId: string,
  tagId: string,
  name: string,
  status: string,
  type: string,
  lastView: {
    date:string
  }|null
}

class ConversionTag extends Model<ConversionTagResponse200>{

  public getAccountId(){
    return this.data.accountId;
  }

  public getTagId(){
    return this.data.tagId;
  }

  public getName(){
    return this.data.name;
  }

  public getStatus(){
    return this.data.status;
  }

  public getLastView(){
    return this.data.lastView ? new Date(this.data.lastView.date) : null;
  }

  public getType(){
    return this.data.type;
  }

  getSchemaToSerialize(): JSONSchemaType<ConversionTagResponse200> {
    return {
      type: "object",
      required: ['accountId', 'tagId', 'name', 'status', 'type'],
      properties: {
        accountId: {
          type: "string"
        },
        tagId: {
          type: "string"
        },
        name: {
          type: "string"
        },
        status: {
          type: "string"
        },
        type: {
          type: "string"
        },
        lastView: {
          type: "object",
          required: ['date'],
          properties:{
            "date" : {
              type: "string"
            }
          },
          nullable: true
        },
      }
    };
  }
}

export default ConversionTag;