import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface NetworkAuthorizationInformationHTTP {
  type: string,
  authType: string
  endpoint?: string,
  internalEndpoint?: string
}

class NetworkAuthorizationInformation extends Model<NetworkAuthorizationInformationHTTP>{

  public getType(){
    return this.data.type;
  }

  public getAuthType(){
    return this.data.authType;
  }

  public getEndpoint(){
    return this.data.endpoint;
  }

  public getInternalEndpoint(){
    return this.data.internalEndpoint;
  }

  getSchemaToSerialize = (): JSONSchemaType<NetworkAuthorizationInformationHTTP> => ({
    type: "object",
    properties: {
      "type": { type: "string" },
      "authType": { type: "string" },
      "endpoint": { type: "string", nullable: true },
      "internalEndpoint": { type: "string", nullable: true },
    },
    required: ['type', 'authType']
  });
}

export default NetworkAuthorizationInformation;