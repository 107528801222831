import {YEAR} from "../datastudio-type/datastudio-type-enum";

export const AVG = "AVG";
export const COUNT = "COUNT";
export const COUNT_DISTINCT = "COUNT_DISTINCT";
export const MAX = "MAX";
export const MIN = "MIN";
export const SUM = "SUM";
export const NONE = "NONE";
export const NULL = null;
export const AUTO = "AUTO";


export const allAggregation = [
    {
        "id": AVG,
        "name": "Average",
        "description": "The numerical average (mean) of the entries."
    },
    {
        "id": COUNT,
        "name": "Count",
        "description": "The number of entries."
    },
    {
        "id": COUNT_DISTINCT,
        "name": "Count Distinct",
        "description": "The number of distinct entries."
    },
    {
        "id": MAX,
        "name": "Max",
        "description": "The maximum of the entries."
    },
    {
        "id": MIN,
        "name": "Min",
        "description": "The minimum of the entries."
    },
    {
        "id": SUM,
        "name": "Sum",
        "description": "The sum of the entries."
    },
    {
        "id": NONE,
        "name": "None",
        "description": "No aggregation"
    },
    {
        "id": AUTO,
        "name": "Auto",
        "description": "Should be set for calculated fields involving an aggregation"
    },
    {
        "id": NULL,
        "name": "Null",
        "description": "No default aggregation."
    },
];