import API from "../../api/http/client";
import ConversionTag from "../../models/http/alert/conversion-tag";
import Proxy from "../../api/http/proxy";
import Pagination from "../../models/http/paginated";

class ConversionTagAPI extends API{

  public _list(platform: string, accountId: string) : Promise<Pagination<ConversionTag>>{
    return this.getClient().get(`${this.endpoint}${this.uri}/list/${platform}/${accountId}`)
  }

  serializer() {
    return {
      _list: ConversionTag
    };
  }
}

export default Proxy<ConversionTagAPI>(new ConversionTagAPI("/alerts/conversion-tags"))

