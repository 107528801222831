import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import AccessibilityModel from "../../../../../services/models/http/alert/accessibility";
import {
  CalculatedField, detection,
  during,
  During, operator,
  MetricOperator,
  Threshold,
  Threshold as ThresholdType,
  ThresholdType as ThresholdOperatorType
} from "../../helpers/sentry-template";
import { classNames } from "../../../../../services/helper";
import Button from "../../../../../ui/components/button/button";
import { pickBy } from "lodash";

export const Validation = Yup.object().shape({
  operator: Yup.string().required().oneOf(["sum", "avg", "max", "min", "last", "first"]),
  thresholdValue: Yup.number().required().moreThan(-1),
  thresholdType: Yup.string().required().oneOf(['above', 'below', 'equals']),
  metric: Yup.string().required(),
  during: Yup.string().required().oneOf(["4_hour", "8_hour", "12_hour", "24_hour", "yesterday", "7_days", "30_days", "last_month", "lifetime"])
})

type FormField = {
  operator: MetricOperator,
  thresholdValue?: number,
  metric: string,
  during: During,
  thresholdType: ThresholdOperatorType
}

const ThresholdComponent = ({key, accessibility, calculatedField, onChange, value, initialValues}: {onChange: (config: ThresholdType) => void, value: ThresholdType, initialValues: ThresholdType, key: string, accessibility: AccessibilityModel, calculatedField: CalculatedField[]}) => {

  const {t} = useTranslation();

  const fields = accessibility.data.properties.metrics.properties.map(elem => ({name: elem.name, accessor: `${elem.accessor}`})).concat(calculatedField.map(elem => ({name: elem.name as string, accessor: elem.name as string})));

  const init = {
    operator: "sum",
    thresholdValue: undefined,
    thresholdType: "above",
    during: "yesterday",
    metric: fields[0].accessor,
    "type": "threshold"
  };
  const form = useFormik<Partial<FormField>>({
    initialValues: {...init, ...pickBy(initialValues, v => v !== undefined)} as Partial<FormField>,
    validationSchema: Validation,
    onSubmit: (values) => {
      onChange({...values, type: "threshold"} as Threshold)
    }
  });

  const [opened, setOpened] = useState(true);

  async function handleSubmit(e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    const validateData = await form.validateForm();
    if (Object.keys(validateData).length === 0) {
      setOpened(false);
      form.handleSubmit();
    }
  }

  return (
    <div className="text-sm text-gray-600 rounded bg-white shadow" onKeyDown={async e => {
      if(e.key === "Enter"){
        await handleSubmit(e);
      }
    }}>
      <div className=" cursor-pointer hover:bg-gray-100 flex justify-between gap-2 items-center px-4 pt-4 pb-4 border-b border-gray-200 " onClick={async () => {
        if(opened){
          const validateData = await form.validateForm();
          if(Object.keys(validateData).length === 0){
            setOpened(!opened);
            form.handleSubmit();
          }
        }else {
          setOpened(!opened)
        }
      }} role="button">
        <h3 className="block">When the <b>{t((operator.find(elem => elem.value === form.values.operator) as { name: string }).name)}</b> of <b>{t((fields.find(elem => elem.accessor === form.values.metric) as { name: string }).name)}</b> is <b>{t((detection.find(elem => elem.value === form.values.thresholdType) as { name: string }).name)}</b> <b>{form.values.thresholdValue !== undefined ? form.values.thresholdValue : t('X')}</b> during <b>{t((during.find(elem => elem.value === form.values.during) as { name: string }).name)}</b></h3>
        <ChevronDownIcon className="block h-8"/>
      </div>
      <Transition
        as={Fragment}
        show={opened}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
      <div className="p-4">
        <div className="flex items-center gap-4 justify-between">
          <div className="w-full">
            <label htmlFor={`aggregation-${key}`} className="block text-xs font-medium text-gray-700">
              {t('Aggregation method :')}
            </label>
            <select
              id={`aggregation-${key}`}
              name={`aggregation-${key}`}
              value={form.values.operator}
              onChange={e => form.setFieldValue('operator', e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {operator.map(elem => <option value={elem.value}>{t(elem.name)}</option>)}
            </select>
          </div>
          <div className="w-full">
            <label htmlFor={`metric-${key}`} className="block text-xs font-medium text-gray-700">
              {t('Metrics :')}
            </label>
            <select
              id={`metric-${key}`}
              name={`metric-${key}`}
              value={form.values.metric}
              onChange={e => form.setFieldValue('metric', e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {fields.map(elem => <option value={elem.accessor}>{elem.name}</option>)}
            </select>
          </div>
        </div>
        <div className="mt-4 text-sm text-gray-600 flex items-center gap-2 justify-between">
          <div className="w-full">
            <label htmlFor={`detection-${key}`} className="block text-xs font-medium text-gray-700">
              {t('Detection method :')}
            </label>
            <select
              id={`detection-${key}`}
              name={`detection-${key}`}
              value={form.values.thresholdType}
              onChange={e => form.setFieldValue('thresholdType', e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {detection.map(elem => <option value={elem.value}>{t(elem.name)}</option>)}

            </select>
          </div>
          <div className="w-full">
            <label htmlFor={`threshold-${key}`} className="block text-xs font-medium text-gray-700">
              {t('Threshold : ')}
            </label>
            <div className="relative">
              <input
                type="number"
                step={0.1}
                min={0}
                id={`threshold-${key}`}
                name={`threshold-${key}`}
                value={form.values.thresholdValue}
                onChange={e => form.setFieldValue('thresholdValue', parseFloat(e.target.value))}
                className={
                  classNames(
                    "border-gray-300 border mt-1 block w-full pl-3py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                    form.errors.thresholdValue ? "pr-10" : ""
                  )
                }              />
              {form.errors.thresholdValue ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>: null}
            </div>
          </div>
        </div>
        <div className="mt-4 text-sm text-gray-600 flex items-center gap-2 justify-between">
          <div className="w-full">
            <label htmlFor={`during-${key}`} className="block text-xs font-medium text-gray-700">
              {t('During last :')}
            </label>
            <select
              id={`during-${key}`}
              name={`during-${key}`}
              value={form.values.during}
              onChange={e => form.setFieldValue('during', e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {during.map(elem => <option value={elem.value}>{t(elem.name)}</option>)}
            </select>
          </div>
        </div>

        <div className="mt-4 w-full">
          <Button onClick={e => handleSubmit(e)} className="w-full text-center flex justify-center">{t('Add')}</Button>
        </div>
      </div>
      </Transition>
    </div>
  );
};

export default ThresholdComponent;
