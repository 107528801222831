import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import moment from "moment";
import { useBillingSessionPortal, useMe } from "../../../hooks/user";

const BillingNotification = () => {
  const me = useMe();
  if(me.data?.getCompany().stripeSubscriptionState === 'incomplete'){
    const billingPortal = useBillingSessionPortal();
    return <div className="border-l-4 border border-yellow-400 bg-yellow-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 flex justify-between items-center">
          <div className="text-sm text-blue-700">
            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div className="mt-2 text-sm text-yellow-700">
              There is a problem with your payment method. Please update your payment method to continue using our service.
            </div>
          </div>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            {billingPortal.data ? <a href={billingPortal.data?.data.url} >
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Update payment method
              </button>
            </a> : null}
          </p>
        </div>
      </div>
    </div>
  } else if(me.data?.getCompany().stripeSubscriptionState === null && moment().isAfter(moment(me.data?.getCompany().trialFinished))){
    return <div className="border-l-4 border border-yellow-400 bg-yellow-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 flex justify-between items-center">
          <div className="text-sm text-blue-700">
            <h3 className="text-sm font-medium text-yellow-800">Your 14 day free-trial is over.</h3>
            <div className="mt-2 text-sm text-yellow-700">
              To continue using our service, please subscribe to one of our plan. If you have any questions, do not hesitate to contact us.
            </div>
          </div>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link to='/buy' >
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Subscribe now
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  }else if(me.data?.getCompany().stripeSubscriptionState === null && moment().add(7, 'days').isAfter(moment(me.data?.getCompany().trialFinished))){
    return <div className="border-l-4 border border-yellow-400 bg-yellow-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 flex justify-between items-center">
          <div className="text-sm text-blue-700">
            <h3 className="text-sm font-medium text-yellow-800">Your 14 day free-trial is almost over.</h3>
            <div className="mt-2 text-sm text-yellow-700">
              To continue using our service, please subscribe to one of our plan. If you have any questions, do not hesitate to contact us.
            </div>
          </div>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link to='/buy' >
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Subscribe now
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  }else if((me.data?.getCompany().userNumberDataSourceForLast30days || 0) > (me.data?.getCompany().stripeSubscriptionNumberDataSource || 0) && me.data?.getCompany().stripeSubscriptionState === 'active'){
    return <div className="border-l-4 border border-yellow-400 bg-yellow-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 flex justify-between items-center">
          <div className="text-sm text-blue-700">
            <h3 className="text-sm font-medium text-yellow-800">Your are currently using more account than allowed.</h3>
            <div className="mt-2 text-sm text-yellow-700">
              You have used {me.data?.getCompany().userNumberDataSourceForLast30days} accounts in the last 30 days. Your plan allows you to use {me.data?.getCompany().stripeSubscriptionNumberDataSource} accounts. Please delete some of your account or update your plan accordingly.
              <br />
              Without any action, your account will be locked.
              <br />
              You can view your account usage in <Link to="/data-connector/usage">here</Link>.
            </div>
          </div>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link to='/buy' >
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Update now
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  }
  return null;
};

export default BillingNotification;
