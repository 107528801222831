import React from "react";
import { useTranslation } from "react-i18next";
import SourceForm from "../containers/source-form";

const CreateSource = () => {

  const {t} = useTranslation();

  return (
    <div className="w-full py-4 flex justify-center">
      <SourceForm mode="CREATE"  />
    </div>
  );
};

export default CreateSource;
