export const ANOMALY = "anomaly";
export const PACING = "pacing";
export const METRIC = "metric";
export const KPI = "kpi";
export const OBJECTIVE = "objective";
export const URL_CHECKER = "url_checker"  as const;
export const CONVERSION_TAG  = "conversion_tag" as const;
export const SECURITY = "security"  as const;
export const ADS = "ads"  as const;

export type SPECIFIC_ALERT = "url_checker" | "conversion_tag" | "security" | "ads";

export type ALL_ALERT = "anomaly" | "pacing" | "objective" | "url_checker" | "conversion_tag" | "security" | "ads" | "metric" | "kpi";

export const backendAlertToTypeMapper = (type:string) : string|null => {
    switch (type){
        case "ADS":
            return ADS;
        case "URL_CHECKER":
            return URL_CHECKER;
        case "CONVERSION_TAG":
            return CONVERSION_TAG;
        case "SECURITY":
            return SECURITY;
        default:
            throw new Error(`Unable to get alertType for ${type}`);
    }

}