import React from "react";
import { Route, Routes } from "react-router-dom";
import URLChecker from "./components/u-r-l-checker";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import Security from "./components/security";
import ConversionTag from "./components/conversion-tag";
import { SentryRoutes } from "../../../index";

const Index = ({dataSource}: {dataSource: DataSource}) => {
  return (
    <SentryRoutes>
      <Route path="/url-checker" element={<URLChecker dataSource={dataSource} />}/>
      <Route path="/security" element={<Security dataSource={dataSource} />}/>
      <Route path="/conversion-tag" element={<ConversionTag dataSource={dataSource} />}/>
    </SentryRoutes>
  );
}

export default Index;
