import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Dropdown, DropdownItem} from "../../../ui/components/dropdown/dropdown";
import {useDataSourceListing} from "../../../hooks/datasource";
import Async from "../../../ui/helper/async";
import {useMe} from "../../../hooks/user";
import Client from "../components/client";
import DataSource from "../../../services/models/http/datasource/datasource-all";
import DataSourceClient, { DataSourceHTTP } from "../../../services/models/http/datasource/data-source-client";
import { getResultOnAnomaly, getResultOnPacing } from "../../../services/health";
import NetworkProvider from "../../../config/network/network-provider";
import BaseNetwork from "../../../config/network/base-network";


const Portfolio = () => {
    const {t} = useTranslation();

    const dropdownSort : Array<{
        id: "by-client"|"by-urgent",
        name: string
    }> = [
        {id: 'by-client', name: t('Sort by client name')},
        {id: 'by-urgent', name: t('Sort by alert number')},
    ]
    const [selectedSort, setSelectedSort] = useState<"by-client"|"by-urgent">('by-client');
    const [mode, setMode] = useState<"mine"|"all">('mine');

    const dataSourceRequest = useDataSourceListing({activateAlert: true});

    const sortFunc = (a: DataSource,b: DataSource) => {
        if(selectedSort=== "by-client"){
            return a.getName().localeCompare(b.getName());
        }else{
            const dataSourceClientA = new DataSourceClient(a.data as DataSourceHTTP);
            const dataSourceClientB = new DataSourceClient(b.data as DataSourceHTTP);

            const alertsA = getResultOnAnomaly(dataSourceClientA);
            const pacingA = getResultOnPacing(dataSourceClientA);

            const alertsB = getResultOnAnomaly(dataSourceClientB);
            const pacingB = getResultOnPacing(dataSourceClientB)

            return (alertsA.amount + pacingA.amount) - (alertsB.amount + pacingB.amount)
        }
    }
    return (
        <div>
            <div className="flex items-center">
                <h1 className="flex-1 text-lg font-medium">{t('Your clients')}</h1>
                <Dropdown title={(dropdownSort.find(elem => elem.id === selectedSort) as {name: string}).name} >
                    {dropdownSort.map(elem => <DropdownItem key={elem.id} onClick={() => setSelectedSort(elem.id)}>{elem.name}</DropdownItem>)}
                </Dropdown>
            </div>
            <div className="mt-3">
                <Async {...dataSourceRequest}>
                    <ul>
                        {dataSourceRequest.data?.data.filter(elem => (NetworkProvider.getByPlatform(elem.getPlatform()) as BaseNetwork).isAlertActivated()).sort(sortFunc).map(elem => <Client dataSource={elem} />)}
                    </ul>
                </Async>
            </div>
        </div>
    );
};

export default Portfolio;
