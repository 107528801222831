import React from "react";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useFormik} from "formik";
import Container from "../../../ui/components/container/container";
import Modal, {DefaultBottom, Title} from "../../../ui/components/modal";
import FieldTranslationForm, {FormFieldTranslation, FormFieldTranslationValidation} from "./form/field-translation-form";
import FieldTranslation, {PostFieldTranslation} from "../../../services/models/http/google-datastudio/field-translation";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import {usePostFieldTranslationGoogleDataStudioMutation} from "../../../hooks/google-datastudio";

export const CreateFieldTranslation = ({visible, setVisible}: {visible: boolean, setVisible: (value: boolean) => void}) => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const postGoogleDataStudio = usePostFieldTranslationGoogleDataStudioMutation();

    const initialValues = {
        id: undefined,
        name: undefined,
        description: undefined,
        languageCode: undefined,
        field: `/api/fields/${id}`
    };

    const {t} = useTranslation();

    const onClose = () => {
        setVisible(false);
    }

    const onSubmit = async (values: FormFieldTranslation) => {
        try {
            const response = await postGoogleDataStudio.mutateAsync(values as PostFieldTranslation);
            NotificationManager.success(t('Your field has been successfully created'), "")
            await queryClient.cancelQueries(['field_translation_datastudio']);
            queryClient.setQueriesData(['field_translation_datastudio'], (old) => (old as Pagination<FieldTranslation>).pushElement(response))
            setVisible(false);
        } catch(error) {
            NotificationManager.error(t('Unable to add the field'), t('An error occurred. Please try again.'))
        }
    }

    const form = useFormik<FormFieldTranslation>({
        initialValues,
        validationSchema: FormFieldTranslationValidation,
        onSubmit
    });

    return <Container>
        <Modal
            open={visible}
            onClose={onClose}
            bottom={<DefaultBottom disabledOkButton={!(form.dirty && form.isValid)} loading={postGoogleDataStudio.isLoading} onClose={() => setVisible(false)} titleButtonOk={t('Save')} onOk={() => form.submitForm()} titleButtonClose={t('Close')} />}
        >
            <Title title={t('Create a field')} />
            <FieldTranslationForm form={form} />
        </Modal>
    </Container>
}

export default CreateFieldTranslation;