import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import DestinationAPI from "../../../../services/repository/data-connector/destination";
import { CREATE, EDIT } from "../../../../constant/crud";
import Input from "../../../../ui/components/input/input";
import DestinationProvider from "../../../../config/destination/destination-provider";
import BaseDestination from "../../../../config/destination/base-destination";
import DestinationSelector from "../../../../ui/components/select/destination";
import GoogleBigQueryOptions, { GoogleBigQueryOptionsType, Validation as GoogleBigQueryValidation } from "../options/google-big-query";
import Button from "../../../../ui/components/button/button";
import { GOOGLE_BIG_QUERY } from "../../../../constant/destination";

export type Destination = {
  name: string,
  destination: BaseDestination
  options: GoogleBigQueryOptionsType
};

export const Validation = Yup.object().shape({
  name:  Yup.string().required('You need to set a name to the connection.'),
  destination:  Yup.mixed().required('You need to set a destination to the connection.'),
  options: Yup.lazy((item : any) => {
    if(item.type === GOOGLE_BIG_QUERY){
      return GoogleBigQueryValidation;
    }
    return Yup.mixed();
  })
});


const OptionsFactory = ({type, formik}: {type: string, formik: any}) => {
  switch (type){
    case "GOOGLE_BIG_QUERY":
      return <GoogleBigQueryOptions formik={formik} />
    default:
      throw new Error(`Unable to get the type ${type}`);
  }
}

const DestinationForm = ({ mode, onSubmit, submitting, initialValue }: { initialValue?:Destination,  submitting: boolean, mode: CREATE | EDIT, onSubmit: (data: Destination) => void }) => {
  const { t } = useTranslation();
  const FirstDestination = DestinationProvider.getAllConfigurable()[0];
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = {name: "",
    destination: FirstDestination,
    options: {
      type: "GOOGLE_BIG_QUERY" as const,
      projectId: '',
      dataSetId: null,
      jsonKey: ''
    }, ...initialValue};

  const formik = useFormik<Destination>({
    initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setValidating(true);
      setError(null);
      try {
        const result = await DestinationAPI.checkConnection(values.options.type, values.options);
        if(result.data.status === true){
          onSubmit(values);
        }else{
          setError(result.data.message);
        }
      }catch (e){
        // @ts-ignore
        setError(e.message);
      }
      setValidating(false);
    }
  });

  return (
    <div className="space-y-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3
                className="text-lg leading-6 font-medium text-gray-900">{mode === "CREATE" ? t("Create a new destination") : t("Update your destination")}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("If you want to export data to Google Data Studio or Google Sheets, you do not need any destination. Otherwise, just follow the step.")}
              </p>
            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Input
                id="name"
                name="name"
                label={t("Name :")}
                type="text"
                error={formik.errors.name}
                value={formik.values.name}
                touched={formik.touched.name}
                onChange={formik.handleChange}
                description={t("Set a name for the destination.")}
              />
            </div>
            <div>
              <DestinationSelector
                value={formik.values.destination}
                onChange={(destination) => formik.setFieldValue("destination", destination)}
                label={t("Destination :")}
              />
            </div>
          </div>
          <div>
            <OptionsFactory type={formik.values.options.type} formik={formik} />
          </div>
          <div className="py-6 px-4 flex justify-end items-center">
            {
              error ? <div className="text-red-500 mr-4">
                  {error}
            </div> : null
            }
            <div>
              <Button loading={submitting || validating} type="primary" htmlType="submit">
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default DestinationForm;
