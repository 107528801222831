// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BellIcon,
  CalendarIcon,
  ChatAltIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  ShieldCheckIcon, TrashIcon, UserCircleIcon,
  XIcon
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import { format, formatDistanceToNow } from "date-fns";
import { useQueryClient } from "react-query";
import { useFormik } from "formik";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import { useConfiguredFor, useDeActivateMutation, usePutSnoozedMutation } from "../../../hooks/alert";
import Async from "../../../ui/helper/async";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useDidMountEffect } from "../../../hooks/helpers/use-did-mount-effect";
import { classNames } from "../../../services/helper";
import UserModel from "../../../services/models/http/user/user";
import Pagination from "../../../services/models/http/paginated";
import ConfiguredFor, { Result } from "../../../services/models/http/alert/configured_for";
import { AlertBadge } from "../components/basge";
import PacingVisualizer from "../visualizer/pacing";
import Modal, { Title } from "../../../ui/components/modal";
import FactoryMetric from "../visualizer/metric";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import routing from "../../../routing/index";


const Activity = ({configuredFor}: {configuredFor: ConfiguredFor}) => {
  const {t} = useTranslation();
  const activity = configuredFor.data.results.length > 0 ? configuredFor.data.results[0].histories.map(elem => ({
    type: "analyze",
    date: new Date(elem.date)
  })) : [];


  const form = useFormik<{
    comment: string
  }>({
    initialValues: {
      comment: undefined
    },
    onSubmit : (values) => {

    }
  });

  return <section aria-labelledby="activity-title" className="mt-8 xl:mt-10">
    <div>
      <div className="divide-y divide-gray-200">
        <div className="pb-4">
          <h2 id="activity-title" className="text-lg font-medium text-gray-900">
            {t('Activity')}
          </h2>
        </div>
        <div className="pt-6">
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              {activity.sort((a,b) => b.date - a.date).map((item, itemIdx) => (
                <li key={item.id}>
                  <div className="relative pb-8">
                    {itemIdx !== activity.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      {item.type === 'comment' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <UserCircleIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div className="text-sm">
                                <a className="font-medium text-gray-900">
                                  {item.user.getEmail()}
                                </a>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">Commented {item.date}</p>
                            </div>
                            <div className="mt-2 text-sm text-gray-700">
                              <p>{item.comment}</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <ShieldCheckIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div className="text-sm text-gray-500">
                              {t('Analyzed on')} {' '}
                              <b><span className="whitespace-nowrap">{item.date.toLocaleString()}</span></b>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* <div className="mt-6">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <div>
                  <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                      <UserCircleIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </div><div className="min-w-0 flex-1">
                <form onSubmit={form.handleSubmit}>
                  <div>
                    <label htmlFor="comment" className="sr-only">
                      {t('Comment')}
                    </label>
                    <textarea
                      id="comment"
                      name="comment"
                      rows={3}
                      onChange={form.handleChange}
                      value={form.values.comment}
                      className="shadow-sm block w-full focus:border-purple-600 focus:border-purple-600 sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Leave a comment"
                      defaultValue={''}
                    />
                  </div>
                  <div className="mt-6 flex items-center justify-end space-x-4">
                    <Button
                      type="primary-purple"
                      htmlType="submit"
                    >
                      {t('Comment')}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>
}


const PacingDetail = ({configuredFor}: {configuredFor: ConfiguredFor}) => {

  const responses = {};
  if(configuredFor.data.results.length > 0 ){
    configuredFor.data.results[configuredFor.data.results.length - 1].histories.forEach(elem => {
      responses[elem.date] = {
        data : [
          {values: [elem.result]}
        ]
      };
    })
    return <PacingVisualizer response={responses} variable={configuredFor.data.sentry.variables[0]} />
  }
  return <div>No enough data</div>;
}

const MetricDetail = ({visible, onClose, result, configuredFor} : {configuredFor: ConfiguredFor, visible: boolean, onClose: () => void, result : Result}) => {
  const responses = {};
  const {t} = useTranslation();

  result.histories.forEach((history) => {
    responses[history.date] = {
      data: [{
        values: history.result.values,
        identifications: result.identifications,
        status: history.result.status,
        identifierName: result.identifications.identifierName
      }]
    }
  })
  return <Modal open={visible} onClose={onClose}>
    <Title title={t('Metric visualization')} />
    <div className="h-96 mb-12">
      <FactoryMetric variable={configuredFor.data.sentry.variables[0]} response={responses}/>
    </div>
  </Modal>
}


const Details = ({configuredFor}: {configuredFor: ConfiguredFor}) => {

  if(configuredFor.data.sentry.type === "pacing"){
    return <PacingDetail configuredFor={configuredFor} />
  }

  const [selectedMetric, setSelectedMetric] = useState(undefined);
  const headers = configuredFor.data.results[0].identifierName ? ['Identifier Name'] : [...Object.keys(configuredFor.data.results[0].identifications).filter(key => key !== 'requests')];

  const {t} = useTranslation();
  const rows: any[] = [];

  configuredFor.data.results.forEach(elem => {
    const subRow : any = {};
    if(elem.identifierName){
      subRow['Identifier Name'] = elem.identifierName;
    }else {
      Object.keys(elem.identifications).filter(key => key !== 'requests').forEach(key => {
        subRow[key] = elem.identifications[key];
      })
    }
    subRow.status = elem.lastStatus;
    rows.push(subRow);
  })

  return <>
    {selectedMetric !== undefined ? <MetricDetail configuredFor={configuredFor} visible={selectedMetric !== undefined} onClose={() => setSelectedMetric(undefined)} result={selectedMetric} /> : true}
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 table  w-full">
              <tr>
                {headers.map(elem => <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {elem}
                </th>)}
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">{t('Status')}</span>
                </th>
              </tr>
              </thead>
              <tbody className="h-96 block overflow-y-scroll">
              {rows.map((row, personIdx) => (
                <tr onClick={() => setSelectedMetric(configuredFor.data.results[personIdx])} key={personIdx} className='cursor-pointer bg-white table w-full hover:bg-gray-50' >
                  {headers.map(elem =>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row[elem]}</td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <AlertBadge status={row.status} />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>
}

const SnoozedDatePicker = ({ id, snoozed }: { id: number, snoozed: Date | undefined }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(new Date());
  const mutation = usePutSnoozedMutation();
  const queryClient = useQueryClient()


  const deactivateSnoozing = () => {
    mutation.mutate({
      id,
      date: null
    }, {
      onSuccess: () => {
        NotificationManager.success(t('Alert successfully un-snooze.', { date: startDate.toLocaleDateString() }), t('From now, you should start to receive alert.'))
        queryClient.setQueryData(['configured_for', id], old => {
          return old.update({snoozed: undefined})
        });
        mutation.reset();
      },
      onError: () => {
        NotificationManager.error(t('Unable to un-snooze the alert.'), t('There is an internal error system.'));
        mutation.reset();
      }
    })
  }

  useDidMountEffect(() => {
    if(startDate !== null) {
      mutation.mutate({
        id,
        date: startDate
      }, {
        onSuccess: () => {
          NotificationManager.success(t('Alert successfully snoozed until {{date}}.', { date: startDate.toLocaleDateString() }), t('No alert should be dispatched from now.'))
          queryClient.setQueryData(['configured_for', id], old => old.update({snoozed: startDate}));
          mutation.reset();
        },
        onError: () => {
          NotificationManager.error(t('Unable to snooze the alert.'), t('There is an internal error system.'));
          mutation.reset();
        }
      })
    }
  }, [startDate]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const CustomInput = forwardRef(({ value, onClick }: { value: Date, onClick: () => void }, ref) => (
    <Button
      loading={mutation.isLoading}
      onClick={onClick}
      ref={ref}
      data-tip={snoozed ? t("You have snooze the reporting of this alert until {{date}}", { date: snoozed.toLocaleString() }) : t("Snooze the alert")}
      className={classNames(
        "truncate inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900",
        snoozed ? "bg-purple-500 text-white" : 'text-gray-700 hover:bg-gray-50'
      )}
      type={snoozed ? "primary-purple" : "transparent"}
    >
      <BellIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      <span>{snoozed ? t("Snoozed until {{date}}", snoozed.toDateString()) : t("Snooze")}</span>
    </Button>
  ));

  if(snoozed){
    return <span className="truncate relative z-0 inline-flex shadow-sm rounded-md" data-tip={snoozed ? t("You have snooze the reporting of this alert until {{date}}", { date: snoozed.toLocaleString() }) : t("Snooze the alert")}
    >
      <button
        type="button"
        className="cursor-not-allowed relative inline-flex items-center px-4 py-2 rounded-l-md border bg-purple-500 border-purple-600 text-sm font-medium text-white hover:bg-purple-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
      <BellIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
        {snoozed ? t("Snoozed until {{date}}", snoozed.toDateString()) : t("Snooze")}
      </button>
      <button
        onClick={deactivateSnoozing}
        type="button"
        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border bg-purple-500 border-purple-600 text-sm font-medium text-white hover:bg-purple-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        {mutation.isLoading ? <svg className="animate-spin -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg> : <XIcon className="h-5 text-white"/> }
      </button>
    </span>
  }
  return (
    <DatePicker
      selected={startDate}
      onSelect={(date) => setStartDate(date)}
      customInput={<CustomInput />}
      nextMonthButtonLabel=">"
      previousMonthButtonLabel="<"
      renderCustomHeader={({
                             date,
                             decreaseMonth,
                             increaseMonth,
                             prevMonthButtonDisabled,
                             nextMonthButtonDisabled
                           }) => (
        <div className="flex items-center justify-between px-2 py-2">
                                <span className="text-lg text-gray-700">
                                    {format(date, "MMMM yyyy")}
                                </span>

          <div className="space-x-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`${prevMonthButtonDisabled && "cursor-not-allowed opacity-50"} inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
            >
              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
            </button>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`${nextMonthButtonDisabled && "cursor-not-allowed opacity-50"}inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
            >
              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>
      )}
    />
  );
};
const DetailFactory = ({ dataSource }: { dataSource: DataSource }) => {
  // eslint-disable-next-line camelcase
  const { configured_for_id } = useParams();

  // eslint-disable-next-line camelcase
  const configuredFor = useConfiguredFor(parseInt(configured_for_id as string, 10) as number);
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const users : Pagination<UserModel> = queryClient.getQueryData('users');

  const lastRun = configuredFor.data?.data.results.length > 0 && configuredFor.data?.data.results[0].lastAnalyze ? (new Date(configuredFor.data?.data.results[0].lastAnalyze)).toLocaleString() : t("never");
  const lastRunDistance = configuredFor.data?.data.results.length > 0 && configuredFor.data?.data.results[0].lastAnalyze ? formatDistanceToNow(new Date(configuredFor.data?.data.results[0].lastAnalyze)) : t("never");

  const [saving, setSaving] = useState(false);
  const deleteMutation = useDeActivateMutation();

  const deleteAlert = async () => ConfirmManager.confirm({
    title: t("Do you really want to delete this alert ? "),
    description: t("Once you have delete it, you can retrieve historical data."),
    validButton: t("Delete"),
    cancelButton: t("Cancel"),
    typeImg: "danger",
    onValid: async () => {
      setSaving(true);
      try {
        // eslint-disable-next-line camelcase
        await deleteMutation.mutateAsync({id: parseInt(configured_for_id as string, 10) as number});
        navigate(-1);
      } catch(e) {
        NotificationManager.error(t('Unable to delete the alert'), t('An error occurred. Please try again.'))
      }
      setSaving(false);
    }
  });
  return (
    <Async {...configuredFor}>
      {configuredFor.data ? <main className="flex-1">
        <ReactTooltip effect="solid" />
        <div className="py-8 xl:py-10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
            <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
              <div>
                <div>
                  <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                    <div className="truncate text-ellipsis overflow-hidden">
                      <h1
                        className="text-xl font-bold text-gray-900 ">{configuredFor.data?.data.sentry.title || configuredFor.data?.data.sentry.alertName}</h1>
                      <p className="mt-2 text-sm text-gray-500">
                        Run : <b
                        className="font-medium text-gray-900"> {lastRunDistance}</b>
                      </p>
                    </div>
                    <div className="mt-4 flex space-x-3 md:mt-0">

                      <Link to={routing.user.edit_alert.replace(':client_id', dataSource.getId()).replace(':alert_id', configuredFor.data.data.sentry.id)}>
                        <Button
                          data-tip={configuredFor.data.data.sentry.useAsTemplate ? t("You can't edit a template") : t("Edit")}
                          disabled={configuredFor.data.data.sentry.useAsTemplate}
                          type="white"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        >
                          <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>{t("Edit")}</span>
                        </Button>
                      </Link>

                      <SnoozedDatePicker id={configuredFor.data.getId()} snoozed={configuredFor.data.getSnoozed()} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-3 xl:pt-6 xl:pb-0">
                <div className="h-96 mb-4">
                  {configuredFor.data.data.results.length > 0 ? <Details configuredFor={configuredFor.data} /> : <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">{t('No data')}</h3>
                    <p className="mt-1 text-sm text-gray-500">{t('It could take up to 24 hours to start an alert by our system.')}</p>
                  </div> }
                </div>
                <Activity configuredFor={configuredFor.data}  />
              </div>
            </div>

            <aside className="hidden xl:block xl:pl-8">
              <h2 className="sr-only">{t('Details')}</h2>
              <div className="space-y-5">
                <div className="flex items-center space-x-2">
                  {configuredFor.data.haveAlert() ? <>
                    <ShieldCheckIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    <span className="text-red-700 text-sm font-medium">{t('Open Issue')}</span>
                  </> : <>
                    <ShieldCheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                    <span className="text-green-700 text-sm font-medium">{t('Everything fine')}</span>
                  </>}
                </div>
                {/* <div className="flex items-center space-x-2">
                  <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="text-gray-900 text-sm font-medium">4 comments</span>
                </div> */}
                <div className="flex items-center space-x-2">
                  <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="text-gray-900 text-sm font-medium">
                        Last run : <time dateTime="2020-12-02">{lastRun}</time>
                      </span>
                </div>
              </div>
              <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                <div>
                  <h2 className="text-sm font-medium text-gray-500">{t('Assignee')}</h2>
                  <ul role="list" className="mt-3 space-y-3">
                    {users.data.filter(elem => dataSource.getAccountUsersManager().some(user => user.id === elem.getId())).map(elem => <li className="flex justify-start">
                      <a href="#" className="flex items-center space-x-3">
                        <div className="text-sm font-medium text-gray-900">{elem.getEmail()}</div>
                      </a>
                    </li>)}
                  </ul>
                </div>
                <div>
                  <h2 className="text-sm font-medium text-gray-500">{t('Tags')}</h2>
                  <ul role="list" className="mt-2 leading-8">
                    <li className="inline">
                      <div
                        className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                      >
                        <div className="absolute flex-shrink-0 flex items-center justify-center">
                          <span className={`h-1.5 w-1.5 rounded-full ${configuredFor.data.haveAlert() ? 'bg-red-500' : 'bg-green-500'}`} aria-hidden="true" />
                        </div>
                        <div className="ml-3.5 text-sm font-medium text-gray-900">{configuredFor.data.haveAlert() ? t('Bug') : t('Everything fine')}</div>
                      </div>{' '}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                <h2 className="text-sm font-medium text-gray-500">{t('Settings')}</h2>
                <ul role="list" className="mt-2 leading-8">
                  <li className="inline">
                    <Button
                      type="danger"
                      onClick={deleteAlert}
                      loading={saving}
                    >
                      <TrashIcon className="mr-1 h-4" />
                      {t('Delete')}
                    </Button>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </main> : <div> Not available </div>}
    </Async>
  );
};

export default DetailFactory;
