import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    ArrowSmDownIcon,
    ArrowSmRightIcon,
    ArrowSmUpIcon,
    ChartBarIcon,
    PlusIcon,
    ShieldCheckIcon,
    ShieldExclamationIcon
} from "@heroicons/react/solid";
import {Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import {getIcon} from "../../../ui/components/icons/alert-type";
import {ADS, ANOMALY, CONVERSION_TAG, OBJECTIVE, PACING, SECURITY, URL_CHECKER} from "../../../constant/alert";
import DataSource from '../../../services/models/http/datasource/data-source-client';
import routing from '../../../routing';
import { getResultOnAnomaly } from "../../../services/health";
import { classNames } from "../../../services/helper";
import { useGetAllUrlChecker } from "../../../hooks/alert/url-checker";
import { useGetAllSecurity } from "../../../hooks/alert/security";
import { useGetAllConversionTag } from "../../../hooks/alert/conversion-tag";

const getColor = (card : {amount: number, total: number}) => {
    if(card.total === 0) {
        return 'yellow-600';
    }
    if(card.amount === 0) {
        return 'green-400';
    }

    return 'red-600';
}
const Resume = ({dataSource} : {dataSource: DataSource} ) => {
    const {t} = useTranslation();

    const urls = useGetAllUrlChecker(dataSource.getAccountId(), dataSource.getPlatform());
    const conversionTag = useGetAllConversionTag(dataSource.getAccountId(), dataSource.getPlatform());
    const securities = useGetAllSecurity(dataSource.getAccountId(), dataSource.getPlatform());

    const urlsStats = {
        'total' : urls.data && !urls.isLoading ? urls.data.pagination.totalItems : 0,
        'in_error' : urls.data && !urls.isLoading ? urls.data.data.filter(elem => elem.getLastStatus() !== 200).length : 0,
    };

    const securityStats = {
        'total' : securities.data && !securities.isLoading ? securities.data.pagination.totalItems : 0,
        'in_error' : securities.data && !securities.isLoading ? securities.data.data.filter(elem => elem.getVerifiedAt() === undefined).length : 0,
    };

    const conversionTagStats = {
        'total' : conversionTag.data && !conversionTag.isLoading ? conversionTag.data.data.length : 0,
        'in_error' : conversionTag.data && !conversionTag.isLoading ? conversionTag.data.data.filter(elem => elem.getStatus() !== "ENABLED").length : 0,
    };


    const cards = [
        {name: t('Alerts'), icon: getIcon(ANOMALY), ...getResultOnAnomaly(dataSource) , href: routing.user.anomalies.replace(':client_id', dataSource.getId().toString())},
        {name: t('Pacing'), icon: getIcon(PACING), amount: dataSource.getHealthAlert()['pacing/default'] ? dataSource.getHealthAlert()['pacing/default'].numberStatusTrue : 0, total: dataSource.getHealthAlert()['pacing/default'] ? dataSource.getHealthAlert()['pacing/default'].numberTotalConfigured : 0, href: routing.user.pacing.replace(':client_id', dataSource.getId().toString())},
    ];

    const automaticCheckCard = [
        {id: URL_CHECKER, name: t('Url'), icon: getIcon(URL_CHECKER), amount: urlsStats.in_error, total: urlsStats.total, href: routing.user.url_checker.replace(':client_id', dataSource.getId().toString())},
        {id: SECURITY, name: t('Security'), icon: getIcon(SECURITY), amount:  securityStats.in_error, total: securityStats.total, href: routing.user.security.replace(':client_id', dataSource.getId().toString())},
        {id: ADS, name: t('Ads'), icon: getIcon(ADS), amount: 2, total: 12, href: routing.user.objectives.replace(':client_id', dataSource.getId().toString())},
        {id: CONVERSION_TAG, name: t('Conversions tag'), icon: getIcon(CONVERSION_TAG), amount: conversionTagStats.in_error, total: conversionTagStats.total, href: routing.user.conversion_tag.replace(':client_id', dataSource.getId().toString())},
    ]

    const metrics = [
        {name: t('Impressions'), id: "impressions", type: "int"},
        {name: t('Cost'), id: "cost", type: "money"},
        {name: t('Conversion'), id: "conversions", type: "int"},
        {name: t('Clicks'), id: "clicks", type: "int"},
    ];


    return (
        <>
            <div className="px-4 mt-6 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900 flex items-center mb-4">
                    {cards.reduce(((previousValue, currentValue) => previousValue + currentValue.amount), 0) ? <ShieldExclamationIcon className="h-5 text-red-600" /> :  <ShieldCheckIcon className="h-5 text-green-400" />}
                    <span className="ml-2">{t('Configurable check')}</span>
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {cards.map((card) => (
                        <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <card.icon className={`h-6 w-6 text-${getColor(card)}`} aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-cyan-700 truncate">{card.name}</dt>
                                            <dd className="flex items-center">
                                                <div className={`text-lg font-medium text-${getColor(card)}`}>{card.amount}</div>
                                                <div className="ml-2 text-sm text-gray-500">{t('on')} {card.total}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-5 py-3">
                                <div className="text-sm">
                                    <Link to={card.href} className="flex items-center font-medium text-cyan-700 hover:text-cyan-900">
                                        {card.total > 0 ? <>{t('View all')} <ArrowSmRightIcon className="h-4"/> </> : <> <PlusIcon className="h-4 mr-2"/>  {t('Create your first')}</>}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <h2 className="text-lg leading-6 font-medium text-gray-900 flex items-center mt-4 mb-4">
                    {automaticCheckCard.filter(elem => dataSource.getNetworkConfig()?.alertOptions().includes(elem.id)).reduce(((previousValue, currentValue) => previousValue + currentValue.amount), 0) ? <ShieldExclamationIcon className="h-5 text-red-600" /> :  <ShieldCheckIcon className="h-5 text-green-400" />}
                    <span className="ml-2">{t('Automatic check')}</span>
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {automaticCheckCard.filter(elem => dataSource.getNetworkConfig()?.alertOptions().includes(elem.id)).map((card) => (
                        <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <card.icon className={`h-6 w-6 text-${getColor(card)}`} aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-cyan-700 truncate">{card.name}</dt>
                                            <dd className="flex items-center">
                                                <div className={`text-lg font-medium text-${card.amount > 0 ? 'red-600' : 'green-400'}`}>{card.amount}</div>
                                                <div className="ml-2 text-sm text-gray-500">{t('on')} {card.total}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-5 py-3">
                                <div className="text-sm">
                                    <Link to={card.href} className="flex items-center font-medium text-cyan-700 hover:text-cyan-900">
                                        {t('View all')} <ArrowSmRightIcon className="h-4"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-6 px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900 flex items-center mt-4 mb-4">
                    <ChartBarIcon className="h-5" />
                    <span className="ml-2">{t('Metrics')}</span>
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {metrics.map((card) => {
                        const thresholdDate7days = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000));
                        const thresholdDate14days = new Date(Date.now() - (14 * 24 * 60 * 60 * 1000));

                        // @ts-ignore
                        const data = dataSource.getHealthMetrics().map(elem => ({time: new Date(elem.time), [card.id]: elem[card.id]}));

                        const lastSevenDayDate = [...Array(7)].map((_, i) => {
                            const d = new Date()
                            d.setDate(d.getDate() - i)
                            return d
                        });

                        const datesAreOnSameDay = (first: Date, second: Date) =>
                            first.getFullYear() === second.getFullYear() &&
                            first.getMonth() === second.getMonth() &&
                            first.getDate() === second.getDate();

                        const dataGraph = lastSevenDayDate.reduce<Array<{time: number, value: number}>>((prev, current) => {
                            prev.push({time: current.getTime(), value: data.filter(elem => datesAreOnSameDay(elem.time, current)).reduce((prev,current) => prev + current[card.id], 0)});

                            return prev;
                        }, []);

                        const total7days = data.filter(elem => elem.time > thresholdDate7days).reduce((prev,current) => prev + current[card.id], 0);
                        const total14days = data.filter(elem => elem.time > thresholdDate14days && elem.time < thresholdDate7days).reduce((prev,current) => prev + current[card.id], 0);
                        return <div key={card.id} className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="pt-5 px-1">
                                <div className="flex items-center">
                                    <div className="mx-2 w-0 flex-1">
                                        <dl className="flex items-center justify-between">
                                            <dt className="text-sm font-medium text-cyan-700 truncate">{card.name}</dt>
                                            <dt className={classNames(
                                              total7days > total14days ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                              'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                                            )}>{total7days > total14days ? (
                                              <ArrowSmUpIcon
                                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <ArrowSmDownIcon
                                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                                                aria-hidden="true"
                                              />
                                            )}
                                                { Number.isNaN((total14days - total7days) / total7days) ? "N/A" : Intl.NumberFormat(undefined, {
                                                    style: 'percent',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format((total14days - total7days) / total7days)}
                                            </dt>

                                        </dl>
                                        <div className="flex items-center justify-between">
                                            <dl className="mt-2">
                                                <dt>
                                                    <h3>{card.type === "money" ? new Intl.NumberFormat(undefined, {style: 'currency', currency: 'EUR'}).format(total7days) : new Intl.NumberFormat(undefined).format(total7days)}</h3>
                                                </dt>
                                                <dt className="text-xs text-gray-500">
                                                    {t('Last seven days')}
                                                </dt>
                                            </dl>
                                            <dl className="mt-2 text-right">
                                                <dt>
                                                    <h3>{card.type === "money" ? new Intl.NumberFormat(undefined, {style: 'currency', currency: 'EUR'}).format(total14days) : new Intl.NumberFormat(undefined).format(total14days)}</h3>
                                                </dt>
                                                <dt className="text-xs text-gray-500">
                                                    {t('Previous period')}
                                                </dt>
                                            </dl>
                                        </div>
                                        <div>
                                            <ResponsiveContainer width="100%" aspect={3}>
                                                <AreaChart
                                                    data={dataGraph}
                                                >
                                                    <defs>
                                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#8B5CF6" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#8B5CF6" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis
                                                        dataKey="time"
                                                        scale="time"
                                                        type="number"
                                                        domain={["dataMin", "dataMax + 1"]}
                                                        hide
                                                    />
                                                    <Tooltip
                                                        labelFormatter={label => new Date(label).toDateString()}
                                                        formatter={(value: number | bigint, name: any, props: any) => [card.type === "money" ? new Intl.NumberFormat(undefined, {style: 'currency', currency: 'EUR'}).format(value) : new Intl.NumberFormat(undefined).format(value), t(card.id)]}
                                                    />
                                                    <Area type="monotone" dataKey="value" stroke="#8B5CF6" strokeWidth="2px" fillOpacity={1} fill="url(#colorUv)" />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </>
    );
};

export default Resume;
