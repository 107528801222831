import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from "i18next";
import { CREATE, EDIT } from "../../../../constant/crud";
import { ALL, EVERY_DAY, EVERY_ONE_HOUR } from "../../../../constant/connection/connection-schedule";
import Input from "../../../../ui/components/input/input";
import { useDataSourceListing } from "../../../../hooks/datasource";
import DatasourceSelect from "../../../../ui/components/select/datasource-select";
import Patron from "../../../../ui/components/input/patron";
import DestinationSelect from "../../../../ui/components/select/destination-select";
import Select from "../../../../ui/components/select/select";
import Button from "../../../../ui/components/button/button";
import {useSegmentTrack} from "react-segment-analytics";



export type ConnectionFormValue = {
  dataSourceId: number,
  destinationId: number
  schedule: ALL,
  name: string
}

type Props = {
  initialValues: Partial<ConnectionFormValue>,
  mode: CREATE | EDIT,
  onSubmit: (data: ConnectionFormValue) => void,
  submitting: boolean
}

const Validation = Yup.object().shape({
  dataSourceId: Yup.number().required('You must select a data source.'),
  name: Yup.string().required('You must select a name for the connection.'),
  destinationId: Yup.number().required('You must select a destination.'),
  schedule: Yup.string().required('You must select a schedule rate.'),
});

const defaultValue : Partial<ConnectionFormValue> = {
  dataSourceId: undefined,
  destinationId: undefined,
  name: undefined,
  schedule: undefined,
}

const ConnectionForm = ({initialValues, mode, onSubmit, submitting}: Props) => {
  const track = useSegmentTrack();
  const formik = useFormik<Partial<ConnectionFormValue>>(
    {
      initialValues: {...defaultValue, ...initialValues},
      validationSchema: Validation,
      onSubmit : (data) => {
        track('Trying to save connection');
        onSubmit(data as ConnectionFormValue);
      }
    }
  )

  return (
    <div className="space-y-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white  shadow sm:rounded-md divide-y divide-y-2">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3
                className="text-lg leading-6 font-medium text-gray-900">{mode === "CREATE" ? t("Create a new connection") : t("Update your connection")}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("If you want to export data to Google Data Studio or Google Sheets, you do not need any connection. Just use our connector.")}
              </p>
            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Input
                id="name"
                name="name"
                label={t("Name :")}
                type="text"
                error={formik.errors.name}
                value={formik.values.name}
                touched={formik.touched.name}
                onChange={formik.handleChange}
                description={t("Set a name for the connection.")}
              />
            </div>
            <div>
              <Patron
                id="dataSource"
                label={t('Select a data source to connect : ')}
                error={formik.errors.dataSourceId}
                description={t("Set a data source to find data on.")}
              >
                <DatasourceSelect
                  className="mt-1"
                  id="datasource"
                  onChange={(e: any) => formik.setFieldValue('dataSourceId', parseInt(e.value, 10))}
                  value={formik.values.dataSourceId}
                  defaultValue={formik.values.dataSourceId}
                />
              </Patron>
            </div>
            <div>
              <Patron
                id="destination"
                label={t('Select a destination to send data on : ')}
                error={formik.errors.dataSourceId}
                description={t("Select your destination to send data.")}
              >
                <DestinationSelect
                  className="mt-1"
                  id="datasource"
                  onChange={(e: any) => formik.setFieldValue('destinationId', parseInt(e.value, 10))}
                  value={formik.values.destinationId}
                  defaultValue={formik.values.destinationId}
                />
              </Patron>
            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Patron
                id="schedule"
                label={t("Refresh rate :")}
                error={formik.errors.schedule}
                description={t("Set the refresh rate you want for your data.")}
              >
                <Select placeholder={t('Schedule')} value={formik.values.schedule} onChange={e => formik.setFieldValue('schedule', e)} list={[{name: t('Every Hour'), value: EVERY_ONE_HOUR}, {name: t('Every Day'), value: EVERY_DAY}]} />
              </Patron>
            </div>
          </div>
          <div className="py-6 px-4 flex justify-end">
            <Button loading={submitting} type="primary" htmlType="submit">
              {t('Save')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConnectionForm;
