import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useDestinationListing } from "../../../hooks/destinations";
import DestinationProvider from '../../../config/destination/destination-provider';

const DestinationSelect =  ({ value, ...props }: { value: string } & any) => {

  const destinations = useDestinationListing(1);

  const options = destinations.data?.data.map(elem => ({value: elem.getId(), label: <span className="flex items-center"><img className="h-6 mr-1" alt={elem.getType()} src={DestinationProvider.getByPlatform(elem.getType())?.destinationImage()} />{elem.getName()}</span>}));

  let localValue, defaultValue;

  if(value){
    localValue = options?.find(elem => elem.value === value);
  }
  if(props.defaultValue){
    defaultValue = options?.find(elem => elem.value === props.defaultValue);
  }

  console.log(value, defaultValue, options, localValue, defaultValue);

  return (
    <Select classNamePrefix="react-select" {...props}  defaultValue={defaultValue}  value={localValue} isLoading={destinations.isLoading} options={options} />
  );
};

export default DestinationSelect;
