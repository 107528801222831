import API from "../../api/http/client";
import AuthorizeModel from "../../models/http/network-authorization/authorize";
import Proxy from "../../api/http/proxy";
import CheckConnection from "../../models/http/source/check-connection";

type AuthData = ({
  code : string
} | {
  key: string,
  secret: string
})& {options: any}

type Auth2Data = {options: any}

type PostData = AuthData & {name: string, email?: string}

class ConnectionAPI extends API{

  _checkConnection = (platform: string, data: PostData) : Promise<CheckConnection> => {
    const url = `/networks/check_connection/${platform}`;
    return this.getClient().post(url, data)
  }

  _checkConnectionOAuth2 = async (platform: string, data: Auth2Data) : Promise<CheckConnection> => {
    const url = `/networks/check_connection_o_auth_2/${platform}`;
    return this.getClient().post(url, data)
  }

  serializer = () => ({
    _checkConnection: CheckConnection,
    _checkConnectionOAuth2: CheckConnection
  });
}

export default Proxy<ConnectionAPI>(new ConnectionAPI('/networks/check_connection/'));
