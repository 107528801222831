import React from "react";
import { useTranslation } from "react-i18next";
import { useConfiguredFor, useConfiguredForAll } from "../../../hooks/alert";
import { useDataSourceListing } from "../../../hooks/datasource";
import Async from "../../../ui/helper/async";
import AlertCard from "../../alerts/components/alert-card";

const Alerts = () => {
  const { t } = useTranslation();
  const configuredFor = useConfiguredForAll();
  const dataSource = useDataSourceListing({ "mode": "listing" });
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <h1 className="flex-1 text-lg font-medium">{t("Your alerts")}</h1>
      </div>
      <Async {...configuredFor}>
        <Async {...dataSource}>
          <div className="space-y-4">
            {configuredFor.data && dataSource.data && configuredFor.data.data.length > 0? configuredFor.data.data.map(elem => {
                console.log(elem);
              const foundDataSource = dataSource.data?.data.find(datasource => datasource.getId() === elem.getDataSourceId()["A"]);
              if (foundDataSource) {
                return <AlertCard dataSource={foundDataSource} sentry={elem.getSentry()} />;
              }
            }) : <div>{t("No alert configured")}</div>
            }
          </div>
        </Async>
      </Async>
    </div>
  );
};

export default Alerts;
