import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { pickBy } from "lodash";
import Button from "../../../../ui/components/button/button";
import Input from "../../../../ui/components/input/input";

export type DataStreamJob = {
  name: string,
};


export const Validation = Yup.object().shape({
  name:  Yup.string().required('You need to set a name to the connection.'),
});

type Props = {
  onSubmit: (data: Partial<DataStreamJob>) => void,
  defaultValue?: Partial<DataStreamJob>,
  saving?: boolean
}
const StepOne = ({onSubmit, defaultValue, saving} : Props) => {
  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const initialValues = {
    name: undefined
  };
  const formik = useFormik<Partial<DataStreamJob>>({
    initialValues: defaultValue ? {...initialValues, ...pickBy(defaultValue, (value, key) => Object.keys(initialValues).includes(key))} : initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setError(null);
      try {
        onSubmit(values);
      }catch (e){
        // @ts-ignore
        setError(e.message);
      }
    }
  });
  return (
    <div>
      <div className="space-y-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3
                  className="text-lg leading-6 font-medium text-gray-900">{t('Fill the basic information about your data stream')}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("At this step, you just need to input basic information like name.")}
                </p>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <Input
                  id="name"
                  name="name"
                  label={t("Name :")}
                  type="text"
                  error={formik.errors.name}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  onChange={formik.handleChange}
                  description={t("Set a name for the data stream.")}
                />
              </div>
            </div>
            <div className="py-6 px-4 flex justify-end items-center">
              {
                error ? <div className="text-red-500 mr-4">
                  {error}
                </div> : null
              }
              <div>
                <Button disabled={formik.values.name?.length === 0} loading={saving} type="primary" htmlType="submit">
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepOne;
