import { JSONSchemaType } from "ajv/dist/types/json-schema";
import { AlertType, Variable } from "../../../../features/alerts/editor/helpers/sentry-template";
import Model from "../../model";
import { PostRequest } from "../../form-interface";

export interface SentryPost {

  variables: Array<Variable>,
  requests: Array<{
    key: string,
    accountId: string|number,
    platform: string,
    level: string|undefined
  }>,
  type: AlertType,
  pivot: boolean
}

export interface SentryResponse {
  id: number,
  alertName: string,
  title: string | undefined,
  useAsTemplate: boolean,
  variables: Array<Variable>,
  requests: Array<{
    key: string,
    accountId: string|number,
    platform: string,
    level: string|undefined
  }>,
  type: AlertType,
  pivot: boolean
}

class Sentry extends Model<SentryResponse> implements PostRequest<SentryPost>{

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.title || this.data.alertName
  }

  getType(){
    return this.data.type;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSchemaPostToValidate = (): JSONSchemaType<SentryPost> => ({
    "type": "object",
    "properties": {
      "variables": {
        "type": "array"
      },
      "requests": {
        "type": "object"
      },
      "type": {
        "type": "string"
      },
      "pivot": {
        "type": "boolean"
      }
    },
    required: ["variables", "requests", "type", "pivot"]
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSchemaToSerialize = (): JSONSchemaType<SentryResponse> => ({
    "type": "object",
    "properties" : {
      "variables": {
        "type": "array"
      },
      "requests": {
        "type": "array"
      },
      "type": {
        "type": "string"
      },
      "pivot": {
        "type": "boolean"
      }
    }
  });

}


export default Sentry;