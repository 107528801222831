import Model from "../../model";
import {JSONSchemaType} from "ajv/dist/types/json-schema";
import { capitalize } from "../../../helper";

interface User {
  id: number,
  email: string,
  firstName?: string,
  lastName?: string,
  createdAt: string,
  isActive: boolean
}


class UserModel extends Model<User> {

  getId(){
    return this.data.id;
  }

  getEmail(){
    return this.data.email
  }

  getCreatedAt(){
    return this.data.createdAt
  }

  isActive(){
    return this.data.isActive
  }

  getName(){
    if(this.data.firstName && this.data.lastName)
      return `${capitalize(this.data.firstName)} ${capitalize(this.data.lastName)}`;

    return this.getEmail();
  }

  getSchemaToSerialize = (): JSONSchemaType<User> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "email": { type: "string" },
      "createdAt": { type: "string" },
      "isActive": { type: "boolean" },
      "lastName": {
        type: "string",
        nullable: true
      },
      "firstName": {
        type: "string",
        nullable: true
      },
    },
    required: ["id", "email"]
  });
}

export default UserModel;