import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import {NETWORK, INSTAGRAM} from '../../constant/network';
import { networkImageFromURI } from "../../ui/components/network";
import { SOCIAL } from "../../constant/constant";

class Instagram extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Instagram";

  getNetworkIdentifier = (): NETWORK => INSTAGRAM;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];

}

export default Instagram;