import React, {ReactElement, useState} from 'react';
import {useTranslation} from "react-i18next";
import {classNames} from "../../services/helper";
import Portfolio from "./containers/portfolio";
import Alerts from "./containers/alerts";

const Home = () => {
    const {t} = useTranslation();

    const tabs : Array<{name: string, id: string, href: string, component: () => ReactElement}> = [
        { name: t('Portfolio'), id: "portfolio", href: "#portfolio", component: Portfolio },
        { name: t('Alerts'), id: "alert", href: "#alert", component: Alerts},
    ]

    const [table, setTab] = useState<string>('portfolio');
    // @ts-ignore
    const Component = tabs.find(elem => elem.id === table).component;
    return (
        <div>
            <div className="w-1/2 m-auto -mt-32">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        defaultValue={table}
                        onChange={value => setTab(value.target.value)}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name} value={tab.id}> {tab.name} </option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                        {tabs.map((tab, tabIdx) => (
                            <a
                                key={tab.id}
                                href={tab.href}
                                className={classNames(
                                    tab.id === table ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                                )}
                                onClick={value => setTab(tab.id)}
                                aria-current={tab.id === table  ? 'page' : undefined}
                            >
                                <span>{tab.name}</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        tab.id === table  ? 'bg-indigo-500' : 'bg-transparent',
                                        'absolute inset-x-0 bottom-0 h-0.5'
                                    )}
                                />
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
            <div className="mt-24">
                {React.createElement(Component)}
            </div>
        </div>
    );
};

export default Home;
