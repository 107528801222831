import React from 'react';
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import HomeComponent from "../home";
import routing from '../../routing';
import Client from "../client";
import AdminIndex from "../admin";
import {useGetAllUser, useMe} from "../../hooks/user";
import Loading from "../loading";
import Profile from "../profile";
import Button from "../../ui/components/button/button";
import CompanySetting from "../settings";
import DataConnectorIndex from "../data-connectors";
import Logout from "../logout";
import Home from "../../ui/containers/home";
import DataSource from "../data-source";
import Authorize from "../authorize";
import OAuth2 from "../authorize/containers/oauth2";
import PricingPage from "../pricing";
import AddOn from "../authorize/addons";
import {usePageView} from "../../hooks/segment";
import CreateUser from '../company/create-user';
import Redirect from "../redirect";
import Success from "../billing/success";
import { SentryRoutes } from "../../index";
import DataStream from "../data-connectors/datastream";
import RedirectToStripeSetting from "../redirect/billing";

const HeaderHome = () => {
  const {t} = useTranslation();
  return <header className="py-10">
    <div className="justify-between flex max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-white">{t('Dashboard')}</h1>
      <Link to={routing.user.data_source_add}>
        <Button type="primary-inverted" >
          <>
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-indigo-600" aria-hidden="true" />
            {t('Add a client')}
          </>
        </Button>
      </Link>
    </div>
  </header>
}

const SettingsHome = () => {
  const {t} = useTranslation();

  return <header className="relative py-10">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-white">{t('User settings')}</h1>
    </div>
  </header>
}

const CompanyHome = () => {
  const {t} = useTranslation();

  return <header className="relative py-10">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-white">{t('Company settings')}</h1>
    </div>
  </header>
}


const Private = () => {
  usePageView();
  const allUser = useGetAllUser();
  const me = useMe();
  if (allUser.isLoading) {
    return (
      <Loading />
    )
  }

  return <SentryRoutes>
    <Route path="/billing-space"  element={<RedirectToStripeSetting />} />
    <Route path={`${routing.public.addons}/*`} element={<AddOn />}/>
    <Route index element={me.data?.getCompany().activateAlert === true ?  <Home header={<HeaderHome />}><HomeComponent /></Home> : <Navigate replace to={`${routing.user.data_connector}`}/>} />
    <Route path="/logout" element={<Logout />} />
    <Route path={routing.public.authorize} element={<Authorize />}/>
    <Route path={routing.public.oauth2_authorize} element={<OAuth2 />}/>
    <Route path={routing.user.billing_success} element={<Success />}/>
    <Route path={`${routing.user.profile}/*`} element={<Home header={<SettingsHome />}><Profile /></Home>} />
    <Route path={`${routing.user.company_setting}/*`} element={<Home header={<CompanyHome />}><CompanySetting /></Home>} />
    <Route path={`${routing.admin.home}/*`} element={<AdminIndex />} />
    <Route path={`${routing.user.data_connector}`} element={<DataConnectorIndex />} />
    <Route path={`${routing.user.data_connector}/*`} element={<DataConnectorIndex />} />
    <Route path={`${routing.user.client}/*`}  element={<Client />} />
    <Route path={`${routing.user.data_source}/*`}  element={<DataSource />} />
    <Route path={routing.public.invite_user} element={<CreateUser />}/>
    <Route path="/billings/payment" element={<Navigate replace to="/buy/google-data-studio" />}/>
    <Route path="/buy/google-data-studio"  element={<PricingPage />} />
    <Route path="/buy"  element={<PricingPage />} />
    <Route path="/redirect" element={<Redirect />}/>
  </SentryRoutes>
}

export default Private;
