import API, { ModelDerived } from "../../api/http/client";
import LoginModel, { LoginResponse200 } from "../../models/http/user/login";
import Proxy from "../../api/http/proxy";
import RegisterFromGoogle, { LoginFromGoogle } from "../../models/http/user/register";

class Register extends API{

  public _registerFromGoogle(data: LoginFromGoogle) : Promise<ModelDerived & LoginResponse200> {
    return this.getClient().post('/register_google', data);
  }

  serializer = () => ({
    '_registerFromGoogle': LoginModel
  });
}

export default Proxy<Register>(new Register());