import { useQuery } from "react-query";
import FieldAPI from "../../services/repository/google-datastudio/field-dto";
import { NETWORK } from "../../constant/network";
import Pagination from "../../services/models/http/paginated";
import FieldDTO from "../../services/models/http/google-datastudio/field-dto";
import ConfigFieldAPI from "../../services/repository/google-datastudio/config-dto";
import ConfigDto from "../../services/models/http/google-datastudio/config-dto";

export const useGetField = (network: NETWORK)  => useQuery(['field-dto', network], () : Promise<Pagination<FieldDTO>> => FieldAPI._getAll({ query: {platform: network} }), {
  enabled: !!network,
});

export const useConfigField = (network: NETWORK)  => useQuery(['config-field', network], () : Promise<Pagination<ConfigDto>> => ConfigFieldAPI._getAll({ query: {platform: network} }), {
  enabled: !!network,
});
