// @ts-nocheck
/* eslint-disable */

import { Popover } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { useFormik } from "formik";
import AccessibilityModel from "../../../../services/models/http/alert/accessibility";
import * as Yup from "yup";
import { PlusIcon } from "@heroicons/react/outline";
import ReactDOM from "react-dom";
import Select from "../../../../ui/components/select/select";
import { useDescribeSearch, useSearchAPI } from "../../../../hooks/search";
import Async from "../../../../ui/helper/async";
import { KeyDescribe } from "../../../../services/models/http/search/describe";
import { useDebounceValue } from "../../../../hooks/helpers/use-debounce";

type Field = {
  field: string,
  operator: string,
  value: Array<string>
}

export const EQUALS = "equals";
export const DOES_NOT_EQUALS = "not_equals";
export const CONTAINS = "contains";
export const DOES_NOT_CONTAINS = "not_contains";

export const Validation = Yup.object().shape({
  name: Yup.string().required("Please select a field"),
  operator: Yup.string().required("Please select an operator"),
  value: Yup.array().required("Please input a value")
});

type Value = {
  value: string,
  name: string
}
const SelectorOperator = ({ ...props }) => {
  const { t } = useTranslation();
  return <Select list={[
    { name: t("Equals"), value: EQUALS },
    { name: t("Does not equals"), value: DOES_NOT_EQUALS },
    { name: t("Contains"), value: CONTAINS },
    { name: t("Does not contains"), value: DOES_NOT_CONTAINS }
  ]} {...props}> </Select>;

};

const ValueSelector = ({
                            data,
                            onChange,
                            values,
                            operator,
                            accountId,
                            platform,
                            level
                          }: { operator: string, accountId: string, platform: string, level: string, values: Array<string>, data: KeyDescribe, onChange: (values: Array<string>) => void }) => {
  const { t } = useTranslation();

  const [defaultState, setDefaultState] = useState(data.values ? data.values.map(elem => ({
    value: elem,
    text: elem
  })) : []);

  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState(defaultState);

  const lookup = useDebounceValue(search, 200);

  const searchAPI = data.values && data.values.length > 0 ? {isSuccess: true} : useSearchAPI(platform, level, { accountId: accountId, [data.accessor]: lookup }, {
    onSuccess: (requestData) => {
      let map = requestData.data.data.map(elem => ({ value: elem[data.value], text: elem[data.accessor] }));
      setDefaultState(map);
      setSearchData(search && search.length > 0 ? map.filter(elem => elem.text.toLowerCase().indexOf(search.toLowerCase()) !== -1) : map);
    }
  });

  useEffect(() => {
    return () => {
      setSearchData(search && search.length > 0 ? defaultState.filter(elem => elem.text.toLowerCase().indexOf(search.toLowerCase()) !== -1) : defaultState);
    };
  }, [lookup]);


  return <>
    <p className="block text-sm font-medium text-gray-700 mb-2">
      {t("Select your value : ")}
    </p>
    <div className="h-64 max-h-64 border-gray-300 rounded-md border py-2 px-2 overflow-y-scroll">
      <input
        type="text"
        name="search"
        autoComplete={false}
        aria-autocomplete={"none"}
        id="search"
        onChange={e => {
          setSearch(e.target.value);
          if(operator === CONTAINS || operator === DOES_NOT_CONTAINS) {
            onChange([e.target.value]);
          }
        }}
        value={search}
        className="block w-full border-none rounded-mg bg-gray-100 focus:border-indigo-600 focus:ring-0 sm:text-sm"
        placeholder="Search"
      />
      <div className="space-y-1 mt-2">
        <Async {...searchAPI}>
          {searchData ? searchData.map(elem => {
            return <div className="relative flex items-center">
              <div className="flex items-center h-6">
                {operator === EQUALS || operator === DOES_NOT_EQUALS ?<input
                  id={elem.value}
                  onClick={e => {
                    if (values.includes(elem.value)) {
                      onChange(values.filter(value => elem.value !== value));
                    } else {
                      onChange(values.concat([elem.value]));
                    }
                  }}
                  name={elem.text}
                  checked={values.includes(elem.value)}
                  type="checkbox"
                  className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                /> : null}
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={elem.text} className="font-medium text-gray-700">
                  {t(elem.text)}
                </label>
              </div>
            </div>;
          }) : null}
        </Async>
      </div>
    </div>
  </>;
};

const PopoverFilter = ({
                         initialValues,
                         platform,
                         level,
                         accountId,
                          onSubmit
                       }: {onSubmit: (field : Field) => void ,  accountId: string, platform: string, level: string, initialValues?: Field, accessibility: AccessibilityModel }) => {

  const { t } = useTranslation();

  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: "bottom" });



  return (
    <Popover>
      {
        ({ close }) => {
          const popOverFom = useFormik<Partial<Field>>({
            initialValues: {
              field: undefined,
              operator: undefined,
              value: [],
              ...initialValues
            },
            onSubmit(values) {
              popOverFom.resetForm();
              onSubmit(values);
              close();
            }
          });

          useEffect(() => {
            return () => {
              popOverFom.setFieldValue("operator", undefined);
              popOverFom.setFieldValue("value", []);
            };
          }, [popOverFom.values.field]);

          useEffect(() => {
            return () => {
              popOverFom.setFieldValue("value", []);
            };
          }, [popOverFom.values.operator]);

          const describe = useDescribeSearch(platform, level);

          return <>
            <Popover.Button
              ref={setReferenceElement}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-normal rounded-md text-gray-700 hover:text-white bg-transparent hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <PlusIcon className="h-4 mr-2" />
              {t("Add Filter")}
            </Popover.Button>
            {ReactDOM.createPortal(<Popover.Panel
              ref={setPopperElement}
              className={"z-10 w-screen max-w-sm px-4 mt-3 sm:px-0"}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 pt-4 bg-white "
                   onKeyDown={(e) => {
                     if (e.key === "Enter") {
                       e.preventDefault();
                       e.stopPropagation();
                       calculatedFieldForm.handleSubmit();
                     }
                   }} onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.nativeEvent.preventDefault();
                popOverFom.handleSubmit(e);
              }}>
                <Async {...describe}>
                  <div className="relative bg-white px-7 py-1">
                    <Select
                      label={t("Select a field :")}
                      value={popOverFom.values.field}
                      onChange={
                        (value) => popOverFom.setFieldValue("field", value)
                      } list={
                      describe.data ? Object.entries(describe.data.getProperties()).map(value => ({
                        value: value[0],
                        name: value[1].name
                      })) : []
                    }
                      placeholder={t("Select a field")}
                    />
                  </div>
                  {popOverFom.values.field ? <div className="relative bg-white px-7 py-1">
                    <SelectorOperator
                      label={t("Select an operator : ")}
                      value={popOverFom.values.operator}
                      onChange={
                        (value) => {
                          popOverFom.setFieldValue("operator", value);
                        }
                      }
                      placeholder={t("Select an operator")}
                    />
                  </div> : null}
                  {popOverFom.values.operator ? <div className="relative bg-white px-7 py-1 ">

                    <ValueSelector
                      operator={popOverFom.values.operator}
                      platform={platform}
                      level={level}
                      accountId={accountId}
                      values={popOverFom.values.value}
                      onChange={e => popOverFom.setFieldValue("value", e)}
                      data={describe.data?.getProperties()[popOverFom.values.field]}
                    />
                  </div> : null
                  }
                  <button
                    type="submit"
                    className="mt-4 w-full cursor-pointer bg-purple-500 text-white rounded-t-none p-4 bg-gray-50 text-center flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-purple-600 focus:outline-none "
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      e.nativeEvent.preventDefault();
                      popOverFom.handleSubmit(e);
                    }}
                  >
                    {t("Save")}
                  </button>
                </Async>
              </div>
            </Popover.Panel>, document.body)
            }
          </>;
        }
      }
    </Popover>
  );
};

export default PopoverFilter;
