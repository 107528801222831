import { useMutation, useQuery } from "react-query";
import Pagination from "../../services/models/http/paginated";
import Field, {PostField, PutField} from "../../services/models/http/google-datastudio/field";
import FieldTranslation, {PostFieldTranslation, PutFieldTranslation} from "../../services/models/http/google-datastudio/field-translation";
import FieldAPI from '../../services/repository/google-datastudio/field';
import FieldTranslationAPI from '../../services/repository/google-datastudio/field-translation';

export const useFieldGoogleDataStudio = () => useQuery(
    "field_datastudio",
    (): Promise<Pagination<Field>> => FieldAPI._getAll({ pagination: false }),
    { retry: 0 }
);

export const usePostFieldGoogleDataStudioMutation = () => useMutation<Field, unknown, PostField>((data)  => FieldAPI._postOne(data));
export const usePutFieldGoogleDataStudioMutation = () => useMutation<Field, unknown, PutField>((data)  => FieldAPI._put(data.id, data));
export const useDeleteFieldGoogleDataStudioMutation = () => useMutation((id: number) => FieldAPI._delete(id));

export const useFieldTranslationGoogleDataStudio = (id: number) => useQuery(
    "field_translation_datastudio",
    (): Promise<Pagination<FieldTranslation>> => FieldTranslationAPI._getAll({ pagination: false, query: {"field.id": id}}),
    { retry: 0 }
);

export const usePostFieldTranslationGoogleDataStudioMutation = () => useMutation<FieldTranslation, unknown, PostFieldTranslation>((data)  => FieldTranslationAPI._postOne(data));
export const usePutFieldTranslationGoogleDataStudioMutation = () => useMutation<FieldTranslation, unknown, PutFieldTranslation>((data)  => FieldTranslationAPI._put(data.id, data));
export const useDeleteFieldTranslationGoogleDataStudioMutation = () => useMutation((id: number) => FieldTranslationAPI._delete(id));