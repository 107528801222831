import axios, { AxiosInstance } from "axios";
import decode from "jwt-decode";
import Logger from "../../logger/index";
import JWT from "../../auth/jwt";
import config from "../../../config";
import Pagination from "../../models/http/paginated";

export type ModelDerived = any;


abstract class API {

  /**
   * Axios instance bind to this particular UI class.
   * @private
   */
  private readonly axios: AxiosInstance;

  /**
   * The URI of the Ressource
   * @private
   */
  protected readonly uri: string | null;

  /**
   * The endpoint of the application
   * @private
   */
  protected readonly endpoint: string;

  /**
   * Represent the concatenation between endpoint and uri.
   * @protected
   */
  protected readonly baseUrl: string;

  private nextRequestAsAdmin = false;

  constructor(uri: string | null = null) {
    this.endpoint = config.api.endpoint;
    this.uri = uri;
    this.axios = axios.create({
      baseURL: this.endpoint,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authentication-Type" : "JWT"
      }
    });

    this.baseUrl = `${this.endpoint}${this.uri}`;

    this.axios.interceptors.request.use(
      (config) => {

        if(this.nextRequestAsAdmin){
          this.nextRequestAsAdmin = false;
          config.headers = { ...config.headers, 'x-admin-key' : "true"};
        }
        Logger.debug({
          method: config.method,
          url: config.url
        }, "Make a request from our apiClient");
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axios.interceptors.response.use(
      (response) => {
        Logger.debug({
          data: response.data
        }, "Get a response from our request");
        return response;
      },
      (error) => {
        Logger.debug({
          data: error.response
        }, "An error occurred from the request");
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.request.use((localConfig) => {
      const jwt = JWT.getToken();
      const currentTime = (new Date()).getTime() / 1000;

      console.log("JWT", jwt);
      if (
        jwt !== null
        && jwt !== undefined
        && decode<any>(jwt).exp > currentTime
      ) {
        // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        localConfig.headers.Authorization = `Bearer ${jwt}`;
      }
      return localConfig;
    }, error => Promise.reject(error));
  }

  protected getClient() {
    return this.axios;
  }

  public _getOne(id: any, options: any = {}): Promise<ModelDerived> {
    const query: any = {};

    return this.getClient().get(`${this.endpoint}${this.uri}/${id.toString()}`, { params: query });
  }

  public _postOne<PostData>(data: PostData) : Promise<ModelDerived>{
    return this.axios.post(`${this.endpoint}${this.uri}`, data);
  }

  public _put<PutData>(id: number | string, data: Partial<PutData>) : Promise<ModelDerived>{
    return this.axios.put(`${this.endpoint}${this.uri}/${id}`, data);
  }

  public _delete(id:number) {
    return this.axios.delete(`${this.endpoint}${this.uri}/${id}`);
  }

  public _getAll(options: { pagination?: boolean | number, query?: { [key: string]: any } } = {}): Promise<ModelDerived & Pagination<ModelDerived>> {
    return this.internalGetAllRequest(options);
  }


  public asAdmin() {
    this.nextRequestAsAdmin = true;
    return this;
  }

  protected internalGetAllRequest(options: { pagination?: boolean | number; query?: { [p: string]: any } }) : Promise<ModelDerived & Pagination<ModelDerived>> {
    let query: any = {};

    if (options.pagination === false) {
      query.pagination = false;
    }

    if (options.query) {
      query = { ...query, ...options.query };
    }

    return this.getClient().get(`${this.endpoint}${this.uri}`, { params: query });
  }

  abstract serializer(): Record<string, ModelDerived>;
}

export default API;