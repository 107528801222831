import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, INSTAGRAM, INSTAGRAM_PUBLIC_DATA, PARDOT } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { CRM, SOCIAL } from "../../constant/constant";
import { boolean, string } from "yup";

class Pardot extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Salesforce Pardot";

  getNetworkIdentifier = (): NETWORK => PARDOT;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => CRM;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "business-id": args.businessId,
    }
  }
}

export default Pardot;
