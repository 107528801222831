import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import {NETWORK, LINKEDIN, MAILCHIMP} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {EMAILING} from "../../constant/constant";

class Mailchimp extends BaseNetwork{
    connectingMethod = (): LinkMethod => OAUTH_2;

    getName = (): string => "Mailchimp";

    getNetworkIdentifier = (): NETWORK => MAILCHIMP;

    isAlertActivated = (): boolean => true;

    isAvailableForAll = (): boolean => true;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => EMAILING;

    isETLActivated(): boolean {
        return true;
    }

    alertOptions = () =>  [];

}

export default Mailchimp;