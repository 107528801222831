import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  ArrowRightIcon,
  BellIcon,
  CheckCircleIcon,
  CloudDownloadIcon,
  ExclamationCircleIcon,
  PencilIcon, RefreshIcon, SearchCircleIcon, XCircleIcon
} from "@heroicons/react/solid";


import { formatDuration, intervalToDuration } from "date-fns";
import { useGetConnection, useGetLogConnection } from "../../../../hooks/connections";
import Async from "../../../../ui/helper/async";
import Header from "../../components/header";
import routing from "../../../../routing";
import ConnectionModel from "../../../../services/models/http/data-connector/connection";
import Button from "../../../../ui/components/button/button";
import NetworkProvider from "../../../../config/network/network-provider";
import BaseNetwork from "../../../../config/network/base-network";
import DestinationProvider from "../../../../config/destination/destination-provider";
import BaseDestination from "../../../../config/destination/base-destination";
import LogModel from "../../../../services/models/http/data-connector/log";
import {GOOGLE_DATA_STUDIO, GOOGLE_SHEETS} from "../../../../constant/destination";

function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  // @ts-ignore
  return `${minutes}:${seconds < 10 ? '0' : ''  }${seconds}`;
}

export const ConnectionLiveComponent = ({connection}: {connection:ConnectionModel}) => {
  const numberFormatter = new Intl.NumberFormat();
  const {t} = useTranslation();
  const source = NetworkProvider.getByPlatform(connection.getPlatform()) as BaseNetwork;
  const destination = DestinationProvider.getByPlatform(connection.getDestinationType()) as BaseDestination;

  const statusMapper = {
    FAILURE: {text: t("Failure"), color: "red", icon: XCircleIcon},
    SUCCESS: {text: t("Success"), color: "green", icon: CheckCircleIcon},
    RUNNING: {text: t("Running"), color: "purple", icon: RefreshIcon},
    WAITING: {text: t("Waiting"), color: "blue", icon: SearchCircleIcon},
  }

  // @ts-ignore
  const lastLog : Log | null = connection.getLogs() !== undefined && (connection.getLogs() as Array<any>).length > 0 ? connection.getLogs()[0] : connection.getLogs();

  const selectedStatus = statusMapper.SUCCESS;

  const EditConnectionButton = <Link to={routing.user.source_edit.replace(":id", connection.getSourceId().toString())}>
    <Button type="grey">
      <PencilIcon className="h-6 w-6 mr-2" />
      {t('Edit source')}
    </Button>
  </Link>;

  return <>
    <Header actionButton={EditConnectionButton} title={<div className="flex items-center"><selectedStatus.icon className={`h-7 w-7 mr-2 text-${selectedStatus.color}-500`}/> {connection.getName()}</div>} />
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-4">
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <img src={source.networkImage()}  className="h-5 w-5 mr-2" /> {connection.getDataSourceName()}
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <ArrowRightIcon className="flex-shrink-0 mr-1.5 h-5 w-5"/>
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <img src={destination.destinationImage()} className="h-5 w-5 mr-2"/> {destination.getName()}
      </div>
    </div>
    <div className="w-100 mt-8">
      <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Overview')}</h2>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div key="last-status" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <selectedStatus.icon className={`h-6 w-6 text-${selectedStatus.color}-400`} aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className={`text-sm font-medium text-${selectedStatus.color}-500 truncate`}>{t('Last status')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{selectedStatus.text}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BellIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Last run')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{lastLog ? new Date(lastLog.startOfTheRequest).toLocaleString() : "N/A"}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <CloudDownloadIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Total number request')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{ connection.getLogsCount() }</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="mt-4 text-lg leading-6 font-medium text-gray-900">
        {t('Recent activity')}
      </h2>
      {(connection.getLogs() || []).length > 0 ? <div className="mt-4 min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('Status')}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('Request Date')}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('Time')}
          </th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {connection.getLogs()?.map((logDetails) => {
          const [open, setOpen] = useState(false);
          const statusDetail = statusMapper[logDetails.success ? "SUCCESS" : "FAILURE"];
          return <Fragment key={logDetails.id}>
            <tr key={logDetails.id} className="bg-white">
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                <div className="flex">
                  <div className="group inline-flex space-x-2 truncate text-sm">
                    <statusDetail.icon
                        className={`flex-shrink-0 h-5 w-5 text-${statusDetail.color}-400`}
                        aria-hidden="true"
                    />
                    <span>{statusDetail.text}</span>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span className="text-gray-900 font-medium">
                      {logDetails.startOfTheRequest ? new Date(logDetails.startOfTheRequest).toLocaleString() : 'N/A'}
                    </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    <span className="text-gray-900 font-medium">
                      {logDetails.duration ? millisToMinutesAndSeconds(logDetails.duration) : 'N/A'}
                    </span>
              </td>
            </tr>
          </Fragment>
        })}
        </tbody>
      </table>
    </div> : null}
    </div>
    </>
}

export const ConnectionETLComponent = ({connection}: {connection:ConnectionModel}) => {
  const {t} = useTranslation();
  const logQuery = useGetLogConnection(parseInt(connection.getId() as unknown as string, 10));

  const log = logQuery.data;

  const statusMapper = {
    FAILURE: {text: t("Failure"), color: "red", icon: XCircleIcon},
    SUCCESS: {text: t("Success"), color: "green", icon: CheckCircleIcon},
    RUNNING: {text: t("Running"), color: "purple", icon: RefreshIcon},
    WAITING: {text: t("Waiting"), color: "blue", icon: SearchCircleIcon},
  }


  const EditConnectionButton = <Link to={routing.user.connection_edit.replace(":id", connection.getId().toString())}>
    <Button type="grey">
      <PencilIcon className="h-6 w-6 mr-2" />
      {t('Edit connection')}
    </Button>
  </Link>;

  const numberFormatter = new Intl.NumberFormat();

  const source = NetworkProvider.getByPlatform(connection.getPlatform()) as BaseNetwork;
  const destination = DestinationProvider.getByPlatform(connection.getDestinationType()) as BaseDestination;

  const dateStart = log === undefined || (log as never as LogModel).getLastCrawlStart() === undefined ? "N/A" : new Date(log?.getLastCrawlStart() as string).toDateString();
  const dateEnd = log === undefined || (log as never as LogModel).getLastCrawlEnd() === undefined ? "N/A" : new Date(log?.getLastCrawlEnd() as string).toDateString();
  const status = log === undefined ? "N/A" : log.getStatus();
  const numberExtractedRow = log === undefined ? "N/A" : numberFormatter.format(log.getRowExtracted());

  const selectedStatus = log !== undefined && statusMapper[log.getStatus() as "SUCCESS" | "FAILURE" | "RUNNING" | "WAITING"] !== undefined ? statusMapper[log.getStatus() as "SUCCESS" | "FAILURE" | "RUNNING" | "WAITING"] : statusMapper.WAITING;

  const logs = log?.getLogs();
  return <>
    <Header title={<div className="flex items-center"><selectedStatus.icon className={`h-7 w-7 mr-2 text-${selectedStatus.color}-500`}/> {connection.getName()}</div>} actionButton={EditConnectionButton} />
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-4">
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <img src={source.networkImage()}  className="h-5 w-5 mr-2" /> {source.getName()}
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <ArrowRightIcon className="flex-shrink-0 mr-1.5 h-5 w-5"/>
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <img src={destination.destinationImage()} className="h-5 w-5 mr-2"/> {destination.getName()}
      </div>
    </div>
    <div className="w-100 mt-8">
      <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Overview')}</h2>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div key="last-status" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <selectedStatus.icon className={`h-6 w-6 text-${selectedStatus.color}-400`} aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className={`text-sm font-medium text-${selectedStatus.color}-500 truncate`}>{t('Last status')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{selectedStatus.text}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BellIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Last run')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{dateStart}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <CloudDownloadIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Number rows extracted')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{numberExtractedRow}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="mt-4 text-lg leading-6 font-medium text-gray-900">
        {t('Recent activity')}
      </h2>
      <div className="flex flex-col mt-4">
        {(logs || []).length > 0 ? <div className="min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('Status')}
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('Date Start')}
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('Date End')}
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('Actions')}
              </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {logs?.map((logDetails) => {
              const [open, setOpen] = useState(false);
              const statusDetail = statusMapper[logDetails.status as "SUCCESS" | "FAILURE" | "RUNNING" | "WAITING"];
            return <Fragment key={logDetails.id}>
                <tr key={logDetails.id} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex">
                      <div className="group inline-flex space-x-2 truncate text-sm">
                        <statusDetail.icon
                          className={`flex-shrink-0 h-5 w-5 text-${statusDetail.color}-400`}
                          aria-hidden="true"
                        />
                        <span>{statusDetail.text}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span className="text-gray-900 font-medium">
                      {logDetails.launchAt ? new Date(logDetails.launchAt).toLocaleString() : 'N/A'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    <span className="text-gray-900 font-medium">
                      {logDetails.endAt ? new Date(logDetails.endAt).toLocaleString() : 'N/A'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <a href="#" onClick={(e) => {setOpen(true); e.stopPropagation(); e.preventDefault()}}>{t('View more')}</a>
                  </td>
                </tr>
                {
                  open ? <tr className="border-t border-gray-200 ">
                    <th colSpan={4} className="p-4">
                    {!Array.isArray(logDetails.error) && logDetails.error.type ? <div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                          <div className="ml-3">
                            <h3 className="text-sm text-left font-medium text-red-800">{t(`There is one error on your data dump : ${logDetails.error.type}`)}</h3>
                            <div className="mt-2 text-sm text-red-700" />
                          </div>
                        </div>
                      </div> : null}

                      <h3 className="mt-2 text-lg text-left mb-2 leading-6 font-medium text-gray-900">Overview</h3>
                      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <div key="last-status" className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <selectedStatus.icon className={`h-6 w-6 text-${statusDetail.color}-400`} aria-hidden="true" />
                              </div>
                              <div className="ml-5 w-0 flex-1 text-left">
                                <dl>
                                  <dt className={`text-sm font-medium text-${statusDetail.color}-500 truncate`}>{t('Status')}</dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">{statusDetail.text}</div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <BellIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                              </div>
                              <div className="ml-5 w-0 flex-1 text-left">
                                <dl>
                                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Duration')}</dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">{logDetails.endAt ? formatDuration(intervalToDuration({start: new Date(logDetails.launchAt), end: new Date(logDetails.endAt) })) : 'N/A'}</div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div key="last-run" className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <CloudDownloadIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                              </div>
                              <div className="ml-5 w-0 flex-1 text-left">
                                <dl>
                                  <dt className="text-sm font-medium text-gray-500 truncate">{t('Number rows extracted')}</dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">{logDetails.steps.reduce((acc, current) => acc + current.extractedRow, 0)}</div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 className="mt-2 text-lg text-left mb-2 leading-6 font-medium text-gray-900">Data Extraction</h3>

                      <table className="w-full divide-y divide-gray-200 border-gray-200 border">
                        <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {t('Stream')}
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {t('Date Start')}
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {t('Date End')}
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {t('Number row')}
                          </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                          logDetails.steps.map(elem => {
                            const statusDetailStream = statusMapper[elem.status as "SUCCESS" | "FAILURE" | "RUNNING" | "WAITING"];
                            return <tr key={elem.id} className="bg-white">
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-900">
                                        <div className="flex">
                                          <div className="group inline-flex space-x-2 truncate text-sm">
                                            <statusDetail.icon
                                              className={`flex-shrink-0 h-5 w-5 text-${statusDetailStream.color}-400`}
                                              aria-hidden="true"
                                            />
                                            <span>{elem.stream}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                                        <span className="text-gray-900 font-medium">
                                          {elem.launchAt ? new Date(elem.launchAt).toLocaleString() : 'N/A'}
                                        </span>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500 ">
                                        <span className="text-gray-900 font-medium">
                                          {elem.endAt ? new Date(elem.endAt).toLocaleString() : 'N/A'}
                                        </span>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500 ">
                                        <span className="text-gray-900 font-medium">
                                          {numberFormatter.format(elem.extractedRow)}
                                        </span>
                                      </td>
                                  </tr>
                          })
                        }
                        </tbody>
                      </table>

                    </th>
                  </tr> : null
                }
              </Fragment>
            })}

            </tbody>
          </table>
        </div> : <div className="flex justify-center items-center mt-8 text-gray-500">{t('It could take up to one hour to launch.')}</div> }
      </div>
    </div>
  </>
}


const ConnectionDetail = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const connection = useGetConnection(parseInt(id as string, 10));
  return (
    <div className="w-full py-4">
      <Async {...connection}>
        {connection.data ?
            ([GOOGLE_SHEETS, GOOGLE_DATA_STUDIO].includes(connection.data.getDestinationType()) ? <ConnectionLiveComponent connection={connection.data} /> : <ConnectionETLComponent connection={connection.data} /> )
          : <div>{t('Not available')}</div>}
      </Async>
    </div>
  );
};

export default ConnectionDetail;
