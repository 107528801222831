import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ShieldCheckIcon, ShieldExclamationIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import SentryListing from "../../../services/models/http/alert/sentry-listing";
import routing from "../../../routing";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import DataSourceListing from "../../../services/models/http/datasource/datasource-all";

const AlertCard = ({dataSource, sentry} : {dataSource: DataSource|DataSourceListing, sentry: SentryListing}) => {
 const {t} = useTranslation();

  return (
    <li
      className="col-span-1 flex flex-col text-center bg-white rounded-r-lg shadow divide-y divide-gray-200 mb-4"
    >
      <Link to={routing.user.details.replace(":configured_for_id", sentry.getConfiguredFor()[0]).replace(':client_id', dataSource.getId().toString())}>
        <div className="flex items-center py-5 px-4 sm:py-6 sm:px-4">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              {sentry.getNumberAlertOnEntity() ? <ShieldExclamationIcon className="h-12 text-red-600"/> : <ShieldCheckIcon className="h-12 text-green-500"/>}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-purple-600 truncate text-left">{sentry.getName()}</p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate">{t('{{number_analyzed}} anomalies on {{number_total}}.', {number_analyzed: sentry.getNumberAlertOnEntity(), number_total: sentry.getNumberAnalyzedOnEntity()})}</span>
                </p>
              </div>
              <div className="hidden md:block self-center">
                  <p className="text-sm text-gray-900">
                    <b>{t('Last run : ')}</b> {sentry.getLastAnalyzed() !== null ? <time dateTime={new Date(sentry.getLastAnalyzed() as string).toLocaleDateString()}>{new Date(sentry.getLastAnalyzed() as string).toLocaleDateString()}</time> : t('Never')}
                  </p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default AlertCard;
