import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import {NETWORK, FACEBOOK_PUBLIC_DATA} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import {SOCIAL} from "../../constant/constant";
import { CONVERSION_TAG, SECURITY, URL_CHECKER } from "../../constant/alert";

class FacebookAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Facebook Public Data";

  getNetworkIdentifier = (): NETWORK => FACEBOOK_PUBLIC_DATA;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => SOCIAL;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [CONVERSION_TAG, SECURITY, URL_CHECKER];

}

export default FacebookAds;