import { JSONSchemaType } from "ajv/dist/types/json-schema";
import { ALERT_STATUS, AlertType, Network, Variable } from "../../../../features/alerts/editor/helpers/sentry-template";
import Model from "../../model";
import { PostRequest } from "../../form-interface";

export type NetworkType = {
  key: string,
  accountId: string | number,
  platform: string,
  level: string | undefined
};

export interface SentryVisualizerPost {
  variables: Array<Variable>,
  title: string | undefined,
  requests: Array<NetworkType>,
  type: AlertType,
  pivot: boolean
}

export interface SentryVisualizerResponse {
  results : {
    [key: string]: {
      results: Array<{
        data: {
          values: Array<{
            name: string,
            value: number | string
          } & { kpi: string, value: string | number }>
        },
        status: ALERT_STATUS
        points: {
          [key: string]: string | number | Array<{
            [key: string]: string | number
          }>
        },
        identifierName?: string
      }>
    }
  }
}

class Visualize extends Model<SentryVisualizerResponse> implements PostRequest<SentryVisualizerPost>{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSchemaPostToValidate = (): JSONSchemaType<SentryVisualizerPost> => ({
    "type": "object",
    "properties": {
      "variables": {
        "type": "array"
      },
      "requests": {
        "type": "object"
      },
      "type": {
        "type": "string"
      },
      "pivot": {
        "type": "boolean"
      }
    },
    required: ["variables", "requests", "type", "pivot"]
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  getSchemaToSerialize = (): JSONSchemaType<SentryVisualizerResponse> => ({
    "type": "object",
    properties: {
      "results": {
        type: "object",
        additionalProperties: true,
        required: [],
        "patternProperties": {
          ".*": {
            "type": "object",
            "required": ["results"],
            "properties": {
              "results": {
                "type": "array",
                items: {
                  type: "object",
                  required: ["data", "points", "status"],
                  properties: {
                    identifierName: {
                      type: "string",
                      nullable: true
                    },
                    data: {
                      type: "object",
                      required: [],
                      additionalProperties: true,
                      properties: {
                        values: {
                          type: "array",
                          items : {
                            type: "object",
                            required: [],
                            oneOf: [{
                              type: "object",
                              required: ['kpi', 'value'],
                              properties: {
                                kpi: {
                                  "type": "string"
                                },
                                value: {
                                  type: ["string", "number"]
                                }
                              }
                            }, {
                              type: "object",
                              required: ['name', 'value'],
                              properties: {
                                name: {
                                  "type": "string"
                                },
                                value: {
                                  type: ["string", "number"]
                                }
                              }
                            }]
                          }
                        }
                      }
                    },
                    points: {
                      type: "object",
                      required: [],
                      "patternProperties": {
                        ".*": {
                          type: ["object", "array", "string", "number"],
                          required: [],
                          oneOf: [{
                            type: ["string", "number"],
                          },{
                            type: "array",
                            items: {
                              type: "object",
                              required: [],
                              "patternProperties": {
                                ".*": {
                                  type: ["string", "number"],
                                }
                              }
                            }
                          }]
                        }
                      }
                    },
                    status : {
                      type: "string"
                    }
                  }
                }
              }
            }
          }
        },
      },
    },
    required: ['results'],
    additionalProperties: true
  });

}


export default Visualize;