import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import datastudioTypeProvider from "../../../features/google-datastudio/datastudio-type/datastudio-type-provider";
import Select from "./select";

const DatastudioFieldTypeSelector = ({
                           ...props
                         }: { label?: string, value?: string, onChange: (item: string) => void, placeholder: string }) => {

    const list = datastudioTypeProvider.getAllTypes().reduce((stack: Array<{name: string, value: string}>, current) => {
        const elem = {name: current.getName(), value: current.getId()};
        stack.push(elem);
        return stack;
    }, []);
  return <Select {...props} list={list} />;
};

export default DatastudioFieldTypeSelector;
