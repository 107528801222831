import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import datastudioTypeProvider from "../../../features/google-datastudio/datastudio-type/datastudio-type-provider";
import Select from "./select";

const DatastudioTypeSelector = ({
                           ...props
                         }: { label?: string, value?: string, onChange: (item: string) => void, placeholder: string }) => {

    const {t} = useTranslation();
    const list = [
        {
            name: "Dimension",
            value: "dimension"
        },
        {
            name: "Metric",
            value: "metric"
        },
    ];
  return <Select {...props} list={list} />;
};

export default DatastudioTypeSelector;
