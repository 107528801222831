import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";
import { Variable } from "../../../../features/alerts/editor/helpers/sentry-template";

export interface Sentry {
  id: number,
  alertName: string,
  title: string | undefined,
  entityAnalyzed: number,
  type: string,
  entityInAlert: number,
  useAsTemplate: boolean,
  lastAnalyze: string | null,
  configuredFor: Array<string>,
}

class SentryListing extends Model<Sentry> {

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.title || this.data.alertName
  }

  getType(){
    return this.data.type;
  }

  getNumberAlertOnEntity(){
    return this.data.entityInAlert
  }

  getNumberAnalyzedOnEntity(){
    return this.data.entityAnalyzed
  }

  getLastAnalyzed(){
    return this.data.lastAnalyze
  }

  getConfiguredFor(){
    return (typeof this.data.configuredFor === 'object' && !Array.isArray(this.data.configuredFor) ? Object.values(this.data.configuredFor) : this.data.configuredFor).map(elem => {
      const strings = (elem as string).split("/");
      return strings[strings.length - 1];
    })
  }

  getSchemaToSerialize = (): JSONSchemaType<Sentry> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "alertName": { type: "string" },
      "type": { type: "string" },
      "useAsTemplate": { type: "boolean" },
      "title": { type: "string", nullable: true },
      "configuredFor": {
        type: "array",
        items: {
          type: "string"
        }
      },
      "entityAnalyzed": { type: "number" },
      "entityInAlert": { type: "number" },
      "lastAnalyze": {
        "type": "string",
        "nullable" : true
      },
    },
    additionalProperties: true,
    required: ["id", "alertName", "configuredFor", "entityAnalyzed", "entityInAlert", "lastAnalyze"]
  });
}

export default SentryListing;