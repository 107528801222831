import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, APP_STORE_CONNECT } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { STORE_APPLICATION, WEBSITE } from "../../constant/constant";

class AppStoreConnect extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "App Store Connect";

  getNetworkIdentifier = (): NETWORK => APP_STORE_CONNECT;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => STORE_APPLICATION;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "vendorId": args.vendorId,
      "issuerId": args.issuerId,
    }
  }
}

export default AppStoreConnect;
