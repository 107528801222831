import React, { useEffect } from "react";
import Loading from "../loading";
import { useBillingSessionPortal } from "../../hooks/user";

const RedirectToStripeSetting = () => {
  console.log("Test");
  const portal = useBillingSessionPortal();
  useEffect(() => {
    if (portal.data) {
      window.location.href = portal.data.data.url;
    }
  }, [portal.data]);
  return <Loading />;
};

export default RedirectToStripeSetting;
