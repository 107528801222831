import React from "react";
import { AlertType, Kpi, Metric, Pacing, Variable, VisualizerData } from "../editor/helpers/sentry-template";
import FactoryMetric from "../visualizer/metric";
import ConfigurationVisualizer from "../visualizer/configuration";
import PacingVisualizer from "../visualizer/pacing";

const VisualizerFactory = ({type, variable, response} : {type: AlertType, response: VisualizerData, variable: Variable}) => {
  switch (type) {
    case "metric":
      return <FactoryMetric response={response} variable={variable as Metric} />
    case "kpi":
      return <ConfigurationVisualizer response={response} variable={variable as Kpi} />
    case "pacing":
      return <PacingVisualizer response={response} variable={variable as Pacing} />
    default:
      throw new Error(`Unable to get visualizer for ${type}`);
  }
};

export default VisualizerFactory;
