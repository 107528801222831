import API from "../../api/http/client";
import AuthorizeModel from "../../models/http/network-authorization/authorize";
import Proxy from "../../api/http/proxy";

type AuthData = ({
  code : string
} | {
  key: string,
  secret: string
}| {
  oauthVerifier: string,
  oauthToken: string,
})& {options: any}

type PostData = AuthData & {name: string, email?: string}

class AuthorizeAPI extends API{

  _authorize = (platform: string, data: PostData, mode: string|undefined) : Promise<AuthorizeModel> => {
    let url = `/authorize/${platform}`;
    if(mode){
      url = `/authorize/${platform}?mode=${mode}`;
    }
    return this.getClient().post(url, data)
  }

  _refreshToken = (id:number, platform: string, data: AuthData) : Promise<AuthorizeModel> => {
    let url = `/authorize/${platform}/${id}`;
    return this.getClient().put(url, data)
  }

  serializer = () => ({
    _authorize: AuthorizeModel,
    _refreshToken: AuthorizeModel,
  });
}

export default Proxy<AuthorizeAPI>(new AuthorizeAPI('/authorize'));
