import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DestinationForm, { Destination } from "../containers/destination-form";
import { usePostDestinationMutation } from "../../../../hooks/destinations";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import { PostGoogleBigQuery } from "../../../../services/models/http/data-connector/destination/google-big-query";
import { omit } from "lodash";
import { useNavigate } from "react-router";
import routing from "../../../../routing";
import {useSegmentTrack} from "react-segment-analytics";

export const postFactory = (destination: Destination) : PostGoogleBigQuery => {
  const object = {
    name: destination.name,
    type: destination.destination.getDestinationIdentifier(),
    ...omit(destination.options, ['type'])
  }
  return object;
}

const CreateDestination = () => {
  const {t} = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const mutate = usePostDestinationMutation();

  const navigate = useNavigate();
  const track = useSegmentTrack();
  const onSubmit = async (data: Destination) => {
    track("Trying to save a destination");
    setSubmitting(true);
    try {
      await mutate.mutateAsync(postFactory(data));
      NotificationManager.success(t('Your destination has been added.'), t('You can now use this destination in connection tab.'));
      navigate(routing.user.destination);
    }catch (e){
      NotificationManager.error(t('Unable to create the destination.'), t('An error occurred when we try to add your destination.'));
    }finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full py-4 flex justify-center">
      <DestinationForm mode="CREATE" onSubmit={onSubmit} submitting={submitting}/>
    </div>
  );
};

export default CreateDestination;
