import API from "./client";
import {PostRequest} from "../../models/form-interface";
import Ajv from "ajv";
import {PostValidationError, SerializeValidationError} from "../../../errors/models/validation-error";

import Model from "../../models/model";
import {AxiosResponse} from "axios";
import Pagination from "../../models/http/paginated";
import DeleteSerializer from "../../models/http/delete";

const handler = {
    get: function (target: any, prop: any, receiver: any) {
        if (typeof target[prop] === 'function' && prop.indexOf('_') !== -1) {
            return new Proxy(target[prop], {
                apply: async function (target: any, thisArg: API, argumentsList: any) {
                    const serializerList = thisArg.serializer();

                    let serializer: any = null;
                    if (serializerList[prop]) {
                        serializer = serializerList[prop];
                    } else {
                        serializer = serializerList.default;
                    }

                    const ajv = new Ajv();

                    if (prop === "_postOne" && (serializer as PostRequest<any>).getSchemaPostToValidate) {
                        // A post request exist, we need to validate the data.
                        const validate = ajv.compile((serializer as PostRequest<any>).getSchemaPostToValidate());
                        const valid = validate(argumentsList[0]);
                        if (!valid && validate.errors && process.env.REACT_APP_ENV !== "production") {
                            throw new PostValidationError(validate.errors, serializer)
                        }
                    }

                    const data: AxiosResponse = await target.apply(thisArg, argumentsList);

                    if(serializer?.prototype?.constructor == DeleteSerializer){
                        return new DeleteSerializer();
                    }
                    let jsonSchemaToSerialize = new serializer().getSchemaToSerialize();
                    const validate = ajv.compile(jsonSchemaToSerialize);
                    if(data.data.data){
                        //This is a paginated response, we need to get the good one
                        return new Pagination(data.data.data.map((elem: any) => {
                            const valid = validate(elem);
                            if (!valid && validate.errors && process.env.REACT_APP_ENV !== "production") {
                                throw new SerializeValidationError(validate.errors, serializer);
                            }
                            return new serializer(elem);
                        }), data.data.pagination);
                    }else {
                        const valid = validate(data.data);
                        if (!valid && validate.errors && process.env.REACT_APP_ENV !== "production") {
                            throw new SerializeValidationError(validate.errors, serializer);
                        }

                        return new serializer(data.data);
                    }
                }
            });
        }else{
            return target[prop];
        }
    },
};

export default <T>(classToProxy: T): T => {
    return new Proxy(classToProxy, handler);
}