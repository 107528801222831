import React from 'react';
import Segment from "react-segment-analytics";
import Private from "../private";
import Public from "../public";
import Loading from "../loading";
import { useMe } from "../../hooks/user";
import JWT from "../../services/auth/jwt";
import config from "../../config";
import decode from "jwt-decode";

const Root = () => {
    let jwt = JWT.getToken();

    const current_time = (new Date()).getTime() / 1000;

    if(jwt !== null && (decode(jwt) as any).exp < current_time) {
        JWT.clearToken();
        jwt = null;
    }

    if(!jwt){
        return <Segment writeKey={config.segment_key}> <Public /> </Segment>
    }


    const { isLoading, isSuccess, data: user } = useMe();

    const isLoggedIn = isSuccess && user !== undefined

    if (isLoading) {
        return (
            <Loading />
        )
    }

    let userTrait = {};
    if(isLoggedIn){


        userTrait = {
            email: user.data.email,
            id: user.data.id,
            'Free trial end at': new Date(user.data.company.trialFinished),
            'Status': user.data.company.stripeSubscriptionId ? 'Payed' : 'Free',
            company: {
                name: user.data.company.name,
                id: user.data.company.id
            }
        };
    }

    return <Segment writeKey={config.segment_key} traits={{...userTrait}}> {isLoggedIn ? <Private />: <Public />}</Segment>
}

export default Root;
