import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { getCurrentUser, useMutatePasswordUser } from "../../../hooks/user";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import CurrentUser, { CurrentUserHTTP } from "../../../services/models/http/user/current-user";

type FormValue = {
  password: string,
  repeatPassword: string
}

const Validation = Yup.object().shape({
  password: Yup.string().min(3).required(),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], "The two password should be equals").min(3).required()
});


const ChangePassword = () => {
  const currentUser = getCurrentUser();
  const {t} = useTranslation();
  const mutate = useMutatePasswordUser();

  const form = useFormik<FormValue>({
    initialValues: {
      password: "",
      repeatPassword: ""
    },
    validationSchema: Validation,
    onSubmit: async (values) => {
      try {
        const data = {id: currentUser.getId(), plainPassword: values.password};
        const response = await mutate.mutateAsync(data);
        NotificationManager.success(t('Your password has been saved'), t('The system has updated your profile information.'));
      }catch (e){
        NotificationManager.error(t('Unable to save your new password'), t('There is an internal error when you try to save your profile information.'))
      }
    }
  })

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={form.handleSubmit}>
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Change Password')}</h2>
          <p className="mt-1 text-sm text-gray-500">
            {t('Change your password.')}
          </p>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              id="password"
              type="password"
              name="password"
              label={t('Password : ')}
              defaultValue={form.initialValues.password}
              value={form.values.password}
              onChange={form.handleChange}
              error={form.errors.password}
              touched={form.touched.password}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Input
              id="repeatPassword"
              type="password"
              name="repeatPassword"
              label={t('Repeat Password : ')}
              defaultValue={form.initialValues.repeatPassword}
              value={form.values.repeatPassword}
              onChange={form.handleChange}
              error={form.errors.repeatPassword}
              touched={form.touched.repeatPassword}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 py-4 flex justify-end sm:px-6">
        <Button
          loading={mutate.isLoading}
          type="primary-purple"
          htmlType="submit"
        >
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default ChangePassword;
