import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import _, { pickBy } from "lodash";
import { useGetAllNetworkAuthorization } from "../../../../hooks/network-authorization";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";
import Button from "../../../../ui/components/button/button";
import NetworkProvider from "../../../../config/network/network-provider";
import {
  DataStreamJob as HTTPDataStreamJob
} from "../../../../services/models/http/data-connector/data-stream/data-stream-job";

export type DataStreamJob = {
  authorizations: Array<{
    authorizationId: number,
    id: string,
  }>,
};


export const Validation = Yup.object().shape({
  authorizations: Yup.array().required("You need to set at least one accounts.")
});

type Props = {
  onSubmit: (data: Partial<DataStreamJob>) => void,
  dataStream: DataStreamModel,
  defaultValue?: Partial<HTTPDataStreamJob>,
  saving?: boolean
}
const StepTwo = ({ onSubmit, dataStream, defaultValue, saving }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const initialValues = {
    authorizations: []
  };
  const destination = useGetAllNetworkAuthorization();
  const optionsAccounts = useMemo(() => {
    console.log(destination);
    if (!destination.data) {
      return [];
    }
    const accounts = destination.data?.data
      ?.filter(
        (destinationItem) => destinationItem.getPlatform() === dataStream.getPlatform()
      )
      .reduce<{ value: string; label: string; authorization_id: number; }[]>((acc, curr) => acc.concat(
        curr.getAvailableDataSource().map((dataSource) => ({
          value: dataSource.accountId,
          label: dataSource.name,
          authorization_id: curr.getId()
        }))
      ), []);
    return _.uniqBy(accounts, "value");
  }, [destination.data]);

  const formik = useFormik<DataStreamJob>({
    initialValues: defaultValue && defaultValue.sources ? {
      ...initialValues,
      authorizations: defaultValue.sources.map(elem => ({ id: elem.accountId, authorizationId: elem.authorization.id }))
    } : initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setError(null);
      try {
        onSubmit(values);
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    }
  });
  console.log(formik.values.authorizations, optionsAccounts);
  console.log(optionsAccounts.filter((account) =>
    formik.values.authorizations.some(
      (selected) => selected.id === account.value
    )
  ));
  return (
    <div>
      <div className="space-y-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3
                  className="text-lg leading-6 font-medium text-gray-900">{t("Put the accounts your want to work on.")}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("You could always add more accounts later.")}
                </p>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <label className="block text-sm font-medium text-gray-700">
                Select account for
                your <b>{`${NetworkProvider.getByPlatform(dataStream.getPlatform()).getName()}`}</b> data stream
              </label>
              <Select
                isLoading={destination.isLoading}
                isMulti
                menuPortalTarget={document.body}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "authorizations",
                    newValue.map((elem) => ({
                      id: elem.value,
                      authorizationId: elem.authorization_id
                    }))
                  );
                }}
                defaultValue={optionsAccounts.filter((account) =>
                  formik.values.authorizations.some(
                    (selected) => selected.id === account.value
                  )
                )}
                value={optionsAccounts.filter((account) =>
                  formik.values.authorizations.some(
                    (selected) => selected.id === account.value
                  )
                )}
                name="authorizations"
                options={optionsAccounts}
                classNamePrefix="react-select"
              />
            </div>
            <div className="py-6 px-4 flex justify-end items-center">
              {
                error ? <div className="text-red-500 mr-4">
                  {error}
                </div> : null
              }
              <div>
                <Button disabled={formik.values.authorizations.length === 0} loading={saving} type="primary"
                        htmlType="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepTwo;
