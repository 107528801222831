import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, ADOBE_ANALYTICS } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS } from "../../constant/constant";

class AdobeAnalytics extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Adobe Analytics";

  getNetworkIdentifier = (): NETWORK => ADOBE_ANALYTICS;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ANALYTICS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {

    }
  }
}

export default AdobeAnalytics;
