import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DataSource from "../../../services/models/http/datasource/data-source-client";
import { classNames } from "../../../services/helper";
import SettingsTab from '../settings/general';
import DeleteTab from "../settings/delete";

const SettingsFactory = ({type, dataSource } : {type: string, dataSource: DataSource}) => {
  switch (type) {
    case "general":
      return <SettingsTab dataSource={dataSource} />;
    case "remove":
      return <DeleteTab dataSource={dataSource} />;
    default:
      throw new Error(`Unable to found tab data for ${type}`)
  }
}
const Settings = ({ dataSource }: { dataSource: DataSource}) => {
  const {t} = useTranslation();

  const tabs = [
    {name: t('General'), key: "general"},
    {name: t('Remove'), key: "remove"},
  ]

  const [selectedTab, setSelectedTab] = useState("general");

  return (
    <main className="flex-1">
      <div className="relative mx-8 md:px-4 xl:px-0">
        <div className="pt-6 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900">{t('Settings')}</h1>
          </div>
          <div className="px-4 sm:px-6 md:px-0">
            <div className="py-6 pt-4">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <a
                      onClick={() => setSelectedTab(tab.key)}
                      key={tab.name}
                      className={classNames(
                        tab.key === selectedTab
                          ? 'border-purple-500 text-purple-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                      )}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="mt-10 divide-y divide-gray-200">
                <SettingsFactory type={selectedTab} dataSource={dataSource} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
};

export default Settings;
