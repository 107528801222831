import { Transition } from "@headlessui/react";
import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {listMap, NotificationManager} from "../notification/notification";
import {useTranslation} from "react-i18next";

let mountNumber = 1;

export interface ConfirmInterface {
    title: string|ReactElement,
    description: string|ReactElement,
    onValid: () => void,
    validButton: string,
    cancelButton: string,
    typeImg?: "success"|"danger"
}

export interface TypeConfirmInterface {
    img: ReactElement,
    buttonClass: string
};

const typeConfirm: {success: TypeConfirmInterface, danger: TypeConfirmInterface} = {
    success: {
        img: <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
            </svg>
        </div>,
        buttonClass: "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
    },
    danger: {
        img: <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full  bg-red-100">
            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
        </div>,
        buttonClass: "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
    }
};

const Confirm = ({title, description, validButton, cancelButton, onValid, typeImg}:  ConfirmInterface) => {
    const {t} = useTranslation();
    const [visible, setVisible] = useState<boolean>(true);

    const onValidWrapper = () => {
        setVisible(false)
        onValid();
    }
    const onCancel = () => setVisible(false)

    let img = null;
    let {buttonClass} = typeConfirm.success;

    if(typeImg !== undefined) {
        img = typeConfirm[typeImg].img;
        buttonClass = typeConfirm[typeImg].buttonClass;
    }

    return <Transition.Root show={visible}>
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                    aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                    className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                        {img}
                        <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                {title}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={onCancel}
                        >
                            {cancelButton}
                        </button>
                        <button type="button"
                                className={buttonClass}
                                onClick={onValidWrapper}
                        >
                            {validButton}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition.Root>
};

export const ConfirmManager = {
    confirm: ({title, description, validButton, cancelButton, onValid, typeImg}: ConfirmInterface) => {
        const event = new CustomEvent('confirm', {detail: <Confirm key={mountNumber} onValid={onValid} description={description} title={title} validButton={validButton} cancelButton={cancelButton} typeImg={typeImg} />})
        window.dispatchEvent(event);
        mountNumber++;
    }
};

export const ConfirmContainer = () => {
    const [elem, setElem] = useState<ReactElement|null>(null);
    useEffect(() => {
        window.addEventListener("confirm", (e: CustomEvent|Event) => {
            const eventNotification = e as CustomEvent;
            setElem(eventNotification.detail);
        });
    }, []);
    return <div id="confirm-container">
        {
            elem
        }
    </div>
}