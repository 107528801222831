import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, GOOGLE_SEARCH_CONSOLE } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS, SOCIAL } from "../../constant/constant";

class GoogleSearchConsole extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Google Search Console";

  getNetworkIdentifier = (): NETWORK => GOOGLE_SEARCH_CONSOLE;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ANALYTICS;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];

}

export default GoogleSearchConsole;
