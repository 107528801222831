import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next"
import { useFormik } from "formik";
import * as Yup from "yup";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import AccessibilityModel from "../../../../services/models/http/alert/accessibility";
import {
  Kpi as Model, kpiOperator, KPIOperator
} from "../helpers/sentry-template";
import { classNames } from "../../../../services/helper";
import Button from "../../../../ui/components/button/button";
import { pickBy } from "lodash";

export const Validation = Yup.object().shape({
  operator: Yup.string().required().oneOf(["not_null", "null", "equal", "not_equal"]),
  kpi: Yup.string().required(),
  value: Yup.string()
})


type FormField = {
  operator: KPIOperator,
  kpi: string,
  value: string
}


const Configuration = ({onChange, value, accessibility, initialValues} : {accessibility: AccessibilityModel, onChange: (metric: Model) => void, value: Model, initialValues: Model}) => {
  const {t} = useTranslation();
  const fields = Object.keys(accessibility.data.properties).filter(elem => elem !== "metrics");

  const form = useFormik<Partial<FormField>>({
    initialValues: {...{
      operator: "not_null",
      kpi: accessibility.data.properties[fields[0]].accessor,
      value: undefined
    }, ...pickBy(initialValues, v => v !== undefined)},
    validationSchema: Validation,
    onSubmit: (values) => {
      onChange({ ...values, type: "kpi" } as unknown as Model)
    }
  });

  const [opened, setOpened] = useState(true);

  async function handleSubmit(e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    const validateData = await form.validateForm();
    if (Object.keys(validateData).length === 0) {
      setOpened(false);
      form.handleSubmit();
    }
  }

  return (
    <div className="text-sm text-gray-600 rounded bg-white shadow" onKeyDown={async e => {
      if(e.key === "Enter"){
        await handleSubmit(e);
      }
    }}>
      <div className=" cursor-pointer hover:bg-gray-100 flex justify-between gap-2 items-center px-4 pt-4 pb-4 border-b border-gray-200 " onClick={async () => {
        if(opened){
          const validateData = await form.validateForm();
          if(Object.keys(validateData).length === 0){
            setOpened(!opened);
            form.handleSubmit();
          }
        }else {
          setOpened(!opened)
        }
      }} role="button">
        <h3 className="block">When <b>{t((Object.entries(accessibility.data.properties).find(elem => accessibility.data.properties[elem[0]].accessor === form.values.kpi) as any )[1].name)}</b> is <b>{t(form.values.operator as string)}</b> to <b>{t(form.values.value as string)}</b></h3>
        <ChevronDownIcon className="block h-8"/>
      </div>
      <Transition
        as={Fragment}
        show={opened}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="p-4">
          <div className="flex items-center gap-4 justify-between">
            <div className="w-full">
              <label htmlFor="kpi" className="block text-xs font-medium text-gray-700">
                {t('Fields :')}
              </label>
              <select
                id="kpi"
                name="kpi"
                value={form.values.kpi}
                onChange={e => form.setFieldValue('kpi', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {fields.map(elem => <option value={accessibility.data.properties[elem].accessor}>{t(accessibility.data.properties[elem].name)}</option>)}
              </select>
            </div>
            <div className="w-full">
              <label htmlFor="method" className="block text-xs font-medium text-gray-700">
                {t('Method :')}
              </label>
              <select
                id="method"
                name="method"
                value={form.values.operator}
                onChange={e => form.setFieldValue('operator', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {kpiOperator.map(elem => <option value={elem.value}>{elem.name}</option>)}
              </select>
            </div>
          </div>
          {form.values.operator === "equal" || form.values.operator === "not_equal" ?
            <div className="mt-4 text-sm text-gray-600 flex items-center gap-2 justify-between">
                <div className="w-full">
                  <label htmlFor="value" className="block text-xs font-medium text-gray-700">
                    {t('Value :')}
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      id="value"
                      name="value"
                      value={form.values.value}
                      onChange={e => form.setFieldValue('value', e.target.value)}
                      className={
                        classNames(
                  "border-gray-300 border mt-1 block w-full pl-3py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                           form.errors.value ? "pr-10" : ""
                        )
                      }
                    />
                    {form.errors.value ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>: null}
                  </div>
            </div>
          </div> : null}

          <div className="mt-4 w-full">
            <Button onClick={e => handleSubmit(e)} className="w-full text-center flex justify-center">{t('Add')}</Button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Configuration;
