import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import {NETWORK, HUBSPOT} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS, CRM } from "../../constant/constant";

class Pipedrive extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Hubspot";

  getNetworkIdentifier = (): NETWORK => HUBSPOT;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => CRM;

  alertOptions = () =>  [];

  isETLActivated(): boolean {
    return true;
  }
}

export default Pipedrive;
