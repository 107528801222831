import AggregationType from "./aggregation-type";
import {useTranslation} from "react-i18next";
import {allAggregation} from "./aggregation-type-enum";

class AggregationTypeProvider {
    getSpecificAggregation = (id: string|null): AggregationType => {
        const {t} = useTranslation();
        const aggregation = allAggregation.find(element => element.id === id);
        if(aggregation === undefined) {
            throw new Error(`The aggregation ${id} provided is not a valid Datastudio Type.`);
        }
        return new AggregationType(aggregation.id, t(aggregation.name), aggregation.description);
    }

    getAllAggregation = (): Array<AggregationType> => {
        const {t} = useTranslation();
        const response: Array<AggregationType> = [];
        allAggregation.forEach((element) => response.push(new AggregationType(element.id, t(element.name), element.description)))
        return response;
    }
}

export default new AggregationTypeProvider;