import API from "../../api/http/client";
import URLChecker from "../../models/http/alert/404-checker";
import Proxy from "../../api/http/proxy";

class URLCheckerAPI extends API{
  serializer = () => ({
    _getAll: URLChecker
  });
}

export default Proxy<URLCheckerAPI>(new URLCheckerAPI('/website/urls'))