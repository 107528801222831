import { classNames } from "../../../services/helper";

const Checkbox = ({ id, name, label, description, labelClassName, ...props }: {labelClassName?: string, id: string, name: string, label: string | Element, description?: string | Element }) => {
    return <div className="relative flex items-start">
        <div className="flex items-center h-5">
            <input
                aria-describedby={`${name}-description`}
                name={name}
                id={id}
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                {...props}
            />
        </div>
        <div className="ml-3 text-sm">
            <label htmlFor={name} className={classNames(labelClassName ||'' , "font-medium text-gray-700")}>
                {label}
            </label>
            {description ? <span id="comments-description" className="text-gray-500">
                {description}
              </span> : null}
        </div>
    </div>
}

export default Checkbox