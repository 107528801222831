import {JSONSchemaType} from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface CurrentUserHTTP {
    id: number,
    email: string,
    username: string,
    firstName?: string,
    lastName?: string,
    notAvailable: boolean
    company: {
        id: number,
        name: string,
        mainUserId: number,
        apiKey: string,
        activateAlert: boolean,
        trialFinished: string,
        networkAuthorizations: Array<string>
        stripeSubscriptionNumberDataSource?: number,
        userNumberDataSourceForLast30days?: number,
        stripeSubscriptionId?: string,
        stripeSubscriptionState?: string,
    }
    roles: string[]
}

class CurrentUser extends Model<CurrentUserHTTP> {

    getId(){
        return this.data.id;
    }

    getEmail(){
        return this.data.email
    }

    getFirstName(){
        return this.data.firstName
    }

    getLastName(){
        return this.data.lastName
    }

    isAvailable(){
        return this.data.notAvailable;
    }

    getCompany(){
        return this.data.company;
    }

    getRoles(){
        return this.data.roles;
    }

    getSchemaToSerialize = (): JSONSchemaType<CurrentUserHTTP> => ({
        type: "object",
        properties: {
            "id": { type: "number" },
            "email": { type: "string" },
            "username": { type: "string" },
            "notAvailable": { type: "boolean" },
            "roles": {
                type: "array",
                items: {
                    type: "string"
                }
            },
            "firstName": {
                type: "string",
                nullable: true
            },
            "lastName": {
                type: "string",
                nullable: true
            },
            "company": {
                type: "object",
                properties: {
                    id: {
                        type: "number"
                    },

                    "mainUserId": { type: "number" },
                    activateAlert: {
                        type: "boolean"
                    },
                    name: {
                        type: "string"
                    },
                    apiKey: {
                        type: "string"
                    },
                    trialFinished: {
                        type: "string"
                    },
                    stripeSubscriptionId: {
                        type: "string",
                        nullable: true
                    },
                    stripeSubscriptionState: {
                        type: "string",
                        nullable: true
                    },
                    networkAuthorizations: {
                        type: "array",
                        items: {
                            "type" : 'string'
                        },
                        nullable: true
                    },
                    userNumberDataSourceForLast30days: {
                        type: "number",
                        nullable: true
                    },
                    stripeSubscriptionNumberDataSource: {
                        type: "number",
                        nullable: true
                    }
                },
                required: ['id']
            },
        },
        required: ["id", "email", "username", "company"]
    });
}

export default CurrentUser;
