import React from "react";
import { Kpi, Metric, VisualizerData } from "../../editor/helpers/sentry-template";
import { useTranslation } from "react-i18next";
import { AlertBadge } from "../../components/basge";

const ConfigurationVisualizer =  ({variable, response} : {response: VisualizerData, variable: Kpi}) => {

  const {t} = useTranslation();
  const first = response[Object.keys(response)[0]];

  const headers = first.data[0].identifierName ? ['Identifier Name', variable.kpi] : [...Object.keys(first.data[0].identifications).filter(key => key !== 'requests'), variable.kpi];

  const rows: any[] = [];

  first.data.forEach(elem => {
    const subRow : any = {};

    if(elem.identifierName){
      subRow['Identifier Name'] = elem.identifierName
    }else {
      Object.keys(elem.identifications).filter(key => key !== 'requests').forEach(key => {
        subRow[key] = elem.identifications[key];
      })
    }

    subRow[elem.values[0].kpi] = elem.values[0].value;
    subRow.status = elem.status;
    rows.push(subRow);
  })

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                {headers.map(elem => <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {elem}
                </th>)}
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Status</span>
                </th>
              </tr>
              </thead>
              <tbody>
              {rows.map((row, personIdx) => (
                <tr key={personIdx} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  {headers.map(elem =>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row[elem]}</td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <AlertBadge status={row.status} />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationVisualizer;
