import { JSONSchemaType, SomeJSONSchema } from "ajv/dist/types/json-schema";

abstract class Model<T> {
    private _data;

    constructor(data: T) {
        this._data = data;
    }

    get data() {
        return this._data;
    }

    abstract getSchemaToSerialize() : JSONSchemaType<T> | SomeJSONSchema

    update(newData: Partial<T>){
        console.log("Update model with ", newData)
        this._data = { ...this._data, ...newData};
        console.log("Updated model", this._data)
        return this;
    }
}

export default Model;