import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { AMAZON_SELLER, NETWORK } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class AmazonSeller extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Amazon Seller";

  getNetworkIdentifier = (): NETWORK => AMAZON_SELLER;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  isETLActivated(): boolean {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  connectionMethod(args: any) : any {
    return args;
  }

  alertOptions = () =>  [];
}

export default AmazonSeller;