import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import "./i18n";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from "@sentry/react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { store, history } from "./stores/store";
import Root from "./features/root";
import { listMap, NotificationContainer } from "./ui/components/notification/notification";
import {ConfirmContainer} from "./ui/components/confirm/confirm";

Sentry.init({
  environment: process.env.REACT_APP_ENV !== "production" ? "development" : "production",
  release: process.env.REACT_APP_RELEASE_VERSION ?? "development",
  dsn: "https://99e01b378b32442fa5bb8a7d64b9f5e5@o344133.ingest.sentry.io/5316170",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.catchr\.io/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
var FallbackComponent = () => {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <Provider store={store}>
            <Router history={history}>
              <Root />
              <NotificationContainer list={listMap} />
              <ConfirmContainer />
            </Router>
        </Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
