import { useMutation, useQuery, useQueryClient } from "react-query";
import CurrentUserAPI from '../../services/repository/user/current-user';
import UserAPI from '../../services/repository/user/user';
import CurrentUser, { CurrentUserHTTP } from "../../services/models/http/user/current-user";
import Pagination from "../../services/models/http/paginated";
import UserModel from "../../services/models/http/user/user";
import RegisterFromGoogle, { LoginFromGoogle } from "../../services/models/http/user/register";
import RegisterAPI from "../../services/repository/user/register";
import CompanyModel from "../../services/models/http/company/company";
import CompanyAPI from "../../services/repository/company/company";
import DestinationAPI from "../../services/repository/data-connector/destination";

export const useMe = () => {
    return useQuery('user', () => CurrentUserAPI._getMe(), {
        retry: 0,
        staleTime: 1000 * 60 * 60 * 8
    })
}

export const useBillingSessionPortal = () => {
    return useQuery('billing-session', () => CurrentUserAPI.getBillingPortalSession(), {
        retry: 0,
        refetchInterval: 1000 * 60
    })
}
export const useGetAllUser = () => useQuery('users', () : Promise<Pagination<UserModel>> => UserAPI._getAll(), {
    retry: 0
})

export const useDeleteUser = () => useMutation((id:number)  => UserAPI._delete(id));

export const useGetAllUserAsAdmin =  (page: number, options = {}) => useQuery(['users', page, options], () : Promise<Pagination<UserModel>> => UserAPI.asAdmin()._getAll({query : {page, ...options}}), {
    retry: 0
})

export const getCurrentUser = ()  : CurrentUser => {
    const client = useQueryClient();
    return client.getQueryData('user') as CurrentUser;
}

export const getAllUser = ()  : Pagination<UserModel> => {
    const client = useQueryClient();
    return client.getQueryData('users') as Pagination<UserModel>;
}

export const useMutateUser = () => useMutation(({ id, ...data } : {id: number} & Partial<CurrentUserHTTP>) => CurrentUserAPI._put(id, data));
export const useMutatePasswordUser = () => useMutation(({ plainPassword, id } : {id: number; plainPassword: string}) => UserAPI.changePassword({ plainPassword, id }));

export const useUseAs = () => useMutation(({ id } : {id: number}) => UserAPI.useAs(id));

export const useCompanyByInvitedUser = (token: string) => useQuery(['user', token], () : Promise<UserModel> => UserAPI._getByToken(token));

export const useRegisterFromGoogleMutation = () => useMutation<RegisterFromGoogle, unknown, LoginFromGoogle>(data => RegisterAPI._registerFromGoogle(data));
