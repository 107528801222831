import React from "react";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import Container from "../../../ui/components/container/container";
import Modal, {DefaultBottom, Title} from "../../../ui/components/modal";
import {SUM} from "../aggregation-type/aggregation-type-enum";
import FieldForm, {FormField, FormFieldValidation} from "./form/field-form";
import Field, {PostField} from "../../../services/models/http/google-datastudio/field";
import {NotificationManager} from "../../../ui/components/notification/notification";
import Pagination from "../../../services/models/http/paginated";
import {usePostFieldGoogleDataStudioMutation} from "../../../hooks/google-datastudio";
import {NUMBER} from "../datastudio-type/datastudio-type-enum";

export const CreateField = ({visible, setVisible}: {visible: boolean, setVisible: (value: boolean) => void}) => {
    const queryClient = useQueryClient();
    const postGoogleDataStudio = usePostFieldGoogleDataStudioMutation();

    const initialValues = {
        id: undefined,
        companyId: undefined,
        platformName: undefined,
        platform: undefined,
        formula: undefined,
        defaultDescription: undefined,
        defaultName: undefined,
        description: undefined,
        aggregationType: SUM,
        fieldType: NUMBER,
        type: "metric",
        name: undefined,
        status: undefined
    };

    const {t} = useTranslation();

    const onClose = () => {
        setVisible(false);
    }

    const onSubmit = async (values: FormField) => {
        try {
            const response = await postGoogleDataStudio.mutateAsync(values as PostField);
            NotificationManager.success(t('Your field has been successfully created'), "")
            await queryClient.cancelQueries(['field_datastudio']);
            queryClient.setQueriesData(['field_datastudio'], (old) => (old as Pagination<Field>).pushElement(response))
            setVisible(false);
        } catch(error) {
            NotificationManager.error(t('Unable to add the field'), t('An error occurred. Please try again.'))
        }
    }

    const form = useFormik<FormField>({
        initialValues,
        validationSchema: FormFieldValidation,
        onSubmit
    });

    return <Container>
        <Modal
            open={visible}
            onClose={onClose}
            bottom={<DefaultBottom disabledOkButton={!(form.dirty && form.isValid)} loading={postGoogleDataStudio.isLoading} onClose={() => setVisible(false)} titleButtonOk={t('Save')} onOk={() => form.submitForm()} titleButtonClose={t('Close')} />}
        >
            <Title title={t('Create a field')} />
            <FieldForm form={form} />
        </Modal>
    </Container>
}

export default CreateField;