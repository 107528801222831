import React from "react";
import { useTranslation } from "react-i18next";
import {
  BellIcon,
  CreditCardIcon, CurrencyDollarIcon,
  DocumentDuplicateIcon,
  UserCircleIcon,
  UsersIcon,
  ViewGridAddIcon
} from "@heroicons/react/solid";
import { Route, Routes, useMatch, useResolvedPath } from "react-router-dom";
import { CustomLink } from "../profile";
import General from "./containers/general";
import { useCompany } from "../../hooks/company";
import { getCurrentUser, useBillingSessionPortal, useMe } from "../../hooks/user";
import Async from "../../ui/helper/async";
import Users from "./containers/users";
import AlertTemplateListing from "./containers/alert-template";
import Editor, { EditorById } from "../alerts/editor";
import { SentryRoutes } from "../../index";

const CompanySetting = () => {
  const {t} = useTranslation();
  const me = useMe();
  const billingPortal = useBillingSessionPortal();
  const subNavigation = [
    {name: t('Company'), key: "profile", link: "/company/settings", icon: UserCircleIcon},
    {name: t('Users'), key: "plans", link: "/company/settings/members", icon: UsersIcon},
    // {name: t('Plan & Billing'), key: "plans", link: "/company/settings/billings", icon: CreditCardIcon},
  ];

  if(billingPortal.data){
    subNavigation.push( {name: t('Billing'), key: "profile", link: billingPortal.data.data.url , icon: CurrencyDollarIcon})
  }
  const resolved = useResolvedPath("/company/settings/alerts/templates/create/:id");
  const match = useMatch({ path: resolved.pathname, end: true })


  const user = getCurrentUser();
  const company = useCompany(user.getCompany().id);
  return (
    <main className="relative -mt-32">
      <div className="mx-auto pb-6 lg:pb-16 ">
        <div className="bg-white rounded-lg shadow">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className={`${match ? 'lg:col-span-1' : 'lg:col-span-3'} py-6 `} >
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <CustomLink to={item.link} icon={item.icon}>{item.name}</CustomLink>
                ))}
              </nav>
            </aside>
            <Async {...company}>
              {company.data ? <SentryRoutes>
                <Route index element={<General company={company.data}/>}/>
                <Route path="members" element={<Users company={company.data}/>}/>
                <Route path="alerts/templates/create/:id" element={<div className="divide-y divide-gray-200 lg:col-span-11
                "><EditorById template /></div>}/>
                <Route path="alerts/templates" element={<AlertTemplateListing company={company.data}/>}/>
              </SentryRoutes> : <div>No company</div> }
            </Async>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CompanySetting;
