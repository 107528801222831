import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, MATOMO } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS, WEBSITE } from "../../constant/constant";

class Matomo extends BaseNetwork{
    connectingMethod = (): LinkMethod => APIKEY;

    getName = (): string => "Matomo";

    getNetworkIdentifier = (): NETWORK => MATOMO;

    isAlertActivated = (): boolean => false;

    isAvailableForAll = (): boolean => false;

    isDataStudioActivate = (): boolean => true;

    networkImage(): string {
        return networkImageFromURI(this.getNetworkIdentifier());
    }

    categoryType = (): string => ANALYTICS;

    isETLActivated(): boolean {
        return false;
    }

    alertOptions = () =>  [];


    connectionMethod(args: any): any {
        return {
            "url": args.url
        }
    }
}

export default Matomo;
