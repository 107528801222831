import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface Security200Response{
  ndd: string,
  accountId: string,
  platform: string,
  verifiedBy?: number,
  verifiedAt?: string
}

class Security extends Model<Security200Response> {

  public getNdd(){
    return this.data.ndd;
  }

  public getAccountId(){
    return this.data.accountId;
  }

  public getPlatform(){
    return this.data.platform;
  }

  public getVerifiedBy(){
    return this.data.verifiedBy;
  }

  public  getVerifiedAt(){
    return this.data.verifiedAt
  }

  getSchemaToSerialize = (): JSONSchemaType<Security200Response> => ({
    type: "object",
    required: ['ndd', 'accountId', 'platform'],
    properties: {
      ndd: {
        type: "string"
      },
      accountId: {
        type: "string"
      },
      platform: {
        type: "string"
      },
      verifiedBy: {
        type: "number",
        nullable: true
      },
      verifiedAt: {
        type: "string",
        nullable: true
      },
    }
  });
}

export default Security;